import * as React from 'react';
import ProductItem from '../../../../../shop-ui/components/product-item/ProductItem';
import { IStoreConfig } from '../../../../../shop-ui/components/search-page/interfaces';
import { IProductItem } from '../../../../../shop-ui/interfaces';
import classNames from 'classnames';
import {NUMBER_PRODUCT_NO_LAZY_LOAD} from 'PyzShopUi/scripts/shop-ui/constants';

interface IProductResultGridProps {
    products: IProductItem[];
    storeConfig: IStoreConfig;
    isCompactMode: boolean;
}

const ProductResultGrid: React.FunctionComponent<IProductResultGridProps> = props => {
    const { products, storeConfig, isCompactMode } = props;

    const gridItems = products.map((product, index) => {
        const sku = product.abstractSku;

        const name = product.abstractName;
        const brandName = product.brandName;
        const url = product.url;
        const images = product.images;
        const seoTitleSecondary = product.seoTitleSecondary ?? null;
        const customerPrice = product.prices.customer ?? null;
        const catalogPrice = product.prices.catalog ?? null;
        const merchantPrice = product.prices.merchant ?? null;
        const hasMultiplePrices = product.hasMultiplePrices ?? false;
        const basicPrice = product.basicPrice ?? null;
        const type = product.type;

        const attributes = product.attributes;
        const isNew = attributes.isNew ?? false;
        const ratingAverage = attributes.ratingAverage ?? 0;
        const isSale = attributes.isSale ?? false;
        const suitability = product.suitability ?? null;
        const catalogOrigin = attributes.catalogOrigin;

        const extraInformation = product.extraInformation;
        const clickTrackingParams = extraInformation?.clickTrackingParams ?? null;
        const gtm = extraInformation?.gtm ?? null;
        const useLazyLoad = index >= NUMBER_PRODUCT_NO_LAZY_LOAD;

        return (
            <div key={sku} className='cell'>
                <ProductItem
                    sku={sku}
                    name={name}
                    brandName={brandName}
                    url={url}
                    images={images}
                    isNew={isNew}
                    isSale={isSale}
                    suitability={suitability}
                    ratingAverage={ratingAverage}
                    hasMultiplePrices={hasMultiplePrices}
                    catalogPrice={catalogPrice}
                    customerPrice={customerPrice}
                    merchantPrice={merchantPrice}
                    basicPrice={basicPrice}
                    currency={storeConfig.currency}
                    currencyFactor={storeConfig.currencyFactor}
                    clickTrackingParams={clickTrackingParams}
                    gtm={gtm}
                    isCompactMode={isCompactMode}
                    type={type}
                    catalogOrigin={catalogOrigin}
                    useLazyLoad={useLazyLoad}
                    seoTitleSecondary={seoTitleSecondary}
                />
            </div>
        );
    });

    const className = classNames(
        'grid-x grid-padding-x small-up-2 product-result-grid',
        {
            'large-up-4': isCompactMode,
            'large-up-3': !isCompactMode
        },
    );

    return (
        <div className={className}>
            {gridItems}
        </div>
    );
};

export default ProductResultGrid;
