import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import {IListScrollToTopPayLoad} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import {ScrollEvent} from 'PyzShopUi/scripts/react/common/events/scrollEvent';

export const scrollToTopElement = (options?: IListScrollToTopPayLoad) => {
    const offset = options && options.offset || 0;
    let targetElement = document.querySelector('.catalog-search-page-client-init') ?? document.querySelector('.page-layout-search-items-result');
    const target = options && options.target || targetElement;
    const elementToScroll = options && options.elementToScroll || document.querySelector('body');

    let payload = {
        offset: offset,
        target: target,
        elementToScroll: elementToScroll
    };

    MiddlewareEventBus.getInstance().publish({
        type: ScrollEvent.SCROLL_TO,
        payload: payload,
    });
}

export const scrollToTopHelmetBuyerGuide = () => {
    scrollToTopElement({
        offset: 0,
        elementToScroll: null,
        target: document.querySelector('#spryker-helmet-buyer-guide')
    });
}
