class CmsStoreSpecialOpeningAccordion {
    private accordionContainer: HTMLElement;

    constructor(accordionElement: HTMLElement) {
        this.accordionContainer = accordionElement;
        this.addClassToFirstRenderedAccordion();
    }

    private addClassToFirstRenderedAccordion = (): void => {
        this.accordionContainer.querySelector('.accordion-icon').classList.add('is-active');
        const accordionContent: HTMLElement = this.accordionContainer.querySelector('.accordion-content');
        accordionContent.style.display = 'block';
    }
}

export default class CmsStoreSpecialOpeningAccordionHandler {
    public static init = (): void => {
        const firstAccordionElement = document.querySelector('.cms_store__special-openings-accordion');
        if (firstAccordionElement) {
            // tslint:disable-next-line:no-unused-expression
            new CmsStoreSpecialOpeningAccordion(firstAccordionElement as HTMLElement);
        }
    }
}
