import { fromEvent, Observable, empty, from, fromEventPattern } from 'rxjs';
import { filter, merge, mergeMap, map, distinct } from 'rxjs/operators';
import { CLICK_TRACKING_ATTRIBUTE } from './clickTracking.event';
import { IStoreInterface } from './payload';

export type IBaseHandler = (event: IBaseEvent) => void;

export interface IBaseEvent {
    readonly name: string;
}

/**
 * A list of known listeners with their names. You should have the same list as constants
 * in your php code in class \Application\Model\Event\ListenerType haveing the here and there as
 * constants helps not to make any writing issues.
 */
export enum LouisEventType {
    CLICK_EVENT_WITH_DATA_TOKEN = 'ClickEventWithDataToken',
    CLICK_EVENT = 'ClickEvent',
    ERROR = 'onError',
    CLICK_EVENT_WITH_DATA_TOKEN_BY_SELECTOR = 'onClickWithDataTokenBySelector',
    LOAD_PAGE_WITH_PRODUCT_LIST = 'onLoadPageWithProductLists',
    CLICK_EVENT_AFTER_CHANGE_AMOUNT = 'ClickEventAfterChangeAmount',
    LOAD_EVENT = 'LoadEvent',
    ONE_TIME_EVENT = 'OneTimeEvent',
    CLICK_TRACKING = 'ClickEventWithTracking',
}

export class ClickEventWithDataToken implements IBaseEvent {
    public readonly name = LouisEventType.CLICK_EVENT_WITH_DATA_TOKEN;
    constructor(public payload: HTMLElement) { }
}

export class ClickEventWithDataTokenBySelector implements IBaseEvent {
    public readonly name = LouisEventType.CLICK_EVENT_WITH_DATA_TOKEN;
    constructor(public payload: string) { }
}

export class ClickEvent implements IBaseEvent {
    public readonly name = LouisEventType.CLICK_EVENT;
    constructor(public payload: MouseEvent | Event) { }
}

export class ClickEventAfterChangeAmount implements IBaseEvent {
    public readonly name = LouisEventType.CLICK_EVENT_AFTER_CHANGE_AMOUNT;
    constructor(public payload: Element) { }
}
export class ProductListImpressionEvent implements IBaseEvent {
    public readonly name = LouisEventType.LOAD_PAGE_WITH_PRODUCT_LIST;
    constructor(public payload: Element) { }
}

export class LouisErrorEvent implements IBaseEvent {
    public readonly name = LouisEventType.ERROR;
    constructor(public payload: ErrorEvent) { }
}

export class ClickEventWithTracking implements IBaseEvent {
    public readonly name = LouisEventType.CLICK_TRACKING;
    constructor(public payload: HTMLElement, public href: string) { }
}

const getElementPath = el => {
    let path = el.nodeName + (el.className ? '.' + el.className : '[unknown]');
    let parent = el.parentNode;
    while (parent) {
        path = parent.nodeName + (parent.className ? '.' + parent.className : '[unknown]') + '/' + path;
        parent = parent.parentNode;
    }
    return path;
};

const clickedElements: { [id: string]: Element } = {};

/**
 * This listener expects an attribute called 'data-token' and passes an ClickEventWithDataToken, which holds that
 * token as payload, to a stream of events you can subscribe on.
 */
export function onClickWithDataToken(selector: string): Observable<ClickEventWithDataToken> {
    const element = document.querySelector(selector);
    const streamOfExisting = element
        ? fromEvent(element, 'click')
        : empty();

    return streamOfExisting.pipe(
        filter((event: MouseEvent) => {
            const target = event.currentTarget as HTMLElement;
            if (!target) {
                return false;
            }
            if (target.hasAttribute('data-token')) {
                return true;
            }
            const tokenNode = target.closest('[data-token]');
            return tokenNode && tokenNode.hasAttribute('data-token');
        }),
        map(event => {
            const target = event.currentTarget as HTMLElement;
            if (target.hasAttribute('data-token')) {
                return new ClickEventWithDataToken(target);
            }
            const tokenNode: HTMLElement = target.closest('[data-token]');

            return new ClickEventWithDataToken(tokenNode);
        })
    );
}

/**
 * This listener expects an attribute called 'data-token' and passes an ClickEventWithDataToken, which holds that
 * token as payload, to a stream of events you can subscribe on.
 */
export function onClickWithDataTokenBySelector(selector: string, store: IStoreInterface): Observable<ClickEventWithDataTokenBySelector> {
    if (!store.selectors.hasOwnProperty(selector)) {
        throw new Error('When registerung this event you should persist a token selectors combination');
    }
    const dataToken = store.selectors[selector];
    return onPureClick(selector).pipe(
        map(_event => new ClickEventWithDataTokenBySelector(dataToken))
    );
}

/**
 * A pure click is a registration on elements, that even do not exists in the moment we register the event.
 *
 * So we merge normal click events, based on the given selector with general click events, whrere filter for events on the given elment only.
 */
const onPureClick = (selector: string): Observable<MouseEvent | Event> => {
    const existingList = document.querySelectorAll(selector);
    const list: Element[] = [];
    existingList.forEach(item => {
        list.push(item);
    });
    const streamOfExisting = list.length ? from(list).pipe(mergeMap(element => fromEvent(element, 'click'))) : empty();

    const click = handler => document.addEventListener('click', handler);
    const streamOfNewOnes = fromEventPattern(click).pipe(
        distinct(),
        filter((event: MouseEvent) => {
            const element = event.target as HTMLElement;
            const closestBySelector: Element = element.closest(selector);
            if (closestBySelector && closestBySelector.nodeName === 'A') {
                if (clickedElements.hasOwnProperty(getElementPath(closestBySelector))) {
                    return false;
                }
                clickedElements[getElementPath(closestBySelector)] = closestBySelector;
                return true;
            }

            return false;
        })
    );

    return empty().pipe(merge(streamOfExisting, streamOfNewOnes));
};

/**
 * This listener simply passes all click events on a given selector in a flat stream you can subscribe on.
 *
 * @param selector string
 */
export function onClick(selector: string): Observable<ClickEvent> {
    return onPureClick(selector).pipe(
        map(event => new ClickEvent(event))
    );
}

/**
 * Pure listener as an rxjs wrapper around the error event to theose events in a stream also.
 */
export function onLoadPageWithProductLists(selector: string): Observable<ProductListImpressionEvent> {
    return fromEvent(window, 'load').pipe(
        filter(_event => null !== document.querySelector(selector)),
        map(_event => new ProductListImpressionEvent(document.querySelector(selector)))
    );
}

/**
 * Pure listener as an rxjs wrapper around the error event to get those events in a stream also.
 */
export function onError(): Observable<LouisErrorEvent> {
    return fromEvent(window, 'error').pipe(
        filter((event: ErrorEvent) => !event.message.includes('Script error')),
        map((event: ErrorEvent) =>  new LouisErrorEvent(event))
    );
}

/**
 * This listener expects an attribute called 'data-token' and passes an ClickEventWithDataToken, which holds that
 * token as payload, to a stream of events you can subscribe on.
 */
export function onClickWithTracking(element: Element): Observable<ClickEventWithTracking> {
    const streamOfExisting = element
        ? fromEvent(element, 'click')
        : empty();

    return streamOfExisting.pipe(
        filter((event: MouseEvent) => {
            const target = event.currentTarget as HTMLElement;

            if (!target) {
                return false;
            }

            if (target.hasAttribute(CLICK_TRACKING_ATTRIBUTE)) {
                return true;
            }

            const tokenNode = target.closest('[' + CLICK_TRACKING_ATTRIBUTE + ']');

            return tokenNode && tokenNode.hasAttribute(CLICK_TRACKING_ATTRIBUTE);
        }),
        map(event => {
            const target = event.currentTarget as HTMLElement;
            const href = target.hasAttribute('href') ? target.getAttribute('href') : '';
            if (target.hasAttribute(CLICK_TRACKING_ATTRIBUTE)) {
                return new ClickEventWithTracking(target, href);
            }

            const tokenNode: HTMLElement = target.closest('[' + CLICK_TRACKING_ATTRIBUTE + ']');

            return new ClickEventWithTracking(tokenNode, href);
        })
    );
}
