import { FILTER_LABEL_MAPPING, FilterNames } from 'PyzShopUi/scripts/catalog-page/constants';
import { IFilterStateHandler, ISelectFilterOption, ISelectFilter } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import AbstractSelectFilterHandler from 'PyzShopUi/scripts/shop-ui/includes/filter-state/AbstractSelectFilterHandler';
import i18next from 'i18next';

export default class OutletFilterHandler extends AbstractSelectFilterHandler implements IFilterStateHandler {
    public createQueryParams = (filter: ISelectFilter, params: object): void => {
        const selectedFilterOptionValues = filter.options
            .filter(option => option.selected)
            .map(selectedOption => selectedOption.value);

        params[FilterNames.OUTLET] = !selectedFilterOptionValues.length ? null : selectedFilterOptionValues[0];
    };

    public resetQueryParams = (params: object): void => {
        params[FilterNames.OUTLET] = null;
    }

    public getSelectedOptions = (filter: ISelectFilter): ISelectFilterOption[] => {
        const selectedOptionList = filter.options.filter(option => option.selected);

        if (!selectedOptionList.length) {
            return [];
        }

        return [
            {
                ...selectedOptionList[0],
                label: i18next.t(FILTER_LABEL_MAPPING[FilterNames.OUTLET], { ns: 'article-list'}).toLocaleUpperCase()
            }
        ];
    }

    public updateSelectedOptions = (filter: ISelectFilter, selectedValues: string[]): void => {
        filter.options = filter.options.map((option: ISelectFilterOption) => {
            option.selected = selectedValues.includes(option.value);

            return option;
        });
    }
}
