import { BREAKPOINTS } from '../config';
import AccordionHandler, { Accordion } from './accordion';
import Tabs from './tabs';
import EventBus from '../utils/eventBus';
import ITabOptions, { ITab } from '../interfaces/ITabOptions';
import { ETabEvent } from 'PyzShopUi/scripts/modules/events/tab.event';
import { EAccordionEvent } from 'PyzShopUi/scripts/modules/events/accordion.event';

enum TabsAccordionContainerStatus {
    TABS,
    ACCORDION,
}

class TabsAccordionContainer {

    private accordions: Accordion[] = [];
    private tabs: Tabs;
    private currentStatus: TabsAccordionContainerStatus;
    private eventBus: EventBus;
    private lastActivatedItem: HTMLElement;

    public constructor(container: HTMLElement, eventBus: EventBus, allowScrollUp: boolean) {
        const tabs: ITab[] = [];

        this.eventBus = eventBus;

        container.querySelectorAll(':scope > * > .accordion-header').forEach((accordionHeader: HTMLElement): void => {
            const content: HTMLElement = accordionHeader.nextElementSibling as HTMLElement;

            if (content.classList.contains('accordion-content')) {
                this.accordions.push(AccordionHandler.initSingle(accordionHeader, content, eventBus, allowScrollUp));
                tabs.push({
                    header: accordionHeader,
                    content,
                });
            }
        });

        const tabOptions: ITabOptions = {
            eventBus,
            tabs,
        };

        this.tabs = new Tabs(tabOptions);

        this.detachAttach();

        eventBus.subscribe('window:resize:debounced', this.detachAttach);

        eventBus.subscribe(EAccordionEvent.OPEN, (target: HTMLElement): void => {
            this.lastActivatedItem = target;
        });
        eventBus.subscribe(ETabEvent.ACTIVATED, (target: HTMLElement): void => {
            this.lastActivatedItem = target;
        });
    }

    private detachAttach = (): void => {

        if ((this.currentStatus === undefined
            || this.currentStatus === TabsAccordionContainerStatus.TABS)
            && BREAKPOINTS.smallOnly()
        ) {
            this.switchToAccordion();
        } else if ((this.currentStatus === undefined
                    || this.currentStatus === TabsAccordionContainerStatus.ACCORDION)
                    && BREAKPOINTS.minMedium()
        ) {
            this.switchToTabs();
        }
    }

    private switchToAccordion = (): void => {
        this.accordions.forEach((accordion: Accordion): void => {
            accordion.activate();
        });
        this.tabs.deactivate();
        this.currentStatus = TabsAccordionContainerStatus.ACCORDION;
        if (this.lastActivatedItem) {
            this.eventBus.publish(EAccordionEvent.OPEN, this.lastActivatedItem);
        }
    }

    private switchToTabs = (): void => {
        this.accordions.forEach((accordion: Accordion): void => {
            accordion.deactivate();
        });
        this.tabs.activate(this.lastActivatedItem);
        this.currentStatus = TabsAccordionContainerStatus.TABS;
    }
}

export default class TabsAccordionContainerHandler {

    public static init = (eventBus: EventBus, allowScrollUp: boolean = true): void => {
        document.querySelectorAll('.tab-accordion').forEach((tabAccordion: HTMLElement): void => {
            new TabsAccordionContainer(tabAccordion, eventBus, allowScrollUp);
        });
    }
}
