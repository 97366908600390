import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface ITagItemProps {
    label: string;
    dataQa?: string;
    onClick(): void;
}

const TagItem: React.FunctionComponent<ITagItemProps> = props => {
    const { label, onClick, dataQa } = props;

    return (
        <div 
            className="tag-item"
            onClick={onClick}
            onKeyDown={(e) => {
                if (e.key === "Enter") {
                    onClick()
                }
            }}
            role="button"
            tabIndex={0}
            data-qa={dataQa}
        >
            <span> {label} </span>
            <i className="icon icon-close" />
        </div>
    )
};

export default withMemo(TagItem);
