import FormUtils from '../../utils/formUtils';

export default class Button {
    private isProgressing: boolean;
    private readonly htmlElementButton: HTMLButtonElement;
    private readonly svgLoadingCircle: Element;
    private readonly icon: HTMLElement|null;

    constructor(button: HTMLButtonElement) {
        this.isProgressing = false;
        this.htmlElementButton = button;
        const placeholder = document.createElement('div');
        placeholder.innerHTML = '<svg class="loading-circle" version="1.1" width="512" height="512" viewBox="0 0 512 512"><path d="M75.157 74.805c-46.432 46.344-75.157 110.415-75.157 181.195v0c0 141.385 114.615 256 256 256v0c141.385 0 256-114.615 256-256v0c0-70.78-28.725-134.851-75.154-181.191l-0.004-0.004-33.942 33.941c37.746 37.658 61.099 89.73 61.099 147.255v0c0 114.875-93.125 208-208 208v0c-114.875 0-208-93.125-208-208v0c0.001-57.525 23.353-109.596 61.095-147.25l0.004-0.004-33.942-33.942z"></path></svg>';
        this.svgLoadingCircle = placeholder.firstElementChild;
        this.icon = this.htmlElementButton.querySelector('.icon');
    }

    public toggleButtonProgressing = (): void => {
        if (!this.isProgressing) {
            this.enableProgressing();
        } else {
            this.disableProgressing();
        }
    }

    public enableProgressing = (): void => {
        this.isProgressing = true;
        if (this.icon !== null) {
            this.icon.style.display = 'none';
        }

        this.htmlElementButton.append(this.svgLoadingCircle);
        this.htmlElementButton.classList.add('button-progressing');
    }

    public disableProgressing = (): void => {
        this.isProgressing = false;
        this.svgLoadingCircle.remove();
        this.htmlElementButton.classList.remove('button-progressing');

        if (this.icon !== null) {
            this.icon.style.display = '';
        }
    }

    public toggleDisable = (): void => {
        if (this.htmlElementButton.disabled) {
            FormUtils.enableButton(this.htmlElementButton);
        } else {
            FormUtils.disableButton(this.htmlElementButton);
        }
    }
}
