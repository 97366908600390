import { MDCDialog } from '@material/dialog';
import EventBus from '../utils/eventBus';
import { EDialogEvents } from './events/dialogEvent';
import { EVideoEvents } from './events/videoEvents';
import { EAudioEvents } from './events/audioEvents';
import IDialogOptions from './../interfaces/IDialogOptions';
import IEventBusSubscription from '../interfaces/Utils/IEventBusSubscription';
import ScrollUtils from '../utils/scrollUtils';

class Dialog {

    private mdcDialog: MDCDialog;
    private eventBus: EventBus;
    private dialogRemoveSubscription: IEventBusSubscription;
    private dialogOpenSubscription: IEventBusSubscription;
    private readonly dialogTriggerList: NodeListOf<HTMLElement>;
    private readonly dialogElement: HTMLElement;
    private readonly dialogContainer: HTMLElement | null;
    private currentPageOffset: number;

    constructor(dialogElement: HTMLElement, eventBus: EventBus, dialogContainer?: HTMLElement) {
        this.dialogTriggerList = document.querySelectorAll(`[data-target="${dialogElement.id}"]`);
        this.mdcDialog = new MDCDialog(dialogElement);

        if (dialogElement.getAttribute('data-disable-scrim')) {
            this.mdcDialog.scrimClickAction = '';
        }

        if (dialogElement.getAttribute('data-disable-esc')) {
            this.mdcDialog.escapeKeyAction = '';
        }

        this.eventBus = eventBus;
        this.dialogElement = dialogElement;
        this.dialogContainer = dialogContainer;

        this.addEventListener();
        this.dialogOpenOnLoad(dialogElement);
    }

    private addEventListener = (): void => {
        this.dialogTriggerList.forEach((dialogTrigger: HTMLElement): void => {
            dialogTrigger.addEventListener('click', (event: MouseEvent): void => {
                event.preventDefault();
                this.eventBus.publish(EDialogEvents.OPEN_DIALOG, { id: dialogTrigger.dataset.target });
            });

            dialogTrigger.addEventListener('keydown', (event: KeyboardEvent): void => {
                if (event.key === "Enter") {
                    event.preventDefault();
                    this.eventBus.publish(EDialogEvents.OPEN_DIALOG, { id: dialogTrigger.dataset.target });
                }
            });
        });

        this.dialogRemoveSubscription = this.eventBus.subscribe(EDialogEvents.CLEANUP, (target: { dialogElement: HTMLElement }): void => {
            if (target.dialogElement.id === this.dialogElement.id) {
                this.mdcDialog.destroy();
                this.dialogElement.remove();
                this.dialogTriggerList.forEach((dialogTrigger: HTMLElement): void => {
                    dialogTrigger.remove();
                });

                this.dialogOpenSubscription.unsubscribe();
                this.dialogRemoveSubscription.unsubscribe();
            }
        });

        this.dialogOpenSubscription = this.eventBus.subscribe(EDialogEvents.OPEN_DIALOG, (target: any): void => {
            if (target !== undefined && target.id === this.dialogElement.id) {
                if (this.dialogContainer && this.dialogElement.parentNode !== this.dialogContainer) {
                    this.dialogContainer.appendChild(this.dialogElement);
                }

                this.currentPageOffset = ScrollUtils.getViewportPositionY();
                this.mdcDialog.open();
            }
        });

        this.eventBus.subscribe(EDialogEvents.CLOSE_DIALOG, target => {
            if (target !== undefined && target.id === this.dialogElement.getAttribute('id')) {
                this.mdcDialog.close();
            }
        });

        this.mdcDialog.listen('MDCDialog:closed', () => {
            this.eventBus.publish(EDialogEvents.DIALOG_CLOSED, this.dialogElement);
            this.eventBus.publish(EVideoEvents.PAUSE, this.dialogElement);
            this.eventBus.publish(EAudioEvents.PAUSE, this.dialogElement);
            if (this.dialogElement.getAttribute('id') === 'add-to-cart-dialog') {
                const orderVariantButton = document.querySelector("#order-variant-button") as HTMLButtonElement;
                orderVariantButton.disabled = false;
            }
            document.querySelector('html').scrollTop = this.currentPageOffset;
        });

        this.mdcDialog.listen('MDCDialog:opened', () => {
            document.querySelectorAll('.mdc-dom-focus-sentinel').forEach((element: HTMLElement): void => {
                element.removeAttribute('aria-hidden');
                element.setAttribute('role', 'button');
                element.setAttribute('aria-label', 'Focus Trap');
            });
        });
    }

    private dialogOpenOnLoad = (dialogElement: HTMLElement): void => {
        const dialogOnload: string = dialogElement.getAttribute('data-open-onload');
        if (dialogOnload === 'true') {
            this.mdcDialog.open();
        }
    }
}

export default class DialogHandler {

    public static init = (eventBus: EventBus): void => {
        DialogHandler.globalEventBus = eventBus;

        DialogHandler.globalEventBus.subscribe(EDialogEvents.INIT, DialogHandler.initDialog);

        document.querySelectorAll('.mdc-dialog').forEach((dialogElement: HTMLElement): void => {
            eventBus.publish(EDialogEvents.CLEANUP, { dialogElement });
            eventBus.publish(EDialogEvents.INIT, { dialogElement });
        });
    }

    private static globalEventBus: EventBus;

    private static initDialog = (initialDialogs: IDialogOptions): void => {
        const dialogContainer: HTMLElement = document.getElementById('dialogs');

        new Dialog(initialDialogs.dialogElement, DialogHandler.globalEventBus, dialogContainer);
    }
}
