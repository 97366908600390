import { BREAKPOINTS } from '../config';
import { IMyBikeTogglePayload } from '../interfaces/IMyBikeTogglePayload';
import EventBus from '../utils/eventBus';
import ScrollUtils, { ScrollDirection } from '../utils/scrollUtils';
import IModuleOptions from '../interfaces/IModuleOptions';
import { EMyBikeEvents } from './events/myBikeEvent';
import { EFlyToCartEvents } from './events/flyToCartEvent';
import IEventBusSubscription from '../interfaces/Utils/IEventBusSubscription';
import IScrollingPublisherOptions from '../interfaces/IScrollingPublisherOptions';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';
import { IProductWishListEventPublishAllProductPayload } from 'PyzShopUi/scripts/product-wish-list/interfaces';
import { ProductWishListEvent } from 'PyzShopUi/scripts/product-wish-list/events';

export enum HeaderTranslateStatus {
    SHOWING = 1,
    HIDDEN = 2,
}

export class Header {
    public static CLASSES = {
        CONTAINER: '.header-container',
        SETTINGS: '.header-settings',
        LOGO: '.header-logo',
        LINKS: '.header-links',
        ICON_MY_BIKE: '.icon-mein-bike',
        ICON_MEMO: '.icon-like',
        SEARCH_FORM: '.header-search-container form',
    };

    public static ATTRIBUTES = {
        TRANSLATE_OFFSET_ATTRIBUTE: 'data-translate-offset',
    };

    public static THRESHOLDS = {
        DEFAULT: 1,
        TRANSFORM: 240,
    };

    private readonly headerContainer: HTMLElement;
    private readonly headerIconMyBike: HTMLElement;
    private readonly headerIconMemo: HTMLElement;
    private readonly headerSearchForm: HTMLElement;
    private myBikeCount: number;
    private wishListCount: number;
    private eventBus: EventBus;
    private translateStatus: HeaderTranslateStatus;
    private translateOffset: number;
    private isTranslateHeaderEnabled: boolean = true;
    private translateSubscription: IEventBusSubscription;

    constructor(container: HTMLElement, options: IModuleOptions) {
        this.headerContainer = container;
        this.headerIconMyBike = this.headerContainer.querySelector(Header.CLASSES.ICON_MY_BIKE);
        this.headerIconMemo = this.headerContainer.querySelector(Header.CLASSES.ICON_MEMO);
        this.headerSearchForm = this.headerContainer.querySelector(Header.CLASSES.SEARCH_FORM);
        this.myBikeCount = this.headerIconMyBike ? parseInt(this.headerIconMyBike.dataset?.counter) || 0 : 0;
        this.wishListCount = this.headerIconMemo ? parseInt(this.headerIconMemo.dataset?.counter) || 0 : 0;

        this.eventBus = options.eventBus;

        this.calculateMenuOffset();
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.eventBus.subscribe('window:resize:debounced', this.calculateMenuOffset);

        this.addTranslateHandler();

        this.eventBus.subscribe(EMyBikeEvents.MY_BIKE_TOGGLE, this.myBikeToggle);

        if (this.headerSearchForm) {
            this.headerSearchForm.addEventListener('submit', (event: Event): void => {
                const searchInput: HTMLInputElement = this.headerSearchForm.querySelector('input#search');

                searchInput.value = searchInput.value.trim();
            });
        }

        this.onFlyToCart();
        this.onWishListUpdate();

        if (this.headerContainer.querySelector('button[data-toggle="responsive-menu"]')) {
            this.headerContainer.querySelector('button[data-toggle="responsive-menu"]').addEventListener('click', (): void => {
                this.headerContainer.classList.toggle('drilldown-menu-open');
                if (this.headerContainer.classList.contains('drilldown-menu-open')) {
                    this.removeTranslateHandler();
                } else {
                    this.addTranslateHandler();
                }
            });
        }

        const menuDrilldown: HTMLElement = this.headerContainer.querySelector('.menu.drilldown');
        if (menuDrilldown) {
            menuDrilldown.addEventListener('open.zf.drilldown hide.zf.drilldown', (): void => {
                const eventOptions: IScrollingPublisherOptions = {
                    target: this.headerContainer,
                };

                this.eventBus.publish('scrolling:scrollTo', eventOptions);
            });
        }
    }

    private addTranslateHandler = (): void => {
        this.translateSubscription = this.eventBus.subscribe('window:scroll', (): void => {
            if (this.isTranslateHeaderEnabled) {
                this.translateHeader();
            }
        });
    }

    private removeTranslateHandler = (): void => {
        if (!this.translateSubscription) {
            return;
        }
        this.translateSubscription.unsubscribe();
    }

    private calculateMenuOffset = (): void => {
        if (BREAKPOINTS.medium()) {
            const headerSettings: HTMLElement = this.headerContainer.querySelector(Header.CLASSES.SETTINGS);
            const settingsHeight: number = headerSettings.offsetHeight;
            const headerLogo: HTMLElement = this.headerContainer.querySelector(Header.CLASSES.LOGO);
            const logoHeight: number = headerLogo.offsetHeight;
            const headerLinks: HTMLElement = this.headerContainer.querySelector(Header.CLASSES.LINKS);
            const linksHeight: number = headerLinks ? headerLinks.offsetHeight : 0;

            this.translateOffset = settingsHeight + Math.max(logoHeight, linksHeight);
            this.headerContainer.setAttribute(Header.ATTRIBUTES.TRANSLATE_OFFSET_ATTRIBUTE, (this.translateOffset).toString());
        } else {
            const headerSettings: HTMLElement = this.headerContainer.querySelector(Header.CLASSES.SETTINGS);
            this.translateOffset = headerSettings.offsetHeight + 8;
            this.headerContainer.setAttribute(Header.ATTRIBUTES.TRANSLATE_OFFSET_ATTRIBUTE, (this.translateOffset).toString());
        }
    }

    private translateHeader = (): void => {
        if (BREAKPOINTS.medium()) {
            this.translateHeaderSmallMedium();
        } else {
            this.translateHeaderLarge();
        }
    }

    private translateHeaderSmallMedium = (): void => {
        const direction: ScrollDirection = ScrollUtils.getScrollDirection();

        if (direction === ScrollDirection.UP
                && this.translateStatus !== HeaderTranslateStatus.SHOWING
                && ScrollUtils.getViewportPositionY() < Header.THRESHOLDS.DEFAULT) {
            this.translateToShowing();
        } else if (direction === ScrollDirection.DOWN
                && this.translateStatus !== HeaderTranslateStatus.HIDDEN
                && (ScrollUtils.getViewportPositionY() + 15) > this.translateOffset) {
            this.translateToHidden(this.translateOffset);
            this.translateStatus = HeaderTranslateStatus.HIDDEN;
            this.headerContainer.classList.add('small');
            this.headerContainer.style.transform = `translateY(-${this.translateOffset}px)`;
        }
    }

    private translateHeaderLarge = (): void => {
        const direction: ScrollDirection = ScrollUtils.getScrollDirection();

        if (direction === ScrollDirection.UP
                && this.translateStatus !== HeaderTranslateStatus.SHOWING
                && ScrollUtils.getViewportPositionY() < Header.THRESHOLDS.DEFAULT) {
            this.translateToShowing();
        } else if (direction === ScrollDirection.DOWN
                && this.translateStatus !== HeaderTranslateStatus.HIDDEN
                && ScrollUtils.getViewportPositionY() > Header.THRESHOLDS.TRANSFORM) {
            this.translateToHidden(this.translateOffset);
        }
    }

    private translateToShowing = (): void => {
        this.headerContainer.classList.remove('small');
        this.headerContainer.style.transform = 'translateY(0)';
        this.translateStatus = HeaderTranslateStatus.SHOWING;
    }

    private translateToHidden = (offset: number): void => {
        this.headerContainer.classList.add('small');
        this.headerContainer.style.transform = `translateY(-${offset}px)`;
        this.translateStatus = HeaderTranslateStatus.HIDDEN;
    }

    private myBikeToggle = (payload: IMyBikeTogglePayload): void => {
        if (true === payload.status) {
            this.myBikeCount++;
            this.headerIconMyBike.classList.add('highlight', 'counter');
        } else {
            this.myBikeCount--;

            if (this.myBikeCount <= 0) {
                this.myBikeCount = 0;
                this.headerIconMyBike.classList.remove('highlight', 'counter');
            }
        }

        this.headerIconMyBike.setAttribute('data-counter', (this.myBikeCount.toString()));
    }

    private onWishListUpdate = (): void => {
        MiddlewareEventBus.getInstance().subscribe(
            (event: IReactEvent<IProductWishListEventPublishAllProductPayload>) => {
                if (event.type !== ProductWishListEvent.PUBLISH_ALL_PRODUCT) {
                    return;
                }

                const { abstractProductWishLists, concreteProductWishLists } = event.payload;

                this.wishListCount = abstractProductWishLists.length + concreteProductWishLists.length;
                if (this.wishListCount > 0) {
                    this.headerIconMemo.classList.add('highlight', 'counter');
                } else {
                    this.headerIconMemo.classList.remove('highlight', 'counter');
                }

                this.headerIconMemo.setAttribute('data-counter', (this.wishListCount).toString());
            }
        );
    }

    private onFlyToCart = (): void => {
        let beforeFlyToCartTranslateStatus: HeaderTranslateStatus;
        let beforeFlyToCartScrollPosition: number;

        this.eventBus.subscribe(EFlyToCartEvents.BEGIN, (event): void => {
            if (BREAKPOINTS.medium() && event.isCartFromHeader) {
                beforeFlyToCartScrollPosition = document.documentElement.scrollTop;
                beforeFlyToCartTranslateStatus = this.translateStatus;
                this.isTranslateHeaderEnabled = false;

                if (this.translateStatus === HeaderTranslateStatus.SHOWING) {
                    return;
                }

                this.translateToShowing();
            }
        });

        this.eventBus.subscribe(EFlyToCartEvents.COMPLETED, (event): void => {
            if (BREAKPOINTS.medium() && event.isCartFromHeader) {
                const afterFlyToCartScrollPosition = document.documentElement.scrollTop;
                if (afterFlyToCartScrollPosition >= beforeFlyToCartScrollPosition
                    && beforeFlyToCartTranslateStatus === HeaderTranslateStatus.HIDDEN) {
                    this.translateToHidden(this.translateOffset);
                }

                this.isTranslateHeaderEnabled = true;
            }
        });
    }
}

export default class HeaderHandler {

    public static initHeader = (eventBus: EventBus): void => {
        const container: HTMLElement = document.querySelector(Header.CLASSES.CONTAINER);

        if (container) {
            const additionalOptions: IModuleOptions = {
                eventBus,
            };

            new Header(container, additionalOptions);
        }
    }
}
