import * as React from 'react';
import AbstractFilterHandler from './AbstractFilterHandler';
import { IRangeFilter } from '../../components/search-page/interfaces';
import SelectedRangeFilterLabel from '../../components/search-page/components/sidebar/SelectedRangeFilterLabel';

export default abstract class AbstractRangeFilterHandler extends AbstractFilterHandler {
    public getSelectionTagComponent = (filter: IRangeFilter): React.FunctionComponent | null => {
        const roundedMax = Math.ceil(filter.max / 100);
        const roundedMin = Math.floor(filter.min / 100);
        const roundedActiveMax = Math.ceil(filter.activeMax / 100);
        const roundedActiveMin = Math.floor(filter.activeMin / 100);

        if (roundedActiveMax === roundedMax && roundedActiveMin === roundedMin) {
            return null;
        }

        return SelectedRangeFilterLabel;
    }

    public isFilterActive = (filter: IRangeFilter): boolean => filter.activeMax < filter.max || filter.activeMin > filter.min;
}
