import { isTrackingConsentGiven, setFixedDefaultConsentState, GoogleConsentStateValue, isConsentModeUsed, setFixedUpdateConsentState } from 'PyzShopUi/scripts/shop-ui/services/GoogleConsentModeService';
import CookieUtils from 'PyzShopUi/scripts/utils/cookieUtils';

interface IGtmEvent {
    event: string;
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
}

const GTM_CLICK_EVENTS_SELECTOR = '#spryker-gtm-script-click';
const GTM_ID_NODE_ID = 'gtmId';
const DATA_EVENTS = 'data-events';
const DATA_LABEL = 'data-label';

// the name of the cookie that determines if the user accepted cookies via the cookie notice
const ACCEPTANCE_COOKIE_NAME = 'cookie-notice-accepted';
const ACCEPTANCE_COOKIE_ACCEPTED_VALUE = '1';
const ACCEPTANCE_COOKIE_DENIED_VALUE = '0';
const ACCEPTANCE_COOKIE_DEFAULT_EXPIRATION_DAYS = 30;

// nodes with this id are meant to opt the user out on click
const OPT_OUT_NODE_ID = 'cookie-notice-optout';
const RESET_NODE_ID = 'cookie-notice-reset';

const addResetListener = () => {
    const resetNode = document.getElementById(RESET_NODE_ID);

    if (!resetNode) {
        return;
    }

    resetNode.addEventListener('click', (): void => {
        CookieUtils.removeCookie(ACCEPTANCE_COOKIE_NAME);

        location.reload();
    })
}

const addOptOutListener = () => {
    const optOutNode = document.getElementById(OPT_OUT_NODE_ID);

    if(!optOutNode) {
        return;
    }

    optOutNode.addEventListener('click', (): void => {
        setCookieAcceptedValue(false);

        if (!isConsentModeUsed()) {
            // this reload is necessary because there is no other convinient way to "stop" GTM in
            // non-consent mode
            window.location.reload();
            
            return;
        }
        
        setFixedUpdateConsentState('denied');
    });
}

const addEventListenerForClicks = (): void => {
    const gtmClickElement: HTMLElement = document.querySelector(GTM_CLICK_EVENTS_SELECTOR)

    if (!gtmClickElement) {
        return;
    }

    const dataEvents = gtmClickElement.getAttribute(DATA_EVENTS) || '';
    const clickEventsPerSelector = JSON.parse(dataEvents);

    for (const selector in clickEventsPerSelector) {
        const gtmEvents: IGtmEvent[] = clickEventsPerSelector[selector];
        const elements = document.querySelectorAll(selector);

        elements.forEach(element => {
            element.addEventListener('click', () => {
                gtmEvents.forEach(gtmEvent => {
                    gtmEvent.eventLabel = element.getAttribute(DATA_LABEL) || '';

                    if (!isTrackingConsentGiven()) {
                        return;
                    }

                    window.dataLayer.push(gtmEvent);
                });
            })
        });
    }
}

export const isCookieNoticeAccepted = () => {
    const cookieNoticeValue = CookieUtils.getCookieValue(ACCEPTANCE_COOKIE_NAME);

    return cookieNoticeValue === ACCEPTANCE_COOKIE_ACCEPTED_VALUE;
}

export const setCookieAcceptedValue = (accepted: boolean) => {
    const value = accepted ? ACCEPTANCE_COOKIE_ACCEPTED_VALUE : ACCEPTANCE_COOKIE_DENIED_VALUE;

    CookieUtils.setCookie(ACCEPTANCE_COOKIE_NAME, value, ACCEPTANCE_COOKIE_DEFAULT_EXPIRATION_DAYS, 'Lax');
}

const setupConsentMode = () => {
    // Define dataLayer and the gtag function.
    window.dataLayer = window.dataLayer || [];
    window.gtag = function gtag() { window.dataLayer.push(arguments); }

    const defaultStateValue: GoogleConsentStateValue = isCookieNoticeAccepted() ? 'granted' : 'denied';

    setFixedDefaultConsentState(defaultStateValue);
}

export const injectGtmScript = () => {
    const gtmIdNode = document.getElementById(GTM_ID_NODE_ID);

    if (!gtmIdNode) {
        // we can't throw an error here because this code will be bundled into the main js bundle
        // that gets loaded in NEOS as well. The problem is that the NEOS pages wont' include the GTM
        // id node
        console.error(`No node with ${GTM_ID_NODE_ID} found. GTM can't be loaded`);

        return;
    }

    const { gtmId } = gtmIdNode.dataset;


    // the following snippet loads the Google Tag Manager
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s) as HTMLScriptElement, dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', gtmId);
}

/**
 * This function will trigger all events that have been rendered server-side
 */
export const triggerSsrEvents = () => {
    window.renderOnLoadEvents && window.renderOnLoadEvents();
    window.renderCustomEvents && window.renderCustomEvents();
    addEventListenerForClicks();
}

export const setupGtm = () => {
    addOptOutListener();
    addResetListener();

    // if neither consent mode is used nor cookies are accepted, don't inject the GTM script tag
    if (!isConsentModeUsed() && !isCookieNoticeAccepted()) {
        return;
    }

    if (isConsentModeUsed()) {
        setupConsentMode();
    }

    injectGtmScript();

    if (isCookieNoticeAccepted()) {
       triggerSsrEvents();
    }
}

