(window as any).global = window;

import i18next from 'i18next';

export default function defNumberFormat(value: number): string {
    if (Intl.NumberFormat) {
        const numberFormat = new Intl.NumberFormat(i18next.language);
        if (typeof(numberFormat.format) === 'function') {
            return numberFormat.format(value);
        }
    }

    return value.toString();
}
