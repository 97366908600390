import * as React from 'react';
import {ICategorySearch} from '../../interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ICategoryResultItemProps {
    category: ICategorySearch
}

const CategoryResultItem: React.FunctionComponent<ICategoryResultItemProps> = props => {
    const {category} = props;
    const { translate } = useTranslationHook();

    return (
        <div className="category-result-item">
            <a className="mobile-link hide-for-medium" href={category.url}></a>
            <h2>{category.name}</h2>
            <p className="link">
                <a href={category.url}>
                    <span dangerouslySetInnerHTML={{ __html: translate('Go to {{title}};', { replace: { title: category.name }, ns: 'search'}) }} />
                </a>
            </p>
            <button className="next-button hide-for-medium">
                <i className="icon icon-chevron-thin-right icon-only icon-size-small" />
            </button>
        </div>
    );
};

export default CategoryResultItem;
