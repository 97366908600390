import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import LinkButton from '../../react/common/components/form/LinkButton';
import Tooltip from '../../react/common/components/Tooltip';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { BuyerGuideBannerTeaser } from './BuyerGuideBannerTeaser';

interface IHelmetBuyerGuideLandingState {
    categoryKey: string;
    helmetBuyerGuidePageLink: string;
}

const categoryKeysHavingHBGLanding = ['c-836', 'c-837', 'm-150', 'c-833', 'c-832'];

const HelmetBuyerGuideLandingComponent: React.FunctionComponent<IHelmetBuyerGuideLandingState> = props => {
    const { categoryKey, helmetBuyerGuidePageLink } = props;
    const { translate } = useTranslationHook();

    if (!categoryKeysHavingHBGLanding.includes(categoryKey)) {
        return null;
    }

    return (
        <BuyerGuideBannerTeaser additionalClassnames='buyer-guide-teaser--catalog-page' teaserInfoAdditionalClassNames='hbg-teaser__info__bg'>
            <h2>
                {translate(`The Louis helmet buyer's guide`, { ns: 'helmet-buyer-guide' })}

                <Tooltip
                    content={<p>{translate(`The unique helmet buyer's guide enables you...`, { ns: 'helmet-buyer-guide' })}</p>}
                    id='tooltip-hbg-landing-info'
                    isHoverEnabled={false}
                />
            </h2>

            <LinkButton
                additionalClass='button-bordered button-teaser'
                label={translate(`To helmet buyer's guide`, { ns: 'helmet-buyer-guide' })}
                icon='arrow'
                href={helmetBuyerGuidePageLink}
                iconPosition='right'
            />
        </BuyerGuideBannerTeaser>
    );
};

export const HelmetBuyerGuideLanding = withMemo(HelmetBuyerGuideLandingComponent);
