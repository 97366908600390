import { recommendedDomainService } from './services/RecommendDomainService';

class RecommendedDomain {
    private static DEFAULT = {
        COUNTRY_LIST: '.country-list',
        BUTTON_SHOW_ALL: '.button-show-all',
        SCROLL_SELECTION: '.scroll-selection',
        COUNTRY_LIST_TOP_GRADIENT: '.country-list__top-gradient',
        COUNTRY_LIST_BOTTOM_GRADIENT: '.country-list__bottom-gradient',
        COUNTRY_LIST_EXPANDED: 'country-list--expanded',
        HIDDEN: 'hidden',
        BUTTON_CLOSE: '[data-mdc-dialog-action="close"]',
        CHECKED_INPUT: 'input:checked',
    }

    private countryListElement: HTMLElement;
    private showAllButtonElement: HTMLElement;
    private scrollSelectionElement: HTMLElement;
    private countryListTopGradientElement: HTMLElement;
    private countryListBottomGradientElement: HTMLElement;
    private closeButtonElement: HTMLElement;

    constructor(dialogElement: HTMLElement) {
        this.countryListElement = dialogElement
            .querySelector(RecommendedDomain.DEFAULT.COUNTRY_LIST);
        this.showAllButtonElement = dialogElement
            .querySelector(RecommendedDomain.DEFAULT.BUTTON_SHOW_ALL);
        this.scrollSelectionElement = this.countryListElement
            .querySelector(RecommendedDomain.DEFAULT.SCROLL_SELECTION);
        this.countryListTopGradientElement = this.countryListElement
            .querySelector(RecommendedDomain.DEFAULT.COUNTRY_LIST_TOP_GRADIENT);
        this.countryListBottomGradientElement = this.countryListElement
            .querySelector(RecommendedDomain.DEFAULT.COUNTRY_LIST_BOTTOM_GRADIENT);
        this.closeButtonElement = dialogElement
            .querySelector(RecommendedDomain.DEFAULT.BUTTON_CLOSE);

        this.onClickShowAllButton();
        this.onScrollList();
        this.onClickCancelButton();
    }

    private onClickShowAllButton = () => {
        this.showAllButtonElement.addEventListener('click', () => {
            this.countryListElement
                .classList.add(RecommendedDomain.DEFAULT.COUNTRY_LIST_EXPANDED);
            this.showAllButtonElement
                .classList.add(RecommendedDomain.DEFAULT.HIDDEN);
            this.countryListTopGradientElement
                .classList.add(RecommendedDomain.DEFAULT.HIDDEN);
        })
    }

    private onScrollList = () => {
        this.scrollSelectionElement.addEventListener('scroll', () => {
            const scrollPosition = this.scrollSelectionElement.scrollTop;
            const containHeight = this.scrollSelectionElement.clientHeight;
            const maxScroll = this.scrollSelectionElement.scrollHeight;

            if (scrollPosition === 0) {
                this.countryListTopGradientElement
                    .classList.add(RecommendedDomain.DEFAULT.HIDDEN);
            } else {
                this.countryListTopGradientElement
                    .classList.remove(RecommendedDomain.DEFAULT.HIDDEN);
            }

            if (scrollPosition + containHeight >= maxScroll) {
                this.countryListBottomGradientElement
                    .classList.add(RecommendedDomain.DEFAULT.HIDDEN);
            } else {
                this.countryListBottomGradientElement
                    .classList.remove(RecommendedDomain.DEFAULT.HIDDEN);
            }
        })
    }

    private onClickCancelButton = () => {
        this.closeButtonElement.addEventListener('click', () => {
            const supportedDomains = JSON.parse(this.countryListElement.dataset.supportedDomains) || [];

            recommendedDomainService.disableRecommendedDomain(supportedDomains);
        })
    }
}

export default class RecommendedDomainHandler {
    public static init = (): void => {
        const dialogElement: HTMLElement = document.querySelector('#recommended-domain-dialog');

        if (!dialogElement) {
            return;
        }

        new RecommendedDomain(dialogElement);
    }
}
