import EventBus from '../utils/eventBus';
import IModuleOptions from '../interfaces/IModuleOptions';
import { HeaderTranslateStatus } from './header';
import { EMyBikeEvents } from './events/myBikeEvent';
import { EFlyToCartEvents } from './events/flyToCartEvent';
import { EWishListEvent } from './events/wishListEvent';
import { IMyBikeTogglePayload } from '../interfaces/IMyBikeTogglePayload';

class ArticleFlyToCart {
    public static DEFAULT = {
        FLY_TO_CART: 'fly-to-cart',
        WISH_LIST_CART: '.memo-cart',
        RUBBER_BAND: 'rubber-band',
        DATA_FLY_TO_CART: 'data-fly-to-cart',
        PAGE_HEADER: '#page-header',
        BIKE_CARD_CONTAINER: '.bike-card-container, .bike-container',
        BIKE_CART: '.bike-cart',
        SMALL: 'small',
        FLY_TO_CART_OBJECT: '[data-fly-to-cart-object="true"]',
        Z_INDEX_HEADER: '10000',
    };

    private eventBus: EventBus;
    private headerTranslateStatus: HeaderTranslateStatus;

    constructor(options: IModuleOptions) {
        this.eventBus = options.eventBus;

        this.onFlyToCartStarting();
    }

    private onFlyToCartStarting() {
        this.eventBus.subscribe(EWishListEvent.ARTICLE_ADDED_TO_WISH_LIST, event => {
            if (!event.imageElement) {
                return;
            }

            this.flyToWishListCart(event.imageElement);
        });

        this.eventBus.subscribe(EMyBikeEvents.MY_BIKE_TOGGLE, (event: IMyBikeTogglePayload) => {
            const bikeTriggerElement = event.bikeTriggerElement;

            if (!event.status || (bikeTriggerElement && bikeTriggerElement.getAttribute(ArticleFlyToCart.DEFAULT.DATA_FLY_TO_CART) !== 'true')) {
                return;
            }

            this.flyToMyBikeCart(bikeTriggerElement);
        });
    }

    private flyToWishListCart = (imageElement: HTMLElement) => {
        const cart: HTMLElement = document.querySelector(ArticleFlyToCart.DEFAULT.WISH_LIST_CART);

        this.flyToCartHandler(imageElement, cart);
    }

    private flyToMyBikeCart = (clickedElement: HTMLElement) => {
        const articleImage: HTMLElement = clickedElement.closest(
            ArticleFlyToCart.DEFAULT.BIKE_CARD_CONTAINER
        ).querySelector(ArticleFlyToCart.DEFAULT.FLY_TO_CART_OBJECT);

        if (!articleImage) {
            return;
        }

        const cart: HTMLElement = document.querySelector(ArticleFlyToCart.DEFAULT.BIKE_CART);

        this.flyToCartHandler(articleImage, cart);
    }

    private flyToCartHandler = (articleImage: HTMLElement, cart: HTMLElement) => {
        if (!cart || !articleImage) {
            return;
        }
        articleImage.style.position = 'relative';

        const cartPosition = cart.getBoundingClientRect();
        const articleImagePosition = articleImage.getBoundingClientRect();

        // getTranslateCoordinates() returns the distances (X, Y) between the source and the target
        // with the respective direction (-, +).
        const {distanceY,distanceX} = this.getTranslateCoordinates(articleImagePosition, cartPosition);

        // The animation duration is set to the distance times two so that the time of flight is proportional to the vertical position (Y)
        // of the source. Thus, the images at the bottom of the list do not fly too fast.
        // The min duration of the animation is set to 1000.
        const animationDuration = Math.max(Math.abs(distanceY)*2, 1000);

        const wrapperStyles = `
            position: fixed;
            z-index: ${ArticleFlyToCart.DEFAULT.Z_INDEX_HEADER};
            left: ${articleImagePosition.left}px;
            top: ${articleImagePosition.top}px;
            width: ${articleImagePosition.width}px;
        `;

        this.headerTranslateStatus = this.isTranslateHeaderHidden() ? HeaderTranslateStatus.HIDDEN : HeaderTranslateStatus.SHOWING;
        this.eventBus.publish(EFlyToCartEvents.BEGIN, true);

        const articleImageClone = <HTMLElement>articleImage.cloneNode(true);
        // remove id as a precaution to avoid possible duplicates
        articleImageClone.removeAttribute("id");

        const wrapper = document.createElement('div');
        wrapper.setAttribute("style", wrapperStyles);
        wrapper.appendChild(articleImageClone);
        document.documentElement.appendChild(wrapper);

        document.documentElement.style.setProperty('--distance-x', `${distanceX}px`);
        document.documentElement.style.setProperty('--distance-y', `${distanceY}px`);
        document.documentElement.style.setProperty('--animation-duration', `${animationDuration}ms`);

        articleImageClone.classList.add(ArticleFlyToCart.DEFAULT.FLY_TO_CART);

        // The flight is animated first, then the cart icon.
        articleImageClone.addEventListener('animationend', () => {
            // The flight animation class is removed as soon as the animation is finished.
            wrapper.remove();
            cart.classList.add(ArticleFlyToCart.DEFAULT.RUBBER_BAND);

            cart.addEventListener("animationend", () => {
                // The rubber band animation class is removed as soon as the animation is finished.
                cart.classList.remove(ArticleFlyToCart.DEFAULT.RUBBER_BAND);
                this.eventBus.publish(EFlyToCartEvents.COMPLETED, true);
            });
        });
    }

    private getTranslateCoordinates(source: DOMRect, target: DOMRect) {
        // Checking the position of the source and the target in relation to each other
        // to determine the direction of flight.
        const isTargetAboveSource = target.bottom < source.top;
        const isTargetRightFromSource = target.right > source.right;

        const distanceYAbs = Math.round(Math.abs(source.top - target.bottom));
        const distanceXAbs = Math.round(Math.abs(source.right - target.right));

        // the clone should travel distanceXAbs px to the right if target is right from source
        // and distanceXAbs px to the left (*-1) if target is left from source
        const distanceX = isTargetRightFromSource ? distanceXAbs : distanceXAbs*-1;

        // the clone should travel distanceXAbs px upwards (*-1) if target is above source
        // and distanceXAbs px downwards if target is below source
        const distanceY = isTargetAboveSource ? distanceYAbs*-1 : distanceYAbs;

        return {distanceX, distanceY};
    };

    private getHeaderElement = (): HTMLDivElement => document.querySelector(ArticleFlyToCart.DEFAULT.PAGE_HEADER);

    private isTranslateHeaderHidden = () => {
        if (!this.getHeaderElement()) {
            return false;
        }

        return this.getHeaderElement().classList.contains(ArticleFlyToCart.DEFAULT.SMALL);
    }
}

export default class ArticleFlyToCartHandler {
    public static init = (eventBus: EventBus) => {
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        new ArticleFlyToCart(additionalOptions);
    }
}
