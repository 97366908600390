import EventBus from './eventBus';
import { Header } from '../modules/header';
import IScrollToPublisherOptions from '../interfaces/IScrollingPublisherOptions';

export enum ScrollDirection {
    NOT_SCROLLED = 'not scrolled yet',
    UP = 'up',
    DOWN = 'down',
}

export default class ScrollUtils {
    public static DEFAULTS = {
        SCROLLING_TIME: 500
    };

    public static init = (eventBus: EventBus) => {
        ScrollUtils.alreadyScrolling = false;

        ScrollUtils.scrollHeight = document.documentElement.scrollHeight;

        eventBus.subscribe('scrolling:scrollTo', ScrollUtils.scrollTo);

        eventBus.subscribe('window:scroll', ScrollUtils.updateStaticVariables);
    }

    public static getScrollDirection = (): ScrollDirection => {
        if (!ScrollUtils.scrollDirection) {
            ScrollUtils.scrollDirection = ScrollDirection.NOT_SCROLLED;
        }
        return ScrollUtils.scrollDirection;
    }

    public static getScrollHeight = (): number => ScrollUtils.scrollHeight;

    public static getViewportPositionX = (): number => {
        if (!ScrollUtils.viewportPositionX) {
            ScrollUtils.viewportPositionX = 0;
        }
        return ScrollUtils.viewportPositionX;
    }

    public static getViewportPositionY = (): number => {
        if (!ScrollUtils.viewportPositionY) {
            ScrollUtils.viewportPositionY = 0;
        }
        return ScrollUtils.viewportPositionY;
    }

    private static alreadyScrolling: boolean;
    private static scrollDirection: ScrollDirection;
    private static scrollHeight: number;
    private static viewportPositionX: number;
    private static viewportPositionY: number;

    private static SELECTOR = {
        OFFSET_CONTAINER: '[data-scroll-to-offset="true"]',
    };

    private static mutateOffset = (offset: number, target: HTMLElement) => {
        const additionalOffsetContainer: HTMLElement = document.querySelector(ScrollUtils.SELECTOR.OFFSET_CONTAINER);

        if (additionalOffsetContainer) {
            if (target.offsetTop - offset > Header.THRESHOLDS.TRANSFORM) {
                const translateOffsetAttribute = additionalOffsetContainer.getAttribute(Header.ATTRIBUTES.TRANSLATE_OFFSET_ATTRIBUTE);
                if (translateOffsetAttribute) {
                    const translateOffset: number = parseInt(translateOffsetAttribute, 10);
                    offset -= translateOffset;
                }
            }
        }

        return offset;
    };

    private static scrollTo = (options: IScrollToPublisherOptions): void => {
        const target: HTMLElement = options.target;

        target.scrollIntoView({
            behavior: "smooth",
        });

        setTimeout(() => {
            target.focus();
        }, 300);

    }

    private static updateStaticVariables = (): void => {
        const newPosition = window.pageYOffset || document.documentElement.scrollTop;

        ScrollUtils.scrollDirection = (newPosition < ScrollUtils.viewportPositionY) ? ScrollDirection.UP : ScrollDirection.DOWN;
        ScrollUtils.scrollHeight = document.documentElement.scrollHeight;
        ScrollUtils.viewportPositionX = window.pageXOffset || document.documentElement.scrollLeft;
        ScrollUtils.viewportPositionY = newPosition;
    }

    public static scroll = (options: IScrollToPublisherOptions): void => {
        ScrollUtils.alreadyScrolling = false;
        ScrollUtils.scrollHeight = document.documentElement.scrollHeight;
        ScrollUtils.scrollTo(options);
    }
}
