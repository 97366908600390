import { FilterNames } from '../../constants';
import AbstractRangeFilterHandler from '../../../shop-ui/includes/filter-state/AbstractRangeFilterHandler';
import { IFilterStateHandler, ISelectFilterOption, IRangeFilter } from '../../../shop-ui/components/search-page/interfaces';

export default class YearOfConstructionFilterHandler extends AbstractRangeFilterHandler implements IFilterStateHandler {
    public createQueryParams = (filter: IRangeFilter, params: object): void => {
        if (filter.activeMax !== filter.max || filter.activeMin !== filter.min) {
            params[FilterNames.YEAROFCONSTRUCTION + '[min]'] = filter.activeMin.toString();
            params[FilterNames.YEAROFCONSTRUCTION + '[max]'] = filter.activeMax.toString();

            return;
        }

        this.resetQueryParams(params);
    }

    public resetQueryParams = (params: object): void => {
        params[FilterNames.YEAROFCONSTRUCTION + '[min]'] = null;
        params[FilterNames.YEAROFCONSTRUCTION + '[max]'] = null;
    }

    public getSelectedOptions = (_filter: IRangeFilter): ISelectFilterOption[] => []

    public updateSelectedOptions = (filter: IRangeFilter, selectedValues: number[]): void => {
        filter.activeMin = selectedValues[0] ?? filter.min;
        filter.activeMax = selectedValues[1] ?? filter.max;
    }
}
