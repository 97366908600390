import { defMelevenArticleImageUrl } from './melevenArticleImageUrl';
import IImageResolution from '../../interfaces/IImageResolution';

export default function defHandleImageSrcSet(imageSrc: string, defaultResolution: string, resolutions: IImageResolution[]) {
    const srcSet = [];
    resolutions.forEach(item => {
        srcSet.push(defMelevenArticleImageUrl(imageSrc, defaultResolution, item.resolution) + ' ' + item.descriptor);
    });

    return srcSet.join(', ');
}
