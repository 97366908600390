import * as React from 'react';
import { Switch } from '@mui/material';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { FILTER_LABEL_MAPPING, FilterNames, GenderFilterValues } from '../../../../constants';
import { ISelectFilterOption } from '../../../../../shop-ui/components/search-page/interfaces';
import CheckboxListItem from '../../../../../shop-ui/components/CheckboxListItem';

interface IGenderFilterOptionsProps {
    optionList: ISelectFilterOption[];

    onToggleItem?(value: string, isSelected: boolean): void;

    onToggleItems?(toggleOptions: ISelectFilterOption[]): void;
}

const GenderFilterOptionsComponent: React.FunctionComponent<IGenderFilterOptionsProps> = props => {
    const { translate } = useTranslationHook();

    const isMenOrWomenOptionValue = (optionValue: string) => (
        optionValue === GenderFilterValues.WOMEN || optionValue === GenderFilterValues.MEN
    );

    const willMenAndWomenAllUnselected = (optionValue: string) => {
        let selectedMenOrWomenOptions = props.optionList.filter(option => isMenOrWomenOptionValue(option.value) && option.selected);
        if (selectedMenOrWomenOptions.length === 0) {
            return false;
        }

        selectedMenOrWomenOptions = selectedMenOrWomenOptions.filter(option => option.value !== optionValue);
        return selectedMenOrWomenOptions.length <= 0;
    };

    const norMenOrWomenSelected = () => {
        const selectedMenOrWomenOptions = props.optionList.filter(option => isMenOrWomenOptionValue(option.value) && option.selected);
        if (selectedMenOrWomenOptions.length === 0) {
            return true;
        }

        return false;
    };

    const onToggleGenderOption = (option: ISelectFilterOption) => {
        let toggleOptions = [option];
        if (isMenOrWomenOptionValue(option.value)) {
            const unisexOption: ISelectFilterOption[] = props.optionList.filter(o => o.value === GenderFilterValues.UNISEX);
            if (unisexOption.length) {
                if (willMenAndWomenAllUnselected(option.value)) {
                    unisexOption[0].selected = true;
                    toggleOptions = toggleOptions.concat(unisexOption);
                } else {
                    if (!unisexOption[0].selected && !option.selected) {
                        unisexOption[0].selected = false;
                        toggleOptions = toggleOptions.concat(unisexOption);
                    }
                }
            }
        }

        props.onToggleItems(toggleOptions);
    };

    const onToggleUnisexOption = (option: ISelectFilterOption) => {
        if (norMenOrWomenSelected()) {
            return;
        } else {
            onToggleGenderOption(option);
        }
    };

    const displayItem = () => (
        props.optionList.filter(option => (option.value !== GenderFilterValues.UNISEX)).map(option => {
            const optionLabel = FILTER_LABEL_MAPPING[FilterNames.GENDER].hasOwnProperty(option.value) ? FILTER_LABEL_MAPPING[FilterNames.GENDER][option.value] : option.value;

            return (
                <CheckboxListItem
                    key={option.value}
                    dataQa={'gender-filter-item-'+ optionLabel.toLowerCase()}
                    label={translate(optionLabel, { ns: 'article-list-filter' })}
                    labelDataQa={'gender-filter-label-' + optionLabel.toLowerCase()}
                    value={option.value}
                    isSelected={option.selected}
                    additionalWrapperClassName='checkbox-menu__item'
                    onClick={() => onToggleGenderOption(option)}
                />
            )
        })
    );

    const displayUnisexSwitch = () => (
        props.optionList.filter(option => (option.value === GenderFilterValues.UNISEX)).map(option => (
            <li
                key={option.value}
                onClick={() => onToggleUnisexOption(option)}
                className={`checkbox-menu__toggle`}
                data-qa={'unisexSwitch'}
            >
                <label htmlFor={option.label + '_' + option.value}> {translate(FILTER_LABEL_MAPPING[FilterNames.GENDER][option.value], { ns: 'article-list-filter' })} </label>
                <Switch
                    id={option.label + '_' + option.value}
                    checked={option.selected}
                    disabled={norMenOrWomenSelected()}
                />
            </li>
        ))
    );

    return (
        <ul className={`checkbox-menu gender-filter`} data-qa={'gender-filters'}>
            {displayItem()}
            {displayUnisexSwitch()}
        </ul>
    );
};

GenderFilterOptionsComponent.defaultProps = {
    onToggleItem: () => ({}),
};

const GenderFilterOptions = withMemo(GenderFilterOptionsComponent);

export default GenderFilterOptions;