import * as React from 'react';
import { ISelectFilterOption } from '../../../../../shop-ui/components/search-page/interfaces';
import CheckboxListItem from '../../../../../shop-ui/components/CheckboxListItem';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IBikeTypeFilterOptionsProps {
    additionalClassName?: string;
    optionList: ISelectFilterOption[];
    isKeyboardFocusable?: boolean;

    onScrollChange?(event: React.UIEvent<HTMLUListElement, UIEvent>): void;

    onToggleItem?(value: string, isSelected: boolean): void;
}

const BikeTypeFilterOptionsComponent: React.FunctionComponent<IBikeTypeFilterOptionsProps> = props => {
    const { translate } = useTranslationHook();

    const displayItem = () => (
        props.optionList.map(option => (
            <CheckboxListItem
                key={option.value}
                label={translate(option.label, { ns: 'bike-db'})}
                value={option.value}
                isSelected={option.selected}
                additionalWrapperClassName='checkbox-menu__item'
                onClick={props.onToggleItem}
            />
        ))
    );

    return (
        <ul onScroll={props.onScrollChange} className={`checkbox-menu ${props.additionalClassName}`} {...(props.isKeyboardFocusable ? { 'tabIndex': 0 } : {})}>
            {displayItem()}
        </ul>
    );
};

BikeTypeFilterOptionsComponent.defaultProps = {
    additionalClassName: '',
    onScrollChange: () => ({}),
    onToggleItem: () => ({}),
};

const BikeTypeFilterOptions = withMemo(BikeTypeFilterOptionsComponent);

export default BikeTypeFilterOptions;
