import * as React from 'react';
import axios from 'axios';
import { History, Location } from 'history';
import FilterStateService from 'PyzShopUi/scripts/shop-ui/components/search-page/services/FilterStateService';
import { updateSearchPageData } from 'PyzShopUi/scripts/shop-ui/components/search-page/actions/searchPage';
import { IApiQueryData, ISearchResponse } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import HistoryHandler from 'PyzShopUi/scripts/react/common/lib/HistoryHandler';
import {
    createStringParamFromApiQueryData,
    getApiQueryDataFromLocation
} from 'PyzShopUi/scripts/shop-ui/includes/query-string-helper';

const PARAMS_WHITE_LIST_MATCHER = [/^utm_.+/] as const;

export interface ISearchPageService {
    getDefaultQueryObject(): IApiQueryData;

    setDefaultQueryObject(defaultQueryObject: IApiQueryData): void;

    setDispatch(dispatch: React.Dispatch<any>): void;

    getData(apiQueryData: IApiQueryData, requestId: number): Promise<ISearchResponse>;

    updateBrowserUrl(apiQueryData: IApiQueryData, newUrl?: string): void;

    setApiUrlSuffix(suffix: string): void;

    addDefaultBrowserQuery(key: string, value: string): void;
}

class SearchPageService implements ISearchPageService {
    private readonly originalApiUrl: string;
    private apiUrl: string;
    private history: History;
    private defaultQueryObject: IApiQueryData = {};
    private dispatch: React.Dispatch<any>;
    private defaultBrowserQuery: IApiQueryData = {};
    private readonly isSearchContext: boolean;

    constructor(
        apiUrl: string,
        filterStateService: FilterStateService,
        isSearchContext: boolean
    ) {
        this.originalApiUrl = apiUrl;
        this.apiUrl = apiUrl;
        this.isSearchContext = isSearchContext;

        this.history = HistoryHandler.create(
            {
                onBackHandler: (processLocation: Location) => {
                    this.dispatch(
                        updateSearchPageData(
                            getApiQueryDataFromLocation(processLocation),
                            filterStateService,
                            true
                        )
                    );
                },
            },
        );
    }

    public setDefaultQueryObject = (defaultQueryObject: IApiQueryData): void => {
        this.defaultQueryObject = defaultQueryObject;
    };

    public getDefaultQueryObject = (): IApiQueryData => this.defaultQueryObject;

    public setApiUrlSuffix = (suffix: string): void => {
        this.apiUrl = this.originalApiUrl + suffix;
    };

    public setDispatch(dispatch: React.Dispatch<any>) {
        this.dispatch = dispatch;
    }

    public getData = async(apiQueryData: IApiQueryData, requestId: number = 0): Promise<ISearchResponse> => {
        const mergedQueryData = {
            ...this.defaultQueryObject,
            ...apiQueryData,
        };

        const dataResponse = await axios.get(this.apiUrl, {
            params: mergedQueryData,
        });

        const data = this.isSearchContext ? dataResponse.data.result : dataResponse.data;

        return {
            ...data,
            requestId
        };
    };

    public updateBrowserUrl = (apiQueryData: IApiQueryData, newUrl?: string) => {
        // add existing params to default browser query if the key matches the white list
        // note that duplicate keys in the existing browser url and apiQueryData will have the value
        // that is defined within apiQueryData
        new URL(window.location.href).searchParams.forEach((value, key) => {
            if (PARAMS_WHITE_LIST_MATCHER.some(regex => regex.test(key))) {
                this.addDefaultBrowserQuery(key, value);
            }
        });

        const param = { ...this.defaultBrowserQuery, ...apiQueryData };

        this.history.push({
            pathname: newUrl || location.pathname,
            search: createStringParamFromApiQueryData(param),
        });
    };

    public addDefaultBrowserQuery(key: string, value: string) {
        this.defaultBrowserQuery[key] = value;
    }
}

let pageService: ISearchPageService;

export const getPageService = (
    apiUrl: string,
    filterStateService: FilterStateService,
    isSearchResult: boolean
): ISearchPageService => {
    if (pageService === undefined) {
        pageService = new SearchPageService(
            apiUrl,
            filterStateService,
            isSearchResult
        );
    }

    return pageService;
};
