import FootnoteManager from './FootnoteManager';

class FirstPageFootnoteHandler {
    public static init(): void {
        const footnoteManagerInstance = FootnoteManager.getInstance();

        footnoteManagerInstance.addFootnote(FootnoteManager.generalVat);
        footnoteManagerInstance.addFootnote(FootnoteManager.uvp);
    }
}

export default FirstPageFootnoteHandler;
