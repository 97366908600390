import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('paypal-buttons', () => importWithFallback(() =>
    import(
        /* webpackMode: "lazy" */
        /* webpackChunkName: "paypal-buttons" */
        './paypal-buttons'
    )),
);
