import * as React from 'react';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { getSelectedConcretes } from 'PyzShopUi/scripts/product-detail-page/includes/variant-helper/variantMap';
import { HeaderContainer } from 'PyzShopUi/scripts/product-detail-page/components/HeaderContainer';
import MdcSelect from 'PyzShopUi/scripts/shop-ui/components/select/MdcSelect';
import {IVariantOption} from 'PyzShopUi/scripts/product-detail-page/interfaces';

interface IMdcSelectSelectionProps {
    variantMap: object;
    variantNames: Map<string, IVariantOption>;
    activeConcreteId?: string;
    onSelectConcrete: (selectedConcrete: number) => void;
    onSelectFilter: (selectedConcretes: Set<number>) => void;
}

export const MdcSelectSelection: React.FunctionComponent<IMdcSelectSelectionProps> = props => {
    const {
        activeConcreteId,
        variantMap,
        variantNames,
        onSelectConcrete,
        onSelectFilter,
    } = props;

    const [selectedConcretes, setSelectedConcretes] = React.useState(new Set<number>());
    const [variantOptions, setVariantOptions] = React.useState([]);
    const [selectedConcrete, setSelectedConcrete] = React.useState('');
    const { translate } = useTranslationHook();


    React.useEffect(() => {
        if (activeConcreteId) {
            setSelectedConcrete(activeConcreteId);
        }

        const currentlySelectedConcretes: Set<number> = getSelectedConcretes([], variantMap);
        setSelectedConcretes(currentlySelectedConcretes);

        const variantOptions = [];
        variantNames.forEach(function (variant: IVariantOption, variantId: string) {
            if (!currentlySelectedConcretes.has(parseInt(variantId))) {
                return;
            }

            variantOptions.push({
                value: variantId,
                label: variant.optionSelectName,
                disabled: false
            });
        });

        variantOptions.unshift({ value: '', label: '', disabled: true });

        setVariantOptions(variantOptions);
    }, [activeConcreteId]);

    const getVariantName = (concreteId: string): string => {
        if (variantNames.has(concreteId)) {
            return variantNames.get(concreteId).optionSelectName;
        }

        return concreteId;
    };

    if (variantOptions.length <= 2) {
        return null;
    }

    const onSelectChange = (value: string) => {
        setSelectedConcrete(value);
        onSelectConcrete(parseInt(value, 10));
        onSelectFilter(selectedConcretes);
    };

    return (
        <HeaderContainer
            headingText={translate('Choose item version', { ns: 'article-detail' })}
            cssClass="variant"
            selectedValue={getVariantName(selectedConcrete)}>
            <MdcSelect
                key={selectedConcrete}
                options={variantOptions}
                selectedValue={selectedConcrete}
                floatingLabel={translate('Item version', { ns: 'article-detail' })}
                onChange={onSelectChange}
                fullWidth={true}
            />
        </HeaderContainer>
    );
};
