import CmsStoreUtils from '../utils/cmsStoreUtils';

class CmsStoreMap {
    private mapContainer: HTMLElement;
    private storeItems: NodeListOf<HTMLElement>;

    constructor(mapElement: HTMLElement) {
        this.mapContainer = mapElement;
        this.storeItems = document.querySelectorAll('.cms_store-teaser');

        // filter stores according to query parameters for state or location
        const urlParams = new URLSearchParams(window.location.search);
        const state = urlParams.get('s') && this.capitalizeFirstLetter(urlParams.get('s'));
        const location = urlParams.get('l') && this.capitalizeFirstLetter(urlParams.get('l'));

        if (state) {
            // color map accordingly
            this.mapContainer.querySelectorAll("g[id^='Highlight.']").forEach(area => {
                if(area.id.toLowerCase() === `Highlight.${state}`.toLowerCase()) {
                    area.querySelectorAll('.cls-1, .cls-2, .cls-3').forEach(cls => {
                        cls.classList.add('active');
                    });
                }
            });
            this.filterByState(state);
        }

        if (location) {
            CmsStoreUtils.showSearchTermIndicator(location);

            // the location from the query parameter is cleaned from special chars,
            // therefore we need to compare it to an also cleaned version of the location stored in the datasource
            const matchingStoreItems = [];
            this.storeItems.forEach(store => {
                if (store.dataset.location && location.toLowerCase() === CmsStoreUtils.cleanLocationName(store.dataset.location).toLowerCase()) {
                    matchingStoreItems.push(store);
                }
            });
            this.filterMatchingStores(matchingStoreItems);
        }

        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.mapContainer.addEventListener('click', e => {
            const target = e.target;

            // remove all active classes on the map, add new one for currently clicked
            CmsStoreUtils.resetMapHighlight();
            if (target instanceof Element) {
                target.classList.add('active');
            }

            // clear search field, search term, currently active items, location dropdown
            CmsStoreUtils.clearSearchInput();
            CmsStoreUtils.hideActiveStores();
            CmsStoreUtils.hideStoreDistances();
            CmsStoreUtils.resetLocationDropdown();

            document.querySelector('.cms_store__search-term').classList.add('hide');

            // set corresponding stores to active, update query parameter
            if (target instanceof Element) {
                const searchState = target.closest("[id^='Highlight.']");
                if (searchState) {
                    const searchStateName = searchState.id.substr(10);
                    this.filterByState(searchStateName);
                    CmsStoreUtils.setQueryParameter(`s=${searchStateName}`);
                }
            }

        });
    }

    private filterByState = (searchTerm: string) => {
        CmsStoreUtils.showSearchTermIndicator(searchTerm);

        const matchingStoreItems = [];
        this.storeItems.forEach(store => {
            if (store.dataset.state && searchTerm.toLowerCase() === store.dataset.state.toLowerCase()) {
                matchingStoreItems.push(store);
            }
        });

        this.filterMatchingStores(matchingStoreItems);
    }

    private filterMatchingStores = matchingStoreItems => {
        if (matchingStoreItems.length) {
            matchingStoreItems.forEach(store => {
                CmsStoreUtils.showStoreImage(store);
                CmsStoreUtils.setStoreToActive(store);
            });
        } else {
            CmsStoreUtils.showNoResultsInfoBox();
        }
    }

    private capitalizeFirstLetter = (string: string) =>
        string.charAt(0).toUpperCase() + string.slice(1);
}

export default class CmsStoreMapHandler {
    public static init = (): void => {
        document.querySelectorAll('.cms_store__map').forEach((mapElement): void => {
            new CmsStoreMap(mapElement as HTMLElement);
        });
    }
}
