import { Action, createBrowserHistory, History, Location } from 'history';

interface IHistoryHandlerOptions {
    onPushHandler?(location: Location): any;

    onReplaceHandler?(location: Location): any;

    onBackHandler?(location: Location): any;
}

export default class HistoryHandler {
    public static create(options: IHistoryHandlerOptions = {}): History {
        const history = createBrowserHistory();
        const defaultAction = () => null;
        const pushHandler = options.onPushHandler || defaultAction;
        const replaceHandler = options.onReplaceHandler || defaultAction;
        const backHandler = options.onBackHandler || defaultAction;

        history.listen(({action, location}) => {
            switch (action) {
                case Action.Pop:
                    backHandler(location);
                    break;

                case Action.Push:
                    pushHandler(location);
                    break;

                case Action.Replace:
                default:
                    replaceHandler(location);
                    break;
            }
        });

        return history;
    }
}
