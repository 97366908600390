import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';
import './address-validation-overlay.scss';

export default register('address-validation-overlay', () =>
    importWithFallback(
        /* webpackMode: "lazy" */
        () => import('./address-validation-overlay')
    ),
);
