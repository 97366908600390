import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { MyLouisOrdersTabBar } from './components/MyLouisOrdersTabBar';

export default class MyLouisOrdersHandler {
    public static init = (): void => {
        const myLouisOrdersTabBar: HTMLElement = document.querySelector('.mylouis-orders-tab-bar');
        if (!myLouisOrdersTabBar) {
            return;
        }

        const tabs = [];

        myLouisOrdersTabBar.querySelectorAll('a').forEach((tabElement: HTMLElement) => {
            tabs.push(
                {
                    label: tabElement.dataset.label,
                    value: tabElement.dataset.value,
                    element: document.getElementById(tabElement.dataset.contentId),
                }
            );
        });

        const loadingElement: HTMLElement = document.querySelector('.mylouis-orders-content .loading-animation');

        const myLouisOrdersTabBarRoot = createRoot(myLouisOrdersTabBar);

        myLouisOrdersTabBarRoot.render(
            <MyLouisOrdersTabBar tabs={tabs} loadingElement={loadingElement} />
        );
    }
}
