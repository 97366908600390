import { STATE_VIEW_MODE, VIEW_MODE_GRID, VIEW_MODE_LIST } from '../constants';
import { IViewModeSwitchPayload, setViewMode } from '../actions/viewModeSwitch';

function useViewModeSwitcher(dispatch: React.Dispatch<any>, callback?: any): [(event: any) => void, (event: any) => void] {
    const onViewModeSwitchToGrid = (_: MouseEvent): void => {
        const payload: IViewModeSwitchPayload = {
            [STATE_VIEW_MODE]: VIEW_MODE_GRID,
        };

        dispatch(setViewMode(payload));

        if (callback) {
            callback();
        }
    };

    const onViewModeSwitchToList = (_: MouseEvent): void => {
        const payload: IViewModeSwitchPayload = {
            [STATE_VIEW_MODE]: VIEW_MODE_LIST,
        };

        dispatch(setViewMode(payload));

        if (callback) {
            callback();
        }
    };

    return [onViewModeSwitchToGrid, onViewModeSwitchToList];
}

export default useViewModeSwitcher;
