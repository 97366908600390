import * as React from 'react';
import BrandItem from '../../../../../shop-ui/components/brand-item/BrandItem';
import { IStoreConfig } from '../../../../../shop-ui/components/search-page/interfaces';
import { IBrandItem } from '../../../../../shop-ui/interfaces';
import classNames from 'classnames';

interface IBrandResultGridProps {
    brands: IBrandItem[];
    storeConfig: IStoreConfig;
    isCompactMode: boolean;
}

const BrandResultGrid: React.FunctionComponent<IBrandResultGridProps> = props => {
    const { brands, isCompactMode } = props;

    const gridItems = brands.map(brand => {
        const name = brand.name;
        const html = brand.html;
        const url = brand.url;

        return (
            <div key={brand.name} className='cell'>
                <BrandItem
                    name={name}
                    html={html}
                    url={url}
                    isCompactMode={isCompactMode}
                />
            </div>
        );
    });

    const className = classNames(
        'grid-x grid-padding-x small-up-2 product-result-grid large-up-4'
    );

    return (
        <div className={className}>
            {gridItems}
        </div>
    );
};

export default BrandResultGrid;
