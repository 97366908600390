class TrustedShops {

    private trustedShopsContainer: HTMLElement;

    constructor(container: HTMLElement) {
        this.trustedShopsContainer = container;
        this.initTrustedShopsBadge();
        this.observeBadgeMounting();
    }

    private initTrustedShopsBadge = (): void => {
        const trustedShopsId: string = this.trustedShopsContainer.dataset.tsid;

        if (!trustedShopsId) {
            return;
        }

        /* eslint-disable-next-line */
        window['_tsConfig'] = {
            yOffset: '',
            variant: 'floating',
            customElementId: 'trusted-shops-container',
            trustcardDirection: '',
            customBadgeWidth: '',
            customBadgeHeight: '',
            disableResponsive: 'false',
            disableTrustbadge: 'false',
            responsive: {
                variant: 'custom_reviews',
                customElementId: 'trusted-shops-container',
            },
        };
        const scriptElem: HTMLScriptElement = document.createElement('script');
        scriptElem.src = `//widgets.trustedshops.com/js/${trustedShopsId}.js`;
        scriptElem.type = 'text/javascript';
        scriptElem.async = true;
        document.head.appendChild(scriptElem);
    }

    /*
    TODO: This is a temporary solution
    Temporary solution for fixing the accessibility for the TrustedShops. The trusted shops widget needs to be
    natively accessible in the future.
    We cannot hook to the TrustedShop js, so we listen for document changes and find out when it is mounted. After
    that we fix the HTML, so it can be accessible.
     */
    private observeBadgeMounting = (): void => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                    mutation.addedNodes.forEach((node) => {
                        if (node instanceof HTMLElement && node.id.startsWith('trustbadge-container')) {
                            setTimeout(() => {
                                //Add objects to the tab order
                                document.querySelectorAll('[data-testid="rating-star-yellow"],[data-testid="rating-star-gray"],[alt="e-trusted-black"],[data-testid="trustbadge-minimized-menu-button"] [alt="ellipsis"]')
                                    .forEach((element) => {
                                        element.setAttribute('tabindex', '0');
                                    });
                            }, 0);
                        }
                    });
                }
            });
        });

        observer.observe(document, {childList: true, subtree: true});
    };
}

export default class TrustedShopsHandler {

    public static init = (): void => {
        const trustedShopsContainer: HTMLElement = document.querySelector('#trusted-shops-container');

        if (trustedShopsContainer) {
            new TrustedShops(trustedShopsContainer);
        }
    }
}
