import { FilterNames } from '../../constants';
import { IFilterStateHandler, ICategoryFilter } from '../../../shop-ui/components/search-page/interfaces';
import AbstractFilterHandler from '../../../shop-ui/includes/filter-state/AbstractFilterHandler';

export default class CategoryFilterHandler extends AbstractFilterHandler implements IFilterStateHandler {
    public createQueryParams = (filter: ICategoryFilter, params: object): void => {
        params[FilterNames.CATEGORY] = filter.selectedValue || null;
    }

    public resetQueryParams = (params: object): void => {
        params[FilterNames.CATEGORY] = null;
    }

    public getSelectedOptions = (_: ICategoryFilter) => [];

    public updateSelectedOptions = (filter: ICategoryFilter, selectedValues: string[]): void => {
        filter.selectedValue = selectedValues[0];
    }

    public getSelectionTagComponent = (_: ICategoryFilter, __: number[]): React.FunctionComponent | null => null

    /* eslint-disable */
    public isFilterActive = (_filter: ICategoryFilter): boolean => {
        // always false because categories are handled differently
        // see implemented in SortFilterSidebar.tsx::isAnyFilterActive()
        return false;
    }
    /* eslint-enable */
}
