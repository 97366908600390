import EventBus from '../utils/eventBus';
import IModuleOptions from '../interfaces/IModuleOptions';
import { BREAKPOINTS } from '../config';

class RadioButtonOption {
    protected eventBus: EventBus;
    protected parent: HTMLElement;
    protected additionalForms: NodeListOf<HTMLElement>;

    public constructor(parent: HTMLElement, options: IModuleOptions) {
        this.eventBus = options.eventBus;
        this.parent = parent;
        this.additionalForms = parent.querySelectorAll(':scope .radio-additional-form');

        this.eventBus.subscribe('window:resize:debounced', this.resize);
        this.eventBus.subscribe('radioButtonOption:resize', this.resize);

        this.resize();
    }

    private resize = (): void => {
        const activeForms: HTMLElement[] = Array.from(this.additionalForms)
            .filter((item: HTMLElement): boolean => item.classList.contains('is-active'));

        if (activeForms.length === 0 || BREAKPOINTS.smallOnly()) {
            this.parent.style.minHeight = '0px';
            this.parent.style.height = 'auto';
            return;
        }

        this.parent.style.height = activeForms[0].offsetHeight + 'px';
        this.setParentMinHeightToSumOfChildrenHeights();
    }

    private setParentMinHeightToSumOfChildrenHeights = (): void => {
        let minHeight: number = 0;

        Array.from(this.parent.children).forEach((element: HTMLElement): void => {
            minHeight += element.offsetHeight;
        });
        this.parent.style.minHeight = minHeight + 'px';
    }
}

export default class RadioButtonOptionHandler {
    public static init = (eventBus: EventBus): void => {
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        document.querySelectorAll('.radioButtonOption').forEach((element: HTMLElement): void => {
            new RadioButtonOption(element, additionalOptions);
        });
    }
}
