import ProductVariantDataHandler from 'PyzShopUi/scripts/product-detail-page/includes/productVariantData';

export const redirectToConcrete = (concreteId: number) => {
    const productVariantData = ProductVariantDataHandler.init();
    const sku = productVariantData[concreteId]?.sku;

    const currentURL = new URL(window.location.href);
    currentURL.searchParams.set('filter_article_number', sku);

    // since the onChange handler is called on init, without this if clause
    // we'd end in an infinity loop of reloads
    if (window.location.href !== currentURL.href) {
        // we should replace the history in the url to deal with the issue of back button on mobile browser
        history.replaceState({ path: currentURL.toString() }, document.title, currentURL.toString());
        window.location.href = currentURL.href;
    }
}
