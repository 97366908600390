import { EventPayloadStore, IActionField, IProductPayload } from './payload';
import { ClickEvent, ClickEventAfterChangeAmount, ClickEventWithDataToken, IBaseHandler, LouisErrorEvent } from './listener';
import { getActiFieldListByWrapperElement, getProductPayloadByWrapperElement as getProductsPayloadByWrapperElement } from './helper';

const selectors: { [id: string]: string[] } = {
    EECcheckoutOption: ["input[name='payment[payment_type]']", "input[name='shipping_type_fieldset[shipping_type_selection]']"]
};

/**
 * A list of defined google event handlers. You should finde them also in your php code in
 * class \Application\Model\GoogleTagManager\DataLayerItem\Event to make no writting issues.
 */
export enum EECEventType {
    PRODUCT_CLICK = 'EECproductClick',
    PRODUCT_CLICK_WITH_DATA_TOKEN = 'EECproductClickWithDataToken',
    CHECKOUT_OPTION = 'EECcheckoutOption',
    CHANGE_CART_AMOUNT = 'EECchangeCartAmount',
    CHECKOUT_ADD_TO_CART = 'EECaddToCart',
    CHECKOUT_REMOVE_FROM_CART = 'EECremoveFromCart',
    ERROR = 'EEerror',
}

export enum Currency {
    EUR = 'EUR'
}

interface IEEerroerEvent {
    event: string;
    eventCategory: string;
    eventAction: string;
    eventLabel: string;
}

interface IEECEcommerceClickEvent {
    actionField: { list: string };
    products: IProductPayload[];
}

interface IEECEcommerceAddToCartEvent {
    actionField: { list: string };
    products: IProductPayload[];
}

interface IEECEcommerceRemoveFromCartEvent {
    actionField: { list: string };
    products: IProductPayload[];
}

interface IEECcheckoutOption {
    actionField: {
        step: number;
        option: string;
    };
}

interface IEECEcommerceEventEnvelope {
    event: EECEventType.CHECKOUT_OPTION
    | EECEventType.PRODUCT_CLICK
    | EECEventType.CHECKOUT_ADD_TO_CART
    | EECEventType.CHECKOUT_REMOVE_FROM_CART;
    ecommerce: {
        click?: IEECEcommerceClickEvent;
        add?: IEECEcommerceAddToCartEvent;
        checkout_option?: IEECcheckoutOption;
        remove?: IEECEcommerceRemoveFromCartEvent;
    };
}

/**
 * This google Event is send when a user clicks a Product in a list.
 *
 * When ever the user clicks on a product with an atached data-token, this event creates
 * the google event from the product data stored in our store and represented
 * through the token.
 */
const EECproductClickWithDataToken: IBaseHandler = (event: ClickEventWithDataToken) => {
    const eecEvent: IEECEcommerceEventEnvelope = {
        event: EECEventType.PRODUCT_CLICK,
        ecommerce: {
            click: {
                actionField: getActiFieldListByWrapperElement(event.payload),
                products: [
                    getProductsPayloadByWrapperElement(event.payload)
                ]
            }
        }
    };

    window.dataLayer.push(eecEvent);
};

const EECchangeCartAmount: IBaseHandler = (event: ClickEventAfterChangeAmount) => {
    if (!event.payload.hasAttribute('data-token')) {
        return;
    }
    const dataToken = event.payload.getAttribute('data-token');
    if (!EventPayloadStore.products.hasOwnProperty(dataToken)) {
        return;
    }

    if (event.payload.getElementsByTagName('input').length !== 1) {
        return;
    }
    const currentValue = parseInt(event.payload.getElementsByTagName('input').item(0).value, 10);

    let addToCard = true;
    const products = EventPayloadStore.products[dataToken]
        .filter(product => product.quantity !== currentValue)
        .map(product => {
            addToCard = currentValue - product.quantity >= 0;
            return { ...product, quantity: Math.abs(currentValue - product.quantity) };
        });
    if (products.length !== 1) {
        return;
    }

    const actionField: IActionField = getActiFieldListByWrapperElement(event.payload);
    let eecEvent = null;
    if (addToCard) {
        eecEvent = {
            event: EECEventType.CHECKOUT_ADD_TO_CART,
            ecommerce: {
                add: {
                    actionField,
                    products
                }
            }
        };
    } else {
        eecEvent = {
            event: EECEventType.CHECKOUT_REMOVE_FROM_CART,
            ecommerce: {
                remove: {
                    actionField,
                    products
                }
            }
        };
    }

    window.dataLayer.push(eecEvent);
};

/**
 * This event should send checkout option events when changing/selecting delivery/payment types in checkout process.
 * @todo: Under Construction
 */
const EECcheckoutOption: IBaseHandler = (_event: ClickEvent) => {
    if (!selectors.hasOwnProperty(EECEventType.CHECKOUT_OPTION)) {
        throw new Error('No selectors found');
    }

    selectors[EECEventType.CHECKOUT_OPTION].forEach(selector => {
        let option = '';
        document.querySelectorAll(selector).forEach((radio: HTMLInputElement) => {
            if (radio.checked) {
                option = radio.value;
            }
        });

        const eecEvent: IEECEcommerceEventEnvelope = {
            event: EECEventType.CHECKOUT_OPTION,
            ecommerce: {
                checkout_option: {
                    actionField: { step: 3, option }
                }
            }
        };

        window.dataLayer.push(eecEvent);
    });
};

/**
 * This event is fired, when ever a javascript error occurs.
 */
const EEerror: IBaseHandler = (event: LouisErrorEvent) => {
    const eecEvent: IEEerroerEvent = {
        event: 'gaEvent',
        eventLabel: event.payload.message,
        eventCategory: 'error',
        eventAction: '',
    };

    if (EventPayloadStore.pageData.pageType) {
        eecEvent.eventAction = EventPayloadStore.pageData.pageType;
    }

    window.dataLayer.push(eecEvent);

};

const eecHandler = {};

// collecting all handler here to make the available when matching listeners and handlers
eecHandler[EECEventType.PRODUCT_CLICK_WITH_DATA_TOKEN] = EECproductClickWithDataToken;
eecHandler[EECEventType.CHECKOUT_OPTION] = EECcheckoutOption;
eecHandler[EECEventType.CHANGE_CART_AMOUNT] = EECchangeCartAmount;
eecHandler[EECEventType.ERROR] = EEerror;

export const handler = eecHandler;
