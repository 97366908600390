import * as React from 'react';
import { ISelectFilterOption } from '../../../../../shop-ui/components/search-page/interfaces';
import CheckboxListItem from '../../../../../shop-ui/components/CheckboxListItem';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IManufacturerFilterOptionsProps {
    additionalClassName?: string;
    optionList: ISelectFilterOption[];
    isKeyboardFocusable?: boolean;

    onScrollChange?(event: React.UIEvent<HTMLUListElement, UIEvent>): void;

    onToggleItem?(value: string, isSelected: boolean): void;
}

const ManufacturerFilterOptionsComponent: React.FunctionComponent<IManufacturerFilterOptionsProps> = props => {
    const displayItem = () => (
        props.optionList.map(option => (
            <CheckboxListItem
                key={option.value}
                label={option.label}
                value={option.value}
                isSelected={option.selected}
                additionalWrapperClassName='checkbox-menu__item'
                labelDataQa='bike-manufacturer-name'
                onClick={props.onToggleItem}
            />
        ))
    );

    return (
        <ul onScroll={props.onScrollChange} className={`checkbox-menu ${props.additionalClassName}`} {...(props.isKeyboardFocusable ? { 'tabIndex': 0 } : {})}>
            {displayItem()}
        </ul>
    );
};

ManufacturerFilterOptionsComponent.defaultProps = {
    additionalClassName: '',
    onScrollChange: () => ({}),
    onToggleItem: () => ({}),
};

const ManufacturerFilterOptions = withMemo(ManufacturerFilterOptionsComponent);

export default ManufacturerFilterOptions;
