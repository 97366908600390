import * as React from 'react';
import { ISearchSuggestion, ISearchSuggestionItem } from '../../../interfaces';
import { SUGGESTION_SHOW_LINK_TEXT_MAPPINGS } from '../../../constants';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ISearchSuggestionDefaultLayoutProps {
    data: ISearchSuggestion;
    searchTerm: string;
    wrapperClasses?: string;
    itemClasses?: string;
}

const SuggestionItems: React.FunctionComponent<ISearchSuggestionDefaultLayoutProps> = props => {
    const { data, searchTerm, wrapperClasses, itemClasses } = props;
    const { translate } = useTranslationHook();

    if (!data || !data.items.length) {
        return null;
    }

    const displaySuggestionItems = data.items.map((item: ISearchSuggestionItem, index: number) => (
        <div key={index} className={`search-suggestions-item ${itemClasses}`}>
            <a href={item.url} title={item.name}>{item.name}</a>
        </div>
    ));

    const displayShowAllLink = React.useCallback(() => {
        if (data.total <= data.items.length) {
            return null;
        }

        const newUrl = new URL(data.link, window.location.origin);
        newUrl.searchParams.set('q', searchTerm);

        return (
            <a href={newUrl.toString()} className="search-suggestion-show-all-link">
                {translate(SUGGESTION_SHOW_LINK_TEXT_MAPPINGS[data.type], { ns: 'search'})}
            </a>
        );
    }, [data.total, data.items.length]);

    return (
        <div className={`search-suggestions-column-wrapper ${wrapperClasses}`}>
            <h3>{data.name}</h3>
            {displaySuggestionItems}
            {displayShowAllLink()}
        </div>
    );
};

SuggestionItems.defaultProps = {
    wrapperClasses: '',
    itemClasses: ''
}

export default SuggestionItems;
