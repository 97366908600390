import * as React from 'react';
import HeaderItemCounter from './HeaderItemCounter';
import HeaderSort from './HeaderSort';
import NativePerPageSelect from '../sidebar/NativePerPageSelect';
import HeaderViewModeSwitcher from './HeaderViewModeSwitcher';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IHeaderProps {
    itemPerPageOptions: number[];
    currentItemsPerPage: string;
    totalSearchResultItemsText: string;
    sortParamNames: string[];
    renderSortSelection: boolean;
    currentSortParam: string;
    renderPerPageSelection: boolean;
    renderViewModeSwitch: boolean;
    viewMode?: string;
    availableViewModes: string[];
    dispatch: React.Dispatch<any>;
}

const Header: React.FunctionComponent<IHeaderProps> = props => {
    const {
        itemPerPageOptions,
        currentItemsPerPage,
        totalSearchResultItemsText,
        sortParamNames,
        currentSortParam,
        renderSortSelection,
        renderPerPageSelection,
        renderViewModeSwitch,
        viewMode,
        availableViewModes,
        dispatch
    } = props;
    const { translate } = useTranslationHook();

    const displayPerPageSelect = () => {
        if (renderPerPageSelection === false) {
            return null;
        }

        if (itemPerPageOptions.length <= 1) {
            return null;
        }

        return (
            <div className="result-header__item-per-page hide-for-small-only">
                <NativePerPageSelect
                    label={translate('per page', { ns: 'article-list'})}
                    itemPerPageOptions={itemPerPageOptions}
                    currentItemsPerPage={currentItemsPerPage}
                    dispatch={dispatch}
                />
            </div>
        );
    }

    const displayViewModeSwitch = () => {
        if (availableViewModes.length <= 1 || !renderViewModeSwitch) {
            return null;
        }

        return (
            <HeaderViewModeSwitcher
                currentViewMode={viewMode}
                availableViewModes={availableViewModes}
                dispatch={dispatch}
            />
        )
    }

    const displaySort = () => {
        if (!renderSortSelection) {
            return null;
        }

        return (
            <div className="result-header__sort hide-for-small-only">
                <HeaderSort
                    label={translate('Sort by', { ns: 'article-list'})}
                    sortParamNames={sortParamNames}
                    currentSortParam={currentSortParam}
                    dispatch={dispatch}
                />
            </div>
        )
    }

    const displayHeaderText = () => (
        <div className="result-header__item-counter bold">
            <HeaderItemCounter totalSearchResultItemsText={totalSearchResultItemsText} />
        </div>
    );

    return (
        <React.Fragment>
            <div>
                {displayHeaderText()}
                {displayPerPageSelect()}
            </div>

            <div>
                {displayViewModeSwitch()}
                {displaySort()}
            </div>
        </React.Fragment>
    );
};

export default Header;
