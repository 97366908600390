import * as React from 'react';
import { Subject } from 'rxjs';
import classNames from 'classnames';
import Accordion from '../../../../../shop-ui/components/Accordion';
import SearchInput from '../../../../../react/common/components/form/SearchInput';
import useMultipleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useMultipleSelectFilter';
import useGradient from '../../../../../shop-ui/components/search-page/hooks/useGradient';
import SizeFilterOptions from './SizeFilterOptions';
import useSearchable from '../../../../../shop-ui/components/search-page/hooks/useSearchable';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

// Six rows
const MAXIMUM_ITEMS_FOR_SCROLL = 30;

const searchInputObserver = new Subject<string>();

const SizeFilter: React.FunctionComponent<IFilterProps> = props => {
    const [options, onSearchInputChange, displayNoMatchText] = useSearchable(props.options, searchInputObserver);
    const [isScrollable, onGradientChange, displayTopGradient, displayBottomGradient] = useGradient(options, MAXIMUM_ITEMS_FOR_SCROLL);

    const selectedOptions = props.options.filter(option => option.selected);
    const [onToggleItem, displaySelectedFilterOptions] = useMultipleSelectFilter(props.name, selectedOptions, props.dispatch);

    const additionalClasses = classNames(
        { scrollable: isScrollable },
    );
    const { translate } = useTranslationHook();

    return (
        <Accordion
            label={translate('Größe', { ns: 'article-list-filter'})}
            headerContent={displaySelectedFilterOptions()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className={`size-filter`}>
                <SearchInput hasLabel={true} isFullWidth={true} onSearchChange={onSearchInputChange}/>

                {displaySelectedFilterOptions()}

                <div className='scroll-selection'>
                    <SizeFilterOptions
                        additionalClassName={additionalClasses}
                        optionList={options}
                        onScrollChange={onGradientChange}
                        onToggleItem={onToggleItem}
                        isKeyboardFocusable={isScrollable}
                    />

                    {displayTopGradient()}
                    {displayBottomGradient()}
                </div>

                {displayNoMatchText()}
            </div>
        </Accordion>
    );
};

export default SizeFilter;
