interface IFootnote {
    namespace: string;
    identifier: string;
    index: number;
}

class FootnoteManager {
    public static readonly defaultNamespace: string = 'default';
    public static readonly generalVat: string = 'general-vat';
    public static readonly uvp: string = 'uvp';
    public static readonly deposit: string = 'deposit';

    public static getInstance(): FootnoteManager {
        if (!FootnoteManager.instance) {
            FootnoteManager.instance = new FootnoteManager();
        }

        return FootnoteManager.instance;
    }

    private static instance: FootnoteManager;
    private footnotes: IFootnote[] = [];

    public addFootnote(identifier: string, namespace: string = FootnoteManager.defaultNamespace): IFootnote {
        const newFootnote: IFootnote = {
            identifier,
            namespace,
            index: this.footnotes.length + 1,
        };

        this.footnotes.push(newFootnote);

        return newFootnote;
    }

    public getFootnote(identifier: string, namespace: string = FootnoteManager.defaultNamespace): IFootnote {
        const result = this.footnotes.filter(footnote => footnote.identifier === identifier && footnote.namespace ===
            namespace);

        return result.length ? result[0] : null;
    }

    public getFootnoteNumber(identifier: string, namespace: string = FootnoteManager.defaultNamespace): number {
        let footnote = this.getFootnote(identifier, namespace);

        if (!footnote) {
            footnote = this.addFootnote(identifier, namespace);
        }

        return footnote.index;
    }

    public getFootnotesByNamespaces(namespace: string = FootnoteManager.defaultNamespace): IFootnote[] {
        return this.footnotes.filter(footnote => footnote.namespace === namespace);
    }
}

export default FootnoteManager;
