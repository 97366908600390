import * as React from 'react';
import { IServicePageSearchResultItem } from '../../interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IServicePagesResultItemProps {
    servicePages: IServicePageSearchResultItem;
}

const ServicePagesResultItem: React.FunctionComponent<IServicePagesResultItemProps> = props => {
    const { servicePages } = props;
    const { translate } = useTranslationHook();
    const linkText = translate('Go to {{title}};', { replace: { title: servicePages.name }, ns: 'search'});

    return (
        <div className="service-pages-result-item">
            <div className="show-for-small-only">
                <a className="mobile-link" href={servicePages.url} aria-label={servicePages.name}>
                </a>
                <h2>{servicePages.name}</h2>
                <p className="link">
                    <a href={servicePages.url} tabIndex={-1} aria-hidden="true">
                        <span dangerouslySetInnerHTML={{ __html: linkText }} />
                    </a>
                </p>
                <button className="next-button" tabIndex={-1} aria-hidden="true">
                    <i className="icon icon-chevron-thin-right icon-only icon-size-small" />
                </button>
            </div>
            <div className="show-for-medium">
                <h2>{servicePages.name}</h2>
                <p className="link">
                    <a href={servicePages.url}>
                        <span dangerouslySetInnerHTML={{ __html: linkText }} />
                    </a>
                </p>
            </div>   
        </div>
    )
};

export default ServicePagesResultItem;
