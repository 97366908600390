import './garment-size-dialog.scss';

import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('garment-size-dialog', () =>
    importWithFallback(
        /* webpackMode: "lazy" */
        () => import('./garment-size-dialog')
    ),
);
