import * as React from 'react';
import Header from '../search-page/components/header/Header';
import Footer from '../search-page/components/footer/Footer';
import { useEnhancedReducer } from '../../includes/enhanced-reducer';
import { updateSearchPageHeadTitle, updateSearchPageTitle } from '../search-page/includes/searchPageHelper';
import SortFilterSidebar from '../search-page/components/sidebar/SortFilterSidebar';
import FilterStateService from '../search-page/services/FilterStateService';
import useApiQueryDataUpdate from '../search-page/hooks/useApiQueryDataUpdate';
import { FILTER_TYPE_MAPPING, VIEW_MODE_OPTIONS } from '../../../catalog-page/constants';
import { createCatalogReducer } from '../../../catalog-page/reducer';
import { initSearchPageData } from '../search-page/actions/searchPage';
import { loadCategoriesTree } from '../search-page/actions/categoriesTree';
import { IApiQueryData, ISearchPageActionState, ISearchResponse } from '../search-page/interfaces';
import {
    CATALOG_PAGE_CONTEXT_NAME,
    FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    VIEW_MODE_GRID
} from '../search-page/constants';
import {
    isNavigationFilterApplyForContext,
    isViewModeApplyForContext
} from '../../../catalog-page/includes/helper/context-helper';
import { resetAllFilter } from '../search-page/actions/filter';
import { numberFormat } from '../../includes/number';
import ProductList from '../../../catalog-page/components/catalog-page-content/product-list/ProductList';
import { IProductItem } from '../../interfaces';
import {DEFAULT_CURRENCY_FACTOR} from 'PyzShopUi/scripts/shop-ui/constants';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IProductListProps {
    apiUrl: string;
    defaultParameter: IApiQueryData;
    filterStateService: FilterStateService;
    isCompactMode: boolean;
    searchResponse?: ISearchResponse;
    riderConfigTimestamp?: number;
    hasRiderConfig?: boolean;
}

const emptyProductListPageState: ISearchPageActionState = {
    sort: {
        sortParamNames: [],
        currentSortParam: '',
    },
    pagination: {
        numFound: 0,
        currentPage: 1,
        maxPage: 1,
        currentItemsPerPage: 0,
        config: {
            defaultItemsPerPage: 48,
            validItemsPerPageOptions: [],
        },
    },
    searchResultItems: [],
    filters: [],
    storeConfig: {
        currency: '',
        currencyFactor: DEFAULT_CURRENCY_FACTOR,
        priceMode: 'GROSS_MODE',
    },
    extraInformation: {
        breadcrumb: null,
    },
    isPageLoading: true,
    apiQueryData: {},
    updatePageDebounceTime: FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    categoriesTree: [],
    navigationActiveCategoryKey: null,
    navigationActiveUrl: null,
    skipUpdateBaseOnApiQueryParams: false,
    initCategoryKey: null,
    activeCategoryName: '',
    headTitle: ''
};

const ProductListContainer: React.FunctionComponent<IProductListProps> = props => {
    const {
        filterStateService,
        apiUrl,
        defaultParameter,
        isCompactMode,
        riderConfigTimestamp,
        hasRiderConfig,
        searchResponse
    } = props;

    const [state, dispatch] = useEnhancedReducer(createCatalogReducer(props.filterStateService), emptyProductListPageState);

    const {
        pagination,
        sort,
        isPageLoading,
        filters,
        storeConfig,
        apiQueryData,
        updatePageDebounceTime,
        navigationActiveCategoryKey,
        skipUpdateBaseOnApiQueryParams,
        activeCategoryName,
        headTitle,
        viewMode
    } = state;

    const isGridViewMode = viewMode === VIEW_MODE_GRID;
    const hasViewMode = isViewModeApplyForContext(defaultParameter);
    const { translate } = useTranslationHook();

    const itemPerPageOptions: number[] = pagination?.config?.validItemsPerPageOptions || [];
    const currentItemsPerPage: string = pagination?.currentItemsPerPage?.toString() || '';
    const pageNumFound: number = pagination?.numFound || 0;
    const currentPage: number = pagination?.currentPage || 0;
    const maxPage: number = pagination?.maxPage || 0;
    const totalSearchResultItemsText: string = translate('article', { ns: 'article-list', count: pageNumFound });

    useApiQueryDataUpdate(
        apiQueryData,
        dispatch,
        updatePageDebounceTime,
        filterStateService,
        skipUpdateBaseOnApiQueryParams,
        [],
        CATALOG_PAGE_CONTEXT_NAME
    );

    React.useEffect(() => {
        if (searchResponse !== null) {
            dispatch(initSearchPageData(apiUrl, defaultParameter, filterStateService, [], null, CATALOG_PAGE_CONTEXT_NAME, searchResponse));

            return;
        }

        dispatch(initSearchPageData(apiUrl, defaultParameter, filterStateService, [], null, CATALOG_PAGE_CONTEXT_NAME));

        if (isNavigationFilterApplyForContext(defaultParameter)) {
            return;
        }

        dispatch(loadCategoriesTree());
    }, []);

    React.useEffect(() => {
        if (activeCategoryName) {
            updateSearchPageTitle(activeCategoryName);
        }
    }, [activeCategoryName]);

    React.useEffect(() => {
        if (headTitle) {
            updateSearchPageHeadTitle(headTitle);
        }
    }, [headTitle]);

    React.useEffect(() => {
        if (riderConfigTimestamp && hasRiderConfig === true) {
            dispatch(resetAllFilter());
        }
    }, [riderConfigTimestamp]);

    const displaySidebar = () => {
        if (isCompactMode) {
            return null;
        }

        return (
            <SortFilterSidebar
                apiQueryData={apiQueryData}
                pagination={pagination}
                sort={sort}
                filters={filters}
                storeConfig={storeConfig}
                isProgressing={isPageLoading}
                dispatch={dispatch}
                categoriesTree={[]}
                navigationActiveCategoryKey={navigationActiveCategoryKey}
                filterStateService={filterStateService}
                filtersMapping={FILTER_TYPE_MAPPING}
                renderPerPageSelection={isGridViewMode}
                renderSortSelection={sort !== null && sort.sortParamNames.length > 1}
                renderTextSearchField={false}
            />
        );
    };

    const displayResultHeader = () => {
        if (isPageLoading || pageNumFound === 0 || isCompactMode) {
            return null;
        }

        return (
            <Header
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                totalSearchResultItemsText={totalSearchResultItemsText}
                sortParamNames={sort?.sortParamNames ?? []}
                currentSortParam={sort?.currentSortParam ?? ''}
                renderSortSelection={sort !== null && sort.sortParamNames.length > 1}
                renderPerPageSelection={isGridViewMode}
                renderViewModeSwitch={hasViewMode}
                availableViewModes={VIEW_MODE_OPTIONS}
                viewMode={viewMode}
                dispatch={dispatch}
            />
        );
    };

    const displayResultFooter = () => {
        if (isPageLoading || pageNumFound === 0 || isCompactMode) {
            return null;
        }

        return (
            <Footer
                perPageSelectText={translate('Articles per page', { ns: 'article-list'})}
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                currentPage={currentPage}
                renderPerPageSelection={isGridViewMode}
                maxPage={maxPage}
                dispatch={dispatch}
            />
        );
    };

    const displayProductList = () => {
        const { searchResultItems } = state;
        const hasActiveFilter = filterStateService.hasActiveFilter(filters);

        return (
            <ProductList
                products={searchResultItems as IProductItem[]}
                isProgressing={isPageLoading}
                storeConfig={storeConfig}
                viewMode={viewMode}
                hasActiveFilter={hasActiveFilter}
                isCompactMode={isCompactMode}
            />
        );
    };

    return (
        <div className='grid-x grid-padding-x' data-qa='product-tab'>
            <div className='cell small-12 medium-4 large-3 page-layout-catalog-sidebar'>
                {displaySidebar()}
            </div>

            <div className='cell small-12 medium-8 large-9 page-layout-search-items-result'>
                <div className='result-header grid-padding-y'>
                    {displayResultHeader()}
                </div>

                <div className='product-list'>
                    {displayProductList()}
                </div>

                <div className='result-footer'>
                    {displayResultFooter()}
                </div>
            </div>
        </div>
    );
};

ProductListContainer.defaultProps = {
    hasRiderConfig: false,
};

export default ProductListContainer;
