import { IFootnote } from '../../interfaces';

export default class FootnoteNamespace {
    private footnotes: IFootnote[] = [];
    private readonly name: string = '';

    constructor(name: string) {
        this.name = name;
    }

    public addFootnote(identifier: string, counter?: number): IFootnote {
        const results = this.footnotes.filter(footnote => footnote.identifier === identifier);

        if (results.length) {
            return results[0];
        }

        const newFootnote: IFootnote = {
            identifier,
            index: counter || this.footnotes.length + 1,
        };

        this.footnotes.push(newFootnote);

        return newFootnote;
    }

    public getFootnote(identifier: string): IFootnote {
        const result = this.footnotes.filter(footnote => footnote.identifier === identifier);

        return result.length ? result[0] : null;
    }

    public getFootnotes(): IFootnote[] {
        return this.footnotes;
    }

    public setFootnotes(footnotes: IFootnote[]): void {
        this.footnotes = footnotes;
    }

    public getName(): string {
        return this.name;
    }

    public reset(): void {
        this.footnotes = [];
    }
}
