import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

export const useTranslationHook = () => {
    const { t, ...rest } = useTranslation();

    const translate = (...parameters: Parameters<TFunction>): string => {
        return t(...parameters);
    }

    return { translate, ...rest };
}