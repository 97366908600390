export const SEARCH_DEBOUNCE_TIME = 500;
export const MIN_SEARCH_STRING_LENGTH_TO_GET_DATA = 2;

export enum ESearchSuggestionTypes {
    CATEGORY = 'category',
    BRAND = 'brand',
    PRODUCT = 'product',
    BIKE = 'bike',
    STORE = 'store',
    SERVICE_PAGE = 'service-page',
}

export const SUGGESTION_SHOW_LINK_TEXT_MAPPINGS = {
    [ESearchSuggestionTypes.PRODUCT]: 'Show all products',
    [ESearchSuggestionTypes.BRAND]: 'Show all brand shops',
    [ESearchSuggestionTypes.BIKE]: 'Show all bikes',
    [ESearchSuggestionTypes.CATEGORY]: 'Show all categories',
    [ESearchSuggestionTypes.STORE]: 'Show all stores',
    [ESearchSuggestionTypes.SERVICE_PAGE]: 'Show all service pages',
};
