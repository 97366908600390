import * as React from 'react';
import classNames from 'classnames';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import { ISelectFilterOption } from '../../../../../shop-ui/components/search-page/interfaces';

export interface IColorFilterOptionsProps {
    additionalClassName?: string;
    optionList: ISelectFilterOption[];
    isKeyboardFocusable?: boolean;

    onScrollChange?(event: React.UIEvent<HTMLUListElement, UIEvent>): void;

    onToggleItem?(value: string, isSelected: boolean): void;

    optionStyle?: React.CSSProperties;
}

const ColorFilterOptionsComponent: React.FunctionComponent<IColorFilterOptionsProps> = props => {
    const displayItems = () => (
        props.optionList.map(option => {
            const listItemClasses = classNames({
                checked: option.selected,
            });
            const labelClasses = classNames({
                disabled: option.disabled,
                multicolor: option.multicolor,
            });

            const onClick = () => props.onToggleItem(option.value, option.selected);

            return (
                <li
                    key={option.value}
                    className={`grid-filter__item ${listItemClasses}`}
                    data-qa="color-filter-option"
                >
                    <div
                        onClick={onClick}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onClick()
                            }
                        }}
                        role="button"
                        tabIndex={0}
                        aria-label={option.label || option.labelCssClass || option.value}
                    >
                        <label className={`${option.value} ${labelClasses} ${option.labelCssClass || ''}`} htmlFor={option.label + '_' + option.value}>
                            { option.multicolor ? <div style={option?.style}></div> : undefined }
                        </label>
                    </div>
                </li>
            );
        })
    );

    return (
        <ul onScroll={props.onScrollChange} className={`grid-filter ${props.additionalClassName}`} {...(props.isKeyboardFocusable ? { 'tabIndex': 0 } : {})}>
            {displayItems()}
        </ul>
    );
};

ColorFilterOptionsComponent.defaultProps = {
    additionalClassName: '',
    onScrollChange: () => ({}),
    onToggleItem: () => ({}),
};

const ColorFilterOptions = withMemo(ColorFilterOptionsComponent);

export default ColorFilterOptions;
