import * as React from 'react';
import classNames from 'classnames';

interface IMyBikeIconProps {
    isActive?: boolean;
    bikeRefElement: React.MutableRefObject<HTMLDivElement>;

    onMyBikeToggle(): void;
}

const MyBikeIcon: React.FunctionComponent<IMyBikeIconProps> = props => {
    const { isActive, onMyBikeToggle, bikeRefElement } = props;
    const iconClass = classNames(
        'icon icon-mein-bike icon-size-standard',
        { active: isActive },
    );

    return (
        <div className='add-bike'>
            <i data-fly-to-cart='true' ref={bikeRefElement} className={iconClass} onClick={onMyBikeToggle}/>
        </div>
    );
};

MyBikeIcon.defaultProps = { isActive: false };

export default React.memo(MyBikeIcon);
