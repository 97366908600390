import { IApiQueryData } from '../../../shop-ui/components/search-page/interfaces';
import { CatalogContextName } from '../../constants';

const CONTEXT_APPLY_NAVIGATION_FILTER = [
    CatalogContextName.SALES,
    CatalogContextName.BIKE_DETAIL,
    CatalogContextName.SEARCH,
    CatalogContextName.BRAND,
    CatalogContextName.PROMOTION,
];

const CONTEXT_APPLY_VIEW_MODE = [
    CatalogContextName.BIKE_DETAIL
];

export const isNavigationFilterApplyForContext = (parameter: IApiQueryData): boolean => {
    const context = parameter.context as CatalogContextName;

    return context && CONTEXT_APPLY_NAVIGATION_FILTER.includes(context);
};

export const isViewModeApplyForContext = (parameter: IApiQueryData): boolean => {
    const context = parameter.context as CatalogContextName;

    return context && CONTEXT_APPLY_VIEW_MODE.includes(context);
};

export const isCompactModeApplyForContext = (parameter: IApiQueryData): boolean => {
    const context = parameter.context as CatalogContextName;

    return context && context === CatalogContextName.GIFT_VOUCHER;
};
