import FilterStateService from '../services/FilterStateService';
import {
    PARAM_CATEGORY_KEY,
    PARAM_PAGE,
    PARAM_PER_PAGE,
    PARAM_SEARCH,
    PARAM_SORT,
    PARAM_VIEW_MODE
} from '../constants';
import { IApiQueryData, ICategoryTreeItem, ISearchPageActionState } from '../interfaces';
import { isCategoryFilter } from '../includes/searchPageHelper';

export const doUpdateCategoriesTree = (
    state: ISearchPageActionState,
    payload: ICategoryTreeItem[]
): ISearchPageActionState => ({
    ...state,
    categoriesTree: payload
});

export const doUpdateNavigationActiveCategoryKey = (
    state: ISearchPageActionState,
    activeCategoryKey?: string|null
): ISearchPageActionState => ({
    ...state,
    navigationActiveCategoryKey: activeCategoryKey || state.initCategoryKey
});

export const doUpdateActiveCategory = (
    state: ISearchPageActionState,
    activeCategory: ICategoryTreeItem,
    filterStateService: FilterStateService
): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        q: state.apiQueryData.q,
        [PARAM_PER_PAGE]: state.apiQueryData[PARAM_PER_PAGE],
        [PARAM_SEARCH]: state.apiQueryData[PARAM_SEARCH],
        [PARAM_PAGE]: '1',
        [PARAM_SORT]: state.apiQueryData.sort,
        [PARAM_VIEW_MODE]: state.apiQueryData[PARAM_VIEW_MODE],
        [PARAM_CATEGORY_KEY]: activeCategory.categoryKey,
    };

    state.filters.forEach(filter => {
        if (isCategoryFilter(filter)) {
            return;
        }

        filterStateService.resetQueryParams(filter.name, newApiQueryData);
    });

    return {
        ...state,
        apiQueryData: newApiQueryData,
        navigationActiveUrl: activeCategory.url,
        navigationActiveCategoryKey: activeCategory.categoryKey,
        activeCategoryName: activeCategory.seoTitle,
        headTitle: activeCategory.pageTitle
    }
};
