import { FilterNames } from '../../constants';
import AbstractSelectFilterHandler from '../../../shop-ui/includes/filter-state/AbstractSelectFilterHandler';
import { ISelectFilterOption, ISelectFilter } from '../../../shop-ui/components/search-page/interfaces';

export default class BikeTypeFilterHandler extends AbstractSelectFilterHandler {
    public createQueryParams = (filter: ISelectFilter, params: object): void => {
        params[FilterNames.BIKETYPE] = filter.options
            .filter(option => option.selected)
            .map(selectedOption => selectedOption.value);
    }

    public resetQueryParams = (params: object): void => {
        params[FilterNames.BIKETYPE] = [];
    }

    public getSelectedOptions = (filter: ISelectFilter): ISelectFilterOption[] => filter.options
        .filter(option => option.selected)
        .sort((option, nextOption) =>
            option.label.toLowerCase().localeCompare(nextOption.label.toLowerCase()),
        );

    public updateSelectedOptions = (filter: ISelectFilter, selectedValues: string[]): void => {
        filter.options = filter.options.map((option: ISelectFilterOption) => {
            option.selected = selectedValues.includes(option.value);

            return option;
        });
    }
}
