import { createRoot } from 'react-dom/client';
import * as React from 'react';
import Footnote from 'PyzShopUi/scripts/footnote/components/Footnote';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';
import { FootnoteEvents } from 'PyzShopUi/scripts/shop-ui/events/footnoteEvents';
import FootnoteService from 'PyzShopUi/scripts/shop-ui/services/FootnoteService';
import { EFootnoteNamespace } from 'PyzShopUi/scripts/shop-ui/constants';
import { IFootnoteNamespaceResetPayload } from 'PyzShopUi/scripts/shop-ui/interfaces';
import { IExtraInformation } from 'PyzShopUi/scripts/footnote/interfaces';
import FootnoteNumber from 'PyzShopUi/scripts/footnote/components/FootnoteNumber';
import { FootnoteActions } from 'PyzShopUi/scripts/footnote/constants';
import {restrictFootnotesByAction} from 'PyzShopUi/scripts/footnote/includes/actions';

const FOOTNOTE_MANAGER = '#footnote-manager';
const DATA_FOOTNOTES = 'data-footnotes';
const DATA_FOOTNOTE_CONTENTS = 'data-footnote-content';
const DATA_EXTRA_INFORMATION = 'data-extra-information';
const DATA_FOOTNOTE_LABEL = 'data-footnote-label';

let viewMode = null;

export default class FootnoteFirstPageHandler {

    public static init() {
        this.initEventBus();
        this.initFootnoteContentFromFirstPage();
        this.initFootnoteNumberForFirstPage();
    }

    private static rootCreated = false;
    private static footerNoteElementRoot;

    private static initEventBus(): void {
        MiddlewareEventBus.getInstance().subscribe((event: IReactEvent<any>) => {
            switch (event.type) {
                case FootnoteEvents.FOOTNOTE_UPDATED:
                    this.renderFootnoteInDOM();
                    break;

                case FootnoteEvents.FOOTNOTE_RESET:
                    const payload: IFootnoteNamespaceResetPayload = event.payload;
                    viewMode = payload.viewMode;

                    FootnoteService.getInstance().getNamespace(payload.name).reset();
                    this.initFootnoteContentFromFirstPage();
                    break;

                default:
                    break;
            }
        });
    }

    /**
     * collect foot-notes and translated contents at first page
     * @private
     */
    private static initFootnoteContentFromFirstPage(): void {
        const $footNote = document.querySelector(FOOTNOTE_MANAGER);

        if (!$footNote) {
            return;
        }

        const footnoteService = FootnoteService.getInstance();
        const footnotes = JSON.parse($footNote.getAttribute(DATA_FOOTNOTES));
        const footnoteContents = JSON.parse($footNote.getAttribute(DATA_FOOTNOTE_CONTENTS));
        const extraInformation: IExtraInformation = JSON.parse($footNote.getAttribute(DATA_EXTRA_INFORMATION));

        footnoteService.setContent(footnoteContents);
        footnoteService.setExtraInformation(extraInformation);

        Object.keys(footnotes).forEach((namespace: string) => {
            const namespaceData = footnotes[namespace];
            footnoteService.getNamespace(namespace);

            Object.keys(namespaceData).forEach(key => {
                footnoteService.addFootnote(key, namespace, namespaceData[key]);
            });
        });

        MiddlewareEventBus.getInstance().publish({ type: FootnoteEvents.FOOTNOTE_UPDATED, payload: {} });
    }

    /**
     * Re-add sub number to whole first load products existing data-footnote-label
     * @private
     */
    private static initFootnoteNumberForFirstPage(): void {
        const $footnoteNumberElements = document.querySelectorAll(`sup[${DATA_FOOTNOTE_LABEL}]`);

        $footnoteNumberElements.forEach(($footnoteNumberElement, index) => {
            const footnoteLabel = $footnoteNumberElement.getAttribute(DATA_FOOTNOTE_LABEL);

            if (!footnoteLabel) {
                return;
            }

            const footnoteNumberRootElement = createRoot($footnoteNumberElement);

            footnoteNumberRootElement.render(
                <FootnoteNumber key={index} identifier={footnoteLabel} namespace={EFootnoteNamespace.ARTICLE_PRICE} />
            );
        });
    }

    /**
     * Find placements in DOM to render <Footnote>
     * @private
     */
    private static renderFootnoteInDOM() {
        const footnoteService = FootnoteService.getInstance();
        const extraInformation = footnoteService.getExtraInformation();
        const placementElements = document.querySelectorAll('[data-footnote-placement]');

        placementElements.forEach((element, index) => {
            const namespace = element.getAttribute('data-footnote-placement');
            if (!namespace) {
                return;
            }

            const action = element.getAttribute('data-footnote-action') as FootnoteActions;
            const footNotes = footnoteService.getNamespace(namespace).getFootnotes();
            const restrictedFootNotes = restrictFootnotesByAction(action, footNotes, viewMode);

            if (!this.rootCreated) {
                this.footerNoteElementRoot = createRoot(element);
            }

            this.footerNoteElementRoot.render(
                <Footnote
                    key={index}
                    footnotes={restrictedFootNotes}
                    extraInformation={extraInformation}
                />
            );
        });

        this.rootCreated = true;
    }
}
