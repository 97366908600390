import { IFootnote } from 'PyzShopUi/scripts/shop-ui/interfaces';
import { EFootnoteNamespace } from 'PyzShopUi/scripts/shop-ui/constants';
import FootnoteNamespace from 'PyzShopUi/scripts/shop-ui/services/footnote/FootnoteNamespace';
import { IExtraInformation } from 'PyzShopUi/scripts/footnote/interfaces';
import { ARTICLE_PRICE_FOOTNOTE_ORDER } from '../../footnote/constants';

export default class FootnoteService {
    public static getInstance(): FootnoteService {
        if (!FootnoteService.instance) {
            FootnoteService.instance = new FootnoteService();
        }

        return FootnoteService.instance;
    }

    private static instance: FootnoteService;
    private namespaces: FootnoteNamespace[] = [];
    private extraInformation: IExtraInformation;
    private contents: object;

    public addFootnote(identifier: string, namespace: string = EFootnoteNamespace.DEFAULT, counter?: number): IFootnote {
        const currentNamespace = this.getNamespace(namespace);

        if (
            (namespace === EFootnoteNamespace.ARTICLE_PRICE)
            || (namespace === EFootnoteNamespace.PDP_ARTICLE_PRICE)
            || (namespace === EFootnoteNamespace.PDP_ALL_VARIANTS_ARTICLE_PRICE)
        ) {
            return this.addArticlePriceFootnote(currentNamespace, identifier);
        }

        return currentNamespace.addFootnote(identifier, counter);
    }

    private addArticlePriceFootnote(currentNamespace: FootnoteNamespace, identifier: string): IFootnote {
        currentNamespace.addFootnote(identifier);

        const footnotesByNamespace = currentNamespace.getFootnotes().slice(0);
        footnotesByNamespace.sort((footnote: IFootnote, nextFootnote: IFootnote) => (
            ARTICLE_PRICE_FOOTNOTE_ORDER.indexOf(footnote.identifier) - ARTICLE_PRICE_FOOTNOTE_ORDER.indexOf(nextFootnote.identifier)
        )).forEach((footnote: IFootnote, index: number) => {
            footnote.index = index + 1;
        });

        currentNamespace.setFootnotes(footnotesByNamespace);

        return currentNamespace.getFootnote(identifier);
    }

    public getFootnote(identifier: string, namespace: string = EFootnoteNamespace.DEFAULT): IFootnote {
        return this.getNamespace(namespace).getFootnote(identifier);
    }

    public createOrGetFootnote(identifier: string, namespace: string = EFootnoteNamespace.DEFAULT): IFootnote {
        const footnote = this.getFootnote(identifier, namespace);

        return footnote ? footnote : this.addFootnote(identifier, namespace);
    }

    public getFootnoteNumber(identifier: string, namespace: string = EFootnoteNamespace.DEFAULT): number {
        const footnote = this.createOrGetFootnote(identifier, namespace);

        return footnote.index;
    }

    public getNamespace(name: string): FootnoteNamespace {
        const footnoteNamespace = this.namespaces.find((item: FootnoteNamespace) => item.getName() === name);

        if (footnoteNamespace) {
            return footnoteNamespace;
        }

        const newFootnoteNamespace = new FootnoteNamespace(name);

        this.namespaces.push(newFootnoteNamespace);

        return newFootnoteNamespace;
    }

    public setExtraInformation(extraInformation: IExtraInformation) {
        this.extraInformation = extraInformation;
    }

    public getExtraInformation(): IExtraInformation {
        return this.extraInformation;
    }

    public setContent(contents: object) {
        this.contents = contents;
    }

    public getContentByNamespace(namespace: string) {
        return this.contents?.[namespace] ?? this.contents?.['article-price'];
    }

}
