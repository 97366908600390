import { Subject } from 'rxjs';
import * as React from 'react';
import { debounceTime, map } from 'rxjs/operators';
import { ISearchSuggestion } from 'PyzShopUi/scripts/search-suggestion/interfaces';
import { searchSuggestionService } from 'PyzShopUi/scripts/search-suggestion/services/SearchSuggestionService';
import { SEARCH_DEBOUNCE_TIME } from 'PyzShopUi/scripts/search-suggestion/constants';

interface IFetchSearchSuggestionObserverObject {
    searchValue: string;
    minSearchStringLengthToGetData: number;
    setSuggestions: React.Dispatch<React.SetStateAction<ISearchSuggestion[]>>;
}

const fetchSearchSuggestionObserver = new Subject<IFetchSearchSuggestionObserverObject>();

function useFetchSearchSuggestion(searchValue: string, minSearchStringLengthToGetData: number): ISearchSuggestion[] {
    const [suggestions, setSuggestions] = React.useState<ISearchSuggestion[]>([]);

    React.useEffect(() => {
        let requestId = 0;

        const subscription = fetchSearchSuggestionObserver
            .pipe(
                debounceTime(SEARCH_DEBOUNCE_TIME),
                map(object => {
                    requestId++;

                    return object;
                })
            )
            .subscribe(object => {
                if (object.searchValue.length < object.minSearchStringLengthToGetData) {
                    object.setSuggestions([]);

                    return;
                }

                searchSuggestionService.fetchDataFromAPI(
                    object.searchValue,
                    requestId
                ).then(data => {
                    if (data.requestId !== requestId) {
                        return;
                    }

                    object.setSuggestions(data.suggestions);
                });
            });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    React.useEffect(() => {
        fetchSearchSuggestionObserver.next({
            searchValue,
            minSearchStringLengthToGetData,
            setSuggestions
        });
    }, [searchValue]);

    return suggestions;
}

export default useFetchSearchSuggestion;
