import * as React from 'react';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

export interface IHeaderContainerProps {
    cssClass?: string;
    headingText?: string;
    selectedValue?: string;
    children: React.ReactNode;
}

export const HeaderContainer: React.FunctionComponent<IHeaderContainerProps> = props => {
    const { translate } = useTranslationHook();

    return (
        <div className="filter-section">
            <div className={props.cssClass}>
                <div className="product-configuration-header">
                    <span
                        className="heading4">{translate(props.headingText, { ns: 'article-detail' })} - </span><i>{props.selectedValue}</i>
                </div>
                {props.children}
            </div>
        </div>
    );
}
