export const PRICE_ORIGIN = {
    previous_year: 'Price comparison with previous year’s Louis price.',
    current_year: 'Price comparison with current year’s Louis price.',
    current_retail_price: 'Price comparison with current recommended retail price.',
    set_comparison: 'Comparison of price as a set and total price if purchased individually',
};

export enum FootnoteValues {
    UVP = 'uvp',
    GENERAL_VAT = 'generalVat',
    DEPOSIT = 'deposit',
}

export enum CatalogOrigins {
    PREVIOUS_YEAR_PRICE = '1',
    CURRENT_YEAR_PRICE = '2',
    WITHOUT_ORIGIN = '3',
    CURRENT_UVP = '4',
    SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE = '5'
}

export enum FootnoteActions {
    ARTICLE_LIST_FOOTNOTE = 'articleListFootnote',
    GENERAL_FOOTNOTE = 'generalFootnote',
}

export const FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE = FootnoteValues.UVP + '#' + CatalogOrigins.SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE;

export const ARTICLE_PRICE_FOOTNOTE_ORDER = [
    FootnoteValues.GENERAL_VAT,
    FootnoteValues.UVP,
    FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE,
    FootnoteValues.DEPOSIT,
];
