import { ICategoryTreeItem } from '../interfaces';

function useFindCategoryByKey(categoryTree: ICategoryTreeItem[], currentCategorykey?: string|undefined): ICategoryTreeItem | null {
    if (!currentCategorykey) {
        return null;
    }

    const findActiveCategory = (categories: ICategoryTreeItem[], categoryKey: string) => {
        for (const category of categories) {
            if (category.categoryKey === categoryKey) {
                return category;
            }

            const activeCategory = findActiveCategory(category.children, categoryKey);

            if (activeCategory) {
                return activeCategory;
            }
        }

        return null;
    }

    return findActiveCategory(categoryTree, currentCategorykey);
}

export default useFindCategoryByKey;
