import axios, { AxiosError, AxiosResponse } from "axios";
import {
    IAddProductDTO,
    IProductWishListResponse,
    IWishListItemApiResponse,
} from "PyzShopUi/scripts/product-wish-list/interfaces";
import * as Sentry from "@sentry/browser";

const productWishListEndpoint = "/api/wishlist/v1/items";
const getRemoveAbstractProductWishListEndpoint = (sku: string) =>
    `${productWishListEndpoint}/abstract-${sku}`;
const getRemoveConcreteProductWishListEndpoint = (sku: string) =>
    `${productWishListEndpoint}/concrete-${sku}`;

class ProductWishListService {
    public getProducts = (): Promise<IProductWishListResponse> => {
        return axios
            .get<IWishListItemApiResponse>(productWishListEndpoint)
            .then((response) => this.createSuccessResponse(response))
            .catch((error) => this.createErrorResponse(error));
    };

    public addProduct = (
        addProductDTO: IAddProductDTO
    ): Promise<IProductWishListResponse> => {
        return axios
            .post<IWishListItemApiResponse>(
                productWishListEndpoint,
                addProductDTO
            )
            .then((response) => this.createSuccessResponse(response))
            .catch((error) => this.createErrorResponse(error));
    };

    public removeProductAbstract = (
        sku: string
    ): Promise<IProductWishListResponse> => {
        return axios
            .delete<IWishListItemApiResponse>(
                getRemoveAbstractProductWishListEndpoint(sku)
            )
            .then((response) => this.createSuccessResponse(response))
            .catch((error) => this.createErrorResponse(error));
    };

    public removeProductConcrete = (
        sku: string
    ): Promise<IProductWishListResponse> => {
        return axios
            .delete<IWishListItemApiResponse>(
                getRemoveConcreteProductWishListEndpoint(sku)
            )
            .then((response) => this.createSuccessResponse(response))
            .catch((error) => this.createErrorResponse(error));
    };

    private createSuccessResponse = (
        response: AxiosResponse<IWishListItemApiResponse>
    ): IProductWishListResponse => {
        this.checkResponse(response);

        return {
            statusCode: response.status,
            abstractProductWishLists: response.data.wishlistAbstractItems,
            concreteProductWishLists: response.data.wishlistItems,
            success: response.data.success,
        };
    };

    private createErrorResponse = (
        error: AxiosError<IWishListItemApiResponse>
    ): IProductWishListResponse => {
        this.checkResponse(error);

        return {
            statusCode: error.response.status,
            abstractProductWishLists: error.response.data.wishlistAbstractItems,
            concreteProductWishLists: error.response.data.wishlistItems,
            success: error.response.data.success,
        };
    };

    /**
     * There are issues with the response from the wishlisht endpoint we want to investigate
     * this is why this function will check the respones and send an error to Sentry, if something
     * is wrong. For more info see SX-2019 and SX-2020
     */
    private checkResponse(
        errorOrResponse:
            | AxiosError<IWishListItemApiResponse>
            | AxiosResponse<IWishListItemApiResponse>,
        isError?: boolean
    ) {
        // this will make the error / response visible in Sentry if one of the captureMessage calls
        // are exectued
        Sentry.addBreadcrumb({ data: errorOrResponse });

        if (!isError) {
            const response =
                errorOrResponse as AxiosResponse<IWishListItemApiResponse>;

            // this is related to the errors in SX-2019
            if (
                !response.data.wishlistAbstractItems ||
                !response.data.wishlistItems
            ) {
                Sentry.captureMessage(
                    `no wishlistAbstractItems or no wishlistItems on ${productWishListEndpoint} response`
                );
            }

            return;
        }

        const error = errorOrResponse as AxiosError<IWishListItemApiResponse>;
        
        // this is related to the errors in SX-2020
        if (!error.response.status) {
            Sentry.captureMessage(`no status on error`);
        }
    }
}

export const productWishListService = new ProductWishListService();
