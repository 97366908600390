import * as React from 'react';
import { ISliderItemProps } from '../../../interfaces';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import ProductItemCompact from '../../../../shop-ui/components/product-item/ProductItemCompact';

const RecentSliderItemComponent: React.FunctionComponent<ISliderItemProps> = props =>{
    
    return (
        <ProductItemCompact
            name={props.item.abstractName}
            merchantPrice={props.item.prices.merchant}
            customerPrice={props.item.prices.customer}
            sku={props.item.abstractSku}
            url={props.item.url}
            images={props.item.images}
            isKeyboardAccessible={props.hideNotVisibleItems && !props.isVisible ? false : true}
            isInViewport={props.isVisible}
        />
    );}

const RecentSliderItem = withMemo(RecentSliderItemComponent);

export default RecentSliderItem;
