import * as React from 'react';
import { ISliderProps } from '../../../interfaces';
import SliderContent from '../../content/SliderContent';
import { SwiperOptions } from 'swiper';
import { withAPI } from '../../../hoc/withAPI';
import { ESliderMinimumForDisplayed } from '../../../constants';
import { withSwiper } from '../../../hoc/withSwiper';
import DefaultSliderItem from '../default-slider/DefaultSliderItem';

const DialogSlider: React.FunctionComponent<React.PropsWithChildren<ISliderProps>> = props => {
    const { title, allItemLink, allItemTitle, children } = props;
    return (
        <SliderContent title={title} allItemTitle={allItemTitle} allItemLink={allItemLink}>
            {children}
        </SliderContent>
    );
};

const swiperOptions: SwiperOptions = {
    longSwipesRatio: 0.2,
    longSwipesMs: 100,
    shortSwipes: false,
    breakpoints: {
        320: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
            spaceBetween: 10,
        },
        640: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10,
        },
    },
};

export default withAPI(ESliderMinimumForDisplayed.DIALOG)(
    withSwiper(
        swiperOptions,
        DefaultSliderItem,
        'transform-50-negative',
        'transform-50-negative',
        true
    )(DialogSlider),
);
