import * as React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import ScrollUtils from 'PyzShopUi/scripts/utils/scrollUtils';
import { useRef } from 'react';
import { ACCORDION_SCROLLABLE_PARENT_CLASS_NAME } from 'PyzShopUi/scripts/shop-ui/constants';
import { useImperativeHandle } from 'react';
import { forwardRef } from 'react';

interface IAccordionProps {
    label: string;
    headerDataQa?: string;
    headerContent?: any;
    hideHeaderContentOnCollapsed?: boolean;
    defaultCollapsed?: boolean;
    isScrollToCurrentOnCollapsed?: boolean;
    ref?: any;
    children?: React.ReactNode;
    // this was needed for https://jira.louis.de/browse/SX-1791
    // if the transition is rendered there is a race condition with
    // the scroll that needs to happen as soon as the category tree is loaded
    skipTransition?: boolean;
}

const Accordion: React.FunctionComponent<IAccordionProps> = forwardRef((props, ref) => {
    const {
        label,
        headerDataQa,
        headerContent,
        hideHeaderContentOnCollapsed,
        children,
        defaultCollapsed,
        isScrollToCurrentOnCollapsed,
        skipTransition
    } = props;

    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(defaultCollapsed);

    const accordionHeader = useRef(null);

    const classes = classNames(
        'accordion',
        { 'accordion--collapsed': isCollapsed },
    );

    useImperativeHandle(ref, () => ({
        scroll() {
            setTimeout(scrollToCurrentAccordion, 300);
        }
    }));

    const scrollToCurrentAccordion = () => {
        ScrollUtils.scroll({
            target: accordionHeader.current,
        });
    };

    const scrollToCurrentAccordionOnToggle = isCollapsed => {
        if (isScrollToCurrentOnCollapsed && isCollapsed) {
            scrollToCurrentAccordion();
        }
    };

    const onToggleFilterItem = () => {
        const toggled = !isCollapsed;
        setIsCollapsed(toggled);
        scrollToCurrentAccordionOnToggle(toggled);
    };

    const displayHeaderContent = () => {
        if ((isCollapsed && hideHeaderContentOnCollapsed) || !headerContent) {
            return null;
        }

        return (
            <div className="accordion__header__content">
                {headerContent}
            </div>
        );
    };

    const wrapTransition = (element: React.ReactNode) => {
        if (skipTransition) {
            return element
        }

        return <CSSTransition appear={true} in={isCollapsed} classNames="accordion__content--active" timeout={500} >
            {element}
        </CSSTransition>
    }

    return (
        <div className={classes}>
            <div ref={accordionHeader} className="accordion__header" data-qa={headerDataQa}>
                <div 
                    onClick={onToggleFilterItem}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onToggleFilterItem()
                        }
                      }}
                    className="accordion__header__toggle-section"
                    aria-expanded={isCollapsed}
                    role="button"
                    tabIndex={0}
                >
                    <span> {label} </span>
                    <i className="accordion__header__toggle-section__toggle-icon"/>
                </div>

                {displayHeaderContent()}
            </div>

            {wrapTransition(
                <div className={skipTransition && isCollapsed ? "accordion__content--active" : "accordion__content"}>
                    {children}
                </div>
            )}
        </div>
    );
});

Accordion.defaultProps = {
    hideHeaderContentOnCollapsed: false,
    defaultCollapsed: false,
    isScrollToCurrentOnCollapsed: false,
    skipTransition: false
};

export default Accordion;
