import * as React from 'react';
import { currencyFormatSeparatedDecimals } from '../lib';

interface IFormattedCurrencyProps {
    price: number;
    currency: string;
    footnoteNumber?: number;
}

const FormattedCurrency: React.FunctionComponent<IFormattedCurrencyProps> = props => {
    const { price, currency, footnoteNumber } = props;

    const formatedCurrency = currencyFormatSeparatedDecimals(
        price,
        currency,
        true,
        footnoteNumber
    );

    return <span dangerouslySetInnerHTML={
        { __html: formatedCurrency }
    }/>;
};

export default FormattedCurrency;
