import EventBus from '../utils/eventBus';
import IModuleOptions from '../interfaces/IModuleOptions';
import { BREAKPOINTS } from '../config';
import { ETooltipEvent } from './events/tooltip.event';

/**
 * add and remove the class .tooltip-show
 * to show and hide the .tooltip-content
 */
class Tooltip {
    private static DEFAULTS = {
        TOOLTIP_CONTAINER: '.tooltip-container',
        TOOLTIP: '.tooltip',
        ICON: '.tooltip-icon',
        SHOW: 'tooltip-show',
        CLOSE: '.tooltip-close',
        CONTENT: '.tooltip-content',
        ARROW: '.tooltip-arrow',
        TRIGGER: '.tooltip-icon, .tooltip-text'
    };
    private readonly container: HTMLElement;
    private readonly tooltip: HTMLElement;
    private content: HTMLElement;
    private readonly closeIcon: HTMLElement;
    private eventBus: EventBus;
    private readonly enableHover: boolean;
    private readonly isInitRequired: boolean;

    constructor(element: HTMLElement, options: IModuleOptions) {
        this.eventBus = options.eventBus;
        this.container = element;
        this.content = this.container.querySelector(Tooltip.DEFAULTS.CONTENT);
        this.tooltip = this.container.querySelector(Tooltip.DEFAULTS.TOOLTIP);
        this.closeIcon = this.container.querySelector(Tooltip.DEFAULTS.CLOSE);
        const tooltipHover: string = this.tooltip.dataset.tooltipHover || 'false';
        const positionInit: string = this.tooltip.dataset.tooltipPositionInit || 'false';
        this.enableHover = Boolean(JSON.parse((tooltipHover === '') ? 'false' : tooltipHover));
        this.isInitRequired = Boolean(JSON.parse((positionInit === '') ? 'false' : positionInit));

        if (this.isRepositionNeeded()) {
            this.repositionInsideViewport();
        }
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.eventBus.subscribe('tooltip:open', target => {
            if (target === this.container) {
                this.showTooltip(this.container.querySelector(Tooltip.DEFAULTS.ICON));
                this.eventBus.publish('tooltip:reposition');

                document.addEventListener('click', this.closeTooltipOnClick);
            }
        });

        this.eventBus.subscribe('tooltip:close', target => {
            if (target === this.container) {
                this.hideTooltip();

                document.removeEventListener('click', this.closeTooltipOnClick);
            }
        });

        this.eventBus.subscribe('tooltip:reposition', () => {
            if (this.isRepositionNeeded()) {
                this.repositionInsideViewport();
            }
        });
        this.eventBus.subscribe('window:resize:debounced', () => {
            if (this.isRepositionNeeded()) {
                this.repositionInsideViewport();
            }
        });

        if (this.enableHover === true) {
            this.container.querySelectorAll(Tooltip.DEFAULTS.TRIGGER).forEach(
                (toolTipTriggerElement: HTMLElement) => toolTipTriggerElement.addEventListener('mouseenter', e => {
                    e.stopPropagation();
                    if (!BREAKPOINTS.minMedium()) {
                        return;
                    }
                    this.eventBus.publish('tooltip:open', this.container);
                })
            );

            this.container.querySelectorAll(Tooltip.DEFAULTS.TRIGGER).forEach(
                (toolTipTriggerElement: HTMLElement) => toolTipTriggerElement.addEventListener('mouseleave', e => {
                    e.stopPropagation();
                    if (!BREAKPOINTS.minMedium()) {
                        return;
                    }
                    this.eventBus.publish('tooltip:close', this.container);
                })
            );

        }

        this.container.querySelectorAll(Tooltip.DEFAULTS.TRIGGER).forEach(
            (toolTipTriggerElement: HTMLElement) => toolTipTriggerElement.addEventListener('click', e => {
                e.stopPropagation();
                const tooltipIcon: HTMLElement = (e.currentTarget as Element).closest(Tooltip.DEFAULTS.TOOLTIP_CONTAINER).querySelector(Tooltip.DEFAULTS.ICON);
                const tooltip: string = tooltipIcon.dataset.tooltip;
                const isTooltipShown = document.querySelector(`#${tooltip}`).classList.contains(Tooltip.DEFAULTS.SHOW);

                if (isTooltipShown) {
                    this.eventBus.publish('tooltip:close', this.container);
                } else {
                    this.eventBus.publish('tooltip:open', this.container);
                }
            })
        );

        if (this.closeIcon) {
            this.closeIcon.addEventListener('click', (): void => {
                this.eventBus.publish('tooltip:close', this.container);
            });
        }

        /*
             Accessibility event listeners for tooltips:
             1. Possibility to open the tooltip when you focus the icon
             2. Close the tooltip when you focus out the icon
         */
        this.container.querySelectorAll(Tooltip.DEFAULTS.TRIGGER).forEach(
            (toolTipTriggerElement: HTMLElement) => {
                toolTipTriggerElement.addEventListener('keydown', e => {
                    if(e.key === 'Enter') {
                        const tooltipIcon: HTMLElement = (e.currentTarget as Element).closest(Tooltip.DEFAULTS.TOOLTIP_CONTAINER).querySelector(Tooltip.DEFAULTS.ICON);
                        const tooltip: string = tooltipIcon.dataset.tooltip;
                        const isTooltipShown = document.querySelector(`#${tooltip}`).classList.contains(Tooltip.DEFAULTS.SHOW);

                        if (isTooltipShown) {
                            this.eventBus.publish('tooltip:close', this.container);
                        } else {
                            this.eventBus.publish('tooltip:open', this.container);
                        }
                    }

                    if(e.key === "Escape") {
                        this.eventBus.publish('tooltip:close', this.container);
                    }
                });
            }
        );
        this.container.querySelectorAll(Tooltip.DEFAULTS.TRIGGER).forEach(
            (toolTipTriggerElement: HTMLElement) => {
                toolTipTriggerElement.addEventListener('focusout', e => {
                    e.stopPropagation();
                    if (!BREAKPOINTS.minMedium()) {
                        return;
                    }
                    this.eventBus.publish('tooltip:close', this.container);
                });
            }
        );
    }

    private closeTooltipOnClick = (event: MouseEvent): void => {
        const tooltip: HTMLElement = this.container.querySelector(Tooltip.DEFAULTS.CONTENT);
        if (event.currentTarget === this.container || !tooltip || !tooltip.classList.contains(Tooltip.DEFAULTS.SHOW) || tooltip.contains(event.target as Node)) {
            return;
        }

        this.eventBus.publish('tooltip:close', this.container);
    }

    private showTooltip = (icon: HTMLElement): void => {
        const tooltip: string = icon.dataset.tooltip;
        this.hideTooltip();

        this.tooltip.querySelector(`#${tooltip}`).classList.add(Tooltip.DEFAULTS.SHOW);
    }

    private hideTooltip = (): void => {
        const tooltipShow: HTMLElement = document.querySelector(`.${Tooltip.DEFAULTS.SHOW}`);

        if (tooltipShow) {
            tooltipShow.classList.remove(Tooltip.DEFAULTS.SHOW);
        }
    }

    private repositionInsideViewport = (): void => {
        const arrowElement: HTMLElement = this.container.querySelector(Tooltip.DEFAULTS.ARROW);

        const offsetX: number = 20;
        const contentBoundingRight: number = this.content.getBoundingClientRect().right;
        const contentLeftProperty: number = Number(this.content.style.left.replace('px', '') || 0);

        if (contentBoundingRight + offsetX > window.innerWidth) {
            const distance: number = window.innerWidth - (contentBoundingRight + offsetX);
            this.content.style.left = `${distance + contentLeftProperty}px`;
            arrowElement.style.left = `${-distance - contentLeftProperty}px`;
        }
    }

    private isRepositionNeeded = (): boolean => (
        this.content.classList.contains('tooltip-show') || this.isInitRequired
    )
}

export default class TooltipHandler {

    public static initTooltips = (eventBus: EventBus): void => {
        const tooltipArr: Tooltip[] = [];
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        document.querySelectorAll('.tooltip-container').forEach((container: HTMLElement): void => {
            if (container.querySelector('.tooltip-content')) {
                tooltipArr.push(new Tooltip(container, additionalOptions));
            }
        });

        eventBus.subscribe(ETooltipEvent.INIT, (payload: { element: HTMLElement }) => {
            if (payload.element.querySelector('.tooltip-content')) {
                tooltipArr.push(new Tooltip(payload.element, additionalOptions));
            }
        });
    }
}
