import {IFootnote} from 'PyzShopUi/scripts/shop-ui/interfaces';
import {FootnoteActions, FootnoteValues} from 'PyzShopUi/scripts/footnote/constants';
import {VIEW_MODE_LIST} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';

/**
 * Depend on ACTION in a placement to customize footnotes before display
 * @param action
 * @param footNotes
 * @param viewMode
 * @private
 */
export function restrictFootnotesByAction(action: FootnoteActions, footNotes: IFootnote[], viewMode: string): IFootnote[] {
    switch (action)
    {
        case FootnoteActions.ARTICLE_LIST_FOOTNOTE:
            if (viewMode === VIEW_MODE_LIST) {
                return footNotes.filter(f => f.identifier !== FootnoteValues.GENERAL_VAT);
            }

            return [];
        case FootnoteActions.GENERAL_FOOTNOTE:
            if (viewMode === VIEW_MODE_LIST) {
                return footNotes.filter(f => f.identifier === FootnoteValues.GENERAL_VAT);
            }
    }

    return footNotes;
}
