import * as React from 'react';

interface IBrandItemProps {
    name: string;
    html: string;
    url: string;
    isCompactMode?: boolean;
    gtm?: any[];
}

const BrandItem: React.FunctionComponent<IBrandItemProps> = props => {
    const {
        html,
    } = props;

    return (
        <article className='product-item'>
            <div dangerouslySetInnerHTML={{ __html: html }}>
            </div>
        </article>
    );
};

export default BrandItem;
