class Print {
    private button: HTMLElement;

    constructor(button: HTMLElement) {
        this.button = button;
        this.clickHandlerToOpenPrintDialog();
    }

    private clickHandlerToOpenPrintDialog = () => {
        this.button.addEventListener('click', (): void => {
            window.print();
        });
    }
}

export default class PrintHandler {

    public static init = (): void => {
        document.querySelectorAll('.print-page-button').forEach((button: HTMLElement): void => {
            new Print(button);

            //Accessibility event listener for ENTER key when focused
            button.addEventListener('keydown', (event): void => {
                if (event.key === 'Enter') {
                    window.print();
                }
            });
        });
    }
}
