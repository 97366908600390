import EventBus from '../../utils/eventBus';
import IModuleOptions from '../../interfaces/IModuleOptions';
import { EDialogEvents } from '../events/dialogEvent';
import { EVideoEvents } from '../events/videoEvents';

class BrandVideo {
    private static VIDEO_DIALOG = {
        OPEN_TIMEOUT: 1500,
    };

    private mediaCenterWrapper: HTMLElement;
    private eventBus: EventBus;
    private readonly videoDialogTriggers: NodeListOf<HTMLElement>;

    private readonly videoDialogId: string;
    private readonly videoDialogContainer: HTMLElement;
    private videoDialogOpenTimeoutId: number;

    constructor(container: HTMLElement, options: IModuleOptions) {
        this.mediaCenterWrapper = container;
        this.eventBus = options.eventBus;
        this.videoDialogTriggers = this.mediaCenterWrapper.querySelectorAll('[data-video-dialog-trigger]');

        if (this.videoDialogTriggers.length) {
            this.videoDialogId = this.videoDialogTriggers[0].getAttribute('data-video-dialog-trigger');
            this.videoDialogContainer = this.mediaCenterWrapper.querySelector(`#${this.videoDialogId}`);
        }

        if (this.videoDialogTriggers.length) {
            this.videoDialogTriggers.forEach(videoDialogTrigger => {
                videoDialogTrigger.addEventListener('click', this.openVideoDialog);
                videoDialogTrigger.addEventListener('keydown', (e) => {
                    if (e.key === 'Enter') {
                        this.openVideoDialog();
                    }
                });
            });

            this.eventBus.subscribe(EVideoEvents.META_LOADED, this.checkAndPublishVideoDialogOpen);
        }
    }

    private openVideoDialog = (): void => {
        this.eventBus.publish(EVideoEvents.PRELOAD, this.videoDialogContainer);

        this.videoDialogOpenTimeoutId = window.setTimeout((): void => {
            this.eventBus.publish(EDialogEvents.OPEN_DIALOG, { id: this.videoDialogId });
        }, BrandVideo.VIDEO_DIALOG.OPEN_TIMEOUT);

        this.eventBus.publish(EVideoEvents.META_LOADED, this.videoDialogContainer);
    }

    private checkAndPublishVideoDialogOpen = (): void => {
        const videos = this.videoDialogContainer.querySelectorAll('video');
        let loadedVideos: number = 0;

        videos.forEach((video: HTMLVideoElement): void => {
            loadedVideos += (video.readyState > 0 ? 1 : 0);
        });

        if (videos.length === loadedVideos) {
            this.eventBus.publish(EDialogEvents.OPEN_DIALOG, { id: this.videoDialogId });
            window.clearTimeout(this.videoDialogOpenTimeoutId);
        }
    }
}

export default class BrandVideoHandler {
    public static init = (eventBus: EventBus): void => {
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        document.querySelectorAll('.brand-video-handler').forEach((brandVideoElement: HTMLElement): void => {
            new BrandVideo(brandVideoElement, additionalOptions);
        });
    }
}
