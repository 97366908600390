import * as React from 'react';
import Utils from 'PyzShopUi/scripts/utils/utils';

export interface IInputProps {
    label?: string;
    name: string;
    value?: string | number;
    dataQa?: string;
    isInvalid?: boolean;
    additionalInputClass?: string;
    iconClass?: string;
    iconLabel?: string;
    isEnableAutocomplete?: boolean;
    inputRef?: any;
    placeholder?: string;
    isFullWidth?: boolean;
    inputProps?: React.HTMLProps<HTMLInputElement>

    onValueChange(name: string, value: string): void;

    onSubmit?(event: React.FormEvent<HTMLFormElement>): void;
}

const Input: React.FunctionComponent<IInputProps> = props => {
    const {
        name,
        isInvalid,
        onValueChange,
        value,
        dataQa,
        label,
        additionalInputClass,
        iconClass,
        iconLabel,
        isEnableAutocomplete,
        inputRef,
        placeholder,
        isFullWidth,
        onSubmit,
        inputProps,
    } = props;

    const [inputFocus, setInputFocus] = React.useState(false);
    const [localValue, setLocalValue] = React.useState('');

    const [styleOutline, setStyleOutline] = React.useState('');
    const [styleLabel, setStyleLabel] = React.useState('');
    const [inValidStyle, setInValidStyle] = React.useState('');
    const [focusStyle, setFocusStyle] = React.useState('');
    const [notchWidth, setNotchWidth] = React.useState<string>('initial');

    const labelRef = React.useRef<HTMLLabelElement>(null);

    React.useEffect(() => {
        if (isInvalid) {
            setInValidStyle('mdc-text-field--invalid');
        } else {
            setInValidStyle('');
        }
    }, [isInvalid]);

    React.useEffect(() => {
        if (inputFocus || parseStringValue() !== '') {
            setStyleOutline('mdc-notched-outline--notched');
            setStyleLabel('mdc-floating-label--float-above');
        } else {
            setStyleOutline('');
            setStyleLabel('');
            setNotchWidth('auto');
        }

        if (inputFocus) {
            setFocusStyle('mdc-text-field--focused');
        } else {
            setFocusStyle('');
        }

    }, [inputFocus, value]);

    let id: string = name + "-" + Utils.getRandomId();
    if(inputProps?.id){
        id = inputProps.id
    }
    const onFocus = () => {
        setInputFocus(true);
    };

    const onBlur = () => {
        setInputFocus(false);
    };

    const parseStringValue = () => {
        let checkValue = value ? value : localValue;
        if (checkValue === null || checkValue === undefined) {
            checkValue = '';
        }

        if (typeof value === 'number') {
            checkValue = value.toString();
        }

        return checkValue;
    };

    const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLocalValue(event.target.value);
        onValueChange(event.target.name, event.target.value);
    };

    const displayLabel = () => {
        if (!label) {
            return null;
        }

        return (
            <div className="mdc-notched-outline__notch" style={{ width: `${notchWidth}` }}>
                <label onTransitionEnd={onLabelTransitionEnd} ref={labelRef} htmlFor={id}
                       className={`mdc-floating-label ${styleLabel}`}>{label}</label>
            </div>
        );
    };


    const onLabelTransitionEnd = () => {
        const notchWidthValue = labelRef
            .current
            .getBoundingClientRect()
            .width;

        if (inputFocus || parseStringValue() !== '') {
            setNotchWidth(notchWidthValue + 4 + 'px');
        }
    };

    const displayIcon = () => {
        if (!iconClass) {
            return null;
        }

        const ariaLabel = iconLabel ?? label;

        return (
            <button
                type="submit"
                tabIndex={-2}
                className={`mdc-text-field__icon icon ${iconClass}`}
                aria-label={ariaLabel ?? name}
            />
        );
    };

    return (
        <form
            className={`mdc-text-field mdc-text-field--outlined ${inValidStyle} ${focusStyle}` + (isFullWidth ? ' mdc--fullwidth' : '')}
            onSubmit={onSubmit}>
            <input
                {...inputProps}
                name={name}
                id={id}
                ref={inputRef}
                value={value !== null ? value : ''}
                data-qa={dataQa || ''}
                className={`mdc-text-field__input ${additionalInputClass}` + (placeholder ? ' mdc-text-field__placeholder' : '')}
                autoComplete={isEnableAutocomplete ? 'on' : 'off'}
                placeholder={placeholder}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={handleValueChange}
            />
            {displayIcon()}
            <div className={`mdc-notched-outline mdc-notched-outline--upgraded ${styleOutline}`}>
                <div className="mdc-notched-outline__leading"/>
                {displayLabel()}
                <div className="mdc-notched-outline__trailing"/>
            </div>
        </form>
    );
};

Input.defaultProps = {
    additionalInputClass: '',
    isEnableAutocomplete: false,
    isFullWidth: false,
    onSubmit: (event) => (event.preventDefault()),
};

export default Input;
