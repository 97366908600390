import * as React from 'react';
import { IStoreConfig } from '../../../../../shop-ui/components/search-page/interfaces';
import { IProductItem } from '../../../../../shop-ui/interfaces';
import ListItem from './ListItem';

interface IProductItemsByCategoryProps {
    title: string;
    products: IProductItem[];
    storeConfig: IStoreConfig;
}

const ProductItemsByCategory: React.FunctionComponent<IProductItemsByCategoryProps> = props => {
    const { title, products, storeConfig } = props;

    const displayListItems = () => products.sort(
        function(a, b) {
            return a.abstractName.localeCompare(b.abstractName);
        }
    ).map(product => <ListItem key={product.sku} product={product} storeConfig={storeConfig} />)

    return (
        <React.Fragment>
            <tr>
                <td className='title' colSpan={3}>
                    <h2 className="heading4"> {title} </h2>
                </td>
            </tr>
            {displayListItems()}
        </React.Fragment>
    );
};

export default ProductItemsByCategory;
