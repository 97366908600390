import './skip-to-main-content.scss';

import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('skip-to-main-content', () =>
    importWithFallback(
        /* webpackMode: "lazy" */
        () => import('./skip-to-main-content')
    ),
);
