export const PARAM_SEARCH_TERM = 'search_term';
export const PARAM_SORTER = 'sort';
export const PARAM_BIKE_VIEW = 'bike_view';
export const PARAM_PAGE = 'page';

export const SEARCH_DEBOUNCE_TIME = 600;

export const MANUFACTURER_FILTER = 'manufacturer';
export const BIKE_TYPE_FILTER = 'biketype';
export const CAPACITY_FILTER = 'capacity';

export const FILTER_ORDERS = [MANUFACTURER_FILTER, BIKE_TYPE_FILTER, CAPACITY_FILTER];

export const VIEW_OPTION_GRID = 'grid';
export const VIEW_OPTION_LIST = 'list';
export const SINGLE_FILTER_TYPE = 'single';

export const MY_BIKE_IS_FULL_REASON = 'myBikeIsFull';
export const SORTER_OPTION_BEST_MATCH = 'relevance_desc';
export const SORTER_OPTION_MANUFACTURER_YEAR = 'manufacture_year';
