import axios, { AxiosResponse } from 'axios';
import IMyBikeService, { IConvertedBikeData } from '../../interfaces/myBike/service/IMyBikeService';
import { IMyBikeDataResponse } from '../interfaces/IMyBikeDataResponse';
import { IBikeItem } from '../../bike/interfaces';

const myBikeApiEndpoint = '/api/my-bikes/v1/bikes';

export default class MyBikeService implements IMyBikeService {
    public static getInstance(): MyBikeService {
        if (this.instance === null) {
            this.instance = new MyBikeService();
        }

        return this.instance;
    }

    private static instance: MyBikeService = null;

    public fetchMyBikeListFromAPI = async (): Promise<IConvertedBikeData> => {
        const bikeDataResponse: AxiosResponse<IMyBikeDataResponse> = await axios.get(myBikeApiEndpoint);

        const bikeDataResponseData = bikeDataResponse.data;

        const convertedBikes: IBikeItem[] = this.convertBikes(bikeDataResponseData);

        return {
            bikes: convertedBikes,
            extraInformation: {
                imageEmptyMyBikeUrl: bikeDataResponseData.emptyMyBikeImage,
                placeholderImage: '//cdn1.louis.de/dynamic/articles/o_pad,w_300,h_300,ha_center,va_center,c_fff::o_extension,e_webp/59.5a.4c.placeholder.JPG',
                totalBikes: bikeDataResponseData.totalBikes,
            },
            pagination: {
                page: 1,
                perPage: 24,
                totalItems: convertedBikes.length,
            },
            sorters: [],
            viewOptions: undefined,
        };
    };

    private convertBikes = (bikeDataResponseData: IMyBikeDataResponse): IBikeItem[] => (
        bikeDataResponseData.myBikes.map((bike: IBikeItem): IBikeItem => {
            bike.isShown = true;
            return bike;
        })
    );
}
