import { FilterNames } from '../../constants';
import { IFilterStateHandler, ISelectFilterOption, ISelectFilter } from '../../../shop-ui/components/search-page/interfaces';
import AbstractSelectFilterHandler from '../../../shop-ui/includes/filter-state/AbstractSelectFilterHandler';

export default class ColorFilterHandler extends AbstractSelectFilterHandler implements IFilterStateHandler {
    public createQueryParams = (filter: ISelectFilter, params: object): void => {
        params[FilterNames.COLOR] = filter.options
            .filter(option => option.selected)
            .map(selectedOption => selectedOption.value);
    }

    public resetQueryParams = (params: object): void => {
        params[FilterNames.COLOR] = [];
    }

    public getSelectedOptions = (filter: ISelectFilter): ISelectFilterOption[] => filter.options
        .filter(option => option.selected);

    public updateSelectedOptions = (filter: ISelectFilter, selectedValues: string[]): void => {
        filter.options = filter.options.map((option: ISelectFilterOption) => {
            option.selected = selectedValues.includes(option.value);

            return option;
        });
    }
}
