import * as React from 'react';
import { SwiperOptions } from 'swiper';
import { ESliderMinimumForDisplayed } from '../../../constants';
import { withAPI } from '../../../hoc/withAPI';
import { withSwiper } from '../../../hoc/withSwiper';
import { ISliderProps } from '../../../interfaces';
import SliderContent from '../../content/SliderContent';
import DefaultSliderItem from './DefaultSliderItem';

const DefaultSlider: React.FunctionComponent<React.PropsWithChildren<ISliderProps>> = props => {
    const { title, allItemLink, allItemTitle, children } = props;

    return (
        <SliderContent
            title={title}
            allItemTitle={allItemTitle}
            allItemLink={allItemLink}
        >
            {children}
        </SliderContent>
    );
};

const swiperOptions: SwiperOptions = {
    longSwipesRatio: 0.2,
    longSwipesMs: 100,
    shortSwipes: false,
    breakpoints: {
        320: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
            spaceBetween: 10,
        },
        640: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 16,
        },
    },
};

export default withAPI(ESliderMinimumForDisplayed.DEFAULT)(
    withSwiper(
        swiperOptions,
        DefaultSliderItem,
        'transform-50-negative',
        'transform-50-negative',
        true
    )(DefaultSlider),
);
