import {createRoot} from 'react-dom/client';
import * as React from 'react';
import MiddlewareEventBus from '../react/common/lib/MiddlewareEventBus';
import MyBikeListContainer from '../react/bike-db/containers/MyBikeListContainer';
import BikeGrid from 'PyzShopUi/scripts/bike-search-page/components/search-page-content/bike-list/BikeGrid';
import MyBikeService from './services/MyBikeService';
import { IConvertedBikeData } from '../interfaces/myBike/service/IMyBikeService';
import Loading from 'PyzShopUi/scripts/react/common/components/Loading';

export default class MyBikePageHandler {
    public static init = (): void => {
        const myBikePageElement: HTMLElement = document.querySelector('#my-bikes-list-content');
        if (!myBikePageElement) {
            return;
        }
        const myBikesListContentRoot = createRoot(myBikePageElement);
        const csrfToken: string = myBikePageElement.dataset.csrfToken;

        const middlewareEventBus = MiddlewareEventBus.getInstance();

        const convertedBikeData: Promise<IConvertedBikeData> = MyBikeService.getInstance().fetchMyBikeListFromAPI();
        convertedBikeData.then((data: IConvertedBikeData): void => {
            myBikesListContentRoot.render(
                <React.Suspense fallback={<Loading />}>
                    <MyBikeListContainer
                        middlewareEventBus={middlewareEventBus}
                        bikeGridComponent={BikeGrid}
                        initialBikeData={data}
                        bikeDatabaseUrl={myBikePageElement.getAttribute('data-bike-db-url')}
                        hasSideBar={true}
                        csrfToken={csrfToken}
                    />
                </React.Suspense>
            );
        });
    };
}
