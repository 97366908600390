import * as React from 'react';
import classNames from 'classnames';
import ColorFilterOptions from './ColorFilterOptions';
import Accordion from '../../../../../shop-ui/components/Accordion';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import useGradient from '../../../../../shop-ui/components/search-page/hooks/useGradient';
import useMultipleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useMultipleSelectFilter';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const MAXIMUM_ITEMS_FOR_SCROLL = 30;

const ColorFilter: React.FunctionComponent<IFilterProps> = props => {
    const [isScrollable, onGradientChange, displayTopGradient, displayBottomGradient] = useGradient(props.options, MAXIMUM_ITEMS_FOR_SCROLL);

    const selectedOptions = props.options.filter(option => option.selected);
    const [onToggleItem, displaySelectedFilterOptions] = useMultipleSelectFilter(props.name, selectedOptions, props.dispatch);

    const additionalClasses = classNames(
        { scrollable: isScrollable },
    );
    const { translate } = useTranslationHook();

    return (
        <Accordion
            label={translate('Farbe', { ns: 'article-list-filter'})}
            headerContent={displaySelectedFilterOptions()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className={`color-filter`}>
                {displaySelectedFilterOptions()}

                <div className='scroll-selection'>
                    <ColorFilterOptions
                        additionalClassName={additionalClasses}
                        optionList={props.options}
                        onScrollChange={onGradientChange}
                        onToggleItem={onToggleItem}
                        isKeyboardFocusable={isScrollable}
                    />

                    {displayTopGradient()}
                    {displayBottomGradient()}
                </div>
            </div>
        </Accordion>
    );
};

export default ColorFilter;
