import * as React from 'react';
import TagItem from '../../../../TagItem';
import { ISelectFilterOption } from '../../../interfaces';
import { ISetListFilterOptionPayload, setListFilterOption } from '../../../actions/filter';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ISelectedFilterOptionsProps {
    filterName: string;
    selectedOptionList: ISelectFilterOption[];

    dispatch: React.Dispatch<any>;
}

const SelectedFilterOptions: React.FunctionComponent<ISelectedFilterOptionsProps> = props => {
    const { selectedOptionList, filterName, dispatch } = props;
    const { translate } = useTranslationHook();

    selectedOptionList.sort((option, nextOption) =>
        option.label.toLowerCase().localeCompare(nextOption.label.toLowerCase()),
    );

    const displaySelectedFilterOptions = React.useCallback(() => {
        if (!selectedOptionList.length) {
            return null;
        }

        const tagItemList = selectedOptionList.map(selectedOption => {
            const label = selectedOption.label;
            const value = selectedOption.value;

            const onClose = () => {

                const selectedOptionListCopy = selectedOptionList
                    .filter(option => option.value !== value)
                    .map(option => option.value);

                const payload: ISetListFilterOptionPayload = {
                    filterName,
                    selectedValues: selectedOptionListCopy,
                };

                dispatch(setListFilterOption(payload));
            };

            return (
                <TagItem key={value} label={label} dataQa={`tag-item-${filterName}-${value}`} onClick={onClose}/>
            );
        });

        return (
            <div className={`selected-filter-option-list`}>
                {tagItemList}
            </div>
        );
    }, [selectedOptionList]);

    const onResetFilter = React.useCallback(() => {
        const payload: ISetListFilterOptionPayload = {
            filterName,
            selectedValues: [],
        };

        dispatch(setListFilterOption(payload));
    }, [filterName]);

    const displayFilterResetAllLabel = React.useCallback(() => {
        if (selectedOptionList.length <= 1) {
            return null;
        }

        return (
            <div className='button-reset-all-selected-filter-option'>
                <div 
                    onClick={() => onResetFilter()}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            onResetFilter()
                        }
                    }}
                    role="button"
                    tabIndex={0}
                    className='tag-item'
                >
                    <span>{translate('Reset selection', { ns: 'article-list-filter' })}</span>
                </div>
            </div>
        );
    }, [selectedOptionList]);

    return (
        <React.Fragment>
            {displaySelectedFilterOptions()}
            {displayFilterResetAllLabel()}
        </React.Fragment>
    );
};

export default SelectedFilterOptions;
