import { Filter } from '../../components/search-page/types';
import { IFilterStateHandler, ISelectFilterOption } from '../../components/search-page/interfaces';

export default abstract class AbstractFilterHandler implements IFilterStateHandler {
    abstract createQueryParams(filter: Filter, params: object): void;

    abstract getSelectedOptions(filter: Filter): ISelectFilterOption[];

    abstract isFilterActive(filter: Filter): boolean;

    abstract getSelectionTagComponent(filter: Filter, selectedValues: string[] | number[]): React.FunctionComponent | null;

    abstract resetQueryParams(params: object): void;

    abstract updateSelectedOptions(filter: Filter, selectedValues: string[] | number[]): void;
}
