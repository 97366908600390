class FormFieldFocusNextBehaviour {

    private fields: NodeListOf<HTMLInputElement>;

    public constructor(fields) {
        this.fields = fields;

        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.fields.forEach((field: HTMLInputElement, index: number): void => {
            field.addEventListener('input', (_event: Event): void => {
                if (index + 1 >= this.fields.length) {
                    // do nothing when it is the last field
                    return;
                }

                if (field.value.length >= parseInt(field.getAttribute('maxlength'), 10)) {
                    this.fields[index + 1].focus();
                }
            })
        });
    }
}

export default class FormFieldFocusNextBehaviourHandler {

    public static init = (): void => {

        document.querySelectorAll('[data-input-next="true"]').forEach((wrapper: HTMLElement): void => {
            const fields: NodeListOf<HTMLInputElement> = wrapper.querySelectorAll('input');

            if (!fields) {
                return;
            }

            new FormFieldFocusNextBehaviour(fields);
        })
    }
}
