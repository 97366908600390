import EventBus from '../utils/eventBus';
import IModuleOptions from '../interfaces/IModuleOptions';
import { EToastEvents } from './events/toastEvent';
import { IToastPayload } from '../interfaces/IToastPayload';
import { createRoot } from 'react-dom/client';
import React from 'react';

class Toast {
    public static DEFAULTS = {
        DISPLAY_TIME: 2200,
        FADE_IN_SPEED: 300,
        FADE_OUT_SPEED: 300,
        TOAST_CLASS: '.toast',
        TOAST_LINK: '.toast a'
    };

    private eventBus: EventBus;
    private body = document.querySelector('body');

    constructor(options: IModuleOptions) {
        this.eventBus = options.eventBus;
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.eventBus.subscribe(EToastEvents.TOAST_FADE_IN, (payload: IToastPayload) => {
            const element = document.createElement('div');
            const elementRoot = createRoot(element);
            this.body.append(element);

            elementRoot.render(
                <div className="toast toast-dark" data-qa="toast" onAnimationEnd={() => element.remove()}>
                    <i className={`icon ${payload.iconClass || "icon-check"} `}></i>
                    <span>{payload.message}</span>
                    {payload.link ? <>&nbsp;<a onClick={() => payload.link.callback()}>{payload.link.text}</a></> : null}
                </div>
            );
        });
    }
}

export default class ToastHandler {
    public static init = (eventBus: EventBus) => {
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        new Toast(additionalOptions);
    }
}
