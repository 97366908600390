import CmsStoreUtils from '../utils/cmsStoreUtils';

class CmsStoreLocationDropdown {
    private locationDropdown: HTMLSelectElement;

    constructor(locationDropdown: HTMLSelectElement) {
        this.locationDropdown = locationDropdown;

        // the select/data-switch outlives a reload (at least on firefox),
        // therefore we need to lazyload on init to prevent missing images
        this.lazyLoadStoreImages();

        this.addEventListener();
    }

    // note: the actual filtering of store teasers is done by data-switch functionality
    // - we just need to take care of the "surrounding" events here
    private addEventListener = () => {
        this.locationDropdown.addEventListener('change', () => {
            CmsStoreUtils.resetMapHighlight();
            CmsStoreUtils.clearSearchInput();
            CmsStoreUtils.hideStoreDistances();

            const locationDropdownValue = this.locationDropdown.value;
            if (locationDropdownValue !== '__default__') {
                CmsStoreUtils.showSearchTermIndicator(locationDropdownValue);

                // set the "cleaned" location as query parameter
                const cleanLocationName = CmsStoreUtils.cleanLocationName(locationDropdownValue);
                CmsStoreUtils.setQueryParameter(`l=${cleanLocationName}`);
            } else {
                CmsStoreUtils.setQueryParameter('');
            }

            this.lazyLoadStoreImages();
        });
    };

    private lazyLoadStoreImages = () => {
        document.querySelectorAll('.cms_store-teaser').forEach(store => {
            if (store instanceof HTMLElement && store.dataset.location === this.locationDropdown.value) {
                CmsStoreUtils.showStoreImage(store);
            }
        });
    }
}

export default class CmsStoreLocationDropdownHandler {
    public static init = () => {
        document.querySelectorAll('.cms_store__location-dropdown').forEach((locationDropdown): void => {
            new CmsStoreLocationDropdown(locationDropdown as HTMLSelectElement);
        });
    }
}
