import * as React from 'react';
import { ISelectFilterOption } from '../interfaces';
import { ISetToggleFilterOptionPayload, setToggleFilterOption } from '../actions/filter';

function useToggleFilter(filterName: string, options: ISelectFilterOption[], dispatch: React.Dispatch<any>): [boolean, () => void] {
    const isSelected = React.useCallback(() => {
        if (!options.length) {
            return false;
        }

        const selectedOption = options.filter(option => option.selected);

        return !!(selectedOption.length && selectedOption[0].value === 'true');
    }, [options]);

    const onToggleFilter = () => {
        const payload: ISetToggleFilterOptionPayload = {
            filterName,
            selectedValue: isSelected() ? null : 'true',
        };

        dispatch(setToggleFilterOption(payload));
    };

    return [isSelected(), onToggleFilter];
}

export default useToggleFilter;
