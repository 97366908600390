import axios from 'axios';
import { ResponseConstants } from '../../constants';

const disableRecommendedDomainEndpoint = '/ajax/disable-recommended-domain-panel';

class RecommendedDomainService {
    public disableRecommendedDomain = async (supportedDomains: string[]): Promise<boolean> => {
        const response = await axios.post(disableRecommendedDomainEndpoint, {
            supportedDomains
        });

        return response.status === ResponseConstants.HTTP_NO_CONTENT;
    };
}

export const recommendedDomainService = new RecommendedDomainService();
