import {
    CONSENT_CHANGED_EVENT_NAME,
    handleAcceptAll
} from "../../../../../CookieNoticeWidget/Theme/default/components/molecules/cookie-notice/cookie-notice";
import {isCookieNoticeAccepted} from "PyzShopUi/scripts/shop-ui/services/GoogleTagManagerService";

const EMBED_ACTIVATED_ATTRIBUTE = 'data-embed';
const EMBED_DEACTIVATED_ATTRIBUTE = 'data-embed-with-consent';
const CONSENT_NOTICE_HIDDEN_CLASS = 'cms_youtube__consent-notice-wrapper--hidden';

/**
 * Handle user consent regarding cookies and 3rd party content for CMS YouTube elements.
 *
 * The elements will render by default in a way that needs no consent - the element will be a link to YouTube.
 * If the user has given consent this function will enhance the element to be played in a lightbox on the page.
 *
 * See: NEOS Project `neos/DistributionPackages/Louis.Site/Resources/Private/Fusion/Integration/Content/Video/Video.fusion`
 */
function init() {
    // don't handle in Neos CMS backend
    if (!document.body.classList.contains('neos-backend')) {
        document.querySelectorAll('.jonnitto-prettyembed-wrapper').forEach(wrapper => {
            try {
                const consentNotice = wrapper.querySelector('.cms_youtube__consent-notice-wrapper') as HTMLDivElement;
                const consentAcceptButton = wrapper.querySelector('.cms_youtube__consent-accept-button') as HTMLButtonElement
                const prettyEmbedElement = wrapper.querySelector('.jonnitto-prettyembed') as HTMLAnchorElement

                consentAcceptButton.onclick = handleAcceptAll

                if (isCookieNoticeAccepted()) {
                    // activate embed lightbox
                    prettyEmbedElement.setAttribute(EMBED_ACTIVATED_ATTRIBUTE, prettyEmbedElement.getAttribute(EMBED_DEACTIVATED_ATTRIBUTE));
                    // hide consent banner
                    consentNotice.classList.add(CONSENT_NOTICE_HIDDEN_CLASS);
                } else {
                    // deactivate embed lightbox
                    prettyEmbedElement.removeAttribute(EMBED_ACTIVATED_ATTRIBUTE);
                    // show consent banner
                    consentNotice.classList.remove(CONSENT_NOTICE_HIDDEN_CLASS);
                }
            } catch (e) {
                console.error(e)
            }
        });
    }
}

// register event listender for user consent change
window.addEventListener(CONSENT_CHANGED_EVENT_NAME, init);

export default {
    init,
}
