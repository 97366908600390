import * as React from 'react';
import { FILTER_ITEM_SEARCH_DEBOUNCE_TIME_MS } from '../constants';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ISelectFilterOption } from '../interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

type TOnSearchInputChange = (name: string, value: string) => void;
type TElementRender = () => null | React.ReactElement;

function useSearchable(items: ISelectFilterOption[], searchInputObserver: Subject<string>): [ISelectFilterOption[], TOnSearchInputChange, TElementRender] {
    const [searchValue, setSearchValue] = React.useState<string>('');
    const { translate } = useTranslationHook();

    React.useEffect(() => {
        const subscription = searchInputObserver
            .pipe(debounceTime(FILTER_ITEM_SEARCH_DEBOUNCE_TIME_MS))
            .subscribe(value => {
                setSearchValue(value);
            });

        return () => subscription.unsubscribe();
    }, []);

    const filterBySearch = React.useCallback(() => (
        items.filter(option =>
            option.label.toLowerCase().replace(/\s/g, '').includes(
                searchValue.toLowerCase().replace(/\s/g, ''),
            ),
        )
    ), [items, searchValue]);

    const onSearchChange = (name: string, value: string) => searchInputObserver.next(value);

    const resultItems = filterBySearch();

    const displayNoMatchText = React.useCallback(() => {
        if (resultItems.length > 0) {
            return null;
        }

        return (
            <div className="no-match-text">
                {translate('No matches', { ns: 'article-list' })}
            </div>
        );
    }, [resultItems]);

    return [resultItems, onSearchChange, displayNoMatchText];
}

export default useSearchable;
