import * as React from 'react';
import { Dialog, DialogContent, IconButton } from '@mui/material';
import SearchInput from 'PyzShopUi/scripts/react/common/components/form/SearchInput';
import { searchValueObserver } from 'PyzShopUi/scripts/search-suggestion/includes/searchSuggestionHelper';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import { ReactFoundationEvent } from 'PyzShopUi/scripts/react/common/events/foundationEvent';

interface ISmallScreenLayoutProps {
    searchValue: string;
    isVisible: boolean;
    searchUrl: string;
    children?: React.ReactNode;

    onClose(): void;
}

export const SmallScreenLayout: React.FunctionComponent<ISmallScreenLayoutProps> = props => {
    const { searchValue, isVisible, children, searchUrl, onClose } = props;

    const [searchInputValue, setSearchInputValue] = React.useState<string>('');
    const middlewareEventBus = React.useRef<MiddlewareEventBus>(MiddlewareEventBus.getInstance());

    React.useEffect(() => {
        setSearchInputValue(searchValue);
    }, [searchValue]);

    const onSearchChange = (_: string, value: string) => {
        setSearchInputValue(value);
        searchValueObserver.next(value);
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const newSearchUrl = new URL(searchUrl);
        newSearchUrl.searchParams.append('q', searchValue);
        window.location.href = newSearchUrl.toString();
    };

    const onTouchMove = () => {
        //Workaround for IOS Safari keyboard scroll behavior
        middlewareEventBus
            .current
            .publish({ type: ReactFoundationEvent.SMALL_SREEN_SUGGESSTION_ON_TOUCH_MOVE, payload: {} });
    };

    return (
        <div className="small-screen-suggest-result">
            <Dialog
                disablePortal
                open={isVisible}
                onClose={() => onClose()}
                onTouchMove={() => onTouchMove()}>
                <DialogContent>
                    <div className="small-screen-suggest-result__header thin-line">
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            className='icon icon-size-standard icon-chevron-thin-left'
                        >
                        </IconButton>
                        <SearchInput
                            value={searchInputValue}
                            isFullWidth={true}
                            onSearchChange={onSearchChange}
                            onSubmit={onSubmit}
                            inputProps={{ autoFocus: true }}
                        />
                    </div>
                    <div className="small-screen-suggest-result__result-board">
                        {children}
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};
