import FilterStateService from '../services/FilterStateService';
import {
    CATALOG_PAGE_CONTEXT_NAME,
    DEFAULT_CONTEXT_NAME,
    FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    PARAM_CATEGORY_KEY,
    PARAM_PAGE,
    PARAM_PER_PAGE,
    PARAM_SEARCH,
    PARAM_SORT,
    PARAM_VIEW_MODE
} from '../constants';
import {
    IApiQueryData,
    ISearchPageActionState,
    ISearchResponse,
    ICategoryFilter
} from '../interfaces';
import { FilterNames } from '../../../../catalog-page/constants';
import { Filter } from '../types';
import { categoriesTreeService } from '../services/CategoriesTreeService';

export const buildSearchPageState = (
    data: ISearchResponse,
    currentApiQueryData: IApiQueryData,
    filterStateService: FilterStateService,
    excludeQueryParamsFromState: string[] = [],
    contextName: string = DEFAULT_CONTEXT_NAME
): ISearchPageActionState => {
    if (contextName === CATALOG_PAGE_CONTEXT_NAME) {
        return buildCatalogPageSearchPageState(
            data,
            currentApiQueryData,
            filterStateService,
            excludeQueryParamsFromState
        );
    }

    return buildBikeSearchPageState(
        data,
        currentApiQueryData,
        filterStateService,
        excludeQueryParamsFromState
    );
};

const buildBikeSearchPageState = (
    data: ISearchResponse,
    currentApiQueryData: IApiQueryData,
    filterStateService: FilterStateService,
    excludeQueryParamsFromState: string[] = []
): ISearchPageActionState => {
    const newFilterQueryParams = {};
    data.filters.forEach(filter => filterStateService.createQueryParams(filter, newFilterQueryParams));

    const page: number = data.pagination?.currentPage || 0;
    let paramPage: number = page;
    if (page === 0) {
        paramPage = 1;
    }

    const currentItemsPerPage: string = data.pagination?.currentItemsPerPage?.toString() || '0';
    const currentSortParam: string = data.sort?.currentSortParam || null;

    const apiQueryData: IApiQueryData = {
        q: currentApiQueryData.q ? currentApiQueryData.q.toString() : null,
        [PARAM_SORT]: currentSortParam,
        [PARAM_SEARCH]: currentApiQueryData[PARAM_SEARCH],
        [PARAM_CATEGORY_KEY]: currentApiQueryData[PARAM_CATEGORY_KEY],
        [PARAM_PAGE]: paramPage.toString(),
        [PARAM_PER_PAGE]: currentItemsPerPage,
        ...newFilterQueryParams,
    };

    excludeQueryParamsFromState.forEach((apiQueryParamToRemove: string) => {
        delete apiQueryData[apiQueryParamToRemove];
    });

    return {
        filters: data.filters,
        searchResultItems: data.resultItems,
        pagination: data.pagination,
        sort: data.sort,
        searchTerm: currentApiQueryData[PARAM_SEARCH],
        storeConfig: data.storeConfig,
        apiQueryData,
        isPageLoading: true,
        updatePageDebounceTime: FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    };
}

export const buildCatalogPageSearchPageState = (
    data: ISearchResponse,
    currentApiQueryData: IApiQueryData,
    filterStateService: FilterStateService,
    excludeQueryParamsFromState: string[] = []
): ISearchPageActionState => {
    const newFilterQueryParams = {};
    data.filters.forEach(filter => filterStateService.createQueryParams(filter, newFilterQueryParams));

    const page: number = data.pagination?.currentPage || 0;
    let paramPage: number = page;
    if (page === 0) {
        paramPage = 1;
    }

    const currentItemsPerPage: string = data.pagination?.currentItemsPerPage?.toString() || null;
    const currentSortParam: string = data.sort?.currentSortParam || null;

    const apiQueryData: IApiQueryData = {
        q: currentApiQueryData.q ? currentApiQueryData.q.toString() : null,
        [PARAM_SORT]: currentSortParam,
        [PARAM_SEARCH]: currentApiQueryData[PARAM_SEARCH],
        [PARAM_PAGE]: paramPage.toString(),
        [PARAM_CATEGORY_KEY]: currentApiQueryData[PARAM_CATEGORY_KEY],
        [PARAM_PER_PAGE]: currentItemsPerPage,
        [PARAM_VIEW_MODE]: data.viewMode,
        ...newFilterQueryParams,
    };

    excludeQueryParamsFromState.forEach((apiQueryParamToRemove: string) => {
        delete apiQueryData[apiQueryParamToRemove];
    });

    return {
        filters: data.filters,
        searchResultItems: data.resultItems,
        pagination: data.pagination,
        sort: data.sort,
        searchTerm: currentApiQueryData[PARAM_SEARCH],
        storeConfig: data.storeConfig,
        apiQueryData,
        isPageLoading: true,
        viewMode: data.viewMode,
        extraInformation: data.extraInformation,
        updatePageDebounceTime: FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    };
}

export const updateSearchPageTitle = (title: string): void => {
    const header = document.getElementsByClassName('page-layout-catalog-header');

    if (header.length) {
        header[0].innerHTML = title;
    }
}

export const updateSearchPageHeadTitle = (title: string): void => {
    const titleElement = document.querySelector('title');

    titleElement.text = title;
}

export const removeCatalogPageSeoText = (): void => {
    const seoTextElements = document.querySelectorAll('.catalog-page-seo-text');

    seoTextElements.forEach(element => {
        element.remove();
    })
}

export const standardizedCategoryFilter = (
    filters: Filter[],
    apiQueryData: IApiQueryData,
    defaultCategoryParam: string = null
) => {
    for (const filter of filters) {
        if (isCategoryFilter(filter)) {
            filter.name = FilterNames.CATEGORY;
            filter.selectedValue = apiQueryData.categoryKey || defaultCategoryParam || 'ROOT';
            filter.categoryTree = categoriesTreeService.buildFilterTree(filter.categoryTree, filter.selectedValue);

            break;
        }
    }
}

export const isCategoryFilter = (filter: Filter): filter is ICategoryFilter => (filter as ICategoryFilter).categoryTree !== undefined
