import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IProductRatingProps {
    ratingAverage: number;
}

const ProductRatingComponent: React.FunctionComponent<IProductRatingProps> = props => {
    const { ratingAverage } = props;

    const rating = (ratingAverage / 5) * 100;

    return (
        <div className='product-rating'>
            <div className='article-reviews-star-rating-container'>
                <span className='icon disabled icon-star'/>
                <span className='icon disabled icon-star'/>
                <span className='icon disabled icon-star'/>
                <span className='icon disabled icon-star'/>
                <span className='icon disabled icon-star'/>
                <div className='article-reviews-inner-star-rating-container' style={{ width: `${rating}%` }}>
                    <span className='icon highlight icon-star'/>
                    <span className='icon highlight icon-star'/>
                    <span className='icon highlight icon-star'/>
                    <span className='icon highlight icon-star'/>
                    <span className='icon highlight icon-star'/>
                </div>
            </div>
        </div>
    );
};

const ProductRating = withMemo(ProductRatingComponent);

export default ProductRating;
