import * as React from 'react';
import { IStoreSearchResultItem } from '../../interfaces';

interface IStoreResultItemProps {
    store: IStoreSearchResultItem;
}

const StoreResultItem: React.FunctionComponent<IStoreResultItemProps> = props => (
    <div className="store-result-item">
        <div dangerouslySetInnerHTML={{ __html: props.store.html }}>
        </div>
    </div>
);

export default StoreResultItem;
