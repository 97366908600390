import * as React from 'react';
import Loading from 'PyzShopUi/scripts/react/common/components/Loading';
import { IStoreConfig } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { VIEW_MODE_GRID, VIEW_MODE_LIST } from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import ProductResultList from 'PyzShopUi/scripts/catalog-page/components/catalog-page-content/product-list/list-view/ProductResultList';
import ProductResultGrid from 'PyzShopUi/scripts/catalog-page/components/catalog-page-content/product-list/grid-view/ProductResultGrid';
import { IProductItem } from 'PyzShopUi/scripts/shop-ui/interfaces';
import Footnote from 'PyzShopUi/scripts/footnote/components/Footnote';
import { EFootnoteNamespace } from 'PyzShopUi/scripts/shop-ui/constants';
import FootnoteService from 'PyzShopUi/scripts/shop-ui/services/FootnoteService';

interface IProductListProps {
    products: IProductItem[];
    isProgressing: boolean;
    storeConfig: IStoreConfig;
    hasActiveFilter: boolean;
    isCompactMode: boolean;
    viewMode?: string;
}

const ProductList: React.FunctionComponent<IProductListProps> = props => {
    const { products, isProgressing, storeConfig, viewMode, hasActiveFilter, isCompactMode } = props;
    const { translate } = useTranslationHook();

    if (!products) {
        return null;
    }

    if (isProgressing) {
        return <Loading />;
    }

    if (!products.length && !hasActiveFilter) {
        return (
            <div className='text-center result-empty-list'>
                <i className='icon icon-error icon-size-large'/>
                <h2> {translate('Oops...', { ns: 'article-list' })} </h2>
                <h3> {translate('Sorry, but we currently have no products in this category in stock.', { ns: 'article-list' })} </h3>
                <span> {translate('So, come by again sometime. Thank you!', { ns: 'article-list' })} </span>
            </div>
        )
    }

    if (!products.length) {
        return (
            <div className='grid-x grid-padding-x small-12'>
                <div className='cell text-center empty-article-list'>
                    <i className='icon icon-error icon-size-large'/>
                    <h2>{translate('Wow, you really like to filter!', { ns: 'article-list' })}</h2>
                    <h3>
                        {translate('Unfortunately we have not found anything suitable.', { ns: 'article-list' })}
                    </h3>
                    <span>{translate('Just change your filter selection a little bit.', { ns: 'article-list' })}</span>
                </div>
            </div>
        );
    }

    if (viewMode === VIEW_MODE_LIST) {
        return  <ProductResultList products={products} storeConfig={storeConfig} />;
    }

    return <ProductResultGrid products={products} storeConfig={storeConfig} isCompactMode={isCompactMode} />;
};

ProductList.defaultProps = {
    viewMode: VIEW_MODE_GRID
};

export default ProductList;
