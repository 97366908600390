import { IActionCreator } from '../interfaces';

export function createActionCreator<T = void>(type: string): IActionCreator<T> {
    const actionCreator: IActionCreator<T> = payload => {
        if (typeof payload === 'undefined') {
            return {
                type,
            }
        }

        return {
            type,
            payload,
        }
    }

    actionCreator.type = type;

    return actionCreator;
}
