import './quantity-counter.scss';

import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('quantity-counter', () =>
    importWithFallback(
        /* webpackMode: "lazy" */
        () => import('./quantity-counter')
    ),
);
