import { MDCTextField } from '@material/textfield';
import { MDCSelect } from '../types/MDCSelect';
import React from 'react';
import { createRoot } from 'react-dom/client';

import MaterialDesignComponentHandler from './materialDesignComponents';  

import Utils from '../utils/utils';
import FormUtils from '../utils/formUtils';
import EventBus from '../utils/eventBus';
import IModuleOptions from '../interfaces/IModuleOptions';
import IScrollingPublisherOptions from '../interfaces/IScrollingPublisherOptions';
import { EAccordionEvent } from 'PyzShopUi/scripts/modules/events/accordion.event';
import i18next from 'i18next';

class FormValidator {
    private static DEFAULTS = {
        FIELDS_SELECTOR: '.mdc-text-field > input, .mdc-select > select, .mdc-text-field > textarea',
        RADIO_FIELDS_SELECTOR: '.mdc-radio > input, .rating-star-container > input',
        RADIO_FIELD_ERROR_SELECTOR: '.radio-toggle-validation-error',
        CHECKBOX_SELECTOR: '.mdc-checkbox > input[type="checkbox"]',
        CHECKBOX_ERROR_INDICATOR_CLASS: 'checkbox-error-indicator',
        INVALID_FIELDS_SELECTOR: '.mdc-text-field > input:invalid, .mdc-select > select:invalid, .mdc-text-field > textarea:invalid',
        INVALID_STATE_SELECTOR: '.mdc-text-field--invalid, .mdc-select--invalid, .radio-toggle-validation-error:not(.hide)',
        CONFIRM_FIELDS_SELECTOR_MAP: {
            'input[name="register[register-mail-confirm]"]': 'input[name="customer-data-fieldset[email]"]',
            'input[name="register[register-password-confirm]"]': 'input[name="register[register-password]"]',
            'input[name="changepwd-password-confirm"]': 'input[name="changepwd-password"]',
            'input[name="registerForm[email][confirm]"]': 'input[name="registerForm[email][email]"]', // spryker customer register
            'input[name="registerForm[customer][email][confirm]"]': 'input[name="registerForm[customer][email][email]"]',// spryker checkout register
            'input[name="guestForm[customer][email][confirm]"]': 'input[name="guestForm[customer][email][email]"]', // spryker checkout as guest
        },
        SCROLL_OFFSET: 50,
        TOOLTIP_CLOSE: '.tooltip-close',
        PHONE_DEFAULT_ERROR_MESSAGE: "'The entered value is invalid'"
    };
    private form: HTMLElement;
    private eventBus: EventBus;
    private singleDigitInputField: HTMLInputElement;
    private adpQuantityInputField: HTMLInputElement;
    readonly maxDigits: number = 1;

    public constructor(form: HTMLElement, options: IModuleOptions) {
        this.form = form;
        this.eventBus = options.eventBus;

        this.addCheckBoxFormFieldValidatorListener();
        this.addRadioFormFieldValidatorListener();
        this.addStandardFormFieldValidatorListener();
        this.scrollToFirstInvalidField();
        this.singleDigitInputHandler('paymentForm_paymentFinancing_identityCard_identity_card_checksum'); // for checksum
        this.adpQuantityInputHandler();
    }

    private addStandardFormFieldValidatorListener = (): void => {
        const fields: NodeListOf<HTMLInputElement> = this.form.querySelectorAll(FormValidator.DEFAULTS.FIELDS_SELECTOR);

        fields.forEach(field => {
            const mdcObject = MaterialDesignComponentHandler.getMDCObjectForFormField(field, false);
            const parentElement = field.parentElement;
            const warningClass = this.getWarningClass(field);

            if (!mdcObject) {
                return;
            }

            field.addEventListener('blur', (): void => {
                this.eventBus.publish('radioButtonOption:resize');

                if (field.classList.contains('uppercase')) {
                    field.value = field.value.toUpperCase();
                }

                this.validateFormField(field, mdcObject);
            });

            field.addEventListener('invalid', (event: Event): void => {
                event.preventDefault();
                this.eventBus.publish('radioButtonOption:resize');
                this.validateFormField(field, mdcObject);
                this.scrollToFirstInvalidField(field);
                return;
            });

            field.addEventListener('input', (): void => {
                this.eventBus.publish('radioButtonOption:resize');
                mdcObject.valid = true;
                mdcObject.getDefaultFoundation().setValid(true)

                field.setCustomValidity('');

                if (parentElement.classList.contains(warningClass)) {
                    const tooltipClose: HTMLElement = parentElement.querySelector(FormValidator.DEFAULTS.TOOLTIP_CLOSE);
                    tooltipClose.click();
                }
            });
        });
    }

    private addCheckBoxFormFieldValidatorListener = (): void => {
        const fields: NodeListOf<HTMLInputElement> = this.form.querySelectorAll(FormValidator.DEFAULTS.CHECKBOX_SELECTOR);

        fields.forEach(field => {
            const formField = field.closest('.form-field');
            field.addEventListener('invalid', (event: Event): void => {
                event.preventDefault();
                formField.classList.add(FormValidator.DEFAULTS.CHECKBOX_ERROR_INDICATOR_CLASS);

                this.scrollToFirstInvalidField(field);
            });

            const removeErrorClass = (): void => {
                formField.classList.remove(FormValidator.DEFAULTS.CHECKBOX_ERROR_INDICATOR_CLASS)
            };

            field.addEventListener('input', removeErrorClass);
            field.addEventListener('change', removeErrorClass);

            this.form.addEventListener('reset', removeErrorClass)
        });
    }

    private singleDigitInputHandler = (elementId:string): void=> {
        this.singleDigitInputField = document.getElementById(elementId) as HTMLInputElement;
        this.singleDigitInputField?.addEventListener('input', this.triggerInputEvent);
    }

    private readonly triggerInputEvent = (): void => {
        if (this.singleDigitInputField.value.length > this.maxDigits) {
            this.singleDigitInputField.value = this.singleDigitInputField.value.substring(0, this.maxDigits); // to prevent user from entering more than 1 digit
        }
        this.singleDigitInputField.value = this.singleDigitInputField.value.replace('e', ''); // to omit e characters if any
    };

    private addRadioFormFieldValidatorListener = (): void => {
        const fields: NodeListOf<HTMLInputElement> = this.form.querySelectorAll(FormValidator.DEFAULTS.RADIO_FIELDS_SELECTOR);

        fields.forEach(field => {
            const fieldNameAttr = field.name;

            const errorBoxes = Array.from(field.closest('.grid-container').querySelectorAll(FormValidator.DEFAULTS.RADIO_FIELD_ERROR_SELECTOR));
            const errorBox = errorBoxes.filter((errorField: HTMLElement): boolean => errorField.dataset['messageBoxFor'] === fieldNameAttr);

            if (errorBox.length === 0) {
                /* eslint-disable no-console */
                console.error('missing error message for invalid input-radio-boxes');
                console.error('  field: ', field);
                /* eslint-enable no-console */
                return;
            }

            field.addEventListener('invalid', (event: Event): void => {
                event.preventDefault();
                errorBox.forEach(e => e.classList.remove('hide'));
                this.scrollToFirstInvalidField(field);
            });

            const hideErrorBox = (): void => {
                errorBox.forEach(e => e.classList.add('hide'));
            };

            field.addEventListener('input', hideErrorBox);
            field.addEventListener('change', hideErrorBox);

            this.form.addEventListener('reset', hideErrorBox)
        });
    }

    private adpQuantityInputHandler = (): void => {
        this.adpQuantityInputField = document.querySelector('[data-qa="article-order-quantity"]') as HTMLInputElement;
        this.adpQuantityInputField?.addEventListener('input', this.restrictInputHandler);
    };

    private readonly restrictInputHandler = (): void => {
        const maxCartQuantityLength = 4;
        if (this.adpQuantityInputField.value.length > maxCartQuantityLength) {
            this.adpQuantityInputField.value = this.adpQuantityInputField.value.substring(0, maxCartQuantityLength); // to prevent user from entering more than 3 digits
        }
        this.adpQuantityInputField.value = this.adpQuantityInputField.value.replace('e', ''); // to omit e characters if any
    };

    private validateFormField = (input: HTMLInputElement, mdcField: MDCTextField | MDCSelect): void => {
        if (!input.validity.valid) {
            // ref: https://developer.mozilla.org/en-US/docs/Web/API/ValidityState
            let message = '';

            switch (true) {
                case input.validity.valueMissing:
                    const houseNumberInput = input.getAttribute('data-attr-validate-field');
                    if (houseNumberInput === 'house-number') {
                        message = i18next.t("Please fill out the marked field. If no house number is available, enter a dot '.'.", { ns: 'customer' })
                    } else {
                        message = i18next.t('Please fill out the marked field.', { ns: 'layout' });
                    }
                    break;
                case input.validity.patternMismatch:
                    message = input.getAttribute('data-regex-message');
                    if (!message) {
                        /* eslint-disable-next-line no-console */
                        console.error('missing data-regex-message attribute');
                    }
                    break;
                case input.validity.rangeOverflow:
                    message = i18next.t('This value should be between {{ min }} and {{ max }}.', { replace: { min: input.getAttribute('min'), max: input.getAttribute('max') }, ns: 'layout' });
                    break;
                case input.validity.rangeUnderflow:
                    message = i18next.t('Please enter at least {{characters}}.', { ns: 'layout', replace: { characters: input.getAttribute('min') } });
                    break;
                case input.validity.stepMismatch:
                    message = (input.name.includes('bodyHeight') || input.name.includes('headCircumference')) ? i18next.t('Please enter only digits.', { ns: 'layout' }) : input.validationMessage;
                    break;
                case input.validity.badInput:
                    message = (input.name.includes('bodyHeight') || input.name.includes('headCircumference')) ? i18next.t('Please enter only digits.', { ns: 'layout' }) : message;
                    break;
                case input.validity.tooLong:
                    // this will not happen because the browser prevents this..
                    message = i18next.t('Please enter no more than {{characters}} characters.', { replace: { characters: input.getAttribute('maxlength') }, ns: 'layout' });
                    break;
                case input.validity.tooShort:
                    message = i18next.t('Please enter at least {{characters}} characters.', { replace: { characters: input.getAttribute('minlength') }, ns: 'layout' });
                    break;
                case input.validity.typeMismatch:
                    const type = input.getAttribute('type');
                    if (type === 'email') {
                        message = i18next.t('Please enter a valid email address.', { ns: 'layout' });
                    } else {
                        /* eslint-disable no-console */
                        console.error('missing error message for invalid typeMismatch');
                        console.error('  input: ', input);
                        /* eslint-enable no-console */
                        return;
                    }
                    break;
                default:
                    message = input.validationMessage;
            }

            this.setValidationMessage(input, message);
            mdcField.valid = false;
            mdcField.getDefaultFoundation().setValid(false);

            return;
        }

        if (!this.validateRequired(input, mdcField)
            || !this.validateAllowedChars(input, mdcField)
            || !this.validateRegex(input, mdcField)
            || !this.validateConfirmFields(input)) {
            return;
        }

        this.validateStreetNumber(input);

        this.validateSalutation(input);

        this.validatePhoneNumber(input, mdcField)
    }

    private validateRequired = (field: HTMLInputElement, mdcField: MDCTextField | MDCSelect): boolean => {
        const required = field.getAttribute('required');

        if (required && field.value.trim().length === 0) { //for validating empty spaces
            const reqMessage: string = i18next.t('Please fill out the marked field.', { ns: 'layout' });
            mdcField.valid = false;
            this.setValidationMessage(field, reqMessage);
            return false;
        }
        return true
    }

    private validateAllowedChars = (field: HTMLInputElement, mdcField: MDCTextField | MDCSelect): boolean => {
        const dataAllowedChars = field.getAttribute('data-allowed-chars');

        if (dataAllowedChars === null) {
            return true;
        }

        const val = field.value;
        const allowedChars = new RegExp(`[${dataAllowedChars}]+`, 'g');
        const unallowedChars = val.replace(allowedChars, '');
        const unallowedCharsLength = unallowedChars.length;

        if (unallowedCharsLength > 0) {
            const invalidCharsMessage = i18next.t(
                'The text contains {{number}} invalid characters: {{chars}}',
                {
                    replace: {
                        number: unallowedCharsLength.toString(),
                        chars: unallowedChars,
                    },
                    ns: 'layout'
                }
            );

            mdcField.valid = false;
            mdcField.getDefaultFoundation().setValid(false);

            this.setValidationMessage(field, invalidCharsMessage);

            return false;
        }

        return true;
    }

    private validateRegex = (field: HTMLInputElement, mdcField: MDCTextField | MDCSelect): boolean => {
        const regex = field.getAttribute('data-regex');
        const required = field.getAttribute('required');

        if (regex === null
            || (required === null && field.value.length === 0)) {
            return true;
        }

        const regexElement = RegExp(regex);

        if (!regexElement.test(field.value)) {
            const maxLength = field.getAttribute('data-max-length');
            const invalidRegexMessage: string = maxLength ?
                i18next.t(field.getAttribute('data-regex-message'), { replace: { limit: maxLength }, ns: 'validation' }) :
                i18next.t(field.getAttribute('data-regex-message'), { ns: 'validation' });

            if (invalidRegexMessage === '' || invalidRegexMessage === null) {
                /* eslint-disable-next-line no-console */
                console.error('missing data-regex-message attribute');
            }

            mdcField.valid = false;
            mdcField.getDefaultFoundation().setValid(false)

            this.setValidationMessage(field, invalidRegexMessage);

            return false;
        }

        return true;
    }

    private validateConfirmFields = (field: HTMLInputElement): boolean => {
        let originalField = null;
        let confirmField = null;
        type SelectorMapTuple = [string, string];
        const objectEntries: SelectorMapTuple[] = Utils.getObjectEntries(FormValidator.DEFAULTS.CONFIRM_FIELDS_SELECTOR_MAP);

        for (const [confirmFieldSelector, originalFieldSelector] of objectEntries) {
            if (field === document.querySelector(confirmFieldSelector)) {
                originalField = this.form.querySelectorAll(originalFieldSelector)[0];
                confirmField = field;
                break;
            } else if (field === document.querySelector(originalFieldSelector)) {
                originalField = field;
                confirmField = this.form.querySelectorAll(confirmFieldSelector)[0];
                break;
            }
        }

        if (originalField === null || confirmField === null) {
            return true; // valid because no originalField or no confirmField to validate
        }

        const confirmMdcField = MaterialDesignComponentHandler.getMDCObjectForFormField(confirmField);

        if (!confirmMdcField) {
            return true;
        }

        if (field === originalField) {
            if (confirmField.value === '') {
                return true; // valid because no confirmField is empty. thus no need to validate
            }
            if (confirmField.value !== originalField.value) {
                confirmMdcField.valid = false;
                confirmMdcField.getDefaultFoundation().setValid(false);


                const invalidCharsMessage = i18next.t('The input entered does not match.', { ns: 'layout' });
                this.setValidationMessage(confirmField, invalidCharsMessage);

                return false;
            } else {
                confirmMdcField.valid = true;
                confirmMdcField.getDefaultFoundation().setValid(true);

                return true;
            }
        } else if (field === confirmField) {
            if (confirmField.value !== originalField.value) {
                confirmMdcField.valid = false;
                confirmMdcField.getDefaultFoundation().setValid(false);

                const invalidCharsMessage = i18next.t('The input entered does not match.', { ns: 'layout' });
                this.setValidationMessage(confirmField, invalidCharsMessage);

                return false;
            }
        }

        return true;
    }

    private validateStreetNumber = (field: HTMLInputElement): void => {
        const dataAttribute = field.getAttribute('data-validate-number');

        if (!dataAttribute) {
            return;
        }

        const valToValidate = field.value;
        const showMessage = (!(/\d+/).test(valToValidate.trim()));
        const warningClass = this.getWarningClass(field);

        if (showMessage && !field.parentElement.classList.contains(warningClass)) {
            field.parentElement.classList.add(warningClass);
            const tooltipContent = i18next.t('Caution!|It seems that you have not entered a house number for the street.', { ns: 'layout' }).split('|');

            const element = document.createElement('div');
            field.parentElement.appendChild(element);

            createRoot(element).render(
                <div id="street-warning" className="tooltip-content tooltip-show">
                    <div className="tooltip-arrow"></div>
                    <div className="tooltip-header">
                        <div className="tooltip-headline heading4">{tooltipContent[0]}</div>
                        <span className="icon icon-close tooltip-close" onClick={() => { field.parentElement.classList.remove(warningClass); element.remove(); }}></span>
                    </div>
                    <div className="tooltip-info">
                        <div className="paragraph-xs">{tooltipContent[1]}</div>
                    </div>
                </div>
            );
        }
    }

    private validateSalutation = (field: HTMLInputElement): void => {
        const dataAttribute = field.getAttribute('data-validate-salutation');

        if (!dataAttribute) {
            return;
        }

        const showMessage = field.value === 'Company';
        const warningClass = this.getWarningClass(field);

        if (showMessage && !field.parentElement.classList.contains(warningClass)) {
            field.parentElement.classList.add(warningClass);
            const tooltipContent = i18next.t('Important!|Please enter the company name in the field "First name". If there is not enough space, please also use "Surname". Please tell us imperatively a contact person in the field "Additional address information".', { ns: 'layout' }).split('|');

            const element = document.createElement('div');
            field.parentElement.appendChild(element);

            createRoot(element).render(
                <div id="salutation-warning" className="tooltip-content tooltip-show">
                    <div className="tooltip-arrow"></div>
                    <div className="tooltip-header">
                        <div className="tooltip-headline heading4">{tooltipContent[0]}</div>
                        <span className="icon icon-close tooltip-close" onClick={() => { field.parentElement.classList.remove(warningClass); element.remove(); }}></span>
                    </div>
                    <div className="tooltip-info">
                        <div className="paragraph-xs">{tooltipContent[1]}</div>
                    </div>
                </div>
            );
        }
    }

    private validatePhoneNumber = (field: HTMLInputElement, mdcField: MDCTextField | MDCSelect): boolean => {
        const dataAttribute = field.getAttribute('data-validate-phone');

        if (!dataAttribute) {
            return;
        }

        let invalidMessage = this.checkPhoneNumberInput(field);

        if(invalidMessage) {
            mdcField.valid = false;
            mdcField.getDefaultFoundation().setValid(false);

            this.setValidationMessage(field, invalidMessage);
        }
    }

    private checkPhoneNumberInput = (field: HTMLInputElement): string => {
        const minDigits = Number(field.getAttribute('data-validate-phone-min-digits') || "3");
        const digitCount = field.value.match(/\d/g)?.length;
    
        if (!digitCount || digitCount < minDigits) {
            const message = field.getAttribute('data-validate-phone-min-digits-message') || FormValidator.DEFAULTS.PHONE_DEFAULT_ERROR_MESSAGE;
            return i18next.t(message, { replace: { limit: minDigits }, ns: 'validation' });
        }

        const regex = field.getAttribute('data-validate-phone-regex');
        if (regex) {
            const regexElement = RegExp(regex);
            if (!regexElement.test(field.value)) {
                const message = field.getAttribute('data-validate-phone-regex-message') || FormValidator.DEFAULTS.PHONE_DEFAULT_ERROR_MESSAGE;
                return i18next.t(message, { ns: 'validation' });
            }
        }
    }

    private setValidationMessage = (field: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement, message: string): void => {
        let errorField: Element;
        if (field instanceof HTMLInputElement || field instanceof HTMLTextAreaElement) {
            errorField = field.parentElement.parentElement.getElementsByClassName('mdc-text-field-helper-text--validation-msg')[0];
        } else if (field instanceof HTMLSelectElement) {
            errorField = field.parentElement.parentElement.getElementsByClassName('mdc-select-helper-text--validation-msg')[0];
        }

        errorField.innerHTML = message;
        field.setCustomValidity('');
    }

    private getWarningClass = (field: HTMLInputElement): string =>  {
        const fieldType = FormUtils.getFieldType(field);
        return `${fieldType}--invalid-warning`;
    }

    private scrollToFirstInvalidField = (callingElement?: HTMLInputElement): void => {
        const invalidElements = this.form.querySelectorAll<HTMLDivElement>(FormValidator.DEFAULTS.INVALID_STATE_SELECTOR);

        if (invalidElements.length === 0) {
            return;
        }

        const [invalidElement] = Array.from(invalidElements);
        const invalidInputs = invalidElement.getElementsByTagName('input');

        if (callingElement && invalidInputs.length > 0 && invalidInputs[0].name !== callingElement.name) {
            return;
        }
        const isElementVisible = invalidElement.getBoundingClientRect().height !== 0;

        if (!isElementVisible) {
            const closestAccordion = invalidElement.closest('.accordion');

            if (!closestAccordion) {
                return;
            }

            const accordionHeader = closestAccordion.querySelectorAll('.accordion-header');

            if (!accordionHeader || !accordionHeader.length) {
                return;
            }

            this.eventBus.publish(EAccordionEvent.OPEN, accordionHeader[0]);
        }

        let eventOptions: IScrollingPublisherOptions = {
            target: invalidElement,
        };

        this.eventBus.publish('scrolling:scrollTo', eventOptions);
    }
}

export default class FormValidatorHandler {

    public static init = (eventBus: EventBus): void => {
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        Array.from(document.querySelectorAll('form')).forEach(form => new FormValidator(form, additionalOptions));
    }
}
