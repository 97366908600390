import * as React from 'react';
import { filterXSS } from 'xss';
import { EClasses } from '../../../constants/lexicon-material';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import ILexiconMaterialReference from '../../../interfaces/lexicon-material/ILexiconMaterialReference';
import LexiconMaterialLink from './LexiconMaterialLink';

interface ILexiconMaterialContentProps {
    content: string;
    references?: ILexiconMaterialReference[];
}

const LexiconMaterialContent: React.FunctionComponent<ILexiconMaterialContentProps> = props => {
    const displayReferences = () => {
        const { translate } = useTranslationHook();
        if (!props.references || props.references.length === 0) {
            return null;
        }

        const referenceLinkElements = props.references.map((reference: ILexiconMaterialReference) => (
            <LexiconMaterialLink key={reference.id} materialId={reference.id} text={reference.title} />
        ));

        return (
            <div className={EClasses.LEXICON_MATERIAL_REFERENCE_LINK}>
                {translate('See', { ns: 'product-comparison' })}: {referenceLinkElements}
            </div>
        );
    };

    return (
        <React.Fragment>
            <div dangerouslySetInnerHTML={{ __html: filterXSS(props.content) }} />
            {displayReferences()}
        </React.Fragment>
    );
};

export default LexiconMaterialContent;
