import { ISearchSuggestionResponse } from '../interfaces';
import axios, { AxiosResponse } from 'axios';

const searchSuggestionEndpoint = '/ajax/search-suggest';

class SearchSuggestionService {
    public fetchDataFromAPI = async (searchValue: string, requestId: number = 0): Promise<ISearchSuggestionResponse> => {
        const autoSuggestApiResponse: AxiosResponse<ISearchSuggestionResponse> = await axios.get(searchSuggestionEndpoint, {
            params: { q: searchValue }
        });

        return {
            ...autoSuggestApiResponse.data,
            requestId,
        };
    };
}

export const searchSuggestionService = new SearchSuggestionService();
