import * as React from 'react';
import { ISelectFilterOption } from '../interfaces';
import { ISetListFilterOptionPayload, setListFilterOption } from '../actions/filter';
import SelectedFilterOptions from '../components/sidebar/filters/SelectedFilterOptions';

type TElementRender = () => null | React.ReactElement;

function useMultipleSelectFilter(filterName: string, selectedOptions: ISelectFilterOption[], dispatch: React.Dispatch<any>): [(value: string, isSelected: boolean) => void, TElementRender, (toggleOptions: ISelectFilterOption[]) => void] {
    const onToggleItem = (value: string, isSelected: boolean) => {
        let newSelectedOptionList: string[];

        if (isSelected) {
            newSelectedOptionList = selectedOptions
                .filter(option => option.value !== value)
                .map(selectedOption => selectedOption.value);
        } else {
            newSelectedOptionList = selectedOptions
                .map(selectedOption => selectedOption.value);

            newSelectedOptionList.push(value);
        }

        const payload: ISetListFilterOptionPayload = {
            filterName,
            selectedValues: newSelectedOptionList,
        };

        dispatch(setListFilterOption(payload));
    };

    const onToggleItems = (toggleOptions: ISelectFilterOption[]) => {
        let newSelectedOptionList: string[] = selectedOptions.map(selectedOption => selectedOption.value);

        toggleOptions.forEach(toggleOption => {
            if (toggleOption.selected) {
                newSelectedOptionList = newSelectedOptionList
                    .filter(option => option !== toggleOption.value)
            } else {
                newSelectedOptionList.push(toggleOption.value);
            }
        });

        const payload: ISetListFilterOptionPayload = {
            filterName,
            selectedValues: newSelectedOptionList,
        };

        dispatch(setListFilterOption(payload));
    };

    const displaySelectedFilterOptions = React.useCallback(() => {
        if (!selectedOptions.length) {
            return null;
        }

        return (
            <SelectedFilterOptions
                filterName={filterName}
                selectedOptionList={selectedOptions}
                dispatch={dispatch}
            />
        );
    }, [selectedOptions]);

    return [onToggleItem, displaySelectedFilterOptions, onToggleItems];
}

export default useMultipleSelectFilter;
