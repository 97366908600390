import * as React from 'react';
import AbstractFilterHandler from './AbstractFilterHandler';
import { ISelectFilter } from '../../components/search-page/interfaces';
import SelectedFilterLabels from '../../components/search-page/components/sidebar/SelectedFilterLabels';

export default abstract class AbstractSelectFilterHandler extends AbstractFilterHandler {
    public getSelectionTagComponent = (filter: ISelectFilter): React.FunctionComponent | null => {
        const selectedValues = this.getSelectedOptions(filter)

        if (selectedValues.length === 0) {
            return null;
        }

        return SelectedFilterLabels;
    }

    public isFilterActive = (filter: ISelectFilter): boolean => this.getSelectedOptions(filter).length > 0;
}
