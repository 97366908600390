import { CLICK_TRACKING_PARAMS_DATA_ATTRIBUTE } from '../constants';

export class ClickTrackingService {
    public static sendClickTracking = (clickTrackingParam: object) => {
        const apiClickTracking = new URL(ClickTrackingService.clickTrackingAPI, window.location.origin);

        Object.keys(clickTrackingParam).forEach(value => {
            apiClickTracking.searchParams.append(value, clickTrackingParam[value]);
        });

        //@ts-ignore
        if (![...apiClickTracking.searchParams.entries()].length) {
            return;
        }

        const img = new Image();
        img.src = apiClickTracking.toString();
    }

    public static firstPageInit = () => {
        const clickTrackingElements: NodeListOf<HTMLElement> = document.querySelectorAll(`[${CLICK_TRACKING_PARAMS_DATA_ATTRIBUTE}]`);

        clickTrackingElements.forEach(clickTrackingElement => {
            const clickTrackingParams = clickTrackingElement.dataset.clicktrackingparams;

            if (!clickTrackingParams) {
                return;
            }

            const linkElements = clickTrackingElement.querySelectorAll('a');
            linkElements.forEach(linkElement => {
                linkElement.addEventListener('click', () => {
                    ClickTrackingService.sendClickTracking(JSON.parse(clickTrackingParams));
                });
            })
        });
    }

    private static clickTrackingAPI = '/rich-relevance/click-tracking';
}
