(window as any).global = window;

import { FootnoteManager } from '../footnote';
import i18next from 'i18next';

const DEFAULT_CURRENCY_CODE = 'EUR';

export function defCurrencyFormat(
    amount: number,
    currencyCode: string = DEFAULT_CURRENCY_CODE,
    showDecimal: boolean = true,
    _currencyLocale: string = 'de-DE',
    _pattern: string = '%v %s'
): string {
    if (!Intl.NumberFormat) {
        return amount + currencyCode;
    }

    const numberFormatOptions: Intl.NumberFormatOptions = {
        style: 'currency',
        currency: currencyCode
    };

    if (!showDecimal) {
        numberFormatOptions.minimumFractionDigits = 0;
        numberFormatOptions.maximumFractionDigits = 0;
    }

    const numberFormat = new Intl.NumberFormat(
        i18next.language,
        numberFormatOptions
    );

    if (typeof(numberFormat.format) === 'function') {
        return numberFormat.format(amount);
    }

    return amount + currencyCode;
}

export function defCurrencyFormatSeparatedDecimals(
    amount: number,
    currencyCode: string = DEFAULT_CURRENCY_CODE,
    showCurrencySymbol: boolean = true,
    footnoteNumber: number = FootnoteManager.getInstance().getFootnoteNumber(FootnoteManager.generalVat),
): string {
    if (Intl.NumberFormat) {
        const numberFormat = new Intl.NumberFormat(i18next.language, { style: 'currency', currency: currencyCode });
        if (typeof(numberFormat.formatToParts) === 'function') {
            const currencyParts = numberFormat.formatToParts(amount);

            const isLeftSideCurrency = currencyParts[0].type === 'currency' && true === showCurrencySymbol;

            if (true === isLeftSideCurrency) {
                currencyParts.forEach(part => {
                    if (part.type === 'fraction') {
                        part.value += '<sup>' + footnoteNumber + '</sup>';
                    }

                    return part;
                });
            } else {
                currencyParts.forEach(part => {
                    if (part.type === 'currency') {
                        part.value += '<sup>' + footnoteNumber + '</sup>';
                    }

                    return part;
                });
            }

            return currencyParts.map(part => part.value).join('');
        }
    }

    return `${amount} ${currencyCode}`;
}

export function defGetCurrencySymbol(currencyCode: string = DEFAULT_CURRENCY_CODE) {
    const defaultResult = {
        isCurrencyLeftSide: false,
        symbol: currencyCode
    };

    if (!Intl.NumberFormat) {
        return defaultResult;
    }

    const numberFormat = new Intl.NumberFormat(
        i18next.language,
        {
            style: 'currency',
            currency: currencyCode
        }
    );

    if (typeof(numberFormat.formatToParts) !== 'function') {
        return defaultResult;
    }

    const currencyParts = numberFormat.formatToParts(0);
    const currencyIndex = currencyParts.findIndex(part => part.type === 'currency');

    return {
        isCurrencyLeftSide: currencyIndex === 0,
        symbol: currencyParts[currencyIndex].value
    }
}
