import { createRoot } from 'react-dom/client';
import * as React from 'react';
import { CatalogSearchPage } from './components/CatalogSearchPage';
import { IApiQueryData } from '../shop-ui/components/search-page/interfaces';

export default class CatalogSearchPageHandler {
    public static init = (): void => {
        const catalogSearchPageElement: HTMLElement = document.querySelector('.catalog-search-page-client-init');
        if (!catalogSearchPageElement) {
            return;
        }

        const catalogSearchPageElementRoot = createRoot(catalogSearchPageElement);

        const csrfToken: string = catalogSearchPageElement.dataset.csrfToken;
        const defaultParameter: IApiQueryData = JSON.parse(catalogSearchPageElement.dataset.defaultParameters);

        catalogSearchPageElementRoot.render(
            <CatalogSearchPage
                defaultParameter={defaultParameter}
                csrfToken={csrfToken}
            />
        );
    }
}
