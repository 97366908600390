import axios from 'axios';
import IFilter from 'PyzShopUi/scripts/interfaces/bike-db/IFilter';
import { ICategoryTreeItem } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';

class CategoriesTreeService {
    private catalogTreeApi = '/ajax/category-tree';

    public getData = async(): Promise<ICategoryTreeItem[]> => {
        const dataResponse = await axios.get(this.catalogTreeApi);

        return this.buildTree(dataResponse.data.categoryTree, 0);
    };

    public buildTree = (items: ICategoryTreeItem[], level: number, parentKey?: string): ICategoryTreeItem[] => (
        items.map(
            item => (
                {
                    ...item,
                    index: level,
                    parentKey,
                    children: this.buildTree(item.children, level + 1, item.categoryKey),
                }
            ),
        )
    );

    public buildFilterTree = (
        categoryItems: ICategoryTreeItem[],
        selectedCategoryItem: string = 'ROOT',
        parentKey: string = 'ROOT',
        index: number = 0
    ) => (
        categoryItems.map(item => {
            const shouldChilrenBeBuilt = this.shouldChildrenBeBuilt(item, selectedCategoryItem) || parentKey === selectedCategoryItem;
            const childrenItems = shouldChilrenBeBuilt ?
                this.buildFilterTree(item.children, selectedCategoryItem, item.categoryKey, index + 1) : [];

            return {
                ...item,
                index,
                parentKey,
                children: childrenItems,
            };
        })
    );

    private shouldChildrenBeBuilt(categoryItem: ICategoryTreeItem, selectedCategoryItem: string): boolean {
        if (categoryItem.categoryKey === selectedCategoryItem) {
            return true;
        }

        let couldChildrenBeBuilt = false;
        for (const childItem of categoryItem.children) {
            couldChildrenBeBuilt = this.shouldChildrenBeBuilt(childItem, selectedCategoryItem);

            if (couldChildrenBeBuilt === true) {
                break;
            }
        }

        return couldChildrenBeBuilt;
    }
}

export const categoriesTreeService = new CategoriesTreeService();
