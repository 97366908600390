import * as React from 'react';
import { MAX_ITEM_FOR_ENABLE_SCROLL } from '../constants';

type TElementRender = () => null | React.ReactElement;
type TOnGradientChange = (event: React.UIEvent<HTMLUListElement, UIEvent>) => void;

function useGradient(items: any[], maximumNumberItemsForScroll: number = MAX_ITEM_FOR_ENABLE_SCROLL): [boolean, TOnGradientChange, TElementRender, TElementRender] {
    const [isGradientTop, setGradientTop] = React.useState<boolean>(false);
    const [isGradientBottom, setGradientBottom] = React.useState<boolean>(false);
    const [isScrollable, setScrollable] = React.useState<boolean>(false);

    React.useEffect(() => setScrollable(items.length >= maximumNumberItemsForScroll), [items]);

    const onGradientChange = (event: React.UIEvent<HTMLUListElement, UIEvent>) => {
        const element = event.currentTarget;

        const scrollPosition = element.scrollTop;
        const containHeight = element.clientHeight;
        const maxScroll = element.scrollHeight;

        if (scrollPosition === 0) {
            setGradientTop(false);
        } else {
            setGradientTop(true);
        }

        if (scrollPosition + containHeight >= (maxScroll - 1)) {
            setGradientBottom(false);
        } else {
            setGradientBottom(true);
        }
    };

    const displayTopGradient = React.useCallback(() => isGradientTop && isScrollable ?
        <div className='scroll-selection__top-gradient'/> : null, [isGradientTop, isScrollable]);

    const displayBottomGradient = React.useCallback(() => (isGradientBottom || (!isGradientTop && !isGradientBottom)) && isScrollable ?
        <div className='scroll-selection__bottom-gradient'/> : null, [isGradientTop, isGradientBottom, isScrollable]);

    return [isScrollable, onGradientChange, displayTopGradient, displayBottomGradient];
}

export default useGradient;
