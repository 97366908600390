import { createRoot } from 'react-dom/client';
import * as React from 'react';
import MyBikeContainer from '../react/bike-db/containers/MyBikeContainer';
import EventBus from '../utils/eventBus';
import { EMyBikeEvents } from './events/myBikeEvent';
import { IMyBikeTogglePayload } from '../interfaces/IMyBikeTogglePayload';
import MyBikeButton from '../react/bike-db/components/MyBikeButton';
import MyLouisService, { EMyBikeAPIEndpoint } from '../react/bike-db/services/MyLouisService';
import Loading from 'PyzShopUi/scripts/react/common/components/Loading';

class BikeDetail {
    private static DEFAULTS = {
        SELECTOR_MYBIKE_BUTTON_WRAPPER: '.bike-button-wrapper',
        SELECTOR_MYBIKE_MARKER_CONTAINER: '.bike-container .marker-container',
    };

    private eventBus: EventBus;
    private readonly markerContainer: HTMLElement;
    private readonly buttonContainer: HTMLElement;
    private readonly validIsInMyBikeValues: string[];
    private readonly csrfToken: string;

    constructor(eventBus: EventBus) {
        this.eventBus = eventBus;
        this.markerContainer = document.querySelector(BikeDetail.DEFAULTS.SELECTOR_MYBIKE_MARKER_CONTAINER);
        this.buttonContainer = document.querySelector(BikeDetail.DEFAULTS.SELECTOR_MYBIKE_BUTTON_WRAPPER);

        this.csrfToken = '';
        if (this.buttonContainer) {
            this.csrfToken = this.buttonContainer.dataset.csrfToken;
        }

        this.validIsInMyBikeValues = ['true', 'false', '0', '1'];

        this.initMyBikeButtonHandler();

        if (this.markerContainer) {
            this.eventBus.subscribe(EMyBikeEvents.MY_BIKE_TOGGLE, this.toggleMyBikeMarker);
        }
    }

    private toggleMyBikeMarker = (payload: IMyBikeTogglePayload): void => {
        if (payload.status) {
            this.markerContainer.classList.remove('hide');
        } else {
            this.markerContainer.classList.add('hide');
        }
    }

    private initMyBikeButtonHandler = (): void => {
        document.querySelectorAll(BikeDetail.DEFAULTS.SELECTOR_MYBIKE_BUTTON_WRAPPER).forEach((myBikeWrapper): void => {
            let isInMyBikeValue: string = myBikeWrapper.getAttribute('data-isinmybike');
            if (!this.validIsInMyBikeValues.includes(isInMyBikeValue)) {
                isInMyBikeValue = 'false';
            }

            const myBikeContainer = React.createElement(
                MyBikeContainer, {
                myLouisService: new MyLouisService(EMyBikeAPIEndpoint.ShopSpryker),
                bikeId: myBikeWrapper.getAttribute('data-bikeid'),
                externalId: myBikeWrapper.getAttribute('data-externalid'),
                isInMyBike: Boolean(JSON.parse(isInMyBikeValue)),
                wrapperComponent: MyBikeButton,
                csrfToken: this.csrfToken,
            });

            const BikeComponent = () => {
                return (
                    <React.Suspense fallback={<Loading />}>
                        {myBikeContainer}
                    </React.Suspense>
                )
            }

            createRoot(myBikeWrapper).render(<BikeComponent />);
        });
    }
}

export default class BikeDetailHandler {
    public static init = (eventBus: EventBus): void => {
        new BikeDetail(eventBus);
    }
}
