import * as React from 'react';
import { MDCTextField } from '@material/textfield';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export interface ICurrencyInputProps {
    additionalClass?: string;
    name?: string;
    value: string;
    currencySymbol: string;
    isSymbolLeftSide: boolean;
    accessibilityLabel?: string;
    onInputChange(value: string): void;
}

const CurrencyInput: React.FunctionComponent<ICurrencyInputProps> = props => {
    const { additionalClass, name, value, currencySymbol, isSymbolLeftSide, onInputChange } = props;

    const textFieldHtmlElementRef = React.useRef<HTMLLabelElement>(null);

    React.useEffect(() => {
        const mdcTextField = new MDCTextField(textFieldHtmlElementRef.current);

        return () => {
            mdcTextField.destroy();
        }
    }, []);

    const onChange = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        const targetValue: string = (target.value).replace(currencySymbol, '');

        onInputChange(targetValue);
    }

    const currencyMask = createNumberMask({
        prefix: isSymbolLeftSide ? currencySymbol : '',
        suffix: isSymbolLeftSide ? '' : currencySymbol,
        includeThousandsSeparator: false,
    });

    return (
        <label ref={textFieldHtmlElementRef} className={`mdc-text-field mdc-text-field--outlined mdc-text-field--no-label ${additionalClass}`}>
            <span className="accessibility-soft-hidden">{props.accessibilityLabel}</span>
            <MaskedInput
                name={name}
                mask={currencyMask}
                className="mdc-text-field__input"
                value={value}
                guide={true}
                onChange={onChange}
            />
            <span className="mdc-notched-outline">
                <span className="mdc-notched-outline__leading" />
                <span className="mdc-notched-outline__trailing" />
            </span>
        </label>
    );
};

CurrencyInput.defaultProps = {
    additionalClass: '',
    name: '',
};

export default CurrencyInput;
