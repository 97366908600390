import Swiper from '../utils/swiperMethods';
import { SwiperOptions } from 'swiper';

class CmsSlider {
    private readonly sliderElement: HTMLElement;
    private swiperPaginationProgressbarFillElement: HTMLElement;

    constructor(sliderElement: HTMLElement) {
        this.sliderElement = sliderElement;
        this.swiperPaginationProgressbarFillElement = this.sliderElement.querySelector('.swiper-pagination-progressbar-fill-custom');
        this.initSlider();
    }

    private initSlider = () => {
        const paginationOptions: SwiperOptions = {
            speed: 2000,
            autoplay: document.body.classList.contains('neos-backend') ? undefined : { delay: 5000, disableOnInteraction: false },
            loop: true,
            // We would prefer scrollbar over pagination, but this is not supported in combination with loop mode:
            // https://github.com/nolimits4web/swiper/issues/2315#issuecomment-343162574.
            // Therefore we mimic the look of the scrollbar drag element to show a current fragment instead of a solid line.
            pagination: {
                el: '.swiper-pagination',
                type: 'custom',
                renderCustom: (swiper, current, total) => {
                    this.swiperPaginationProgressbarFillElement.style.marginLeft = `${100 / total * (current - 1)}%`;
                    this.swiperPaginationProgressbarFillElement.style.width = `${100 / total}%`;
                    return this.swiperPaginationProgressbarFillElement.outerHTML.toString();
                }
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        };

       new Swiper(this.sliderElement, paginationOptions);
    }
}

export default class CmsSliderHandler {
    public static init = () => {
        const sliderElements = document.querySelectorAll('.cms_slider__init');
        if (sliderElements && sliderElements.length) {
            sliderElements.forEach(sliderElement => {
                new CmsSlider(sliderElement as HTMLElement);
            });
        }
    }
}