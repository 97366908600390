import * as React from 'react';
import classNames from 'classnames';
import MaterialLoading from '../MaterialLoading';

interface IButtonProps {
    buttonRef?:  React.MutableRefObject<HTMLButtonElement>
    label?: string;
    additionalClass?: string;
    color?: 'grey' | 'red';
    isBordered?: boolean;
    isDisabled?: boolean;
    isProgressing?: boolean;
    icon?: string;
    iconPosition?: 'left' | 'right';
    iconAdditionalClass?: string;
    style?: React.CSSProperties;
    iconAttributes?: object;
    dataQa?: string;
    ariaAttributes?: {
        [key in `aria-${string}`]: string;
    }

    onClick?(event: Event): void;
    onKeyDown?(event: React.KeyboardEvent<HTMLButtonElement>): void;
}

const Button = (props: IButtonProps) => {
    const {
        buttonRef,
        label,
        additionalClass,
        color,
        isBordered,
        isDisabled,
        isProgressing,
        icon,
        iconPosition,
        iconAdditionalClass,
        onClick,
        style,
        iconAttributes,
        dataQa,
        ariaAttributes = {},
        onKeyDown = () => {},
    } = props;

    const buttonClasses = classNames(
        'button',
        additionalClass,
        {
            [`button-${color}`]: color !== undefined,
            [`button-bordered`]: isBordered && color === 'grey',
            [`button-bordered-${color}`]: isBordered,
        },
    );

    const iconElement = icon ? <i {...iconAttributes} className={`icon icon-${icon} ${iconAdditionalClass}`}/> : null;
    const progressingIconElement = <MaterialLoading/>;
    const labelElement = label ? <span>{label}</span> : null;

    const toggleClick = (e) => {
        if (onClick) {
            onClick(e);
        }
    };

    return (
        <button
            ref={buttonRef}
            className={buttonClasses}
            disabled={isDisabled || isProgressing}
            onClick={toggleClick}
            onKeyDown={onKeyDown}
            style={style}
            data-qa={dataQa || ''}
            {...ariaAttributes}
        >
            {iconPosition === 'left' ? iconElement : null}
            {labelElement}
            {isProgressing ? progressingIconElement : null}
            {iconPosition === 'right' && !isProgressing ? iconElement : null}
        </button>
    );
};

Button.defaultProps = {
    isBordered: false,
    isDisabled: false,
    isProgressing: false,
    iconPosition: 'left',
    additionalClass: '',
    iconAdditionalClass: '',
    iconAttributes: {},
};

export default Button;
