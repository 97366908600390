export const DEFAULT_CURRENCY = 'EUR';
export const DEFAULT_CURRENCY_FACTOR = 1;

export const CLICK_TRACKING_PARAMS_DATA_ATTRIBUTE = 'data-clicktrackingparams';

export enum EFootnoteNamespace {
    DEFAULT = 'default',
    ARTICLE_PRICE = 'article-price',
    PDP_ARTICLE_PRICE = 'pdp-article-price',
    PDP_ALL_VARIANTS_ARTICLE_PRICE = 'pdp-all-variants-article-price',
}

export enum EProductType {
    PRODUCT_ABSTRACT = 'product_abstract',
    PRODUCT_CONCRETE = 'product_concrete',
}

export const ACCORDION_SCROLLABLE_PARENT_CLASS_NAME = 'accordion--scrollable-parent';

export const NUMBER_PRODUCT_NO_LAZY_LOAD = 6;

export const NUMBER_PRODUCT_SLIDER_NO_LAZY_LOAD = 4;
