import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import usePerPageSelect from '../../hooks/usePerPageSelect';
import MdcSelect from '../../../select/MdcSelect';

interface IMdcPerPageSelectProps {
    itemPerPageOptions: number[];
    currentItemsPerPage: string;

    dispatch: React.Dispatch<any>;
}

const MdcPerPageSelectComponent: React.FunctionComponent<IMdcPerPageSelectProps> = props => {
    const [perPageSelectOptions, onPerPageSelect] = usePerPageSelect(props.itemPerPageOptions, props.dispatch);
    const { translate } = useTranslationHook();

    return (
        <MdcSelect
            selectedValue={props.currentItemsPerPage}
            options={perPageSelectOptions}
            onChange={onPerPageSelect}
            ariaLabel={translate('per page', { ns: 'article-list'})}
        />
    );
};

const MdcPerPageSelect = withMemo(MdcPerPageSelectComponent);

export default MdcPerPageSelect;
