import * as React from "react";
import Category from "./items/Category";
import {ICategoriesProps} from "PyzShopUi/scripts/main-rubric-page/interfaces";

const Categories: React.FunctionComponent<ICategoriesProps> = ({categories}) => {
    return (
        <div className="grid-x main-rubric-links">
            {categories.map((item, index) => {
                return <Category key={index} category={item}/>
            })}
        </div>
    );
}

export default Categories;
