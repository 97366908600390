import * as React from 'react';
import { MDCTabBar } from '@material/tab-bar';
import { MDCTabScroller } from '@material/tab-scroller';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import MiddlewareEventBus from '../../../react/common/lib/MiddlewareEventBus';
import IReactEvent from '../../../interfaces/article-list/IReactEvent';
import { WindowEvent } from '../../../react/common/events/windowEvent';
import { MdcTabLinkItem } from './MdcTabLinkItem';
import { IMdcTabItem } from '../../interfaces';

interface IMdcTabBarProps {
    additionalClassNames?: string;
    selectedTab: string;
    tabs: IMdcTabItem[];

    onTabClick(name: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export const MdcTabBar: React.FunctionComponent<IMdcTabBarProps> = props => {
    const { additionalClassNames, selectedTab, tabs, onTabClick } = props;
    const { translate } = useTranslationHook();

    const tabBarElementRef = React.useRef<HTMLDivElement>(null);
    const tabScrollerElementRef = React.useRef<HTMLDivElement>(null);
    const mdcTabScrollerRef = React.useRef<MDCTabScroller>();

    const [isGradientLeft, setIsGradientLeft] = React.useState<boolean>(false);
    const [isGradientRight, setIsGradientRight] = React.useState<boolean>(true);

    const middlewareEventBus = MiddlewareEventBus.getInstance();

    React.useEffect(() => {
        const mdcTabBar = new MDCTabBar(tabBarElementRef.current);
        const mdcTabScroller = new MDCTabScroller(tabScrollerElementRef.current);
        mdcTabScrollerRef.current = mdcTabScroller;

        calculateGradientState();
        mdcTabScroller.listen('transitionend', () => {
            calculateGradientState();
        })

        const eventSubscription = middlewareEventBus.subscribe((event: IReactEvent<any>) => {
            if (event.type !== WindowEvent.WINDOW_RESIZE) {
                return;
            }

            calculateGradientState();
        });

        return () => {
            mdcTabBar.destroy();
            mdcTabScroller.destroy();
            eventSubscription.unsubscribe();
        };
    }, []);

    const handleNextNavigator = () => {
        const width = window.innerWidth || document.documentElement.clientWidth;
        mdcTabScrollerRef.current.incrementScroll(width);
    }

    const handlePreviousNavigator = () => {
        const width = window.innerWidth || document.documentElement.clientWidth;
        mdcTabScrollerRef.current.incrementScroll(-width);
    }

    const calculateGradientState = () => {
        const scrollPosition = mdcTabScrollerRef.current?.getScrollPosition() || 0;
        const scrollContentWidth = mdcTabScrollerRef.current?.getScrollContentWidth() || 0;
        const offsetWidth = tabBarElementRef.current?.offsetWidth || 0;

        if (scrollPosition === 0) {
            setIsGradientLeft(false);
        } else {
            setIsGradientLeft(true);
        }

        if (scrollPosition + offsetWidth >= scrollContentWidth) {
            setIsGradientRight(false);
        } else {
            setIsGradientRight(true);
        }
    }

    const displayPreviousNavigator = () => {
        if (!isGradientLeft) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="mdc-tab-scroller__navigator-gradient mdc-tab-scroller__navigator-gradient--previous" />
                <button onClick={handlePreviousNavigator} className='mdc-tab-scroller__navigator mdc-tab-scroller__navigator--previous' aria-label={translate('pagination.previous', { ns: 'spryker-core'})}>
                    <i className='icon icon-chevron-thin-left'></i>
                </button>
            </React.Fragment>
        );
    }

    const displayNextNavigator = () => {
        if (!isGradientRight) {
            return null;
        }

        return (
            <React.Fragment>
                <div className="mdc-tab-scroller__navigator-gradient mdc-tab-scroller__navigator-gradient--next" />
                <button onClick={handleNextNavigator} className='mdc-tab-scroller__navigator mdc-tab-scroller__navigator--next' aria-label={translate('pagination.next', { ns: 'spryker-core'})}>
                    <i className='icon icon-chevron-thin-right'></i>
                </button>
            </React.Fragment>
        )
    }

    const displayTabList = () => tabs.map(tab => {
        const { value, url, content } = tab;
        const isActive = selectedTab === value;
        return (
            <MdcTabLinkItem
                key={value}
                name={value}
                isMinWidth={true}
                isActive={isActive}
                url={url}
                onClick={(_, tabName, event) => onTabClick(tabName, event)}
                tabIndex={isActive ? 0 : -1}
            >
                {content}
            </MdcTabLinkItem>
        );
    });

    return (
        <div ref={tabBarElementRef} className={`mdc-tab-bar ${additionalClassNames}`}>
            <div ref={tabScrollerElementRef} className='mdc-tab-scroller'>
                <div onScroll={calculateGradientState} className='mdc-tab-scroller__scroll-area'>
                    <div className="mdc-tab-scroller__scroll-content" role="tablist">
                        {displayTabList()}
                    </div>
                </div>

                {displayPreviousNavigator()}
                {displayNextNavigator()}
            </div>
        </div>
    );
};

MdcTabBar.defaultProps = {
    additionalClassNames: '',
}
