import './louis-bike.scss';

import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('louis-bike', () =>
    importWithFallback(
        /* webpackMode: "eager" */
        () => import('./louis-bike')
        ),
);
