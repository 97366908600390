import EventBus from '../utils/eventBus';
import IDataSwitchPublisherOptions from '../interfaces/IDataSwitchPublisherOptions';

export default abstract class ADataSwitchSubscriber {
    protected eventBus: EventBus;
    protected element: HTMLElement;
    protected content: string;
    protected listener: string;

    protected addEventListener(): void {
        this.eventBus.subscribe('contentSwitch:change:' + this.listener, this.onChange);
        this.eventBus.subscribe('contentSwitch:init:' + this.listener, this.onInit);
    }

    protected abstract onInit(options: IDataSwitchPublisherOptions): void;
    protected abstract onChange(options: IDataSwitchPublisherOptions): void;
}
