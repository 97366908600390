
// import the 'register' function from the Shop Application
import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

// register the component
export default register(
    'payment-financing-country-select',
    () => importWithFallback(() => import(/* webpackMode: "lazy" */'./payment-financing-country-select'))
);
