import ManufacturerFilterHandler from '../includes/filter-state/ManufacturerFilterHandler';
import BikeTypeFilterHandler from '../includes/filter-state/BikeTypeFilterHandler';
import CapacityFilterHandler from '../includes/filter-state/CapacityFilterHandler';
import YearOfConstructionFilterHandler from '../includes/filter-state/YearOfConstructionFilterHandler';

import BikeTypeFilter from '../components/sidebar/filters/biketype-filter/BikeTypeFilter';
import ManufacturerFilter from '../components/sidebar/filters/manufacturer-filter/ManufacturerFilter';
import RangeFilter from '../components/sidebar/filters/range-filter/RangeFilter';
import { VIEW_MODE_GRID, VIEW_MODE_LIST } from '../../shop-ui/components/search-page/constants';

export const API_URL = '/api/bikes/v1/';

export enum FilterNames {
    MAKE = 'manufacturer',
    CAPACITY = 'capacity',
    BIKETYPE = 'biketype',
    YEAROFCONSTRUCTION = 'yearOfConstruction',
}

export const FILTER_TYPE_MAPPING = {
    [FilterNames.MAKE]: {
        handler: new ManufacturerFilterHandler(),
        filterComponent: ManufacturerFilter,
    },
    [FilterNames.BIKETYPE]: {
        handler: new BikeTypeFilterHandler(),
        filterComponent: BikeTypeFilter,
    },
    [FilterNames.CAPACITY]: {
        handler: new CapacityFilterHandler(),
        filterComponent: RangeFilter,
    },
    [FilterNames.YEAROFCONSTRUCTION]: {
        handler: new YearOfConstructionFilterHandler(),
        filterComponent: RangeFilter,
    },
};

export const VIEW_MODE_AVAILABLE_OPTIONS: string[] = [VIEW_MODE_GRID, VIEW_MODE_LIST];
