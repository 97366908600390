import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IMarkerProps {
    label: string;
    color: 'blue' | 'red';
    labelFor?:string
}

const Marker: React.FunctionComponent<IMarkerProps> = props => {
    const { label, color } = props;

    const colorClassName = color ? ('marker--' + color) : '';

    let labelElement
    if(props.labelFor) {
        labelElement = <label htmlFor={props.labelFor}>{label}</label>;
    }else{
        labelElement = <span>{label}</span>
    }

    return (
        <div className={`marker ${colorClassName}`}>
            {labelElement}
        </div>
    );
};

export default withMemo(Marker);
