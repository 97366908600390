import * as React from 'react';
import { numberFormat } from '../lib';

interface IFormattedNumberProps {
    number: number;
}

const FormattedNumber: React.FunctionComponent<IFormattedNumberProps> = props => (
    <React.Fragment>
        {numberFormat(props.number)}
    </React.Fragment>
);

export default FormattedNumber;
