import * as React from 'react';
import CategoryNavigationItem from './CategoryNavigationItem';
import Accordion from '../../../../Accordion';
import { ICategoryTreeItem } from '../../../interfaces';
import Button from '../../../../../../react/common/components/form/Button';
import useFindCategoryByKey from '../../../hooks/useFindCategoryByKey';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ICategoryNavigationProps {
    activeCategoryKey?: string;
    items: ICategoryTreeItem[];

    onBack(items: ICategoryTreeItem[], activeItem: ICategoryTreeItem): void;
    onSelect(item: ICategoryTreeItem): void;
}

const CategoryNavigation: React.FunctionComponent<ICategoryNavigationProps> = props => {
    const { items, activeCategoryKey, onBack, onSelect } = props;
    const activeItem = useFindCategoryByKey(items, activeCategoryKey);
    const ref = React.useRef(null);
    const [viewportStyle, setViewportStyle] = React.useState<object>({});
    const isSafariBrowser = () => navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1
    const { translate } = useTranslationHook();

    React.useEffect(() => {
        //fix incorrect viewport in safari browser.
        if (isSafariBrowser()) {
            setViewportStyle({ width: ref.current.offsetWidth + 'px' });
        }
    }, []);

    const displayCurrentItem = React.useCallback(() => {
        if (!activeItem) {
            return <React.Fragment key={0}/>;
        }

        return <CategoryNavigationItem
            onSelect={onSelect}
            key={activeItem.id}
            item={activeItem}
        />;
    }, [activeItem]);

    const onClickBackButton = () => {
        const customContainer = document.getElementById("custom-container");
        customContainer.classList.add("slide-in-from-right");
        onBack(items, activeItem);
        customContainer.addEventListener("animationend", () => {
            customContainer.classList.remove("slide-in-from-right");
            const backButton = document.getElementsByClassName("button-category-back")?.[0] as HTMLElement;
            if(!backButton) {
                const firstSubItem = document.getElementsByClassName('navigation-section__navigation-content-children-item__button')?.[0] as HTMLElement;
                firstSubItem && firstSubItem.focus();
            }
        });
    }

    const displayBackButton = React.useCallback(() => {
        if (!activeItem || !activeItem.parentKey) {
            return null;
        }

        return <Button
            icon={`back`}
            additionalClass={`button-leading-icon button-category-back`}
            onClick={() => onClickBackButton()}
            label={translate(`Back`, { ns: 'bike-db'})}
        />;
    }, [activeItem]);

    return (
        <div ref={ref} className={`navigation-section`}>
            <Accordion
                label={translate('Categories', { ns: 'article-list'})}
                hideHeaderContentOnCollapsed={true}
                defaultCollapsed={true}
                isScrollToCurrentOnCollapsed={true}
                skipTransition
            >
                {displayBackButton()}
                <div id="custom-container" style={viewportStyle}>
                    {displayCurrentItem()}
                </div>
            </Accordion>
        </div>
    );
};

export default CategoryNavigation;
