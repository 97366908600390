import { PARAM_PER_PAGE } from '../constants';
import { createActionCreator } from '../../../includes/enhanced-reducer';

export interface ISetPerPagePayload {
    [PARAM_PER_PAGE]: string;
}

const SET_PER_PAGE = 'SET_PER_PAGE';

export const setPerPage = createActionCreator<ISetPerPagePayload>(SET_PER_PAGE);
