import axios, { AxiosResponse } from 'axios';
import { ResponseConstants } from '../constants';
import {
    IStoreSearchResponse,
    IStoreSearchResponseStore,
    IStoreSearchRequestStore,
    IStoreSearchDistanceRequest
} from '../interfaces/IStoreSearchResponse';
import Utils from '../utils/utils';
import CmsStoreUtils from '../utils/cmsStoreUtils';
import { IToastPayload } from '../interfaces/IToastPayload';
import { EToastEvents } from './events/toastEvent';
import MiddlewareEventBus from '../react/common/lib/MiddlewareEventBus';

class CmsStoreSearch {
    private static readonly AUTOCOMPLETE_URL = 'https://osm.louis.de/autocomplete';
    private static readonly DISTANCE_URL = 'https://osm.louis.de/storeDistance';

    private static sortByDistance = (a: IStoreSearchResponseStore, b: IStoreSearchResponseStore) => a.distanceInKm - b.distanceInKm;

    private autoSuggest = Utils.debounce(
        (): void => {
            const pattern: string = this.searchField.value;

            this.clearAutoSuggestResults();

            if (pattern.length < 2) {
                return;
            }

            // The osm service accepts an optional country name parameter which will lead
            // to suggestions from this country getting prioritised.
            const countryMap = {
                'dk': 'Danmark',
                'be': 'België',
                'at': 'Österreich',
                'de': 'Deutschland',
                'uk': 'Great Britain',
                'ie': 'Ireland',
                'it': 'Italia',
                'fr': 'France',
                'nl': 'Nederland',
                'pl': 'Polska',
                'ch': 'Schweiz',
                'se': 'Sverige',
                'es': 'España',
                'cz': 'Česká republika',
            }
            const hostParts = window.location.hostname.split('.');
            const currentTLD = hostParts.length ? hostParts[hostParts.length - 1].toLowerCase() : '';
            const country = currentTLD ? countryMap[currentTLD.toLowerCase()] : '';

            const url = `${CmsStoreSearch.AUTOCOMPLETE_URL}?q=${pattern}` + (country ? `&countryNameForBoosting=${country}` : '');

            axios.get(url)
                .then((response: AxiosResponse) => {
                    if (response.status !== ResponseConstants.HTTP_OK) {
                        return;
                    }

                    // create a list item for every suggestion
                    response.data.forEach(responseEntry => {
                        this.autosuggestContainer.classList.add('is-active');
                        const result = `<li class="result" data-lat="${responseEntry.lat}" data-lon="${responseEntry.lon}">${responseEntry.displayName}</li>`;
                        this.autosuggestContainer.insertAdjacentHTML('beforeend', result);
                    })

                    // add clickhandlers to the new suggestion items
                    this.autosuggestContainer.querySelectorAll('.result').forEach(item => {
                        item.addEventListener('click', this.selectAutoSuggestResult);
                    });
                })
                .catch(_ => null);
        },
        200,
        false
    );

    private readonly storeSearchButton: HTMLElement;
    private readonly searchField: HTMLInputElement;
    private readonly autosuggestContainer: HTMLElement;
    private searchForm: HTMLElement;
    private storeList: IStoreSearchRequestStore[];
    private readonly storeTeaser: NodeListOf<HTMLElement>;
    private readonly useMyLocationLink: HTMLElement;

    constructor(storeSearchButton: HTMLElement) {
        this.storeSearchButton = storeSearchButton;
        this.searchField = document.getElementById('cms_store__location-search-field') as HTMLInputElement;
        this.autosuggestContainer = document.getElementById('cms_store__autosuggest-result');
        this.searchForm = document.querySelector('.cms_store__location-search-container');
        this.storeTeaser = document.querySelectorAll('.cms_store-teaser');
        this.useMyLocationLink = document.querySelector('.cms_store__use-my-location-link');

        this.initializeStoreList();

        CmsStoreUtils.clearSearchInput();
        document.addEventListener('click', this.clearAutoSuggestResults);
        this.searchField.addEventListener('input', this.autoSuggest);
        this.searchField.addEventListener('keydown', this.focusAutoSuggestResults);
        this.storeSearchButton.addEventListener('click', (): void => {
            this.filterAndSortStoresByDistance();
        });
        this.searchForm.addEventListener('submit', (event): void => {
            event.preventDefault();
            this.filterAndSortStoresByDistance();
        });
        this.useMyLocationLink.addEventListener('click', this.handleUseMyLocation);
    }

    private initializeStoreList = () => {
        this.storeList = [];

        this.storeTeaser.forEach(item => {
            this.storeList.push(
                {
                    id: item.id,
                    lat: parseFloat(item.dataset.lat),
                    lon: parseFloat(item.dataset.lon),
                }
            )
        });
    }

    private clearAutoSuggestResults = () => {
        this.autosuggestContainer.textContent = '';
        this.autosuggestContainer.classList.remove('is-active');
    }

    private selectAutoSuggestResult = e => {
        this.searchField.value = e.target.innerHTML;
        this.clearAutoSuggestResults();
        this.filterAndSortStoresByDistance(true, parseFloat(e.target.dataset.lat), parseFloat(e.target.dataset.lon));
    }

    private focusAutoSuggestResults = e => {
        const firstItem = this.autosuggestContainer.querySelector('.result');
        if (!firstItem) {
            return;
        }
        const lastItem = this.autosuggestContainer.lastElementChild;

        const currentItem = this.autosuggestContainer.querySelector('.selected');
        if (['ArrowDown', 'Down'].includes(e.key)) {
            e.preventDefault();

            if (currentItem) {
                currentItem.classList.remove('selected');
                const nextItem = currentItem.nextElementSibling;
                this.focusItem(nextItem ? nextItem : firstItem);
            } else {
                this.focusItem(firstItem);
            }
        } else if (['ArrowUp', 'Up'].includes(e.key)) {
            e.preventDefault();

            if (currentItem) {
                currentItem.classList.remove('selected');
                const previousItem = currentItem.previousElementSibling;
                this.focusItem(previousItem ? previousItem : lastItem);
            } else {
                this.focusItem(lastItem);
            }
        // Enter
        } else if (e.keyCode === 13) {
            e.preventDefault();

            this.clearAutoSuggestResults();
            if (currentItem) {
                this.searchField.value = currentItem.innerHTML;
                this.filterAndSortStoresByDistance(true, parseFloat((currentItem as HTMLElement).dataset.lat), parseFloat((currentItem as HTMLElement).dataset.lon));
            } else {
                this.filterAndSortStoresByDistance();
            }
        } else if (['Escape', 'Esc'].includes(e.key)) {
            e.preventDefault();

            this.clearAutoSuggestResults();
        }
    }

    private focusItem = (item: Element) => {
        item.classList.add('selected');
        item.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }

    private filterAndSortStoresByDistance = (searchValueIsAutoCompleted: boolean = false, lat?: number, lon?: number, useMyLocationWasUsed?: boolean) => {
        CmsStoreUtils.hideSearchTermIndicator();
        CmsStoreUtils.hideActiveStores();
        CmsStoreUtils.resetMapHighlight();
        CmsStoreUtils.resetLocationDropdown();

        const pattern: string = this.searchField.value;
        if (pattern.length < 2 && !searchValueIsAutoCompleted) {
            return;
        }

        const data: IStoreSearchDistanceRequest = {
            storeLocations: this.storeList,
        };
        if (searchValueIsAutoCompleted) {
            data.lat = lat;
            data.lon = lon;
        } else {
            data.q = pattern;
        }

        if (useMyLocationWasUsed) {
            CmsStoreUtils.showSearchTermIndicator(`${lat}, ${lon}`);
        } else {
            CmsStoreUtils.showSearchTermIndicator(this.searchField.value);
        }

        axios.post(CmsStoreSearch.DISTANCE_URL, data)
            .then((response: AxiosResponse<IStoreSearchResponse>) => {
                if (response.status !== ResponseConstants.HTTP_OK) {
                    return;
                }

                // sort response object, take first 10, insert store-distance, append for DOM sorting, set active
                const sortedAndSlicedResponse = response.data.storeLocations.sort(CmsStoreSearch.sortByDistance).slice(0, 9);
                sortedAndSlicedResponse.forEach(responseEntry => {
                    const storeTeaser = document.getElementById(responseEntry.id);
                    const distance = storeTeaser.querySelector('.cms_store__distance');
                    const distanceValue = storeTeaser.querySelector('.cms_store__distance-value');
                    distanceValue.innerHTML = responseEntry.distanceInKm.toFixed(2);
                    (distance as HTMLElement).style.display = 'block';
                    storeTeaser.parentNode.appendChild(storeTeaser);
                    CmsStoreUtils.showStoreImage(storeTeaser);
                    CmsStoreUtils.setStoreToActive(storeTeaser);
                })
            })
            .catch(_ => {
                CmsStoreUtils.showNoResultsInfoBox();
            });
    }

    private handleUseMyLocation = () => {
        // get geolocation & use for filtering
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                this.filterAndSortStoresByDistance(true, position.coords.latitude, position.coords.longitude, true);
            }, () => {
                const messageElement = document.getElementById('cms_store__geolocation-text');
                const toastPayload: IToastPayload = {
                    message: messageElement.innerHTML,
                    iconClass: 'icon-error',
                };
                const middlewareEventBus: MiddlewareEventBus = MiddlewareEventBus.getInstance();
                middlewareEventBus.publish({ type: EToastEvents.TOAST_FADE_IN, payload: toastPayload });
            });
        }
    }
}

export default class CmsStoreSearchHandler {
    public static init = () => {
        const storeSearchButton = document.getElementById('cms_store__location-search-button');
        if (storeSearchButton) {
            new CmsStoreSearch(storeSearchButton);
        }
    }
}
