import Component from 'ShopUi/models/component'
import { CustomElementModule, CustomElementImporter } from 'ShopUi/app/registry';

/**
 * This class is just a placeholder that is returned if the dynamic import of the JS chunk fails.
 * It won't do anything, which is not good but better than the whole JS thread crashing
 */
class NoOpClass extends Component {
    protected readyCallback(): void {
    }
}

/**
 * There is a (unlikely) edge case that the JS Chunks can't be loaded and/or imported as expected
 * This may be related to caching issues. For more info on the possible error scenario see SX-1836.
 * 
 * This function tries to import the chunk with a dynamic import. If it fails, it will resolve with
 * a NoOpClass. This is done because a failed import would crash the entire JS thread. In some cases
 * the module that couldn't be imported isn't critical to our business logic, so it's desired for the
 * rest of the modules to be loaded 
 */
 export const importWithFallback = async (importer: CustomElementImporter): Promise<CustomElementModule> => {
    try {
        return await importer();
    } catch (e) {
        // TODO: this would be a good place to log this error to Sentry so we can
        // know if chunks couldn't be loaded
        console.error(e);
        console.error(`Couldn't import module for ${importer.toString()}`);
        
        return Promise.resolve({default: NoOpClass});
    }
 }
