import * as React from 'react';
import MyBikeIcon from '../components/MyBikeIcon';
import MyBikeIconFilled from '../components/MyBikeIconFilled';
import MyBikeButton from '../components/MyBikeButton';
import MyLouisService, { IAddToMyBikeResult } from '../services/MyLouisService';
import { BikeDBConstants } from '../../../constants';
import { IMyBikeTogglePayload } from '../../../interfaces/IMyBikeTogglePayload';
import { IToastPayload } from '../../../interfaces/IToastPayload';
import { MyBikeEvent } from '../../common/events/myBikeEvent';
import { DialogEvent } from '../../common/events/dialogEvent';
import MiddlewareEventBus from '../../common/lib/MiddlewareEventBus';
import { EToastEvents } from '../../../modules/events/toastEvent';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IMyBikeContainerProps {
    myLouisService: MyLouisService;
    bikeId: string;
    externalId: string;
    wrapperComponent: any;
    isInMyBike?: boolean;
    csrfToken: string;

    onRemove?(externalId: string): void;
}

const RefElementMap = () => {
    const map = new Map();
    map.set(MyBikeButton, React.useRef<HTMLButtonElement>());
    map.set(MyBikeIcon, React.useRef<HTMLDivElement>());
    map.set(MyBikeIconFilled, React.useRef<HTMLDivElement>());

    return map;
};

const MyBikeContainer: React.FunctionComponent<IMyBikeContainerProps> = (props: IMyBikeContainerProps) => {
    const myLouisService: MyLouisService = props.myLouisService;
    const middlewareEventBus: MiddlewareEventBus = MiddlewareEventBus.getInstance();
    const bikeRefElement = RefElementMap().get(props.wrapperComponent);
    const csrfToken = props.csrfToken;
    const { translate } = useTranslationHook();

    const [isActive, setActive] = React.useState<boolean>(props.isInMyBike);

    const addBikeHandler = (externalIdForAdd: string): void => {
        myLouisService.addToMyBike(externalIdForAdd, csrfToken).then((result: IAddToMyBikeResult) => {
            if (result.success === false) {
                if (result.reason === BikeDBConstants.MY_BIKE_IS_FULL_REASON) {
                    middlewareEventBus.publish({ type: DialogEvent.OPEN_DIALOG, payload: { id: '#bikedbDialog' } });
                }
                return;
            }

            const payload: IMyBikeTogglePayload = {
                articleNumber: externalIdForAdd,
                status: true,
                bikeTriggerElement: bikeRefElement.current,
            };
            middlewareEventBus.publish({ type: MyBikeEvent.TOGGLE, payload });
            setActive(true);
        });
    };

    const removeBikeHandler = (externalIdForRemove: string): void => {
        myLouisService.removeFromMyBike(externalIdForRemove).then((result: boolean) => {
            if (false === result) {
                return;
            }

            const payload: IMyBikeTogglePayload = { articleNumber: externalIdForRemove, status: false };
            middlewareEventBus.publish({ type: MyBikeEvent.TOGGLE, payload });
            setActive(false);

            const toastPayload: IToastPayload = {
                message: translate('Removed from My Bike.', { ns: 'bike-db'}),
                link: {
                    text: translate('Undo', { ns: 'bike-db'}),
                    callback: () => addBikeHandler(externalIdForRemove),
                },
            };
            middlewareEventBus.publish({ type: EToastEvents.TOAST_FADE_IN, payload: toastPayload });

            if (undefined !== props.onRemove) {
                props.onRemove(externalIdForRemove);
            }
        });
    };

    const myBikeToggleMemo = React.useCallback(
        () => isActive ? removeBikeHandler(props.externalId) : addBikeHandler(props.externalId),
        [props.bikeId, isActive],
    );

    return <props.wrapperComponent bikeRefElement={bikeRefElement} isActive={isActive} onMyBikeToggle={myBikeToggleMemo} />;
};

MyBikeContainer.defaultProps = { isInMyBike: false };

export default MyBikeContainer;
