import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import NativeSelect from '../../../select/NativeSelect';
import usePerPageSelect from '../../hooks/usePerPageSelect';
import {scrollToTopElement} from 'PyzShopUi/scripts/helpers/scrollHelpers';

interface INativePerPageSelectProps {
    label: string;
    itemPerPageOptions: number[];
    currentItemsPerPage: string;
    shouldScrollToTop?: boolean;

    dispatch: React.Dispatch<any>;
}

const NativePerPageSelectComponent: React.FunctionComponent<INativePerPageSelectProps> = props => {
    const { label, itemPerPageOptions, currentItemsPerPage, dispatch, shouldScrollToTop } = props;

    const [perPageSelectOptions, onPerPageSelect] = usePerPageSelect(itemPerPageOptions, dispatch);

    const onPerPageChangeHandler = (value: string) => {
        onPerPageSelect(value);

        if (shouldScrollToTop === false) {
            return;
        }

        scrollToTopElement();
    };

    return (
        <NativeSelect
            label={label}
            selectedValue={currentItemsPerPage}
            options={perPageSelectOptions}
            onChange={onPerPageChangeHandler}
        />
    );
};

NativePerPageSelectComponent.defaultProps = {
    shouldScrollToTop: false,
};

const NativePerPageSelect = withMemo(NativePerPageSelectComponent);

export default NativePerPageSelect;
