import { EECproductClickWithDataToken } from './EECproductClickWithDataToken';
import { IProductClickEvent, IActionField } from '../interfaces';

(window as any).global = window;

const GTM_KEY = 'gtm';
const GTM_DATA_KEY = 'data';
const EXTRA_INFORMATION_KEY = 'extraInformation';

function useArticleGTMEvent(data?: object, actionField?: IActionField): void {
    if (
        !data
        || !data.hasOwnProperty(EXTRA_INFORMATION_KEY)
        || !data[EXTRA_INFORMATION_KEY].hasOwnProperty(GTM_KEY)
        || !data[EXTRA_INFORMATION_KEY][GTM_KEY]
    ) {
        return;
    }

    data[EXTRA_INFORMATION_KEY][GTM_KEY].forEach((singleData: object): void => {
        const event: IProductClickEvent = new IProductClickEvent(singleData[GTM_DATA_KEY]);
        
        if ((!event.payload.actionField || !event.payload.actionField.list) && actionField) {
            event.payload.actionField = actionField;
        }

        EECproductClickWithDataToken(event);
    });
}

export default useArticleGTMEvent;
