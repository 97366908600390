import * as React from 'react';
import { ISelectFilterOption } from '../../../../../shop-ui/components/search-page/interfaces';
import CheckboxListItem from '../../../../../shop-ui/components/CheckboxListItem';

interface IMaterialFilterOptionsProps {
    additionalClassName?: string;
    optionList: ISelectFilterOption[];

    onToggleItem?(value: string, isSelected: boolean): void;
}

const FasteningFilterOptions: React.FunctionComponent<IMaterialFilterOptionsProps> = props => {
    const displayItem = () => (
        props.optionList.map(option => (
            <CheckboxListItem
                key={option.value}
                label={option.label}
                value={option.value}
                isSelected={option.selected}
                additionalWrapperClassName='checkbox-menu__item'
                onClick={props.onToggleItem}
            />
        ))
    );

    return (
        <ul className={`checkbox-menu ${props.additionalClassName}`}>
            {displayItem()}
        </ul>
    );
};

export default FasteningFilterOptions;
