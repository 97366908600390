import * as React from 'react';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';
import { ReactFoundationEvent } from 'PyzShopUi/scripts/react/common/events/foundationEvent';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import Input, {IInputProps} from './Input';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

export interface ISearchInputProps {
    value?: string;
    dataQa?: string;
    isEnableAutocomplete?: boolean;
    hasLabel?: boolean;
    labelText?: string;
    placeholder?: string;
    isFullWidth?: boolean;

    onSearchChange(name: string, value: string): void;
    onSubmit?(event: React.FormEvent<HTMLFormElement>): void;
    inputProps?: IInputProps['inputProps']
}

const SearchInput = (props: ISearchInputProps) => {
    const {
        value,
        dataQa,
        onSearchChange,
        isEnableAutocomplete,
        hasLabel,
        labelText,
        placeholder,
        isFullWidth,
        onSubmit,
        inputProps,
    } = props;

    const middlewareEventBus: MiddlewareEventBus = MiddlewareEventBus.getInstance();
    const searchInputRef = React.useRef(null);
    const { translate } = useTranslationHook();

    React.useEffect(() => {
        middlewareEventBus.subscribe((event: IReactEvent<any>) => {
            switch (event.type) {
                case ReactFoundationEvent.NAVIGATION_MENU_MOUSE_ENTER:
                case ReactFoundationEvent.SMALL_SREEN_SUGGESSTION_ON_TOUCH_MOVE:
                    if (!searchInputRef || !searchInputRef.current) {
                        return;
                    }

                    searchInputRef.current.blur();
                    break;
                default:
            }
        });
    }, []);

    const defaultAttributes = {
        label: !hasLabel ? null : (labelText ?? translate('Search', { ns: 'article-list' })),
        dataQa,
    };

    return (
        <Input
            inputRef={searchInputRef}
            name='search'
            {...defaultAttributes}
            iconClass='icon-search'
            value={value}
            onValueChange={onSearchChange}
            isEnableAutocomplete={isEnableAutocomplete}
            placeholder={placeholder}
            isFullWidth={isFullWidth}
            onSubmit={onSubmit}
            inputProps={inputProps}
        />
    );
};

SearchInput.defaultProps = {
    isFullWidth: false,
    autocomplete: false,
    onSubmit: (event) => (event.preventDefault()),
};

export default SearchInput;
