import * as React from 'react';
import IBike from '../interfaces/IBike';
import IPagination from '../interfaces/IPagination';
import { numberFormat } from '../../common/lib';
import { IViewOption } from '../../common/components/layout/view-options/ViewOptions';
import { IBikeItem } from 'PyzShopUi/scripts/bike/interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IBikeGridProps {
    bikes: IBike[] | IBikeItem[];
    viewOptions?: IViewOption;
    placeholderImage?: string;
    hasSideBar: boolean;
    csrfToken: string;

    onRemove?(bikeId: string): void;
}

interface IMyBikeListProps {
    imageEmptyMyBikeUrl: string;
    placeholderImage: string;
    pagination: IPagination;
    bikes: IBike[] | IBikeItem[];
    totalBikes: number;
    bikeGridComponent: React.FunctionComponent;
    bikeDatabaseUrl: string;
    hasSideBar: boolean;
    csrfToken: string;

    onRemove?(bikeId: string): void;
}

const MyBikeList: React.FunctionComponent<IMyBikeListProps> = props => {
    const isBikeListShown = props.pagination.totalItems > 0;
    const BikeGridComponent: React.FunctionComponent<IBikeGridProps> = props.bikeGridComponent;
    const { translate } = useTranslationHook();

    const displayContent = () => {
        const totalBikes = props.totalBikes <= 100 ? Math.floor((props.totalBikes) / 10) * 10 : Math.floor((props.totalBikes) / 100) * 100;

        return (
            <React.Fragment>
                <div className={`grid-x grid-margin-x ${!isBikeListShown ? 'hide' : ''}`}>
                    <div className='cell small-12 bike-list-content-header'>
                        <div className='bike-items-count bold'>
                            {translate('bike', { ns: 'bike-db', count: props.pagination.totalItems })}
                        </div>
                    </div>
                    <BikeGridComponent
                        bikes={props.bikes}
                        placeholderImage={props.placeholderImage}
                        onRemove={props.onRemove}
                        hasSideBar={props.hasSideBar}
                        csrfToken={props.csrfToken}
                    />
                </div>

                <div className={'empty-my-bike' + (isBikeListShown ? ' hide' : '')}>
                    <img src={props.imageEmptyMyBikeUrl} alt={translate('My bike', { ns: 'bike-db'})}/>
                    <h2 className="heading1">{translate('My bike and the Louis Bike Database', 'bike-db')}</h2>
                    <p>
                    {translate('Our Bike Database contains more than {{totalBikes}} models:', { replace: { totalBikes: numberFormat(totalBikes) }, ns: 'bike-db'})}&nbsp;
                    {translate('model-specific wearing parts and accessories...', { ns: 'bike-db'})}&nbsp;
                    {translate('Save your vehicles and „My bike“...', { ns: 'bike-db'})}
                    </p>
                    <p>{translate('You can also permanently transfer your vehicle selection into...', { ns: 'bike-db'})}</p>
                </div>
            </React.Fragment>
        );
    };

    return (
        <div className='grid-x grid-margin-x' data-scroll-to-offset='true'>
            <div className='cell small-12 medium-3'>
                <a className='button button-red mdc--fullwidth button-find-your-bike' href={props.bikeDatabaseUrl}>
                    <span className='mdc--fullwidth text-center'>{translate('Find your bike', { ns: 'bike-db'})}</span>
                    <i className='icon icon-mein-bike'/>
                </a>
            </div>

            <div className='cell small-12 medium-9'>
                {displayContent()}
            </div>
        </div>
    );
};

export default MyBikeList;
