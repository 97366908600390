import {
    EventPayloadStore,
    IProductPayload,
    IStoreInterface,
    IActionField,
    IPageData,
    IGTMEvent
} from './payload';
import * as fromHandler from './listener';
import * as fromEEC from './eec.event';
import * as fromClickTracking from './clickTracking.event';
import { isTrackingConsentGiven } from 'PyzShopUi/scripts/shop-ui/services/GoogleConsentModeService';

/**
 * A class to handle louis own events.
 *
 * In general this class is responsible to fill our store with data represented by a data-token and
 * to bring event listeners and handlers together.
 *
 * The stored data contains product, product lists and list information. All of them are represented through a
 * data-token, which schould be placed on the item we listen for. Doing so we can bring events on a dom item
 * together with the stored data.
 *
 * The process of registering handlers on listeners is technically based on streams emmitted by the listeners.
 * To get one or more actions on events in that streams we subscribe well defined handlers on those events.
 *
 * Doing so, we decouple the world of the listeners, the data and the handlers. It should be possible to register
 * several own handlers on given events, or to add more customized listeners.
 */
export class LouisEvents {
    constructor(private store: IStoreInterface, private listOfHandler: { [type: string]: fromHandler.IBaseHandler }) { }

    /**
     * To register a list of products use this function.
     */
    public bindProductPayloadToElements(products: IProductPayload[], token: string): void {
        this.store.products[token] = products;
    }

    /**
     * Most of the Google Events need some information about the list we are currently in
     * to pass those  data you should use this method.
     */
    public bindActionFieldToElements(actionFields: IActionField, token: string): void {
        this.store.actionFields[token] = actionFields;
    }

    /**
     * The page data is the same data send through the Page View event. We can use them client side also.
     */
    public bindPageData(value: IPageData) {
        this.store.pageData = value;
    }

    /**
     * To register an handler on a stream of events emmited by a listener we use constants/enum values for the handler and
     * listener names. The selector should be a valid css selector.
     */
    public registerHandlerOn(selector: string, listenerType: string, handlerName: string) {
        if (!this.listOfHandler.hasOwnProperty(handlerName)) {
            throw new Error('Handler with name ' + handlerName + ' not found');
        }

        switch (listenerType) {
            case fromHandler.LouisEventType.CLICK_EVENT:
                fromHandler.onClick(selector).subscribe(event => this.listOfHandler[handlerName](event));
                break;
            case fromHandler.LouisEventType.CLICK_EVENT_WITH_DATA_TOKEN:
                fromHandler.onClickWithDataToken(selector).subscribe(event => this.listOfHandler[handlerName](event));
                break;
            case fromHandler.LouisEventType.CLICK_EVENT_WITH_DATA_TOKEN_BY_SELECTOR:
                fromHandler.onClickWithDataTokenBySelector(selector, this.store).subscribe(_event => this.listOfHandler[handlerName]);
                break;
            case fromHandler.LouisEventType.LOAD_PAGE_WITH_PRODUCT_LIST:
                fromHandler.onLoadPageWithProductLists(selector).subscribe(event => this.listOfHandler[handlerName](event));
                break;
            case fromHandler.LouisEventType.CLICK_EVENT_AFTER_CHANGE_AMOUNT:
                fromHandler.onClickWithDataToken(selector).subscribe(event => this.listOfHandler[handlerName](event));
                break;
            case fromHandler.LouisEventType.LOAD_EVENT:
            case fromHandler.LouisEventType.ONE_TIME_EVENT:
                break;
            case fromHandler.LouisEventType.ERROR:
                fromHandler.onError().subscribe(event => this.listOfHandler[handlerName](event));
                break;
            default:
                throw new Error('No matching listener found for type ' + listenerType);
        }
    }

    public handleGTMEvent(eventData: IGTMEvent) {
        switch (eventData.listenerName) {
            case fromHandler.LouisEventType.LOAD_EVENT:
            case fromHandler.LouisEventType.ONE_TIME_EVENT:
                if (isTrackingConsentGiven()) {
                    window.dataLayer.push(eventData.data);
                }
                break;
            case fromHandler.LouisEventType.CLICK_EVENT_WITH_DATA_TOKEN:
                this.registerHandlerOn(eventData.selector, eventData.listenerName, eventData.handlerName);
                this.bindProductPayloadToElements(eventData.data.products, eventData.token);
                this.bindActionFieldToElements(eventData.data.actionField, eventData.token);
                break;
            default:
                throw new Error('No matching listener found for type ' + eventData.listenerName);
        }
    }

    public handleCLickTrackingEvent(element: Element) {
        const clickTrackingHandle = this.listOfHandler[fromClickTracking.CLICK_TRACKING_PRODUCT_CLICK_EVENT];
        fromHandler.onClickWithTracking(element)
            .subscribe(event => clickTrackingHandle(event));
    }
}

export default class CommonEvents {
    public static firstPageInit = (): void => {
        const handler = { ...fromEEC.handler, ...fromClickTracking.handler };

        /**
         * Mechanism to make outcome of our module available on global window object.
         */
        window.Louis = {
            Events: new LouisEvents(EventPayloadStore, handler)
        };
    }
}
