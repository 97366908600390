import * as React from 'react';
import Footer from '../../../shop-ui/components/search-page/components/footer/Footer';
import { IApiQueryData, ISearchResponse } from '../../../shop-ui/components/search-page/interfaces';
import { doUpdateCatalogSearchPageData } from '../../actions/catalogSearchPage';
import {ICategorySearch} from '../../interfaces';
import CategoryResultItem from './CategoryResultItem';
import { numberFormat } from '../../../shop-ui/includes/number';
import Header from '../../../shop-ui/components/search-page/components/header/Header';
import { VIEW_MODE_OPTIONS } from '../../../catalog-page/constants';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ICategoryResultProps {
    defaultParameter: IApiQueryData;
    searchResponse?: ISearchResponse;

    dispatch: React.Dispatch<any>;
}

const CategoryResult: React.FunctionComponent<ICategoryResultProps> = props => {
    const { searchResponse, defaultParameter, dispatch } = props;
    const { pagination, resultItems } = searchResponse;
    const { translate } = useTranslationHook();

    const customPaginationHandler = (newPage: string) => dispatch(doUpdateCatalogSearchPageData(defaultParameter, { page: newPage }));

    const itemPerPageOptions: number[] = pagination?.config?.validItemsPerPageOptions || [];
    const currentItemsPerPage: string = pagination?.currentItemsPerPage?.toString() || '';
    const currentPage: number = pagination?.currentPage || 0;
    const maxPage: number = pagination?.maxPage || 0;
    const pageNumFound: number = pagination?.numFound || 0;

    const displayResultHeader = () => {
        if (pageNumFound === 0) {
            return null;
        }

        const totalSearchResultItemsText: string = translate(
            'category',
            {
                count: pageNumFound,
                ns: 'search'
            }
        );

        return (
            <Header
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                totalSearchResultItemsText={totalSearchResultItemsText}
                sortParamNames={[]}
                currentSortParam={''}
                renderSortSelection={false}
                renderPerPageSelection={true}
                renderViewModeSwitch={false}
                availableViewModes={VIEW_MODE_OPTIONS}
                dispatch={dispatch}
            />
        );
    };

    const displayResultFooter = () => {
        if (pageNumFound === 0) {
            return null;
        }

        return (
            <Footer
                perPageSelectText={translate('Categories per page', { ns: 'search'})}
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                currentPage={currentPage}
                renderPerPageSelection={true}
                maxPage={maxPage}
                customPagination={customPaginationHandler}
                dispatch={dispatch}
            />
        );
    };

    const displayResultItems = () => resultItems.map((resultItem: ICategorySearch) =>
        <CategoryResultItem key={resultItem.url} category={resultItem} />
    );

    return (
        <div className="category-tab page-layout-search-items-result">
            <div className="result-header grid-padding-y">
                {displayResultHeader()}
            </div>

            <div className="category-result">
                {displayResultItems()}
            </div>

            <div className="result-footer">
                {displayResultFooter()}
            </div>
        </div>
    );
}

export default CategoryResult;
