export const SAME_SITE_VALUES = ['Lax', 'Strict', 'None'] as const;
export type SameSiteValue = typeof SAME_SITE_VALUES[number];

export default class CookieUtils {
    public static getCookieValue = (cookieName: string): string => {
        const regex = new RegExp(`[.*; ]?${cookieName}=(.*?(?=\\;))`, 'g');
        const matches = regex.exec(`${document.cookie};`); // add ; for regex matching

        if (matches === null) {
            return '';
        }

        return matches[1];
    }

    public static getDomain(): string {
        const domain: string = document.documentElement.getAttribute('data-share-domain');
        if (domain != null && domain !== '') {
            return domain;
        }

        // FIXME: document.domain is deprecated!
        const domainParts: string[] = document.domain.split('.');
        domainParts.shift();

        return domainParts.join('.');
    }

    public static setCookie = (cookieName: string, cookieVal: string, expireInDays: number|null, sameSite: SameSiteValue = 'None'): void => {
        let expireDate = null;
        // expires is outdated, use max-age! if both are set modern browsers will use max-age
        let maxAge = null;

        if (expireInDays !== null) {
            expireDate = new Date();
            expireDate.setDate(expireDate.getDate() + expireInDays);
            expireDate = expireDate.toUTCString();
            // compute in seconds
            maxAge = expireInDays * 86400;
        } else {
            // Expires with session
            expireDate = '';
        }

        const cookieDomain = CookieUtils.getDomain();

        document.cookie = `${cookieName}=${encodeURI(cookieVal)}; expires=${expireDate}; max-age=${maxAge}; domain=.${cookieDomain}; path=/; Secure; samesite=${sameSite}`;
    }

    public static removeCookie = (cookieName: string): void => {
        const cookieDate = new Date();

        cookieDate.setTime(cookieDate.getTime() - 31536000); // far far in the past
        const cookieDomain = CookieUtils.getDomain();

        document.cookie = `${cookieName}=; expires=${cookieDate.toUTCString()}; max-age=0; domain=.${cookieDomain}; path=/; Secure;`;
    }
}
