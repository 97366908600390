import EventBus from '../../utils/eventBus';
import { EDialogEvents } from '../events/dialogEvent';

class MoveToCartDialog {
    private moveToCartElement: HTMLElement;
    private eventBus: any;

    constructor(moveToCartElement, eventBus) {
        this.moveToCartElement = moveToCartElement;
        this.eventBus = eventBus;
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.moveToCartElement.addEventListener('click', (event: MouseEvent): void => {
            event.preventDefault();
        });

        this.eventBus.subscribe(EDialogEvents.DIALOG_CLOSED, target => {
            if (target !== undefined && target.id === this.moveToCartElement.dataset.target) {
                this.moveToCartElement.closest('form').submit();
            }
        });
    }
}

export default class MoveToCartDialogHandler {
    public static init = (eventBus: EventBus): void => {
        document.querySelectorAll("[data-move-to-cart-dialog='true']").forEach((moveToCartElement: HTMLElement) => {
            // tslint:disable-next-line:no-unused-expression
            new MoveToCartDialog(moveToCartElement, eventBus);
        });
    }
}
