import EventBus from "PyzShopUi/scripts/utils/eventBus";
import IModuleOptions from "PyzShopUi/scripts/interfaces/IModuleOptions";
import {EDataSwitchEvents} from "PyzShopUi/scripts/modules/events/dataSwitchEvent";
import FormUtils from "PyzShopUi/scripts/utils/formUtils";
import ADataSwitchSubscriber from "PyzShopUi/scripts/abstracts/ADataSwitchSubscriber";
import IDataSwitchPublisherOptions from "PyzShopUi/scripts/interfaces/IDataSwitchPublisherOptions";

class ShippingFormUtils {
    public static clearForm = (parentFormShippingAddressElement: HTMLElement) => {
        parentFormShippingAddressElement.querySelectorAll('.form-field')
            .forEach((element: HTMLElement) => {
                FormUtils.clearValueInputField(element);
            });
        const salutation: HTMLSelectElement = parentFormShippingAddressElement.querySelector(`#addressesForm_shippingAddress_salutation`);
        FormUtils.setSelectedIndex(salutation, 0);
    }

    public static showForm = (formShippingAddressElement: HTMLElement, addressSelectElement: HTMLSelectElement) => {
        formShippingAddressElement.classList.remove('is-hidden')

        formShippingAddressElement.querySelectorAll('input')
            .forEach((element: HTMLElement) => {
                FormUtils.enableInputField(element);
            });

        const salutation: HTMLSelectElement = formShippingAddressElement.querySelector(`#addressesForm_shippingAddress_salutation`);
        FormUtils.enableSelectField(salutation);

        FormUtils.requireFields(formShippingAddressElement)
        addressSelectElement.focus({
            preventScroll: true
        })
    }

    public static hideForm = () => {
        const formShippingAddressElement: HTMLElement = document.querySelector('.new-shipping-address-form');

        formShippingAddressElement.classList.add('is-hidden')
        FormUtils.notRequireFields(formShippingAddressElement)

        formShippingAddressElement.querySelectorAll('input')
            .forEach((element: HTMLElement) => {
                FormUtils.disableInputField(element);
            });
    }

    public static showAndFillShippingAddressForm = (idCustomerAddress) => {
        const customerAddress = JSON.parse(document.querySelector('[data-address-map]').getAttribute('data-address-map'))

        if (!idCustomerAddress || !customerAddress[idCustomerAddress]) {
            return
        }

        const parentFormShippingAddressElement: HTMLElement = document.querySelector('.form-shipping-address');
        const formShippingAddressElement: HTMLElement = document.querySelector('.new-shipping-address-form');
        const customerAddressSelected = customerAddress[idCustomerAddress];

        formShippingAddressElement.classList.remove('is-hidden')
        FormUtils.requireFields(formShippingAddressElement)

        parentFormShippingAddressElement.querySelectorAll('input')
            .forEach((element: HTMLElement) => {
                FormUtils.disableInputField(element);
            });

        Object.keys(customerAddressSelected).map((fieldName) => {
            const input: HTMLInputElement = formShippingAddressElement.querySelector(`input#addressesForm_shippingAddress_` + fieldName);
            if (input && customerAddressSelected[fieldName]) {
                FormUtils.setInputFieldValue(input, customerAddressSelected[fieldName])
            }
        })

        const salutation: HTMLSelectElement = formShippingAddressElement.querySelector(`#addressesForm_shippingAddress_salutation`);
        FormUtils.disableSelectField(salutation)
        FormUtils.setSelectValue(salutation, customerAddressSelected['salutation'])

        const country: HTMLSelectElement = formShippingAddressElement.querySelector(`#addressesForm_shippingAddress_iso2_code`);
        FormUtils.setSelectValue(country, customerAddressSelected['iso2_code'])
    }
}

class DataSwitchShippingAddressPublisher {
    private element: HTMLElement;
    private readonly isInitialCall: boolean = true;

    public constructor(element: HTMLElement, options: IModuleOptions) {
        this.element = element;
        this.addEventListener();
        this.onChange(this.isInitialCall);
        this.isInitialCall = false;
    }

    private addEventListener = (): void => {
        this.element.addEventListener('change', (): void => {
            this.onChange(this.isInitialCall);
        });
    }

    private onChange = (initialCall: boolean): void => {
        const formShippingAddressElement: HTMLElement = document.querySelector('.new-shipping-address-form');
        const addressSelectElement: HTMLSelectElement = (this.element as HTMLSelectElement);
        const addressSelectValue = addressSelectElement.value;
        const preSelectData: HTMLElement = document.querySelector('[data-pre-select-index]');
        preSelectData.dataset.preSelect = addressSelectElement.selectedIndex.toString()

        if (initialCall) {
            if (addressSelectValue == '0') {
                ShippingFormUtils.showForm(formShippingAddressElement, addressSelectElement)
            } else {
                ShippingFormUtils.showAndFillShippingAddressForm(addressSelectValue)
            }
            return;
        }

        if (addressSelectValue == '0') {
            ShippingFormUtils.clearForm(formShippingAddressElement);
            ShippingFormUtils.showForm(formShippingAddressElement, addressSelectElement)
        } else {
            ShippingFormUtils.showAndFillShippingAddressForm(addressSelectValue)
        }
    }
}

/**
 * general data switch subscriber
 */
class DataSwitchSubscriber extends ADataSwitchSubscriber {

    private formShippingAddressElement: HTMLElement;
    private addressSelectElement: HTMLSelectElement;

    public constructor(element: HTMLElement, options: IModuleOptions, addressSelectElement: HTMLSelectElement) {
        super();
        this.eventBus = options.eventBus;
        this.element = element;
        this.content = this.element.dataset.switchContent;
        this.listener = this.element.dataset.switchListen;
        this.formShippingAddressElement = document.querySelector('.new-shipping-address-form');
        this.addressSelectElement = addressSelectElement;

        this.addEventListener();
    }

    protected onInit = (options: IDataSwitchPublisherOptions): void => {
    }
    protected onChange = (options: IDataSwitchPublisherOptions): void => {
        const addressSelectValue = this.addressSelectElement.value;
        const preSelectData: HTMLElement = document.querySelector('[data-pre-select-index]');

        if (preSelectData.dataset.preSelectIndex != null) {
            FormUtils.setSelectedIndex(this.addressSelectElement, parseInt(preSelectData.dataset.preSelect))
        }
        if (addressSelectValue == '0') {
            ShippingFormUtils.showForm(this.formShippingAddressElement, this.addressSelectElement)
        } else {
            ShippingFormUtils.showAndFillShippingAddressForm(addressSelectValue)
        }
    }
}

class BillingDataSwitchSubscriber {

    private billingAddressSelector: HTMLSelectElement;
    private shippingAddressSelector: HTMLSelectElement;
    private customerAddress: Object;
    private readonly isInitialCall: boolean = true;

    public constructor(billingAddressSelector: HTMLSelectElement, shippingAddressSelector: HTMLSelectElement) {
        this.billingAddressSelector = billingAddressSelector;
        this.shippingAddressSelector = shippingAddressSelector;


        this.customerAddress = JSON.parse(document.querySelector('[data-country-address-map]').getAttribute('data-country-address-map'))
        this.addEventListener();
        this.onChange(this.isInitialCall);
        this.isInitialCall = false;
    }

    private addEventListener = (): void => {
        this.billingAddressSelector.addEventListener('change', (): void => {
            this.onChange(this.isInitialCall);
        });
    }

    private disableNotValidShippingCountry = () => {
        const validAddressList = this.customerAddress[this.billingAddressSelector.value] ?? []
        if (this.shippingAddressSelector.value != '0' && !validAddressList.includes(parseInt(this.shippingAddressSelector.value))) {
            FormUtils.setSelectedIndex(this.shippingAddressSelector, 0)
        }

        this.shippingAddressSelector.querySelectorAll('option')
            .forEach((element: HTMLOptionElement) => {
                if (validAddressList.includes(parseInt(element.value)) || element.value == '0') {
                    element.removeAttribute('disabled')
                } else {
                    element.disabled = true
                }
            });
    }

    private onChange = (initialCall: boolean): void => {
        if (initialCall) {
            this.disableNotValidShippingCountry()
            return
        }

        this.disableNotValidShippingCountry()

        if (this.shippingAddressSelector.value != '0') {
            ShippingFormUtils.hideForm()
        }
    }
}

export default class ShippingAddressSelectHandler {
    public static init = (eventBus: EventBus) => {
        ShippingAddressSelectHandler.eventBus = eventBus;
        eventBus.subscribe(EDataSwitchEvents.INIT, ShippingAddressSelectHandler.initFormContainer);
        eventBus.publish(EDataSwitchEvents.INIT, document.querySelector('body'));
    }

    private static eventBus: EventBus;

    private static initFormContainer = (container: HTMLElement): void => {
        const additionalOptions: IModuleOptions = {
            eventBus: ShippingAddressSelectHandler.eventBus,
        };

        const shippingAddressSelector: HTMLSelectElement = container.querySelector('[data-shipping-address]');
        if (shippingAddressSelector) {
            new DataSwitchShippingAddressPublisher(shippingAddressSelector, additionalOptions);

            container.querySelectorAll('[data-switch-listen]').forEach((dataSwitchElement: HTMLElement) => {
                new DataSwitchSubscriber(dataSwitchElement, additionalOptions, shippingAddressSelector);
            });

            const billingAddressSelector: HTMLSelectElement = container.querySelector('#addressesForm_billingAddress_iso2_code');
            new BillingDataSwitchSubscriber(billingAddressSelector, shippingAddressSelector);
        }
    }
}
