import { IViewModeSwitchPayload } from '../../shop-ui/components/search-page/actions/viewModeSwitch';
import {
    PARAM_PAGE,
    PARAM_PER_PAGE,
    PARAM_SORT,
    PARAM_VIEW_MODE,
    STATE_VIEW_MODE,
    VIEW_MODE_GRID,
    VIEW_MODE_LIST
} from '../../shop-ui/components/search-page/constants';
import { IApiQueryData, ISearchPageActionState } from '../../shop-ui/components/search-page/interfaces';
import { DEFAULT_ITEM_PER_PAGE_VALUE, DEFAULT_SORT_VALUE } from '../constants';

export const doSetViewMode = (state: ISearchPageActionState, payload: IViewModeSwitchPayload): ISearchPageActionState => {
    const newApiQueryParams: IApiQueryData = {
        ...state.apiQueryData,
        [PARAM_PAGE]: '1',
        [PARAM_VIEW_MODE]: payload[STATE_VIEW_MODE],
    };

    if (payload.viewMode === VIEW_MODE_GRID) {
        newApiQueryParams[PARAM_PER_PAGE] = DEFAULT_ITEM_PER_PAGE_VALUE;
        newApiQueryParams[PARAM_SORT] = DEFAULT_SORT_VALUE;
    }

    if (payload.viewMode === VIEW_MODE_LIST) {
        newApiQueryParams[PARAM_PER_PAGE] = null;
        newApiQueryParams[PARAM_SORT] = null;
    }

    return {
        ...state,
        [STATE_VIEW_MODE]: payload[STATE_VIEW_MODE],
        apiQueryData: newApiQueryParams,
        updatePageDebounceTime: 1,
    };
};
