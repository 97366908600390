import * as React from 'react';
import { ISliderHeaderProps } from '../../interfaces';
import SliderHeader from './SliderHeader';

interface ISliderContentProps extends ISliderHeaderProps {
    children?: React.ReactNode;
}

const SliderContentComponent: React.FunctionComponent<ISliderContentProps> = props => {
    const { allItemTitle, allItemLink, children, title } = props;
    return (
        <div className='article-slider-container'>
            <SliderHeader title={title} allItemTitle={allItemTitle} allItemLink={allItemLink}/>
            {children}
        </div>
    );
};

const SliderContent = React.memo(SliderContentComponent);

export default SliderContent;
