import { ISetPaginationPayload } from '../actions/pagination';
import { IApiQueryData, ISearchPageActionState } from '../interfaces';
import { PARAM_PAGE } from '../constants';

export const doSetPagination = (state: ISearchPageActionState, payload: ISetPaginationPayload): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        ...state.apiQueryData,
        [PARAM_PAGE]: payload[PARAM_PAGE],
    };

    return {
        ...state,
        apiQueryData: newApiQueryData,
    };
};
