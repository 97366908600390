const MinMediumPX = 640;
const MinLargePX = 1024;
const MinXLargePX = 1200;
const MinXXLargePX = 1440;

export const BREAKPOINTS = {
    // < 640
    smallOnly: (): boolean => window.matchMedia(`(max-width: ${(MinMediumPX - 1)}px)`).matches,

    // < 1024
    medium: (): boolean => window.matchMedia(`(max-width: ${(MinLargePX - 1)}px)`).matches,
    // >= 640
    minMedium: (): boolean => window.matchMedia(`(min-width: ${(MinMediumPX)}px)`).matches,
    // >= 640 && < 1024
    mediumOnly: (): boolean => window.matchMedia(`(min-width: ${MinMediumPX}px) and (max-width: ${(MinLargePX - 1)}px)`).matches,

    // >= 1024
    minLarge: (): boolean => window.matchMedia(`(min-width: ${(MinLargePX)}px)`).matches,
    // < 1200
    large: (): boolean => window.matchMedia(`(max-width: ${(MinXLargePX - 1)}px)`).matches,
    // >= 1024 && < 1200
    largeOnly: (): boolean => window.matchMedia(`(min-width: ${MinLargePX}px) and (max-width: ${(MinXLargePX - 1)}px)`).matches,

    // < 1440
    xlarge: (): boolean => window.matchMedia(`(max-width: ${(MinXXLargePX - 1)}px)`).matches,
    // >= 1200 && < 1440
    xlargeOnly: (): boolean => window.matchMedia(`(min-width: ${MinXLargePX}px) and (max-width: ${(MinXXLargePX - 1)}px)`).matches,

    // > 1440
    xxlargeOnly: (): boolean => window.matchMedia(`(min-width: ${MinXXLargePX}px)`).matches,
};
