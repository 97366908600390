import { ProductWishListInterface } from 'PyzShopUi/scripts/product-wish-list/includes/ProductWishListInterface';
import { EProductType } from 'PyzShopUi/scripts/shop-ui/constants';
import {
    IAddProductDTO,
    IProductWishListEventPublishAllProductPayload
} from 'PyzShopUi/scripts/product-wish-list/interfaces';

export class ConcreteProductWishList implements ProductWishListInterface {
    private readonly sku: string;

    constructor(sku: string) {
        this.sku = sku;
    }

    public isInWishList = (payload: IProductWishListEventPublishAllProductPayload) => payload
        .concreteProductWishLists?.findIndex(itemSku => itemSku === this.sku) !== -1;

    public createAddProductDTO = (): IAddProductDTO => ({
        sku: this.sku
    });

    public getProductType = (): EProductType => EProductType.PRODUCT_CONCRETE;
}
