import * as React from 'react';
import CheckBox from 'PyzShopUi/scripts/shop-ui/components/Checkbox';
import Marker from 'PyzShopUi/scripts/shop-ui/components/Marker';
import useToggleFilter from 'PyzShopUi/scripts/shop-ui/components/search-page/hooks/useToggleFilter';
import { IFilterProps } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { FILTER_LABEL_MAPPING, FilterNames } from 'PyzShopUi/scripts/catalog-page/constants';

const OutletFilter: React.FunctionComponent<IFilterProps> = props => {
    if (!props.options.length) {
        return null;
    }

    const [isSelected, onToggleFilter] = useToggleFilter(props.name, props.options, props.dispatch);
    const { translate } = useTranslationHook();

    return (
        <div onClick={() => onToggleFilter()} className={`toggle-filter`}>
            <CheckBox
                id={props.name}
                checked={isSelected}
            />
            <Marker
                label={translate(FILTER_LABEL_MAPPING[FilterNames.OUTLET], { ns: 'article-list-filter' }).toLocaleUpperCase()}
                color={`orange`}
                labelFor={props.name}
            />
        </div>
    );
};

export default OutletFilter;
