import { FILTER_LABEL_MAPPING, FilterNames } from '../../constants';
import { IFilterStateHandler, ISelectFilterOption, ISelectFilter } from '../../../shop-ui/components/search-page/interfaces';
import AbstractSelectFilterHandler from '../../../shop-ui/includes/filter-state/AbstractSelectFilterHandler';
import i18next from 'i18next';

export default class GenderFilterHandler extends AbstractSelectFilterHandler implements IFilterStateHandler {
    public createQueryParams = (filter: ISelectFilter, params: object): void => {
        params[FilterNames.GENDER] = filter.options
            .filter(option => option.selected)
            .map(selectedOption => selectedOption.value);
    };

    public resetQueryParams = (params: object): void => {
        params[FilterNames.GENDER] = [];
    };

    public getSelectedOptions = (filter: ISelectFilter): ISelectFilterOption[] => filter.options.filter(option => option.selected)
        .map(option => ({
            ...option,
            label: i18next.t(FILTER_LABEL_MAPPING[FilterNames.GENDER][option.value], { ns: 'article-list-filter'}),
        }));

    public updateSelectedOptions = (filter: ISelectFilter, selectedValues: string[]): void => {
        filter.options = filter.options.map((option: ISelectFilterOption) => {
            option.selected = selectedValues.includes(option.value);

            return option;
        });
    };
}
