import * as React from 'react';
import { ISelectFilterOption } from '../interfaces';
import { ISetListFilterOptionPayload, setListFilterOption } from '../actions/filter';
import SelectedFilterOptions from '../components/sidebar/filters/SelectedFilterOptions';

type TElementRender = () => null | React.ReactElement;

function useSingleSelectFilter(filterName: string, selectedOptions: ISelectFilterOption[], dispatch: React.Dispatch<any>): [(value: string, isSelected: boolean) => void, TElementRender] {
    const onToggleItem = (value: string, isSelected: boolean) => {
        const payload: ISetListFilterOptionPayload = {
            filterName,
            selectedValues: isSelected ? [] : [value],
        };

        dispatch(setListFilterOption(payload));
    };

    const displaySelectedFilterOptions = React.useCallback(() => {
        if (!selectedOptions.length) {
            return null;
        }

        return (
            <SelectedFilterOptions
                filterName={filterName}
                selectedOptionList={selectedOptions}
                dispatch={dispatch}
            />
        );
    }, [selectedOptions]);

    return [onToggleItem, displaySelectedFilterOptions];
}

export default useSingleSelectFilter;
