import { BREAKPOINTS } from '../config';
import ADataSwitchSubscriber from '../abstracts/ADataSwitchSubscriber';
import { EDataSwitchEvents } from './events/dataSwitchEvent';
import IModuleOptions from '../interfaces/IModuleOptions';
import IDataSwitchPublisherOptions from '../interfaces/IDataSwitchPublisherOptions';
import FormUtils from '../utils/formUtils';
import EventBus from '../utils/eventBus';
import IScrollingPublisherOptions from '../interfaces/IScrollingPublisherOptions';

/**
 * general data switch subscriber
 */
class DataSwitchSubscriber extends ADataSwitchSubscriber {

    private triggerElement: HTMLElement;

    public constructor(element: HTMLElement, options: IModuleOptions) {
        super();
        this.eventBus = options.eventBus;
        this.element = element;
        this.content = this.element.dataset.switchContent;
        this.listener = this.element.dataset.switchListen;

        this.addEventListener();
    }

    protected onInit = (options: IDataSwitchPublisherOptions): void => {
        this.changeVisibilityAndRequiredFields(options);
    }

    protected onChange = (options: IDataSwitchPublisherOptions): void => {
        this.changeVisibilityAndRequiredFields(options);

        if (BREAKPOINTS.smallOnly() && this.element.classList.contains('is-active')) {

            let eventOptions: IScrollingPublisherOptions;
            if (this.triggerElement.dataset.switchScrollToTarget === 'true') {
                eventOptions = {
                    target: this.element,
                };
            } else {
                eventOptions = {
                    target: this.triggerElement,
                };
            }
            this.eventBus.publish('scrolling:scrollTo', eventOptions);
        }
    }

    private changeVisibilityAndRequiredFields = (options: IDataSwitchPublisherOptions): void => {
        this.triggerElement = options.trigger;
        const elementIsVisible = this.changeVisibility(options.target);

        if (elementIsVisible) {
            FormUtils.requireFields(this.element);
            this.element.querySelectorAll('.mdc-radio').forEach((radioButton: HTMLElement): void => {
                if(radioButton.querySelector('.mdc-radio > input').hasAttribute('checked')){
                    FormUtils.checkRadioButton(radioButton.parentElement);
                }
            });
        } else {
            FormUtils.notRequireFields(this.element);
            this.element.querySelectorAll('.mdc-radio').forEach((radioButton: HTMLElement): void => {
                FormUtils.uncheckRadioButton(radioButton.parentElement, this.eventBus);
            });
        }

        this.eventBus.publish('radioButtonOption:resize');
    }

    private changeVisibility = (switchTargets: string = ''): boolean => {
        const isElementInTargets = (switchTargets.includes(this.content));
        const isInputChecked = (this.triggerElement.matches('input') && this.triggerElement.matches(':checked'));
        const isSelectField = (this.triggerElement.matches('select'));
        let dataSwitchTargets = '';

        if (this.triggerElement.matches('input')) {
            dataSwitchTargets = (this.triggerElement.getAttribute('data-switch-target')) ? this.triggerElement.dataset.switchTarget : '';
        } else if (this.triggerElement.matches('select')) {
            const checkedOption: HTMLOptionElement = this.triggerElement.querySelector('option:checked');
            dataSwitchTargets = checkedOption.dataset.switchTarget;
            dataSwitchTargets = (dataSwitchTargets !== undefined) ? dataSwitchTargets : ''; // no option selected in this select field
        }

        const targetMatches = (dataSwitchTargets.includes(this.content));
        let state = false;

        if (isInputChecked || isSelectField) {

            if (isElementInTargets && targetMatches) {
                state = true;
            }

            if (state) {
                this.element.classList.add('is-active');
            } else {
                this.element.classList.remove('is-active');
            }

            this.eventBus.publish('radioButtonOption:changeVisibility');
        }

        return state;
    }
}

/**
 * special behaviour for:
 * - checkout step 1
 * - module personal-information (client-data)
 * - country select field
 * - this element shows/hides and enables/disables options of the next module (delivery-address)
 */
class DataSwitchDeliveryOptionsSubscriber extends ADataSwitchSubscriber {

    private readonly check: string;
    private readonly disableContent: string;

    public constructor(element: HTMLElement, options: IModuleOptions) {
        super();
        this.eventBus = options.eventBus;
        this.element = element;
        this.content = this.element.dataset.switchContent;
        this.listener = this.element.dataset.switchListen;
        this.check = this.element.dataset.switchCheck || '';
        this.disableContent = this.element.dataset.disableContent || '';

        this.addEventListener();
    }

    protected onInit = (options: IDataSwitchPublisherOptions): void => {
        this.onChange(options);
    }

    protected onChange = (options: IDataSwitchPublisherOptions): void => {
        const formField: HTMLElement = this.element.querySelector('.mdc-form-field');
        const elementShouldBeVisible: boolean = (options.target.includes(this.content));

        if (elementShouldBeVisible) {
            this.enableRadioButtonWithCheckAttribute(options.trigger);
            this.changeVisibility(true);
        } else {
            if (this.isElementWithCheckAttributeChecked()) {
                FormUtils.disableRadioButton(formField, this.eventBus);
            } else {
                this.changeVisibility(false);
                FormUtils.uncheckRadioButton(formField, this.eventBus);
            }
        }
    }

    private isElementWithCheckAttributeChecked = (): boolean => {
        if (this.check !== '') {
            const elem = document.querySelector(`#${this.check}`);
            return elem && elem.matches(':checked');
        }
        return false;
    }

    private enableRadioButtonWithCheckAttribute = (trigger: HTMLElement): void => {
        if (this.check === '') {
            return;
        }

        const elem: HTMLElement = document.querySelector(`#${this.check}`);

        if (!elem.matches('radio')) {
            return;
        }

        const checkedOption: HTMLOptionElement = trigger.querySelector('option:checked');
        const triggerDisable: string = (trigger.matches('select') ? checkedOption.dataset.disable : trigger.dataset.disable);

        if (triggerDisable && triggerDisable.includes(this.disableContent)) {
            return;
        }

        FormUtils.enableRadioButton(elem.closest('.mdc-form-field'));
    }

    private changeVisibility = (shouldBeVisible: boolean): void => {
        if (shouldBeVisible) {
            this.element.classList.add('is-active');
        } else {
            this.element.classList.remove('is-active');
        }
    }
}

class DataSwitchPublisher {

    private element: HTMLElement;
    private eventBus: EventBus;
    private readonly targetElementSelectors: string[] = [];
    private readonly isInitialCall: boolean = true;

    public constructor(element: HTMLElement, options: IModuleOptions) {
        this.eventBus = options.eventBus;
        this.element = element;
        this.targetElementSelectors = this.element.dataset.switch.split(',');

        this.addEventListener();
        this.onChange(this.isInitialCall);
        this.isInitialCall = false;
    }

    private addEventListener = (): void => {
        this.element.addEventListener('change', (): void => {
            this.onChange(this.isInitialCall);
        });
    }

    private onChange = (initialCall: boolean): void => {
        if (initialCall
            && this.element.matches('[type="radio"]')
            && !this.element.matches(':checked')) {
            return;
        }

        const selectedOption = this.getSelection();
        const targets = (selectedOption.dataset.switchTarget) ? selectedOption.dataset.switchTarget : [];
        const eventOptions = {
            trigger: this.element,
            value: (this.element as HTMLInputElement).value,
            target: targets,
        };

        this.targetElementSelectors.forEach((eventName: string): void => {
            if (this.isInitialCall) {
                this.eventBus.publish('contentSwitch:init:' + eventName, eventOptions);
            } else {
                this.eventBus.publish('contentSwitch:change:' + eventName, eventOptions);
            }
        });
    }

    private getSelection = (): HTMLElement => (this.element.matches('select')) ? this.element.querySelector('option:checked') : this.element;
}

export default class DataSwitchHandler {

    public static init = (eventBus: EventBus) => {
        DataSwitchHandler.eventBus = eventBus;
        eventBus.subscribe(EDataSwitchEvents.INIT, DataSwitchHandler.initSwitchContainer);
        eventBus.publish(EDataSwitchEvents.INIT, document.querySelector('body'));
    }

    private static eventBus: EventBus;

    private static initSwitchContainer = (container: HTMLElement): void => {
        const additionalOptions: IModuleOptions = {
            eventBus: DataSwitchHandler.eventBus,
        };

        container.querySelectorAll('[data-switch-listen]').forEach((dataSwitchElement: HTMLElement) => {
            if (dataSwitchElement.classList.contains('delivery-option')) {
                new DataSwitchDeliveryOptionsSubscriber(dataSwitchElement, additionalOptions);
            } else {
                new DataSwitchSubscriber(dataSwitchElement, additionalOptions);
            }
        });

        container.querySelectorAll('[data-switch]').forEach((dataSwitchElement: HTMLElement) => {
            new DataSwitchPublisher(dataSwitchElement, additionalOptions);
        });
    }
}
