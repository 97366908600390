import EventBus from '../utils/eventBus';
import { EAudioEvents } from './events/audioEvents';

class Audio {

    private eventBus: EventBus;
    private readonly audioElement: HTMLAudioElement;

    constructor(audioElement: HTMLAudioElement, eventBus: EventBus) {
        this.audioElement = audioElement;
        this.eventBus = eventBus;
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.eventBus.subscribe(EAudioEvents.PRELOAD, this.preloadMetaInContainer);
        this.eventBus.subscribe(EAudioEvents.PLAY_STARTED, this.pauseOtherAudios);
        this.eventBus.subscribe(EAudioEvents.PAUSE, this.pauseAudioInContainer);

        this.audioElement.addEventListener('play', (_: Event): void => {
            this.eventBus.publish(EAudioEvents.PLAY_STARTED, this.audioElement);
        });

        this.audioElement.addEventListener('loadedmetadata', (_: Event): void => {
            this.eventBus.publish(EAudioEvents.META_LOADED, this.audioElement);
        });
    }

    private preloadMetaInContainer = (containerHtmlElement: HTMLElement): void => {
        if (this.audioElement.readyState === this.audioElement.HAVE_NOTHING && containerHtmlElement.contains(this.audioElement)) {
            this.audioElement.setAttribute('preload', 'metadata');
            this.audioElement.load();
        }
    }

    private pauseAudioInContainer = (containerHtmlElement: HTMLElement): void => {
        if (containerHtmlElement.contains(this.audioElement)) {
            this.audioElement.pause();
        }
    }

    private pauseOtherAudios = (startedAudioElement: HTMLAudioElement): void => {
        if (startedAudioElement !== this.audioElement) {
            this.audioElement.pause();
        }
    }
}

export default class AudioHandler {
    public static init = (eventBus: EventBus): void => {
        AudioHandler.globalEventBus = eventBus;

        AudioHandler.globalEventBus.subscribe(EAudioEvents.INIT, AudioHandler.initAudio);

        document.querySelectorAll('audio').forEach((audioElement: HTMLAudioElement): void => {
            AudioHandler.globalEventBus.publish(EAudioEvents.INIT, audioElement);
        });
    }

    private static globalEventBus: EventBus;

    private static initAudio = (audioElement: HTMLAudioElement): void => {
        new Audio(audioElement, AudioHandler.globalEventBus);
    }
}
