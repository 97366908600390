import { EVideoEvents } from '../events/videoEvents';
import EventBus from '../../utils/eventBus';

declare var window: any;
declare var YT: any;

class YoutubeIframeApiIntegration {

    private youtubeWrapperElement: HTMLElement;

    private videoPlayers: any[];

    constructor(youtubeWrapperElement: HTMLElement, eventBus: EventBus) {
        this.youtubeWrapperElement = youtubeWrapperElement;
        this.videoPlayers = [];

        eventBus.subscribe(EVideoEvents.PRELOAD, this.loadYoutubeIframes);
        eventBus.subscribe(EVideoEvents.PAUSE, this.pauseVideosInContainer);
    }

    private loadYoutubeIframes = (): void => {
        this.loadIframeApi();
        window.onYouTubeIframeAPIReady = this.createYoutubePlayers;
    }

    private loadIframeApi = (): void => {
        const scriptElement: HTMLScriptElement = document.createElement('script');
        scriptElement.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTagOnPage: HTMLScriptElement = document.getElementsByTagName('script')[0];
        firstScriptTagOnPage.parentNode.insertBefore(scriptElement, firstScriptTagOnPage);
    }

    private createYoutubePlayers = (): void => {
        const iframePlaceholders: NodeListOf<HTMLElement> = this.youtubeWrapperElement.querySelectorAll('.iframe-placeholder');

        if (!iframePlaceholders) {
            return;
        }
        iframePlaceholders.forEach((placeholder: HTMLElement): void => {
            const videoId: string = placeholder.dataset.videoId;

            if (!videoId) {
                return;
            }

            const elementId: string = placeholder.id;

            if (!elementId) {
                return;
            }

            this.videoPlayers.push(new YT.Player(elementId, {
                videoId,
            }));
        });
    }

    private pauseVideosInContainer = (containerHtmlElement: HTMLElement): void => {
        this.videoPlayers.forEach((videoPlayer: any): void => {
            if (containerHtmlElement.contains(videoPlayer.getIframe())) {
                videoPlayer.pauseVideo();
            }
        })
    }
}

export default class YoutubeIframeApiIntegrationHandler {
    public static init = (eventBus: EventBus): void => {
        document.querySelectorAll('.youtube-iframe-wrapper').forEach((youtubeWrapperElement: HTMLElement): void => {
            new YoutubeIframeApiIntegration(youtubeWrapperElement, eventBus);
        });
    }
}
