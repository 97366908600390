import * as React from 'react';
import classNames from 'classnames';
import CategoryNavigationSubItem from './CategoryNavigationSubItem';
import useGradient from '../../../hooks/useGradient';
import { ICategoryTreeItem } from '../../../interfaces';
import { MAX_CATEGORY_ITEM_FOR_ENABLE_SCROLL } from '../../../constants';

interface ICategoryNavigationItemProp {
    item: ICategoryTreeItem;

    onSelect(item: ICategoryTreeItem): void;
}

const CategoryNavigationItem: React.FunctionComponent<ICategoryNavigationItemProp> = props => {
    const { item, onSelect } = props;

    const [isScrollable, onGradientChange, displayTopGradient, displayBottomGradient] = useGradient(item.children, MAX_CATEGORY_ITEM_FOR_ENABLE_SCROLL);

    const displaySubItems = React.useCallback(() => {
        if (!item.children || !item.children.length) {
            return null;
        }

        const subItems = item.children.map(
            subItem => <CategoryNavigationSubItem onSelect={onSelect} key={subItem.id} item={subItem}/>,
        );

        const classes = classNames(
            `navigation-section__navigation-content-children`,
            { scrollable: isScrollable },
        );

        return (
            <ul className={classes} onScroll={onGradientChange} {...(isScrollable ? { 'tabIndex': 0 } : {})}>
                {subItems}
            </ul>
        );
    }, [item.children, isScrollable]);

    const displayItemTitle = () => {
        if (item.id <= 1) {
            return null;
        }

        return (
            <div className={`navigation-section__navigation-content-current`}>
                {item.name}
            </div>
        )
    }

    return (
        <div className={`navigation-section__navigation-content`}>
            {displayItemTitle()}

            <div className='scroll-selection'>
                {displaySubItems()}
                {displayTopGradient()}
                {displayBottomGradient()}
            </div>
        </div>
    );
};

export default CategoryNavigationItem;
