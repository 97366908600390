import { ISetPerPagePayload } from '../actions/perPage';
import { PARAM_PAGE, PARAM_PER_PAGE } from '../constants';
import { IApiQueryData, ISearchPageActionState } from '../interfaces';

export const doSetPerPage = (state: ISearchPageActionState, payload: ISetPerPagePayload): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        ...state.apiQueryData,
        [PARAM_PER_PAGE]: payload[PARAM_PER_PAGE],
        [PARAM_PAGE]: '1',
    };

    return {
        ...state,
        apiQueryData: newApiQueryData,
    };
};
