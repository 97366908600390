import * as React from 'react';
import { ISetRangeFilterOptionPayload, setRangeFilterOption } from '../../actions/filter';
import { IRangeFilter } from '../../interfaces';
import TagItem from '../../../TagItem';

interface ISelectedRangeFilterLabel {
    filter: any;
    formatterFunction?: any;
    dispatch: React.Dispatch<any>;
}

const SelectedRangeFilterLabel: React.FunctionComponent<ISelectedRangeFilterLabel> = props => {
    const filter: IRangeFilter = props.filter;
    const formatterFunction: any = props.formatterFunction;
    const dispatch = props.dispatch;

    const deselectRangeFilterSelectedLabel = (filterName: string) => {
        const payload: ISetRangeFilterOptionPayload = {
            filterName,
            activeMin: null,
            activeMax: null
        }

        dispatch(setRangeFilterOption(payload));
    }

    const minPriceLabel = formatterFunction ? formatterFunction(filter.activeMin) : filter.activeMin;
    const maxPriceLabel = formatterFunction ? formatterFunction(filter.activeMax) : filter.activeMax;

    return <TagItem
        key={filter.name}
        label={minPriceLabel + ' - ' + maxPriceLabel}
        onClick={() => deselectRangeFilterSelectedLabel(filter.name)}
    />;
};

export default SelectedRangeFilterLabel;
