import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import NativeSelect from '../../../select/NativeSelect';
import useSortSelect from '../../hooks/useSortSelect';

interface IHeaderSortProps {
    label: string;
    sortParamNames: string[];
    currentSortParam: string;

    dispatch: React.Dispatch<any>;
}

const HeaderSortComponent: React.FunctionComponent<IHeaderSortProps> = props => {
    const { label, sortParamNames, currentSortParam, dispatch } = props;

    const [sortSelectOptions, onSortSelect] = useSortSelect(sortParamNames, dispatch);

    return (
        <NativeSelect
            label={label}
            selectedValue={currentSortParam}
            options={sortSelectOptions}
            onChange={onSortSelect}
        />
    );
};

const HeaderSort = withMemo(HeaderSortComponent);

export default HeaderSort;
