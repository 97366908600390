/**
 * this class extends to type definition of materials MCDSelect
 *
 * this needs to currently be done (2019-04-12) because the
 * - (currently) used version of the types-definitions (reference 1)
 * - and (currently) available new versions (reference 2)
 * is not correct, due to the documentation of Material.io (reference 3)
 *
 * reference 1: https://www.npmjs.com/package/@types/material-components-web/v/0.35.0
 * reference 2: https://www.npmjs.com/package/@types/material-components-web/v/0.43.1
 * reference 3: https://github.com/material-components/material-components-web/tree/v0.42.1/packages/mdc-select#mdcselect-api
 *
 */
import { MDCSelect as OriginalMDCSelect } from '@material/select';

export class MDCSelect extends OriginalMDCSelect {
    public valid: boolean;
}
