import FilterStateService from '../services/FilterStateService';
import { IApiQueryData, ISearchPageActionState } from '../interfaces';
import {
    ISetCategoryFilterOptionPayload,
    ISetListFilterOptionPayload,
    ISetRangeFilterOptionPayload,
    ISetToggleFilterOptionPayload
} from '../actions/filter';
import {
    FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    PARAM_CATEGORY_KEY,
    PARAM_PAGE,
    PARAM_PER_PAGE,
    PARAM_SEARCH,
    PARAM_SORT,
    PARAM_FILTER_TOKEN,
    PARAM_VIEW_MODE
} from '../constants';
import { Filter } from '../types';

const doSetFilterState = (
    state: ISearchPageActionState,
    filterName: string,
    selectedValues: string[] | number[],
    filterStateService: FilterStateService
): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        ...state.apiQueryData,
        [PARAM_PAGE]: '1',
    };

    const newFilters: Filter[] = state.filters.map(filter => {
        if (filter.name !== filterName) {
            return filter;
        }

        filterStateService.updateSelectedOptions(filter, selectedValues);
        filterStateService.createQueryParams(filter, newApiQueryData);

        return filter;
    });

    newApiQueryData[PARAM_FILTER_TOKEN] = (new Date()).getTime();

    return {
        ...state,
        apiQueryData: newApiQueryData,
        filters: newFilters,
        updatePageDebounceTime: FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    };
};

export const doSetListFilterOption = (
    state: ISearchPageActionState,
    payload: ISetListFilterOptionPayload,
    filterStateService: FilterStateService
): ISearchPageActionState => (
    doSetFilterState(state, payload.filterName, payload.selectedValues, filterStateService)
);

export const doSetToggleFilterOption = (
    state: ISearchPageActionState,
    payload: ISetToggleFilterOptionPayload,
    filterStateService: FilterStateService
): ISearchPageActionState => (
    doSetFilterState(state, payload.filterName, [payload.selectedValue], filterStateService)
);

export const doSetRangeFilterOption = (
    state: ISearchPageActionState,
    payload: ISetRangeFilterOptionPayload,
    filterStateService: FilterStateService
): ISearchPageActionState => (
    doSetFilterState(state, payload.filterName, [payload.activeMin, payload.activeMax], filterStateService)
);

export const doSetCategoryFilterOption = (
    state: ISearchPageActionState,
    payload: ISetCategoryFilterOptionPayload,
    filterStateService: FilterStateService
): ISearchPageActionState => (
    doSetFilterState(state, payload.filterName, [payload.selectedValue], filterStateService)
);

export const doResetAllFilter = (
    state: ISearchPageActionState,
    filterStateService: FilterStateService
): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        q: state.apiQueryData.q,
        [PARAM_PER_PAGE]: state.apiQueryData[PARAM_PER_PAGE],
        [PARAM_PAGE]: '1',
        [PARAM_SORT]: state.apiQueryData.sort,
        [PARAM_SEARCH]: state.apiQueryData[PARAM_SEARCH],
        [PARAM_VIEW_MODE]: state.apiQueryData[PARAM_VIEW_MODE],
        [PARAM_CATEGORY_KEY]: state.apiQueryData[PARAM_CATEGORY_KEY],
    };

    state.filters.forEach(filter => filterStateService.resetQueryParams(filter.name, newApiQueryData));

    if (state.categoriesTree && state.categoriesTree.length) {
        newApiQueryData[PARAM_CATEGORY_KEY] = state.apiQueryData[PARAM_CATEGORY_KEY];
    }

    newApiQueryData[PARAM_FILTER_TOKEN] = (new Date()).getTime();

    return {
        ...state,
        apiQueryData: newApiQueryData,
    };
};
