import EventBus from '../utils/eventBus';
import Utils from '../utils/utils';
import IAccordionOptions from '../interfaces/IAccordionOptions';
import AToggleContainer from '../abstracts/AToggleContainer';
import IScrollingPublisherOptions from '../interfaces/IScrollingPublisherOptions';
import IEventBusSubscription from '../interfaces/Utils/IEventBusSubscription';
import { EAccordionEvent } from './events/accordion.event';

export class Accordion extends AToggleContainer {

    private static DEFAULTS = {
        ACTIVE: 'is-active',
        HEADER_ICON: '[data-toggleElement="icon"]',
        SLIDE_DOWN: 'accordion-content-slide-down',
        SLIDE_UP: 'accordion-content-slide-up',
    };

    private eventBus: EventBus;
    public accordionHeader: HTMLElement;
    private accordionContent: HTMLElement;
    private accordionHeaderIcon: HTMLElement;
    private anchor: string;
    private allowScrollUp: boolean;
    private accordionContentHeight: string;

    private subscriptions: IEventBusSubscription[] = [];

    public constructor(options: IAccordionOptions) {
        super();
        this.eventBus = options.eventBus;
        this.allowScrollUp = options.allowScrollUp;
        this.accordionHeader = options.header;
        this.accordionContent = options.content;
        this.accordionHeaderIcon = this.accordionHeader.querySelector(Accordion.DEFAULTS.HEADER_ICON);
        this.anchor = this.accordionHeader.dataset.anchor;
        this.accordionContentHeight = 'auto';
    }

    public activate = (): void => {
        this.addEventListener();
        this.checkInitialState();
    }

    public deactivate = (): void => {
        this.removeEventListener();
        this.onClose(true);
    }

    private toggle = (state?: boolean): void => {
        Utils.fixSelectForSizeLarge(this.accordionContent);

        if (state === true) {
            this.eventBus.publish(EAccordionEvent.OPEN, this.accordionHeader);
            return;
        }

        if (state === false) {
            this.eventBus.publish(EAccordionEvent.CLOSE, this.accordionHeader);
            return;
        }

        if (this.accordionHeaderIcon.classList.contains(Accordion.DEFAULTS.ACTIVE)) {
            this.eventBus.publish(EAccordionEvent.CLOSE, this.accordionHeader);
        } else {
            this.eventBus.publish(EAccordionEvent.OPEN, this.accordionHeader);
        }
    }

    private toggleProxy = (): void => {
        this.toggle();
    }

    private checkInitialState = (): void => {
        const urlAnchor = Utils.getURLAnchor();

        if (this.accordionHeader.dataset.anchor === urlAnchor) {
            this.toggle(true);
        }

        this.accordionContent.querySelectorAll('ul[class="error"], .csrf--invalid').forEach((element: HTMLElement): void => {
            this.toggle(true);
            element.focus();
        });

        this.onSelectionChange();
    }

    private setAccordionContentHeight = (): void => {
        const accordionContentHeight = Math.round(this.accordionContent.getBoundingClientRect().height);
        this.accordionContentHeight = `${accordionContentHeight}px`;
    }

    private onClose = (withoutAnimation: boolean = false): void => {
        this.accordionHeaderIcon.classList.remove(Accordion.DEFAULTS.ACTIVE);

        this.setAccordionContentHeight();

        if (withoutAnimation) {
            this.accordionContent.style.display = '';
            return;
        }

        this.accordionContent.classList.add(Accordion.DEFAULTS.SLIDE_UP);

        this.accordionContent.addEventListener('animationend', () => {
            this.accordionContent.style.display = '';
            this.accordionContent.classList.remove(Accordion.DEFAULTS.SLIDE_UP);
        }, {once: true});
    }

    private onOpen = (): void => {
        document.documentElement.style.setProperty('--accordion-content-height', this.accordionContentHeight);

        this.accordionHeaderIcon.classList.add(Accordion.DEFAULTS.ACTIVE);
        this.accordionContent.style.display = 'block';
        this.accordionContent.classList.add(Accordion.DEFAULTS.SLIDE_DOWN);

        this.accordionContent.addEventListener('animationend', () => {
            this.accordionContent.classList.remove(Accordion.DEFAULTS.SLIDE_DOWN);

            if (this.allowScrollUp) {
                const eventOptions: IScrollingPublisherOptions = {
                    target: this.accordionHeader,
                };

                this.eventBus.publish('scrolling:scrollTo', eventOptions);
            }

            this.eventBus.publish(EAccordionEvent.ACTIVATED, this.accordionHeader);

            const checked: HTMLElement = this.accordionContent.querySelector('.radioButtonBoxElement:checked');

            if (!checked) {
                return;
            }

            const target = checked.dataset.switchTarget;

            if (!target) {
                return;
            }

            this.eventBus.publish('radioButtonOption:resize');
        });
    }

    private onSelectionChange = (): void => {
        let label: string = '';
        const input: HTMLInputElement = this.accordionContent.querySelector(`input[data-switch*="${this.anchor}"]:checked`);

        if (input) {
            const selectedElementId = input.getAttribute('id');
            const labelElement: HTMLElement = document.querySelector(`label[for="${selectedElementId}"]`);

            if (labelElement) {
                label = labelElement.textContent;
            }
        }

        this.setSelectedValueInHeader([label], this.accordionHeader, 60);
    }

    private addEventListener = (): void => {
        this.accordionHeader.addEventListener('click', this.toggleProxy);

        //Accessibility event listener for ENTER key when focused
        this.accordionHeader.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                this.toggle();
            }
        });

        const openSubscription = this.eventBus.subscribe(EAccordionEvent.OPEN, (target: HTMLElement): void => {
            if (this.accordionHeader === target) {
                this.onOpen();
            }
        });
        this.subscriptions.push(openSubscription);

        const closeSubscription = this.eventBus.subscribe(EAccordionEvent.CLOSE, (target: HTMLElement): void  => {
            if (this.accordionHeader === target) {
                this.onClose();
            }
        });
        this.subscriptions.push(closeSubscription);

        const switchSubscription = this.eventBus.subscribe('contentSwitch:change:' + this.anchor, this.onSelectionChange);
        this.subscriptions.push(switchSubscription);

        const resetFilterOptionSubscription = this.eventBus.subscribe('filter:resetFilterOption', (): void => {
            this.onClose();
            this.accordionHeaderIcon.classList.remove(Accordion.DEFAULTS.ACTIVE);
        });
        this.subscriptions.push(resetFilterOptionSubscription);
    }

    private removeEventListener = (): void => {
        this.accordionHeader.removeEventListener('click', this.toggleProxy);

        this.subscriptions.forEach((subscription: IEventBusSubscription) => {
            subscription.unsubscribe();
        });

        this.subscriptions = [];
    }
}

export default class AccordionHandler {

    public static initAccordion = (eventBus: EventBus, allowScrollUp: boolean = true): void => {
        document.querySelectorAll('.accordion').forEach((accordion: HTMLElement): void => {
            const header: HTMLElement = accordion.querySelector('.accordion-header');
            if (header) {
                const content: HTMLElement = header.nextElementSibling as HTMLElement;

                if (content.classList.contains('accordion-content')) {
                    AccordionHandler.initSingle(header, content, eventBus, allowScrollUp).activate();
                }
            }
        });

        eventBus.subscribe(EAccordionEvent.INIT, (payload: { element: HTMLElement }) => {
            const header: HTMLElement = payload.element.querySelector('.accordion-header');
            const content: HTMLElement = header.nextElementSibling as HTMLElement;

            if (content.classList.contains('accordion-content')) {
                AccordionHandler.initSingle(header, content, eventBus, allowScrollUp).activate();
                eventBus.publish(EAccordionEvent.CLOSE, header);
            }
        });
    }

    public static initSingle = (header: HTMLElement, content: HTMLElement, eventBus: EventBus, allowScrollUp: boolean = true): Accordion => {
        const accordionOptions: IAccordionOptions = {
            eventBus,
            allowScrollUp,
            header,
            content,
        };
        const acc = new Accordion(accordionOptions);
        AccordionHandler.accordions.push(acc);

        return acc;
    }

    private static accordions = [];
}
