import * as React from 'react';
import { ISliderHeaderProps } from '../../interfaces';

const SliderHeaderComponent: React.FunctionComponent<ISliderHeaderProps> = props => {
    const { title, allItemTitle, allItemLink } = props;
    const shouldDisplay = !(!title && !allItemTitle);

    if (false === shouldDisplay) {
        return null;
    }

    const displayTitle = () => {
        if (!title) {
            return null;
        }

        return <h2 className="heading3">{title}</h2>;
    };

    const displayLink = () => {
        if (!allItemLink) {
            return null;
        }

        const link = new URL(allItemLink, window.location.origin);

        return <a className='read-more-link' href={link.toString()}>{allItemTitle}</a>;
    };

    return (
        <div className='article-slider-header'>
            {displayTitle()}
            {displayLink()}
        </div>
    );
};

const SliderHeader = React.memo(SliderHeaderComponent);

export default SliderHeader;
