import * as React from 'react';
import { Subject, timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import usePrevious from '../../../hooks/usePrevious';
import isEqual from 'react-fast-compare';
import FilterStateService from '../services/FilterStateService';
import { IApiQueryData } from '../interfaces';
import { updateSearchPageData, updateSkipUpdateFromApiQueryParams } from '../actions/searchPage';
import { DEFAULT_CONTEXT_NAME } from '../constants';

interface IUpdateObject {
    dispatch: React.Dispatch<any>;
    apiQueryData: IApiQueryData;
    debounceTime: number;
}

function useApiQueryDataUpdate(
    apiQueryData: IApiQueryData,
    dispatch: React.Dispatch<any>,
    updateDebounceTime: number,
    filterStateService: FilterStateService,
    skipUpdateBaseOnApiQueryParams: boolean = false,
    excludeQueryParamsFromState: string[] = [],
    contextName: string = DEFAULT_CONTEXT_NAME
) {
    const updateSearchPageDataObserver = React.useRef(new Subject<IUpdateObject>());
    const previousApiQueryData = usePrevious(apiQueryData);

    React.useEffect(() => {
        const subscription = updateSearchPageDataObserver.current
            .pipe(debounce(object => timer(object.debounceTime)))
            .subscribe(object => {
                object.dispatch(
                    updateSearchPageData(
                        object.apiQueryData,
                        filterStateService,
                        false,
                        excludeQueryParamsFromState,
                        new Map(),
                        contextName
                    )
                );
            });

        return () => {
            subscription.unsubscribe();
        }
    }, []);

    React.useEffect(() => {
        if (skipUpdateBaseOnApiQueryParams) {
            dispatch(updateSkipUpdateFromApiQueryParams(false));

            return;
        }

        if (!isAllowToUpdate(apiQueryData, previousApiQueryData)) {
            return;
        }

        updateSearchPageDataObserver.current.next({
            dispatch,
            apiQueryData,
            debounceTime: updateDebounceTime,
        });
    }, [apiQueryData, skipUpdateBaseOnApiQueryParams]);
}

const isAllowToUpdate = (apiQueryData: IApiQueryData, previousApiQueryData: IApiQueryData): boolean => (
    previousApiQueryData !== undefined &&
    Object.keys(apiQueryData).length &&
    !isEqual(copyAndIgnoringEmptyKeys(previousApiQueryData), copyAndIgnoringEmptyKeys(apiQueryData)) &&
    +apiQueryData.page !== 0 //Prevent api call when empty list is returned
)

function copyAndIgnoringEmptyKeys(input: object): object {
    const output = {};

    if (!input) {
        return output;
    }

    Object.keys(input).forEach(key => {
        if (input[key] === undefined
            || input[key] === null
            || (typeof input[key] === 'object' && Object.keys(input[key]).length === 0)
        ) {
            return;
        }

        output[key] = input[key];
    });

    return output;
}

export default useApiQueryDataUpdate;
