import { createActionCreator, ThunkActionCreator } from '../../../includes/enhanced-reducer';
import { categoriesTreeService } from '../services/CategoriesTreeService';
import { ICategoryTreeItem, ISearchPageActionState } from '../interfaces';

const STORE_CATEGORIES_TREE = 'STORE_CATEGORIES_TREE';
const UPDATE_NAVIGATION_ACTIVE_CATEGORY_KEY = 'UPDATE_NAVIGATION_ACTIVE_CATEGORY_KEY';
const UPDATE_ACTIVE_CATEGORY = 'UPDATE_ACTIVE_CATEGORY';

export const storeCategoriesTree = createActionCreator<ICategoryTreeItem[]>(STORE_CATEGORIES_TREE);
export const updateNavigationActiveCategoryKey = createActionCreator<string>(UPDATE_NAVIGATION_ACTIVE_CATEGORY_KEY);
export const updateActiveCategory = createActionCreator<ICategoryTreeItem>(UPDATE_ACTIVE_CATEGORY);

export const loadCategoriesTree: ThunkActionCreator<ISearchPageActionState> = () =>
    dispatch => categoriesTreeService.getData().then(data => dispatch(storeCategoriesTree(data)));
