import * as React from 'react';
import NativePerPageSelect from '../sidebar/NativePerPageSelect';
import FooterPagination from './FooterPagination';

interface IFooterProps {
    itemPerPageOptions: number[];
    currentItemsPerPage: string;
    perPageSelectText?: string;
    currentPage: number;
    maxPage: number;
    renderPerPageSelection: boolean;

    customPagination?(newPage: string): void;
    dispatch: React.Dispatch<any>;
}

const Footer: React.FunctionComponent<IFooterProps> = props => {
    const { itemPerPageOptions, currentItemsPerPage, perPageSelectText, currentPage, maxPage, renderPerPageSelection, customPagination, dispatch } = props;

    const displayPerPageSelect = () => {
        if (renderPerPageSelection === false) {
            return null;
        }

        if (itemPerPageOptions.length <= 1) {
            return null;
        }

        return (
            <NativePerPageSelect
                label={perPageSelectText}
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                dispatch={dispatch}
                shouldScrollToTop={true}
            />
        );
    }

    const displayPagination = () => {
        if (maxPage <= 1) {
            return null;
        }

        return (
            <div className="result-footer__selector__pagination">
                <FooterPagination
                    currentPage={currentPage}
                    maxPage={maxPage}
                    dispatch={dispatch}
                    customPagination={customPagination}
                />
            </div>
        );
    }

    const displayThinLine = () => {
        if (renderPerPageSelection === false && maxPage <= 1) {
            return null;
        }

        return (
            <div className="thin-line" />
        );
    }

    return (
        <React.Fragment>
            {displayThinLine()}

            <div className="result-footer__selector">
                <div className="result-footer__selector__item-per-page hide-for-small-only">
                    {displayPerPageSelect()}
                </div>
                {displayPagination()}
            </div>
        </React.Fragment>
    );
};

export default Footer;
