import * as React from 'react';
import MyBikeFilterOptions from './MyBikeFilterOptions';
import useMultipleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useMultipleSelectFilter';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import Accordion from '../../../../../shop-ui/components/Accordion';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const MyBikeFilter: React.FunctionComponent<IFilterProps> = props => {
    const selectedOptions = props.options.filter(option => option.selected);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [onToggleItem, displaySelectedFilterOptions, onToggleItems] = useMultipleSelectFilter(props.name, selectedOptions, props.dispatch);
    const { translate } = useTranslationHook();

    return (
        <div className='filter-section mybike-filter'>
            <Accordion
                label={translate('Mein Bike', { ns: 'article-list-filter'})}
                defaultCollapsed={true}
                isScrollToCurrentOnCollapsed={true}
            >
                <div className='scroll-selection'>
                    <MyBikeFilterOptions
                        key={props.name}
                        optionList={props.options}
                        onToggleItem={onToggleItem}
                        onToggleItems={onToggleItems}
                    />
                </div>
            </Accordion>
        </div>
    );
};

export default MyBikeFilter;
