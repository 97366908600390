import EventBus from '../utils/eventBus';
import IModuleOptions from '../interfaces/IModuleOptions';
import IScrollingPublisherOptions from '../interfaces/IScrollingPublisherOptions';
import Utils from '../utils/utils';

// Delete this code with FEM-565.
declare var shared;

class AnchorScrolling {

    private static SELECTORS = {
        ANCHORLINKS: 'a[href^=\\#]',
    };

    private anchorLinks: NodeList;
    private eventBus: EventBus;

    constructor(options: IModuleOptions) {
        this.anchorLinks = document.querySelectorAll(AnchorScrolling.SELECTORS.ANCHORLINKS);
        this.eventBus = options.eventBus;
        this.addEventListener();
        this.scrollToPresentHash();
    }

    private scrollToAnchor = (href: string): void => {
        if (href.length <= 1) {
            return;
        }
        let scrollToTarget: HTMLElement = null;

        if (href === '#top') {
            scrollToTarget = document.body;
        } else {
            try {
                scrollToTarget = document.querySelector(href);
            } catch (e) {
                // catch error when href is no valid selector
            }
        }

        if (!scrollToTarget) {
            return;
        }

        const eventOptions: IScrollingPublisherOptions = {
            target: scrollToTarget,
        };

        this.eventBus.publish('scrolling:scrollTo', eventOptions);
    }

    private addEventListener = (): void => {
        this.anchorLinks.forEach((item: HTMLElement) => {
            item.addEventListener('click', this.scrollToAnchor.bind(this, item.getAttribute('href')));
        });
    }

    private scrollToPresentHash = (): void => {
        const anchor: string = Utils.getURLAnchor();
        if (anchor) {
            this.scrollToAnchor('#' + anchor);
        }
    }
}

export default class AnchorScrollingHandler {

    public static init = (eventBus: EventBus): void => {
        const additionalOptions: IModuleOptions = {
            eventBus,
        };

        new AnchorScrolling(additionalOptions);
    }
}
