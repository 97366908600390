import * as React from 'react';
import RangeSlider from 'PyzShopUi/scripts/shop-ui/components/RangeSlider';
import Accordion from 'PyzShopUi/scripts/shop-ui/components/Accordion';
import { Subject } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import TagItem from 'PyzShopUi/scripts/shop-ui/components/TagItem';
import { currencyFormat, getCurrencySymbol } from 'PyzShopUi/scripts/react/common/lib';
import CurrencyInput from 'PyzShopUi/scripts/shop-ui/components/CurrencyInput';
import {
    ISetRangeFilterOptionPayload,
    setRangeFilterOption
} from 'PyzShopUi/scripts/shop-ui/components/search-page/actions/filter';
import { PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS } from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IPriceFilterProps {
    name: string;
    max: number;
    min: number;
    activeMax: number;
    activeMin: number;
    storeConfig?: any;

    dispatch: React.Dispatch<any>;
}

interface IUpdateObject {
    roundedMin: number;
    roundedMax: number;
    minInputValue: number;
    maxInputValue: number;

    dispatch: React.Dispatch<any>;
}

const minInputObserver = new Subject<IUpdateObject>();
const maxInputObserver = new Subject<IUpdateObject>();

const PriceFilter: React.FunctionComponent<IPriceFilterProps> = props => {
    const { name, max, min, activeMax, activeMin, storeConfig, dispatch } = props;
    const { translate } = useTranslationHook();

    const currency = storeConfig?.currency || 'EUR';

    const { isCurrencyLeftSide, symbol } = getCurrencySymbol(currency);

    const roundedMin = Math.floor(min / 100);
    const roundedMax = Math.ceil(max / 100);

    const roundedActiveMin = Math.floor(activeMin / 100);
    const roundedActiveMax = Math.ceil(activeMax / 100);

    const [minPriceInputValue, setMinPriceInputValue] = React.useState<string>(roundedActiveMin.toString());
    const [maxPriceInputValue, setMaxPriceInputValue] = React.useState<string>(roundedActiveMax.toString());

    React.useEffect(() => {
        const minSubscription = minInputObserver
            .pipe(
                debounceTime(PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS),
                filter(object => {
                    const { minInputValue, maxInputValue, roundedMin, roundedMax} = object;

                    if (minInputValue === undefined) {
                        return false;
                    }

                    if (minInputValue < roundedMin) {
                        republishMinPriceInput(roundedMin, roundedMax, roundedMin, maxInputValue, object.dispatch);

                        return false;
                    }

                    if (minInputValue >= maxInputValue) {
                        const newMinValue = maxInputValue - 1;
                        republishMinPriceInput(roundedMin, roundedMax, newMinValue, maxInputValue, object.dispatch);

                        return false;
                    }

                    return true;
                })
            ).subscribe(object => {
                const { minInputValue, maxInputValue } = object;

                const payload: ISetRangeFilterOptionPayload = {
                    filterName: name,
                    activeMin: minInputValue,
                    activeMax: maxInputValue,
                };
                object.dispatch(setRangeFilterOption(payload));
            });

        const maxSubscription = maxInputObserver
            .pipe(
                debounceTime(PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS),
                filter(object => {
                    const { minInputValue, maxInputValue, roundedMin, roundedMax} = object;

                    if (maxInputValue === undefined) {
                        return false;
                    }

                    if (maxInputValue > roundedMax) {
                        republishMaxPriceInput(roundedMin, roundedMax, minInputValue, roundedMax, object.dispatch);

                        return false;
                    }

                    if (maxInputValue <= minInputValue) {
                        const newMaxValue = minInputValue + 1;
                        republishMaxPriceInput(roundedMin, roundedMax, minInputValue, newMaxValue, object.dispatch);

                        return false;
                    }

                    return true;
                }),
            ).subscribe(object => {
                const { minInputValue, maxInputValue } = object;

                const payload: ISetRangeFilterOptionPayload = {
                    filterName: name,
                    activeMin: minInputValue,
                    activeMax: maxInputValue,
                };

                dispatch(setRangeFilterOption(payload));
            });

        return () => {
            minSubscription.unsubscribe();
            maxSubscription.unsubscribe();
        };
    }, []);

    React.useEffect(() => {
        setMinPriceInputValue(roundedActiveMin.toString());
        setMaxPriceInputValue(roundedActiveMax.toString());
    }, [roundedActiveMin, roundedActiveMax]);

    const onMinPriceInputChange = (value: string) => {
        setMinPriceInputValue(value);

        minInputObserver.next({
            roundedMin,
            roundedMax,
            minInputValue: value === '' ? undefined : +value,
            maxInputValue: roundedActiveMax,
            dispatch
        });
    };

    const onMaxPriceInputChange = (value: string) => {
        setMaxPriceInputValue(value);

        maxInputObserver.next({
            roundedMin,
            roundedMax,
            minInputValue: roundedActiveMin,
            maxInputValue: value === '' ? undefined : +value,
            dispatch
        });
    };

    const republishMinPriceInput = (
        roundedMin: number,
        roundedMax: number,
        minInputValue: number,
        maxInputValue: number,
        dispatch: React.Dispatch<any>
    ) => {
        setMinPriceInputValue(minInputValue.toString());

        minInputObserver.next({
            roundedMin,
            roundedMax,
            minInputValue,
            maxInputValue,
            dispatch
        });
    };

    const republishMaxPriceInput = (
        roundedMin: number,
        roundedMax: number,
        minInputValue: number,
        maxInputValue: number,
        dispatch: React.Dispatch<any>
    ) => {
        setMaxPriceInputValue(maxInputValue.toString());

        maxInputObserver.next({
            roundedMin,
            roundedMax,
            minInputValue,
            maxInputValue,
            dispatch
        });
    };

    const onSlideChange = (values: number[]) => {
        const payload: ISetRangeFilterOptionPayload = {
            filterName: name,
            activeMin: values[0],
            activeMax: values[1],
        };

        dispatch(setRangeFilterOption(payload));
    };

    const createSelectedLabel = () => {
        if (roundedMax === roundedActiveMax && roundedMin === roundedActiveMin) {
            return null;
        }

        const minPriceLabel = currencyFormat(roundedActiveMin, currency, false);
        const maxPriceLabel = currencyFormat(roundedActiveMax, currency, false);

        const onRemoveLabel = () => {
            const payload: ISetRangeFilterOptionPayload = {
                filterName: name,
                activeMin: null,
                activeMax: null,
            };

            dispatch(setRangeFilterOption(payload));
        };

        return (
            <div className="selected-filter-option-list">
                <TagItem
                    key={name}
                    label={minPriceLabel + ' - ' + maxPriceLabel}
                    onClick={() => onRemoveLabel()}
                />
            </div>
        );
    };

    return (
        <Accordion
            label={translate('Preis', { ns: 'article-list-filter'})}
            headerContent={createSelectedLabel()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className="price-filter">
                {createSelectedLabel()}
                <div>
                    <RangeSlider
                        className="price-filter__range-slider"
                        activeMax={roundedActiveMax}
                        max={roundedMax}
                        activeMin={roundedActiveMin}
                        min={roundedMin}
                        onSlideChange={onSlideChange}
                    />
                </div>
                <div className="price-filter__input-fields">
                    <CurrencyInput
                        additionalClass="price-filter__input-fields__min-input"
                        name="minPrice"
                        value={minPriceInputValue}
                        currencySymbol={symbol}
                        isSymbolLeftSide={isCurrencyLeftSide}
                        onInputChange={onMinPriceInputChange}
                        accessibilityLabel={translate('Minimum Price', {ns: 'article-list-filter'})}
                    />
                    -
                    <CurrencyInput
                        additionalClass="price-filter__input-fields__max-input"
                        name="maxPrice"
                        value={maxPriceInputValue}
                        currencySymbol={symbol}
                        isSymbolLeftSide={isCurrencyLeftSide}
                        onInputChange={onMaxPriceInputChange}
                        accessibilityLabel={translate('Maximum Price', {ns: 'article-list-filter'})}
                    />
                </div>
            </div>
        </Accordion>
    );
};

export default PriceFilter;
