(function () {
    // https://stackoverflow.com/a/37477096
    if (!('remove' in Element.prototype)) {
        // @ts-ignore
        Element.prototype.remove = function() {
            if (this.parentNode) {
                this.parentNode.removeChild(this);
            }
        };
    }
})();
