import * as React from 'react';
import { ISliderContainerProps } from '../../../interfaces';
import SliderContent from '../../content/SliderContent';
import { SwiperOptions } from 'swiper';
import { withAPI } from '../../../hoc/withAPI';
import { ESliderMinimumForDisplayed } from '../../../constants';
import { withSwiper } from '../../../hoc/withSwiper';
import RecentSliderItem from './RecentSliderItem';

const RecentSlider: React.FunctionComponent<React.PropsWithChildren<ISliderContainerProps>> = props => {
    const { title, allItemLink, allItemTitle, children } = props;
    return (
        <SliderContent title={title} allItemTitle={allItemTitle} allItemLink={allItemLink}>
            {children}
        </SliderContent>
    );
};

const swiperOptions: SwiperOptions = {
    shortSwipes: false,
    longSwipesRatio: 0.2,
    longSwipesMs: 100,
    slidesPerView: 6,
    slidesPerGroup: 6,
    spaceBetween: 12,
    breakpoints: {
        320: {
            slidesPerView: 2.2,
            slidesPerGroup: 2,
            spaceBetween: 10,
        },
        640: {
            slidesPerGroup: 4,
            slidesPerView: 4,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 6,
            slidesPerGroup: 6,
            spaceBetween: 12,
        },
    },
};

export default withAPI(ESliderMinimumForDisplayed.RECENT)(
    withSwiper(
        swiperOptions,
        RecentSliderItem,
        'transform-50-positive',
        'transform-50-positive',
        true
    )(RecentSlider),
);
