import axios from 'axios';
import { ICatalogSearchPageResponse } from '../interfaces';
import { History } from 'history';
import HistoryHandler from '../../react/common/lib/HistoryHandler';
import { IApiQueryData } from '../../shop-ui/components/search-page/interfaces';

export default class SearchPageTabService {
    public static getInstance(): SearchPageTabService {
        if (this.instance === null) {
            this.instance = new SearchPageTabService();
        }

        return this.instance;
    }

    private static instance: SearchPageTabService = null;

    private catalogSearchPageAPI = '/ajax/search';
    private defaultQueryObject: IApiQueryData = {};
    private history: History;

    constructor () {
        this.history = HistoryHandler.create();
    }

    public setDefaultQueryObject = (defaultQueryObject: IApiQueryData): void => {
        this.defaultQueryObject = defaultQueryObject;
    }

    public getDefaultQueryObject = (): IApiQueryData => this.defaultQueryObject;

    public getData = async (apiQueryData: IApiQueryData): Promise<ICatalogSearchPageResponse> => {
        const mergedQueryData = {
            ...this.defaultQueryObject,
            ...apiQueryData,
        };

        const dataResponse = await axios.get(this.catalogSearchPageAPI, {
            params: mergedQueryData,
        });

        return dataResponse.data;
    };

    public updateBrowserUrl = (newUrl: string) => {
        this.history.push({ pathname: newUrl });
    };

    public updateBrowserParameters = (parameters: string) => {
        this.history.push({search: parameters});
    }
}
