export const productImageLocator = (iconElement: HTMLElement): HTMLElement => {
    return iconElement
        .closest('.product-item')
        .querySelector('[data-fly-to-cart-object="true"]');
}

export const mediaCenterProductImageLocator = (iconElement: HTMLElement): HTMLElement => {
    return iconElement
        .closest('.article-mediacenter')
        .querySelector('.swiper-slide-thumb-active');
}
