import axios, { AxiosError, AxiosResponse } from 'axios';
import { BikeDBConstants, ResponseConstants } from '../../../constants';

interface IAddToMyBikeResponse {
    my_bikes?: Array<{
        bike_id: string;
    }>;
    myBikeExternalIds?: string[];
    reason?: string;
    message?: string;
}

export interface IAddToMyBikeResult {
    success: boolean;
    reason?: string;
    message?: string;
}

export enum EMyBikeAPIEndpoint {
    Shop2012 = '/api/my-louis/v1/bikes',
    ShopSpryker = '/api/my-bikes/v1/bikes',
}

export default class MyLouisService {
    private readonly endpoint: EMyBikeAPIEndpoint;

    public constructor(endpoint: EMyBikeAPIEndpoint) {
        this.endpoint = endpoint;
    }

    public addToMyBike = (externalId: string, csrfToken: string): Promise<IAddToMyBikeResult> => {
        const apiUrl = new URL(this.endpoint, window.location.origin);

        return axios.post(
            apiUrl.toString(),
            JSON.stringify({ external_id: externalId, csrfToken: csrfToken }),
            {
                headers: {
                    'Content-Type': 'application/json',
                },
            },
        ).then((response: AxiosResponse<IAddToMyBikeResponse>) => {

            if (response.status !== ResponseConstants.HTTP_OK && response.status !== ResponseConstants.HTTP_CREATED) {
                return createErrorResponse(response);
            }

            // for EMyBikeAPIEndpoint.Shop2012
            if (response.data.my_bikes
                && response.data.my_bikes.filter(myBike => myBike.bike_id.toString() === externalId.toString()).length === 0) {
                return createErrorResponse(response);
            }

            // for EMyBikeAPIEndpoint.ShopSpryker
            if (response.data.myBikeExternalIds
                && !response.data.myBikeExternalIds.includes(externalId)) {
                return createErrorResponse(response);
            }

            return createSuccessResponse();
        }).catch((error: AxiosError) => {
            if (error.response.status === ResponseConstants.HTTP_GONE && error.response.data.reason === BikeDBConstants.MY_BIKE_IS_FULL_REASON) {
                return createErrorResponse(error.response);
            }

            return createErrorResponse(error.response);
        });
    }

    public removeFromMyBike = (externalId: string): Promise<boolean> => {
        const apiUrl = new URL(this.endpoint + '/' + externalId, window.location.origin);
        return axios.delete(apiUrl.toString()).then((response: AxiosResponse<{}>) => response.status === ResponseConstants.HTTP_NO_CONTENT);
    }
}

function createSuccessResponse(): IAddToMyBikeResult {
    return {
        success: true,
        reason: '',
        message: '',
    };
}

function createErrorResponse(response): IAddToMyBikeResult {
    return {
        success: false,
        reason: response.data.reason,
        message: response.data.message,
    };
}
