import './register-form.scss';
import register from 'ShopUi/app/registry';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('register-form', () =>
    importWithFallback(
        /* webpackMode:"lazy" */
        () => import('./register-form')
        ),
);
