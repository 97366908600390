import { IProductPayload, IActionField, EventPayloadStore } from './payload';

interface ITrackingData {
    pa: string;
}
const enrichProductPayloadByExistingClickTrackingParams = (jsonData: ITrackingData, product: IProductPayload): IProductPayload => {
    const id = jsonData.pa;
    return { ...product, id };
};

export const getProductPayloadByWrapperElement = (element: Element): IProductPayload => {
    let product: IProductPayload = {
        id: '',
        name: '[Unknown]',
    };

    if (element && element.hasAttribute('data-token')) {
        product = enrichProductPayloadByStoreData(product, element.getAttribute('data-token'));
    }
    if ('[Unknown]' !== product.name && '' !== product.id) {
        return product;
    }

    // Fallback if there is no data
    if (element.hasAttribute('data-clicktrackingparams')) {
        product = enrichProductPayloadByExistingClickTrackingParams(
            JSON.parse(element.getAttribute('data-clicktrackingparams')),
            product
        );
    }
    const closestLink = element.closest('a');
    if (closestLink) {
        product = enrichProductPayloadByLinkElement(element, product);
    }

    if ('' === product.name || '[Unknown]' === product.name) {
        const productNameElements = element.getElementsByClassName('article-item__title-link-title');
        if (productNameElements && productNameElements.length) {
            for (let i = 0; i < productNameElements.length; i++) {
                const productNameElement = productNameElements.item(i);
                if (productNameElement.tagName === 'DIV') {
                    product.name = productNameElement.textContent.trim();
                }
            }
        }
    }
    if ('' === product.name || '[Unknown]' === product.name) {
        const innerLinks = element.querySelectorAll('a');
        let i = 0;
        while (('' === product.name || '[Unknown]' === product.name) && i < innerLinks.length) {
            const link = innerLinks.item(i);
            product = enrichProductPayloadByLinkElement(link, product);
            i++;
        }
    }

    return product;
};

export const getActiFieldListByWrapperElement = (element: Element): IActionField => {
    if (element.hasAttribute('data-token')
        && EventPayloadStore.actionFields.hasOwnProperty(element.getAttribute('data-token'))
    ) {
        return EventPayloadStore.actionFields[element.getAttribute('data-token')];
    }
    const actionField: IActionField = { list: '[unknown]' };

    if (EventPayloadStore.pageData && EventPayloadStore.pageData.pageType !== '') {
        actionField.list = EventPayloadStore.pageData.pageType;
    }
    const closestInner = element.closest('div.inner');
    if (closestInner) {
        const stickerTextCollection = closestInner.getElementsByClassName('sticker__text');
        if (stickerTextCollection && stickerTextCollection.length === 1) {
            actionField.list += stickerTextCollection.item(0).textContent.trim();
            return actionField;
        }
    }

    // try product categories
    const mainContent = element.closest('div.main-content');
    if (mainContent) {
        const headlineContainer = mainContent.querySelector('p.category-nav-container__headline');
        if (headlineContainer) {
            actionField.list += 'Category(' + (headlineContainer.textContent.trim() !== '' ? headlineContainer.textContent.trim() : '');
            const headline = mainContent.querySelector('div.heading__headline');
            if (headline) {
                actionField.list += '|' + headline.textContent.trim();
            }
            actionField.list += ')';

            return actionField;
        }
    }

    return actionField;
};

export const enrichProductPayloadByLinkElement = (element: Element, product: IProductPayload): IProductPayload => {
    const href = (element && element.hasAttribute('href') ? element.getAttribute('href') : null);
    const title = (element && element.hasAttribute('title') ? element.getAttribute('title') : null);
    if (null === href) {
        throw new Error('Unable to get the link properties to get product information for last seen list');
    }
    const linkParts = href.split('\/');
    const id = linkParts.pop();
    const name = title ? title : linkParts.pop();

    return { ...product, name, id };
};

export const enrichProductPayloadByStoreData = (product: IProductPayload, dataToken: string): IProductPayload => {
    if (!EventPayloadStore.products.hasOwnProperty(dataToken)) {
        return product;
    }

    return { ...product, ...EventPayloadStore.products[dataToken] };
};
