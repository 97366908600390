import * as React from 'react';
import { IBreadcrumbs } from '../../interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IBreadcrumbProps {
    breadcrumb: IBreadcrumbs[];
}

const Breadcrumb: React.FunctionComponent<IBreadcrumbProps> = props => {
    const { breadcrumb } = props;
    const { translate } = useTranslationHook();

    if (!breadcrumb) {
        return null;
    }

    const displayBreadcrumb = () => (
        breadcrumb.map((item, index) =>
            <li key={item.label} className="breadcrumb-step" data-qa="component breadcrumb-step"
                property="itemListElement" typeof="ListItem">
                <a className="no-underline" property="item" typeof="WebPage" href={item.url}>
                    <span property="name">{item.label}</span>
                </a>
                <meta property="position" content={`${index + 2}`}/>
            </li>
        )
    );

    return (
        <React.Fragment>
            <div className="grid-x">
                <div className="cell">
                    <ul className="breadcrumb">
                        <li className="breadcrumb-step" data-qa="component breadcrumb-step"
                            property="itemListElement" typeof="ListItem">
                            <a className="no-underline" property="item" typeof="WebPage" href="/">
                                <span property="name">{translate('global.home', { ns: 'spryker-core' })}</span>
                            </a>
                            <meta property="position" content="1"/>
                        </li>

                        {displayBreadcrumb()}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Breadcrumb;
