import * as React from 'react';
import ('PyzShopUi/styles/module/_buyer-guide-teaser.scss');

interface BuyerGuideTeaserProps {
    additionalClassnames?: string;
    teaserInfoAdditionalClassNames?: string;
}

export const BuyerGuideBannerTeaser: React.FunctionComponent<React.PropsWithChildren<BuyerGuideTeaserProps>> = props => {
    const { additionalClassnames, teaserInfoAdditionalClassNames, children } = props;

    return (
        <div className={`buyer-guide-teaser ${additionalClassnames}`}>
            <div className={`buyer-guide-teaser__info ${teaserInfoAdditionalClassNames}`}>
                <div className="buyer-guide-teaser__info__container">
                    {children}
                </div>
            </div>
        </div>
    );
};

BuyerGuideBannerTeaser.defaultProps = {
    additionalClassnames: '',
    teaserInfoAdditionalClassNames: ''
}
