import * as React from 'react';
import { Switch } from '@mui/material';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { MY_BIKE_OPTION_UNIVERSAL } from '../../../../constants';
import { ISelectFilterOption } from '../../../../../shop-ui/components/search-page/interfaces';
import Tooltip from '../../../../../react/common/components/Tooltip';
import CheckboxListItem from '../../../../../shop-ui/components/CheckboxListItem';

interface IGenderFilterOptionsProps {
    optionList: ISelectFilterOption[];

    onToggleItem?(value: string, isSelected: boolean): void;

    onToggleItems?(toggleOptions: ISelectFilterOption[]): void;
}

const MyBikeFilterOptionsComponent: React.FunctionComponent<IGenderFilterOptionsProps> = props => {
    const { translate } = useTranslationHook();

    const isNoBikeSelected = () => {
        const selectedBikes = props.optionList.filter(option => option.selected && option.value !== MY_BIKE_OPTION_UNIVERSAL);
        return !selectedBikes.length;
    };

    const willAllBikesUnselected = (optionValue: string) => {
        let selectedBikes = props.optionList.filter(option => option.selected && option.value !== MY_BIKE_OPTION_UNIVERSAL);
        if (selectedBikes.length === 0) {
            return false;
        }

        selectedBikes = selectedBikes.filter(option => option.value !== optionValue);
        return !selectedBikes.length;
    };

    const onToggleMyBikeOption = (option: ISelectFilterOption) => {
        let toggleOptions = [option];
        const universalOption: ISelectFilterOption[] = props.optionList.filter(o => o.value === MY_BIKE_OPTION_UNIVERSAL);

        if (universalOption.length) {
            if (willAllBikesUnselected(option.value)) {
                universalOption[0].selected = true;
                toggleOptions = toggleOptions.concat(universalOption);
            } else {
                if (!universalOption[0].selected && !option.selected) {
                    universalOption[0].selected = false;
                    toggleOptions = toggleOptions.concat(universalOption);
                }
            }
        }

        props.onToggleItems(toggleOptions);
    };

    const onToggleUniversalOption = (option: ISelectFilterOption) => {
        if (isNoBikeSelected()) {
            return;
        } else {
            onToggleMyBikeOption(option);
        }
    };

    const displayItem = () => (
        props.optionList.filter(option => (option.value !== MY_BIKE_OPTION_UNIVERSAL)).map(option => (
            <CheckboxListItem
                key={option.value}
                label={option.label}
                value={option.value}
                isSelected={option.selected}
                additionalWrapperClassName='checkbox-menu__item'
                onClick={() => onToggleMyBikeOption(option)}
            />
        ))
    );

    const displayUniversalSwitch = () => (
        props.optionList.filter(option => (option.value === MY_BIKE_OPTION_UNIVERSAL)).map(option => (
            <li
                key={option.value}
                className={`checkbox-menu__toggle`}
            >
                <label htmlFor={option.label + '_' + option.value}>
                    {translate('Include universally compatible products.', { ns: 'article-list-filter' })} &nbsp;
                </label>
                <Switch
                    id={option.label + '_' + option.value}
                    onClick={() => onToggleUniversalOption(option)}
                    checked={option.selected}
                    disabled={isNoBikeSelected()}
                />
            </li>
        ))
    );

    const displayToolTip = () => (
        props.optionList.filter(option => (option.value === MY_BIKE_OPTION_UNIVERSAL)).map(option => (
            <Tooltip
                id={`tooltip-${option.value}`}
                content={`${translate('To give you a better choice we have lots of universally...', { ns: 'article-list-filter' })} ${translate('If you would prefer to see only products that are specifically for your motorcycle, please untick.', { ns: 'article-list-filter' })}`}
                isHoverEnabled={false}
                isDisplayInline={false}
                showInfoIcon={false}
                showCloseIcon={false}
            >
            <span className='tooltip-universal tooltip-text tooltip-icon' data-tooltip={`tooltip-${option.value}`} tabIndex={0}>
                {translate('What does this mean?', { ns: 'article-list-filter' })}
            </span>
            </Tooltip>
        ))
    );

    return (
        <ul className='checkbox-menu'>
            {displayItem()}
            {displayUniversalSwitch()}
            {displayToolTip()}
        </ul>
    );
};

MyBikeFilterOptionsComponent.defaultProps = {
    onToggleItem: () => ({}),
};

const MyBikeFilterOptions = withMemo(MyBikeFilterOptionsComponent);

export default MyBikeFilterOptions;
