class PasswordIcon {
    private static INPUT_TYPES = {
        TEXT: 'text',
        PASSWORD: 'password',
    };
    private static ICON_CLASSES = {
        EYE_OPEN: 'icon-eye-open',
        EYE_CLOSED: 'icon-eye-closed',
    };
    private passwordField: HTMLInputElement;
    private passwordFieldIcon: HTMLElement;

    public constructor(passwordField: HTMLInputElement) {
        this.passwordField = passwordField;
        this.passwordFieldIcon = this.passwordField.nextElementSibling as HTMLElement;

        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.passwordFieldIcon.addEventListener('click', this.toggle);
    }

    private toggle = (): void => {
        if (this.passwordField.type === PasswordIcon.INPUT_TYPES.PASSWORD) {
            this.showPassword();
        } else {
            this.hidePassword();
        }

        this.passwordFieldIcon.blur();
    }

    private showPassword = (): void => {
        this.passwordField.type = PasswordIcon.INPUT_TYPES.TEXT;
        this.passwordFieldIcon.classList.add(PasswordIcon.ICON_CLASSES.EYE_CLOSED);
        this.passwordFieldIcon.classList.remove(PasswordIcon.ICON_CLASSES.EYE_OPEN);
    }

    private hidePassword = (): void => {
        this.passwordField.type = PasswordIcon.INPUT_TYPES.PASSWORD;
        this.passwordFieldIcon.classList.add(PasswordIcon.ICON_CLASSES.EYE_OPEN);
        this.passwordFieldIcon.classList.remove(PasswordIcon.ICON_CLASSES.EYE_CLOSED);
    }
}

export default class PasswordInputHandler {

    public static init = () => {
        document.querySelectorAll('input[type="password"]').forEach((passwordField: HTMLInputElement): void => {
            if (!passwordField.nextElementSibling) {
                return;
            }
            if (!passwordField.nextElementSibling.classList.contains('icon')) {
                return;
            }
            // tslint:disable-next-line:no-unused-expression
            new PasswordIcon(passwordField);
        });
    }
}
