import EventBus from '../utils/eventBus';
import { EVideoEvents } from './events/videoEvents';

class Video {

    private eventBus: EventBus;
    private readonly videoElement: HTMLVideoElement;

    constructor(videoElement: HTMLVideoElement, eventBus: EventBus) {
        this.eventBus = eventBus;
        this.videoElement = videoElement;
        this.addEventListener();
    }

    private addEventListener = (): void => {
        this.eventBus.subscribe(EVideoEvents.PRELOAD, this.preloadMetaInContainer);
        this.eventBus.subscribe(EVideoEvents.PRELOAD, this.setPosterAttribute);
        this.eventBus.subscribe(EVideoEvents.PLAY_STARTED, this.pauseOtherVideos);
        this.eventBus.subscribe(EVideoEvents.PAUSE, this.pauseVideoInContainer);

        this.videoElement.addEventListener('play', (_: Event): void => {
            this.eventBus.publish(EVideoEvents.PLAY_STARTED, this.videoElement);
        });

        this.videoElement.addEventListener('loadedmetadata', (_: Event): void => {
            this.eventBus.publish(EVideoEvents.META_LOADED, this.videoElement);
        });
    }

    private preloadMetaInContainer = (containerHtmlElement: HTMLElement): void => {
        if (this.videoElement.readyState === this.videoElement.HAVE_NOTHING && containerHtmlElement.contains(this.videoElement)) {
            this.videoElement.setAttribute('preload', 'metadata');
            this.videoElement.load();
        }
    }

    private setPosterAttribute = (containerHtmlElement: HTMLElement): void => {
        if (containerHtmlElement.contains(this.videoElement) && this.videoElement.dataset.poster !== undefined) {
            this.videoElement.setAttribute('poster', this.videoElement.dataset.poster);
        }
    }

    private pauseVideoInContainer = (containerHtmlElement: HTMLElement): void => {
        if (containerHtmlElement.contains(this.videoElement)) {
            this.videoElement.pause();
        }
    }

    private pauseOtherVideos = (startedVideoElement: HTMLVideoElement): void => {
        if (startedVideoElement !== this.videoElement) {
            this.videoElement.pause();
        }
    }
}

export default class VideoHandler {
    public static init = (eventBus: EventBus): void => {
        VideoHandler.globalEventBus = eventBus;

        VideoHandler.globalEventBus.subscribe(EVideoEvents.INIT, VideoHandler.initVideo);

        document.querySelectorAll('video').forEach((videoElement: HTMLVideoElement): void => {
            VideoHandler.globalEventBus.publish(EVideoEvents.INIT, videoElement);
        });
    }

    private static globalEventBus: EventBus;

    private static initVideo = (videoElement: HTMLVideoElement): void => {
        new Video(videoElement, VideoHandler.globalEventBus);
    }
}
