/**
 * this file contains a function to init Sentry for the client
 * this will log all uncaught errors to the "spryker-client-side" project
 * note that this has nothing to do with the Sentry logging we do within the backend
 */

import * as Sentry from "@sentry/browser";

export const initClientSideSentry = () => {
    // CLIENT_SIDE_SENTRY_DSN and CLIENT_SIDE_SENTRY_SAMPLE_RATE are set by the webpack DefinePlugin,
    // see frontend/libs/sentry-configurator.js and frontend/configs/development.js for details

    // if CLIENT_SIDE_SENTRY_DSN is set to an empty string, we don't want to set up Sentry for 
    // the current environment
    if (CLIENT_SIDE_SENTRY_DSN === '') {
        return;
    }

    Sentry.init({
        dsn: CLIENT_SIDE_SENTRY_DSN,
        sampleRate: CLIENT_SIDE_SENTRY_SAMPLE_RATE
    });
}

