import * as React from 'react';
import Slider from 'rc-slider';
import i18next from 'i18next';

interface IRangeSliderProps {
    className?: string;
    activeMin: number;
    activeMax: number;
    min: number;
    max: number;

    onSlideChange?(values: number[]): void;
}

const RangeSlider: React.FunctionComponent<IRangeSliderProps> = props => {
    const { className, activeMin, activeMax, min, max, onSlideChange } = props;

    const handleChange = (values: number[]) => {
        onSlideChange(values);
    };
    const ariaLabel = i18next.t('cart.price', { ns: 'spryker-core'});
    return (
        <div className={className}>
            <Slider
                range
                allowCross={false}
                min={min}
                max={max}
                value={[activeMin, activeMax]}
                onChange={handleChange}
                ariaLabelForHandle={ariaLabel}
            />
        </div>
    );
};

RangeSlider.defaultProps = {
    className: '',
    onSlideChange: () => ({}),
}

export default RangeSlider;
