import * as React from 'react';
import { Swiper, SwiperSlide, SwiperRef } from "swiper/react";
import { SwiperOptions } from 'swiper';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import IReactEvent from '../../interfaces/article-list/IReactEvent';
import Button from '../../react/common/components/form/Button';
import { WindowEvent } from '../../react/common/events/windowEvent';
import { ISliderItemProps, ISliderProps } from '../interfaces';
import {NUMBER_PRODUCT_SLIDER_NO_LAZY_LOAD} from 'PyzShopUi/scripts/shop-ui/constants';
import { BREAKPOINTS } from '../../config';

type HighOrderComponent<T> = (Component: React.FunctionComponent) => React.FunctionComponent<T>;

const MAX_SIZE_VALUE = 'max';

export function withSwiper(
    swiperOptions: SwiperOptions,
    SliderItem: React.FunctionComponent<ISliderItemProps>,
    previousButtonAdditionalClass?: string,
    nextButtonAdditionalClass?: string,
    hasNavigationButtons?: boolean,
): HighOrderComponent<ISliderProps> {
    function withSwiperComponent(FunctionComponent: React.FunctionComponent<ISliderProps>): React.FunctionComponent<ISliderProps> {
        const wrappedComponent: React.FunctionComponent<ISliderProps> = props => {
            const { middlewareEventBus, items, storeConfig, useLazyLoadOnInit } = props;

            const sliderContentRef = React.useRef<any>(null);
            const swiperRef = React.useRef<SwiperRef>(null);
            const [slideAtBegin, setSlideAtBegin] = React.useState<boolean>(true);
            const [slideAtEnd, setSlideAtEnd] = React.useState<boolean>(false);
            const [slidesPerView, setSlidesPerView] = React.useState<number | 'auto'>(0);
            const { translate } = useTranslationHook();

            const additionalSwiperOptions: SwiperOptions = {
                watchSlidesProgress: true,
            };


            swiperOptions = {
                ...swiperOptions,
                ...additionalSwiperOptions,
            };

            const onSwiperInitialized = () => {
                if (swiperRef === null) {
                    return;
                }

                if (!hasNavigationButtons) {
                    return;
                }

                changeSliderPerView();

                swiperRef.current.swiper.on('slideChange', () => {
                    setSlideAtBegin(swiperRef.current.swiper.isBeginning);
                    setSlideAtEnd(swiperRef.current.swiper.isEnd);
                });

                const subscription = middlewareEventBus.subscribe((event: IReactEvent<any>) => {
                    if (event.type === WindowEvent.WINDOW_RESIZE_DEBOUNCED) {
                        changeSliderPerView();
                    }
                });

                return () => {
                    subscription.unsubscribe();
                };
            };

            React.useEffect(onSwiperInitialized, [swiperRef]);

            const changeSliderPerView = () => {
                //@ts-ignore
                const currentBreakpoint: string = swiperRef.current.swiper.currentBreakpoint;

                if (currentBreakpoint === MAX_SIZE_VALUE) {
                    setSlidesPerView(swiperOptions.slidesPerView);
                    return;
                }

                setSlidesPerView(swiperOptions.breakpoints[Number(currentBreakpoint)].slidesPerView);
            };

            const goNext = () => {
                if (swiperRef === null) {
                    return;
                }

                swiperRef.current.swiper.slideNext();
            };
            const goPrev = () => {
                if (swiperRef === null) {
                    return;
                }
                swiperRef.current.swiper.slidePrev();
            };

            const displayPrevButton = () => {
                if (!hasNavigationButtons) {
                    return null;
                }

                const additionClass = slideAtBegin || items.length <= slidesPerView ? ' button-disabled' : '';
                return (
                    <Button
                        icon='chevron-thin-left'
                        iconAdditionalClass='highlight icon-size-standard icon-only'
                        iconPosition='left'
                        additionalClass={`button-previous hide-for-small-only ${previousButtonAdditionalClass} ${additionClass}`}
                        onClick={goPrev}
                        ariaAttributes={{
                            'aria-label': translate('pagination.previous', { ns: 'spryker-core'}),
                        }}
                    />
                );
            };
            const displayNextButton = () => {
                if (!hasNavigationButtons) {
                    return null;
                }

                const additionClass = slideAtEnd || items.length <= slidesPerView ? ' button-disabled' : '';
                return (
                    <Button
                        icon='chevron-thin-right'
                        iconAdditionalClass='highlight icon-size-standard icon-only'
                        iconPosition='right'
                        additionalClass={`button-next hide-for-small-only ${nextButtonAdditionalClass} ${additionClass}`}
                        onClick={goNext}
                        ariaAttributes={{
                            'aria-label': translate('pagination.next', { ns: 'spryker-core'}),
                        }}
                    />
                );
            };

            const displaySliderItems = () => {
                return items.map((item, index) =>
                    {
                        let lazyLoad = true;
                        if (!useLazyLoadOnInit && index < NUMBER_PRODUCT_SLIDER_NO_LAZY_LOAD) {
                            lazyLoad = false;
                        }

                        return  (
                            <SwiperSlide key={index} className={`swiper-item ${lazyLoad ? 'slider-lazyload-item' : ''}`} onKeyDown={(e) => {
                                if(BREAKPOINTS.smallOnly()) {
                                    if(e.key === "Tab" && !e.shiftKey) {
                                        swiperRef.current?.swiper?.slideTo(index + 1);
                                    }
                                    
                                    if(e.key === "Tab" && e.shiftKey) {
                                        swiperRef.current?.swiper?.slideTo(index - 1);
                                    }
                                }
                            }}>
                                {({ isVisible }) => (
                                    <SliderItem item={item} storeConfig={storeConfig} useLazyLoad={lazyLoad} isVisible={isVisible} hideNotVisibleItems={BREAKPOINTS.minMedium()} />
                                )}
                            </SwiperSlide>
                        )
                    }
                );
            }

            return (
                <FunctionComponent {...props}>
                    <div ref={sliderContentRef} className={`article-slider ${hasNavigationButtons ? '' : 'without-navigation-button'}`}>
                        {displayPrevButton()}
                        <div className={`swiper-container`}>
                            <Swiper ref={swiperRef} {...swiperOptions}>
                                {displaySliderItems()}
                            </Swiper>
                        </div>
                        {displayNextButton()}
                    </div>
                </FunctionComponent>
            );
        };

        wrappedComponent.displayName = 'withSwiper';

        return wrappedComponent;
    }

    return withSwiperComponent;
}
