import { BREAKPOINTS } from '../config';
import AccordionHandler, { Accordion } from './accordion';
import EventBus from '../utils/eventBus';

enum ListAccordionStatus {
    LIST,
    ACCORDION,
}

// breakpoints when it becomes a list
enum ListAccordionBreakpoints {
    MEDIUM = 'medium', // small: Accordion, medium+large: List
    LARGE = 'large', // small+medium: Accordion, large: List
}

export class ListAccordion {
    private accordion: Accordion;
    private currentStatus: ListAccordionStatus;

    public constructor(container: HTMLElement, eventBus: EventBus, allowScrollUp: boolean) {
        const header: HTMLElement = container.querySelector('.accordion-header');
        const content: HTMLElement = header.nextElementSibling as HTMLElement;

        if (!content.classList.contains('accordion-content')) {
            return;
        }

        this.accordion = AccordionHandler.initSingle(header, content, eventBus, allowScrollUp);

        if (container.dataset.breakpoint === ListAccordionBreakpoints.LARGE) {
            this.detachAttachForBreakpointLarge();
            eventBus.subscribe('window:resize:debounced', this.detachAttachForBreakpointLarge);
        } else {
            this.detachAttachForBreakpointMedium();
            eventBus.subscribe('window:resize:debounced', this.detachAttachForBreakpointMedium);
        }
    }

    private detachAttachForBreakpointMedium = (): void => {
        if ((this.currentStatus === undefined
            || this.currentStatus === ListAccordionStatus.LIST)
            && BREAKPOINTS.smallOnly()
        ) {
            this.switchToAccordion();
        } else if ((this.currentStatus === undefined
            || this.currentStatus === ListAccordionStatus.ACCORDION)
            && BREAKPOINTS.minMedium()
        ) {
            this.switchToList();
        }
    }

    private detachAttachForBreakpointLarge = (): void => {
        if ((this.currentStatus === undefined
            || this.currentStatus === ListAccordionStatus.LIST)
            && BREAKPOINTS.medium()
        ) {
            this.switchToAccordion();
        } else if ((this.currentStatus === undefined
            || this.currentStatus === ListAccordionStatus.ACCORDION)
            && BREAKPOINTS.minLarge()
        ) {
            this.switchToList();
        }
    }

    private switchToAccordion = (): void => {
        this.accordion.activate();
        this.accordion.accordionHeader.setAttribute('tabindex', '0');
        this.currentStatus = ListAccordionStatus.ACCORDION;
    }

    private switchToList = (): void => {
        this.accordion.deactivate();
        this.accordion.accordionHeader.removeAttribute('tabindex');
        this.currentStatus = ListAccordionStatus.LIST;
    }
}

export default class ListAccordionHandler {

    public static init = (eventBus: EventBus, allowScrollUp: boolean = true): void => {
        document.querySelectorAll('.list-accordion').forEach((listAccordion: HTMLElement): void => {
            new ListAccordion(listAccordion, eventBus, allowScrollUp);
        });
    }
}
