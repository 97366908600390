import * as React from 'react';
import Label from '../Label';
import SelectOptions from './options/SelectOptions';
import { ISelectOption } from '../../interfaces';
import classNames from 'classnames';
import { MDCSelect } from '../../../types/MDCSelect';
import Utils from 'PyzShopUi/scripts/utils/utils';

interface IMdcSelectProps {
    label?: string;
    floatingLabel?: string;
    options: ISelectOption[];
    selectedValue?: string;
    onChange?(value: string): void;
    fullWidth?: boolean;
    ariaLabel?: string;
}

const MdcSelect = (props: IMdcSelectProps) => {
    const { label, floatingLabel, options, onChange, selectedValue, fullWidth, ariaLabel } = props;
    const selectHtmlElementRef = React.useRef<HTMLDivElement>(null);
    const mdcSelectRef = React.useRef<MDCSelect>();

    React.useEffect(() => {
        mdcSelectRef.current = new MDCSelect(selectHtmlElementRef.current);
        const mdcSelect = mdcSelectRef.current;
        return () => {
            mdcSelect.destroy();
        };
    }, []);

    const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };

    const cssClasses = classNames({
        'mdc--fullwidth': fullWidth,
    });

    const id = 'select-' + Utils.getRandomId();
    return (
        <React.Fragment>
            <Label label={label} htmlFor={id}/>

            <div className={`mdc-select mdc-select--outlined mdc-select__native ${cssClasses}`} ref={selectHtmlElementRef}>
                <i className='icon icon-chevron' />

                <select value={selectedValue === null ? undefined : selectedValue}
                        onChange={onSelectChange}
                        className="mdc-select__native-control"
                        data-qa="variant-select-dropdown" id={id}
                        {...(ariaLabel && {'aria-label': ariaLabel})}>
                    <SelectOptions options={options}/>
                </select>

                <div className='mdc-notched-outline mdc-notched-outline--upgraded'>
                    <div className='mdc-notched-outline__leading' />
                    <div className='mdc-notched-outline__notch'>
                        {floatingLabel ? <label className="mdc-floating-label mdc-floating-always" htmlFor={id}>{floatingLabel}</label> : undefined}
                    </div>
                    <div className='mdc-notched-outline__trailing' />
                </div>
            </div>
        </React.Fragment>
    );
};

MdcSelect.defaultProps = {
    label: '',
    selectedValue: '',
};

export default MdcSelect;
