import * as React from 'react';
import { currencyFormat } from '../../../react/common/lib';
import { ISetRangeFilterOptionPayload, setRangeFilterOption } from '../../../shop-ui/components/search-page/actions/filter';
import { IRangeFilter } from '../../../shop-ui/components/search-page/interfaces';
import TagItem from '../../../shop-ui/components/TagItem';

interface ISelectedPriceRangeFilterLabel {
    filter: any;
    formatterFunction?: any;
    storeConfig?: any;
    dispatch: React.Dispatch<any>;
}

const SelectedPriceRangeFilterLabel: React.FunctionComponent<ISelectedPriceRangeFilterLabel> = props => {
    const filter: IRangeFilter = props.filter;
    const currency = props.storeConfig.currency;
    const dispatch = props.dispatch;

    const deselectPriceRangeFilterSelectedLabel = (filterName: string) => {
        const payload: ISetRangeFilterOptionPayload = {
            filterName,
            activeMin: null,
            activeMax: null
        }

        dispatch(setRangeFilterOption(payload));
    }

    const roundedActiveMin = Math.floor(filter.activeMin / 100);
    const roundedActiveMax = Math.ceil(filter.activeMax / 100);

    const minPriceLabel = currencyFormat(roundedActiveMin, currency, false);
    const maxPriceLabel = currencyFormat(roundedActiveMax, currency, false);

    return <TagItem
        key={filter.name}
        label={minPriceLabel + ' - ' + maxPriceLabel}
        onClick={() => deselectPriceRangeFilterSelectedLabel(filter.name)}
    />;
};

export default SelectedPriceRangeFilterLabel;
