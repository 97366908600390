import * as React from 'react';
import { Filter } from '../../../types';
import { FilterNames } from 'PyzShopUi/scripts/bike-search-page/constants';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IFiltersProps {
    filters: Filter[];
    filtersMapping: any;
    storeConfig?: any;
    dispatch: React.Dispatch<any>;
}

const Filters: React.FunctionComponent<IFiltersProps> = props => {
    const { translate } = useTranslationHook();
    const displayFilters = () => (
        props.filters.map((filter: any) => {
            if (!props.filtersMapping.hasOwnProperty(filter.name)
                || !props.filtersMapping[filter.name].hasOwnProperty('filterComponent')
            ) {
                return null;
            }

            const FilterComponentName = props.filtersMapping[filter.name].filterComponent;

            return <FilterComponentName
                key={filter.name}
                name={filter.name}
                label={generateFilterLabel(filter.name)}
                tagLabelSuffix={generateTagLabelSuffix(filter.name)}
                options={filter.options}
                max={filter.max}
                min={filter.min}
                minAriaLabel={filter.minAriaLabel ?? generateMinMaxAriaLabel(filter.name, 'min')}
                maxAriaLabel={filter.maxAriaLabel ?? generateMinMaxAriaLabel(filter.name, 'max')}
                activeMax={filter.activeMax}
                activeMin={filter.activeMin}
                storeConfig={props.storeConfig}
                dispatch={props.dispatch}
            />;
        })
    );

    const getFilterLabel = (filterName: string): { text: string, ns: string}|null => { 
        switch (filterName) {
            case FilterNames.CAPACITY:
                return { text: 'Engine size', ns: 'search-page-filter' };
            case FilterNames.YEAROFCONSTRUCTION:
                return { text: 'Year of manufacture', ns: 'bike-db' };
            default:
                return;
        }
    }

    const generateFilterLabel = (filterName: string): string => {
        const label = getFilterLabel(filterName);
        return label ? translate(label.text, { ns: label.ns }) : '';
    };

    const generateMinMaxAriaLabel = (filterName: string, type: 'min'|'max'): string => {
        const typeText = type === 'min' ? "Minimum" : "Maximum";
        const label = getFilterLabel(filterName);

        return label ? translate(`${typeText} ${label.text}`, { ns: 'search-page-filter' }) : '';
    };

    const generateTagLabelSuffix = (filterName: string): string|null => {
        let suffix: string|null = null;

        switch (filterName) {
            case FilterNames.CAPACITY:
                suffix = ` ${translate('cc', { ns: 'bike-db' })}`;
        }

        return suffix;
    };

    return (
        <div className={`filter-list`}>
            {displayFilters()}
        </div>
    );
};

Filters.defaultProps = {
    storeConfig: {}
};

export default Filters;
