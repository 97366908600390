import { Dispatch, Reducer, ReducerState, useCallback, useReducer, useRef } from 'react';
import LouisLogger from '../../../../utils/LouisLogger';

export function useEnhancedReducer<R extends Reducer<any, any>>(
    reducer: R,
    initialState: ReducerState<R>
): [ReducerState<R>, Dispatch<any>] {

    const [state, dispatch] = useReducer(reducer, initialState);

    const getState = useCallback(
        () => state,
        [state]
    );

    const logger = useRef(new LouisLogger('EnhancedReducer'));

    const enhancedDispatch = useCallback(
        action => {
            if (typeof action === 'function') {
                logger.current.debug('Dispatch thunk action');
                action(enhancedDispatch, getState);

                return;
            }

            logger.current.debug('Dispatch normal action', action);
            dispatch(action);
        },
        [getState, dispatch]
    )

    return [state, enhancedDispatch];
}
