export const VIEW_OPTION_GRID = 'grid';
export const VIEW_OPTION_ARTICLE = 'article';
export const CLOSE_TRANSITION_CLASS = 'close-transition';
export const SORTER_ID_SELECTOR = 'desktop_sorter';
export const PAGINATION_ID_SELECTOR = 'pagination';
export const VIEW_OPTIONS_ID_SELECTOR = 'view_options_buttons';
export const SALE_RECOMMENDATION_SLIDER_SELECTOR = 'div [data-sale-recommendation-slider="true"]';
export const ARTICLE_LIST_ID_SELECTOR = '#article_list';
export const FILTER_OPTIONS_SELECT_DEBOUNCE_TIME = 750;
export const MAX_NUMBER_OPTIONS = 10;
export const SINGLE_FILTER_TYPE = 'single';
export const MULTIPLE_FILTER_TYPE = 'multiple';
export const DEFAULT_CURRENCY = 'EUR';

export const PAGE_PARAM = 'page';
export const SORTER_PARAM = 'sort';
export const ARTICLE_VIEW_PARAM = 'article_view';
export const WGR_PARAM = 'wgr';
export const RUBRIC_ID_PARAM = 'rubric_id';
export const MAIN_CATEGORY_ID_PARAM = 'main_category_id';

export const NARROW_KEY = 'narrow';

export enum EFilterName {
    BRAND = 'brand',
    COLOUR = 'colour',
    PRICE = 'price',
    PRODUCT = 'product',
    MY_BIKE = 'mybike',
    NARROW = 'narrow',
    NAVIGATION = 'navigation',
    SIZE = 'size',
}

export enum ESliderAttribute {
    TITLE = 'data-slider-title',
    API_URL = 'data-api-url',
    TYPE = 'data-slider-type',
    ALL_ITEM_TITLE = 'data-all-item-title',
    ALL_ITEM_LINK = 'data-all-item-link',
    MINIMUM_ITEMS_FOR_DISPLAY = 'data-slider-minimum-items',
    SKELETON_NAME = 'data-slider-skeleton-name',
}

export enum ESliderType {
    DEFAULT = 'default',
    DIALOG = 'dialog',
    RECENT = 'recent',
    STATIC = 'static',
}

export enum ESliderMinimumForDisplayed {
    DEFAULT = 3,
    DIALOG = 3,
    RECENT = 1,
    STATIC = 1,
}

export enum EPaginationButtonType {
    NEXT = 'next',
    PREVIOUS = 'previous',
}
