/* eslint-disable class-methods-use-this */
import AbstractSelectFilterHandler from '../../../shop-ui/includes/filter-state/AbstractSelectFilterHandler';
import {
    IFilterStateHandler,
    ISelectFilter,
    ISelectFilterOption
} from '../../../shop-ui/components/search-page/interfaces';
import { FilterNames } from '../../constants';

export class MaterialFilterHandler extends AbstractSelectFilterHandler implements IFilterStateHandler {
    public createQueryParams(filter: ISelectFilter, params: object): void {
        params[FilterNames.MATERIAL] = filter.options
            .filter(option => option.selected)
            .map(selectedOption => selectedOption.value);
    }

    public getSelectedOptions(filter: ISelectFilter): ISelectFilterOption[] {
        return filter.options
            .filter(option => option.selected);
    }

    public resetQueryParams(params: object): void {
        params[FilterNames.MATERIAL] = [];
    }

    public updateSelectedOptions(filter: ISelectFilter, selectedValues: string[]): void {
        filter.options = filter.options.map((option: ISelectFilterOption) => {
            option.selected = selectedValues.includes(option.value);

            return option;
        });
    }

}
