import { Subject } from 'rxjs';
import IReactEvent from '../../../interfaces/article-list/IReactEvent';
import { FoundationEvent } from '../../../modules/events/foundation.event';
import { ELexiconMaterialEvent } from '../../../modules/events/lexiconMaterialEvents';
import { EWishListEvent } from '../../../modules/events/wishListEvent';
import { EMyBikeEvents } from '../../../modules/events/myBikeEvent';
import { EDialogEvents } from '../../../modules/events/dialogEvent';
import { ETooltipEvent } from '../../../modules/events/tooltip.event';
import EventBus from '../../../utils/eventBus';
import LouisLogger from '../../../utils/LouisLogger';
import { ReactFoundationEvent } from '../events/foundationEvent';
import { LexiconMaterialEvent } from '../events/lexiconMaterialEvent';
import { WishListEvent } from '../events/wishListEvent';
import { MyBikeEvent } from '../events/myBikeEvent';
import { DialogEvent } from '../events/dialogEvent';
import { TooltipEvent } from '../events/tooltipEvent';
import { AccordionEvent } from '../events/accordionEvent';
import { EAccordionEvent } from '../../../modules/events/accordion.event';
import { WindowEvent } from '../events/windowEvent';
import { ToastEvent } from '../events/toastEvent';
import { EToastEvents } from '../../../modules/events/toastEvent';
import { ScrollEvent } from '../events/scrollEvent';
import { MediaCenterEvent } from '../events/mediaCenterEvent';
import { EMediaCenterEvents } from '../../../modules/events/mediaCenterEvents';

export default class MiddlewareEventBus {
    public static getInstance(): MiddlewareEventBus {
        if (this.instance === null) {
            this.instance = new MiddlewareEventBus(EventBus.getInstance());
        }

        return this.instance;
    }

    public static setInstance(instance: MiddlewareEventBus) {
        this.instance = instance;
    }

    private static instance: MiddlewareEventBus = null;
    private static readonly EVENT_PUBLISHED_MESSAGE = 'Event published';

    private static translateEvent(event: IReactEvent<any>): IReactEvent<any> {
        switch (event.type) {
            case WishListEvent.GET_WISH_LIST_ITEMS:
                return { ...event, ...{ type: EWishListEvent.GET_WISH_LIST_ITEMS } };
            case WishListEvent.ARTICLE_TOGGLE:
                return { ...event, ...{ type: EWishListEvent.ARTICLE_TOGGLE } };
            case MyBikeEvent.TOGGLE:
                return { ...event, ...{ type: EMyBikeEvents.MY_BIKE_TOGGLE } };
            case ScrollEvent.SCROLL_TO:
                event.payload = {
                    offset: event.payload.offset,
                    target: event.payload.target,
                    elementToScroll: event.payload.elementToScroll,
                };
                return { ...event, ...{ type: EMyBikeEvents.SCROLL_TO } };
            case DialogEvent.OPEN_DIALOG:
                event.payload = document.querySelector(event.payload.id);
                return { ...event, ...{ type: EDialogEvents.OPEN_DIALOG } };
            case DialogEvent.CLOSE_DIALOG:
                event.payload = document.querySelector(event.payload.id);
                return { ...event, ...{ type: EDialogEvents.CLOSE_DIALOG } };
            case TooltipEvent.INIT:
                return { ...event, ...{ type: ETooltipEvent.INIT } };
            case AccordionEvent.INIT:
                return { ...event, ...{ type: EAccordionEvent.INIT } };
            case ToastEvent.TOAST_FADE_IN:
                return { ...event, ...{ type: EToastEvents.TOAST_FADE_IN } };
            case LexiconMaterialEvent.DISPLAY_INFORMATION:
                return { ...event, ...{ type: ELexiconMaterialEvent.DISPLAY_INFORMATION } };
            case MediaCenterEvent.RENDER_FILTERED_SLIDES:
                return { ...event, ...{ type: EMediaCenterEvents.RENDER_FILTERED_SLIDES } };
            default:
                return null;
        }
    }

    private globalEventBus: EventBus;
    private reactEventBus: Subject<IReactEvent<any>>;
    private logger: LouisLogger;

    constructor(globalEvent: EventBus) {
        this.globalEventBus = globalEvent;
        this.reactEventBus = new Subject<IReactEvent<any>>();
        this.logger = new LouisLogger('ReactEventBus');

        this.globalEventBus.subscribe(FoundationEvent.NAVIGATION_MENU_MOUSE_ENTER, this.foundationNavigationMouseEnter.bind(this));
        this.globalEventBus.subscribe('window:resize:debounced', this.windowResizeDebounce.bind(this));
        this.globalEventBus.subscribe('window:resize', this.windowResize.bind(this));
    }

    public publish = <T = any>(event: IReactEvent<T>): void => {
        this.logger.debug(MiddlewareEventBus.EVENT_PUBLISHED_MESSAGE, event.type, event.payload);

        // Publish local React event
        this.reactEventBus.next(event);

        // Publish global event
        const convertedEvent = MiddlewareEventBus.translateEvent(event);

        if (null === convertedEvent) {
            return;
        }

        this.globalEventBus.publish(convertedEvent.type, convertedEvent.payload);
    }

    public subscribe = callback => this.reactEventBus.subscribe(event => callback(event));

    private foundationNavigationMouseEnter(element: HTMLElement) {
        this.reactEventBus.next(
            {
                type: ReactFoundationEvent.NAVIGATION_MENU_MOUSE_ENTER,
                payload: { element }
            }
        );
    }

    private windowResizeDebounce(event: Event) {
        this.reactEventBus.next({
            type: WindowEvent.WINDOW_RESIZE_DEBOUNCED,
            payload: { event }
        });
    }

    private windowResize(event: Event) {
        this.reactEventBus.next({
            type: WindowEvent.WINDOW_RESIZE,
            payload: { event }
        });
    }
}
