import * as React from 'react';
import classNames from 'classnames';
import BikeTypeFilterOptions from './BikeTypeFilterOptions';
import Accordion from '../../../../../shop-ui/components/Accordion';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import useGradient from '../../../../../shop-ui/components/search-page/hooks/useGradient';
import useSingleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useSingleSelectFilter';
import useSearchable from '../../../../../shop-ui/components/search-page/hooks/useSearchable';
import { Subject } from 'rxjs';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const searchInputObserver = new Subject<string>();

const prioritizedOptionValue: string = 'Motorcycle';

const BikeTypeFilter: React.FunctionComponent<IFilterProps> = props => {
    const sortFunction = (option, nextOption): number => {
        if (option.value === prioritizedOptionValue) {
            return -1;
        }
        if (nextOption.value === prioritizedOptionValue) {
            return 1;
        }

        return option.label.toLowerCase().localeCompare(nextOption.label.toLowerCase());
    }

    const sortedOptions = props.options.sort(sortFunction);

    const [options, , displayNoMatchText] = useSearchable(sortedOptions, searchInputObserver);

    const [isScrollable, onGradientChange, displayTopGradient, displayBottomGradient] = useGradient(options);

    const selectedOptions = props.options
        .filter(option => option.selected)
        .sort(sortFunction);

    const [onToggleItem, displaySelectedFilterOptions] = useSingleSelectFilter(props.name, selectedOptions, props.dispatch);

    const additionalClassNames = classNames(
        { scrollable: isScrollable },
    );

    const { translate } = useTranslationHook();

    return (
        <Accordion
            label={translate('Type of vehicle', { ns: 'search-page-filter'})}
            headerContent={displaySelectedFilterOptions()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className={`biketype-filter`}>
                {displaySelectedFilterOptions()}
                <div className={`scroll-selection`}>
                    <BikeTypeFilterOptions
                        additionalClassName={additionalClassNames}
                        optionList={options}
                        onScrollChange={onGradientChange}
                        onToggleItem={onToggleItem}
                        isKeyboardFocusable={isScrollable}
                    />

                    {displayTopGradient()}
                    {displayBottomGradient()}
                </div>
                {displayNoMatchText()}
            </div>
        </Accordion>
    );
};

export default BikeTypeFilter;
