import EventBus from 'PyzShopUi/scripts/utils/eventBus';
import {EDataSwitchEvents} from 'PyzShopUi/scripts/modules/events/dataSwitchEvent';
import IModuleOptions from 'PyzShopUi/scripts/interfaces/IModuleOptions';

class DataSwitchShippingAddressPublisher {
    private element: HTMLElement;
    private readonly isInitialCall: boolean = true;

    public constructor(element: HTMLElement, options: IModuleOptions) {
        this.element = element;
        this.addEventListener();
        this.onChange(this.isInitialCall);
        this.isInitialCall = false;
    }

    private addEventListener = (): void => {
        this.element.addEventListener('change', (): void => {
            this.onChange(this.isInitialCall);
        });
    }

    private onChange = (initialCall: boolean): void => {
        if (this.element.checked) {
            if (!initialCall) {
                document.querySelectorAll('[name="customer_address_default_shipping_address_form[is_default_shipping]"]').forEach((element: HTMLElement) => {
                    if (!(element == this.element)) {
                        element.disabled = true;
                        element.checked = false;
                    }
                });
                const loadingContainer: HTMLElement = document.querySelector('.loading');
                loadingContainer.classList.remove('hide');
                loadingContainer.classList.add('loading-show');
                this.element.closest('[name="customer_address_default_shipping_address_form"]').submit();
            } else {
                this.element.disabled = true;
            }
        }
    }
}
export default class DefaultShippingAddressSelectHandler {
    public static init = (eventBus: EventBus) => {
        DefaultShippingAddressSelectHandler.eventBus = eventBus;
        eventBus.subscribe(EDataSwitchEvents.INIT, DefaultShippingAddressSelectHandler.initFormContainer);
        eventBus.publish(EDataSwitchEvents.INIT, document.querySelector('body'));
    }

    private static eventBus: EventBus;

    private static initFormContainer = (container: HTMLElement): void => {
        const additionalOptions: IModuleOptions = {
            eventBus: DefaultShippingAddressSelectHandler.eventBus,
        };
        container.querySelectorAll('[name="customer_address_default_shipping_address_form[is_default_shipping]"]').forEach((dataSwitchElement: HTMLElement) => {
            new DataSwitchShippingAddressPublisher(dataSwitchElement, additionalOptions);
        });
    }
}
