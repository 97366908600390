import * as React from "react";
import { SwiperOptions } from 'swiper';
import DefaultSliderItem from "PyzShopUi/scripts/product-slider/components/types/default-slider/DefaultSliderItem";
import {withSwiper} from "PyzShopUi/scripts/product-slider/hoc/withSwiper";
import SliderContent from "PyzShopUi/scripts/product-slider/components/content/SliderContent";
import {ESliderMinimumForDisplayed} from "PyzShopUi/scripts/product-slider/constants";
import {ISliderDataUpdatedPayload} from "PyzShopUi/scripts/product-slider/interfaces";
import {ProductSliderEvent} from "PyzShopUi/scripts/product-slider/events";
import MiddlewareEventBus from "PyzShopUi/scripts/react/common/lib/MiddlewareEventBus";
import {ISliderProps} from "PyzShopUi/scripts/main-rubric-page/interfaces";


const Slider:React.FunctionComponent<ISliderProps> = ({ items, storeConfig, skeletonName, children, useLazyLoad }) => {
    //send rubric updated event
    MiddlewareEventBus.getInstance().publish<ISliderDataUpdatedPayload>(
        {
            type: ProductSliderEvent.PRODUCT_SLIDER_DATA_UPDATED,
            payload: {
                skeletonName,
            },
        },
    );
    if (items.length < 1 || items.length < ESliderMinimumForDisplayed.STATIC) {
        return null;
    }
    return (
        <>
            <SliderContent title="" allItemTitle="" allItemLink="" items={items} storeConfig={storeConfig}>
                {children}
            </SliderContent>
        </>
    );
}

const swiperOptions: SwiperOptions = {
    shortSwipes: false,
    longSwipesRatio: 0.2,
    longSwipesMs: 100,
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 24,
    breakpoints: {
        640: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10,
        },
        1024: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 16,
        },
    },
};

export default withSwiper(
    swiperOptions,
    DefaultSliderItem,
    null,
    null,
    false
)(Slider);
