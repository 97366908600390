import {IVariantOption} from 'PyzShopUi/scripts/product-detail-page/interfaces';

export default class ProductVariantDataHandler {
    public static init = (): any => {
        const variantListRows = document.querySelectorAll('.article-all-variants [data-concrete-id]');
        const variantData = {};

        Array.from(variantListRows).forEach((row: HTMLElement) => {
            variantData[row?.dataset?.concreteId] = ProductVariantDataHandler.getArticleVariant(row);
        });

        return variantData;
    }

    public static getArticleFilterVariants = (): any => {
        const articleMainInfoElement: HTMLElement = document.querySelector('.article-main-info');
        const productFilterElement: HTMLElement = articleMainInfoElement.querySelector('div [data-spryker-product-filter="true"]');

        if (!productFilterElement) {
            return;
        }
        const variantMap = JSON.parse(productFilterElement.dataset.variants);
        const variantListRows = Object.entries(variantMap);
        const variantData = {};

        variantListRows.forEach(([concreteId, entry]) => {
            variantData[concreteId] = entry;
        });

        return variantData;
    }

    public static getArticleAllVariants = (): Map<string, IVariantOption> => {
        const variantListRows = document.querySelectorAll('.article-all-variants [data-concrete-id]');
        const variantMap: Map<string, IVariantOption> = new Map();

        Array.from(variantListRows).forEach((row: HTMLElement) => {
            const variant = ProductVariantDataHandler.getArticleVariant(row);
            variantMap.set(row?.dataset?.concreteId.toString(), variant);
        });

        return variantMap;
    }

    protected static getArticleVariant = (row: HTMLElement): IVariantOption => {
        const variantNameElement: HTMLElement = row.querySelector('[data-variant-name]');
        const variantSkuElement: HTMLElement = row.querySelector('[data-sku]');
        const variantOptionNameElement: HTMLElement = row.querySelector('[data-variant-option-name]');

        return {
            variantName: variantNameElement?.dataset?.variantName,
            fullName: variantNameElement?.innerText,
            sku: variantSkuElement?.dataset?.sku,
            optionSelectName: variantOptionNameElement?.dataset?.variantOptionName,
        };
    }
}
