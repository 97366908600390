import * as React from 'react';

interface FootnoteContentProps {
    index: number;
    children?: React.ReactNode;
}

const FootnoteContent: React.FunctionComponent<FootnoteContentProps> = props => (
    <p className="price-note">
        <sup className="general-vat">{props.index}</sup>
        <span className="price-note-sub">{props.children}</span>
    </p>
)

export default FootnoteContent;
