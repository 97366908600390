export enum ESliderAttribute {
    TITLE = 'data-slider-title',
    API_URL = 'data-api-url',
    TYPE = 'data-slider-type',
    ALL_ITEM_TITLE = 'data-all-item-title',
    ALL_ITEM_LINK = 'data-all-item-link',
    MINIMUM_ITEMS_FOR_DISPLAY = 'data-slider-minimum-items',
    SKELETON_NAME = 'data-slider-skeleton-name',
}

export enum ESliderType {
    DEFAULT = 'default',
    RECENT = 'recent',
}

export enum ESliderMinimumForDisplayed {
    DEFAULT = 3,
    DIALOG = 3,
    RECENT = 1,
    STATIC = 1,
}

export const SLIDER_PLACEMENT_IMAGE_DIRECT = [
    'home_page.recs_2', // home recommendation
    'generic_page.recs_1', // top sale page
    'category_page.main_rec_1', // rubrik
    'category_page.main_rec_2',
    'category_page.main_rec_3',
    'category_page.main_rec_4',
];
