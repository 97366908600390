import Utils from '../utils/utils';

export default abstract class AToggleContainer {
    private static CLASSES = {
        SELECTED_VALUE_CLASS: 'selected-items',
    };

    protected setSelectedValueInHeader = (content: string[], container: HTMLElement, maxTextLength?: number, target: string = '.accordion-icon'): void => {
        const targetElement: HTMLElement = container.querySelector(target);
        if (!targetElement) {
            return;
        }

        container.querySelectorAll(`.${AToggleContainer.CLASSES.SELECTED_VALUE_CLASS}`).forEach((element: HTMLElement) => { element.remove(); });

        const selectionText = Utils.truncateText(content.join(', '), maxTextLength);
        const newElement = document.createElement('div');
        newElement.classList.add(AToggleContainer.CLASSES.SELECTED_VALUE_CLASS);
        newElement.innerText = selectionText;

        targetElement.insertAdjacentElement('afterend', newElement);
    }
}
