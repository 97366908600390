import * as React from 'react';
import { createRoot } from 'react-dom/client';
import MiddlewareEventBus from '../react/common/lib/MiddlewareEventBus';
import Slider from './components/Slider';
import { ESliderAttribute, SLIDER_PLACEMENT_IMAGE_DIRECT } from './constants';

export default class ProductSliderHandler {
    public static init = (): void => {
        const sliders: NodeListOf<HTMLElement> = document.querySelectorAll('div [data-spryker-slider="true"]');

        if (!sliders.length) {
            return;
        }

        sliders.forEach(slider => {
            ProductSliderHandler.initSingle(slider);
        });
    }

    public static initSingle = (sliderElement: Element) => {
        const sliderElementRoot = createRoot(sliderElement);
        const middlewareEventBus = MiddlewareEventBus.getInstance();

        const title = sliderElement.getAttribute(ESliderAttribute.TITLE);
        const type = sliderElement.getAttribute(ESliderAttribute.TYPE);
        const allItemTitle = sliderElement.getAttribute(ESliderAttribute.ALL_ITEM_TITLE) || null;
        const allItemLink = sliderElement.getAttribute(ESliderAttribute.ALL_ITEM_LINK) || null;
        const apiUrl = new URL(sliderElement.getAttribute(ESliderAttribute.API_URL), window.location.origin);
        const minimumItemsForDisplay = sliderElement.getAttribute(ESliderAttribute.MINIMUM_ITEMS_FOR_DISPLAY) || 0;
        const skeletonName = sliderElement.getAttribute(ESliderAttribute.SKELETON_NAME);

        const params = new URLSearchParams(apiUrl.search);
        const placement = params.get('placement');
        let useLazyLoadOnInit = true;
        if (placement && SLIDER_PLACEMENT_IMAGE_DIRECT.includes(placement)) {
            useLazyLoadOnInit = false
        }

        sliderElementRoot.render(
            <Slider
                apiUrl={apiUrl}
                allItemLink={allItemLink}
                allItemTitle={allItemTitle}
                middlewareEventBus={middlewareEventBus}
                title={title}
                type={type}
                minimumItemForDisplay={+minimumItemsForDisplay}
                skeletonName={skeletonName}
                useLazyLoadOnInit={useLazyLoadOnInit}
            />
        );
    }

    public static destroySlider = (sliderElement: Element) => {
        createRoot(sliderElement).unmount();
    }
}
