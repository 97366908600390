import * as React from 'react';
import FootnoteContent from 'PyzShopUi/scripts/footnote/components/FootnoteContent';
import {IExtraInformation, IFootnoteDepositContent} from 'PyzShopUi/scripts/footnote/interfaces';
import { IFootnote } from 'PyzShopUi/scripts/shop-ui/interfaces';
import {
    FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE,
    FootnoteValues
} from 'PyzShopUi/scripts/footnote/constants';
import FootnoteService from 'PyzShopUi/scripts/shop-ui/services/FootnoteService';

interface FootnoteProps {
    footnotes: IFootnote[];
    extraInformation: IExtraInformation;
}

const Footnote: React.FunctionComponent<FootnoteProps> = props => {
    const {footnotes, extraInformation} = props;
    const footnoteService = FootnoteService.getInstance();

    const displayFootnotes = () => footnotes.map((footnote: IFootnote) => {
        const contents = footnoteService.getContentByNamespace(footnote.identifier);

        switch (footnote.identifier) {
            case FootnoteValues.DEPOSIT:
                return getDepositContent(footnote, contents);
            case FootnoteValues.GENERAL_VAT:
            case FootnoteValues.UVP:
            case FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE:
                return getFootNoteDefaultContent(footnote, contents);
            default:
                return '';
        }
    });

    const getFootNoteDefaultContent = (footnote: IFootnote, contents: object) => {
        return (
            <FootnoteContent index={footnote.index} key={footnote.identifier}>
                {Object.values(contents).map((htmlContent, index) => {
                    return [
                        index > 0 ? <br key={'br' + index} /> : '',
                        <span key={index} dangerouslySetInnerHTML={{__html: htmlContent}} />
                    ];
                })}
            </FootnoteContent>
        );
    };

    const getDepositContent = (footnote: IFootnote, contents: IFootnoteDepositContent) => (
        <FootnoteContent index={footnote.index} key={footnote.identifier}>
            <React.Fragment>
                <span className="bold">
                    {contents.batterChargeContent}
                </span>
                &nbsp;
                {contents.batterValueAndDepositRefundContent && (
                    <span dangerouslySetInnerHTML={{
                        __html: contents.batterValueAndDepositRefundContent
                    }} />
                )}
            </React.Fragment>
        </FootnoteContent>
    );

    return (
        <React.Fragment>
            {displayFootnotes()}
        </React.Fragment>
    );
};

export default Footnote;
