import axios, { AxiosResponse } from 'axios';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { LexiconMaterialConstants } from '../constants';
import { EClasses } from '../constants/lexicon-material';
import { ILexiconMaterialDisplayPayload } from '../interfaces/lexicon-material/ILexiconMaterialDisplayPayload';
import IModuleOptions from '../interfaces/IModuleOptions';
import LexiconMaterialContent from '../react/common/components/LexiconMaterialContent';
import ILexiconMaterial from '../interfaces/lexicon-material/ILexiconMaterial';
import EventBus from '../utils/eventBus';
import { EDialogEvents } from './events/dialogEvent';
import { ELexiconMaterialEvent } from './events/lexiconMaterialEvents';

class LexiconMaterial {
    private static DEFAULTS = {
        DATA_MATERIAL_ID: 'data-material-id',
        DIALOG_TITLE_CSS_SELECTOR: '.mdc-dialog__title span',
        DIALOG_CONTENT_CSS_SELECTOR: '.mdc-dialog__content',
    };

    private LEXICON_MATERIAL_API = '/api/lexicon/v1/material';

    private eventBus: EventBus;
    private dialogElement: HTMLElement;

    constructor(options: IModuleOptions, dialogElement: HTMLElement) {
        this.eventBus = options.eventBus;
        this.dialogElement = dialogElement;

        this.addEventListener();
    }

    public addEventListener = (): void => {
        this.eventBus.subscribe(ELexiconMaterialEvent.INIT, (element: HTMLElement) => {
            element.onclick = () => {
                const materialId = element.getAttribute(LexiconMaterial.DEFAULTS.DATA_MATERIAL_ID);
                this.displayDialogInformation(+materialId);
            };
        });

        this.eventBus.subscribe(ELexiconMaterialEvent.DISPLAY_INFORMATION, (payload: ILexiconMaterialDisplayPayload) => {
            this.displayDialogInformation(payload.lexiconMaterialId);
        });

        this.eventBus.subscribe(EDialogEvents.DIALOG_CLOSED, (target: HTMLElement) => {
            if (target.getAttribute('id') === LexiconMaterialConstants.LEXICON_MATERIAL_DIALOG_ID) {
                const dialogTitle = this.dialogElement.querySelector(LexiconMaterial.DEFAULTS.DIALOG_TITLE_CSS_SELECTOR);
                dialogTitle.replaceChild(document.createTextNode(''), dialogTitle.firstChild);
                createRoot(this.dialogElement.querySelector(LexiconMaterial.DEFAULTS.DIALOG_CONTENT_CSS_SELECTOR)).unmount();
            }
            if (target.getAttribute('id') === 'add-to-cart-dialog') {
                const orderVariantButton = document.querySelector("#order-variant-button") as HTMLButtonElement;
                orderVariantButton.disabled = false;
            }
        });

        document.querySelectorAll(`.${EClasses.LEXICON_MATERIAL_LINK}`).forEach((item: HTMLElement) => {
            this.eventBus.publish(ELexiconMaterialEvent.INIT, item);
        });
    }

    private displayDialogInformation = (lexiconMaterialId: number): void => {
        this.dialogElement.classList.add(EClasses.IS_LOADING);

        this.eventBus.publish(EDialogEvents.OPEN_DIALOG, { id: LexiconMaterialConstants.LEXICON_MATERIAL_DIALOG_ID });

        this.fetchLexiconMaterialDataFromAPI(lexiconMaterialId).then(result => {
            this.dialogElement.classList.remove(EClasses.IS_LOADING);

            if (result === null) {
                this.eventBus.publish(EDialogEvents.CLOSE_DIALOG, { id: `#${LexiconMaterialConstants.LEXICON_MATERIAL_DIALOG_ID}` });

                return;
            }

            const dialogTitle = this.dialogElement.querySelector(LexiconMaterial.DEFAULTS.DIALOG_TITLE_CSS_SELECTOR);
            dialogTitle.replaceChild(document.createTextNode(result.info.title), dialogTitle.firstChild);
            const lexiconMaterialContent = React.createElement(
                LexiconMaterialContent,
                {
                    content: result.info.text,
                    references: result.references,
                },
            );
            createRoot(this.dialogElement.querySelector(LexiconMaterial.DEFAULTS.DIALOG_CONTENT_CSS_SELECTOR)).render(lexiconMaterialContent);
        });
    }

    private fetchLexiconMaterialDataFromAPI = (materialId: number): Promise<ILexiconMaterial> => {
        const apiUrl = new URL(this.LEXICON_MATERIAL_API + '/' + materialId, window.location.origin);
        return axios.get(apiUrl.toString())
            .then(
                (response: AxiosResponse<ILexiconMaterial>) => response.data,
            )
            .catch(
                _ => null,
            );
    }
}

export default class LexiconMaterialHandler {
    public static init(eventBus: EventBus): void {
        const dialogElement: HTMLElement = document.querySelector(`#${LexiconMaterialConstants.LEXICON_MATERIAL_DIALOG_ID}`);

        if (dialogElement) {
            const additionalOptions: IModuleOptions = {
                eventBus
            };

            new LexiconMaterial(additionalOptions, dialogElement);
        }
    }
}
