import { Reducer } from 'react';
import { Action } from '../../shop-ui/includes/enhanced-reducer';
import { updateCatalogSearchPageState } from '../actions/catalogSearchPage';
import { ICatalogSearchPageActionState } from '../interfaces';
import { doUpdateCatalogSearchPageState } from './catalogSearch';

export const catalogSearchPageReducer: Reducer<ICatalogSearchPageActionState, Action<any>> = (state, action) => {
    switch (action.type) {
        case updateCatalogSearchPageState.type:
            return doUpdateCatalogSearchPageState(state, action.payload);
        default:
            return state;
    }
}
