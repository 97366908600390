import { PARAM_PER_PAGE } from '../constants';
import { ISelectOption } from '../../../interfaces';
import { ISetPerPagePayload, setPerPage } from '../actions/perPage';

function usePerPageSelect(itemsPerPageOptions: number[], dispatch: React.Dispatch<any>): [ISelectOption[], (value: string) => void] {
    const perPageSelectOptions: ISelectOption[] = [];

    itemsPerPageOptions.forEach(limit => {
        perPageSelectOptions.push({
            value: limit.toString(),
            label: limit.toString()
        });
    });

    const onPerPageSelect = (value: string) => {
        const selectedApiQueryData: ISetPerPagePayload = {
            [PARAM_PER_PAGE]: value
        }

        dispatch(setPerPage(selectedApiQueryData));
    }

    return [perPageSelectOptions, onPerPageSelect];
}

export default usePerPageSelect;
