import {
    CONSENT_CHANGED_EVENT_NAME,
} from "../../../../../CookieNoticeWidget/Theme/default/components/molecules/cookie-notice/cookie-notice";
import {isCookieNoticeAccepted} from "PyzShopUi/scripts/shop-ui/services/GoogleTagManagerService";
import * as Sentry from "@sentry/browser"

const EMBED_SRC_ATTRIBUTE = 'data-embed-src';

/**
 * Handle user consent regarding cookies and 3rd party content for CMS Vimeo embeds in VideoWithText content elements.
 *
 * If the user has given consent this function will enhance the element to be played in a lightbox on the page.
 *
 * See: NEOS Project `neos/DistributionPackages/Louis.Site/Resources/Private/Fusion/Presentation/Component/VideoWithText/VideoWithText.fusion`
 */
function init() {
    // WHY: [a11y] Respect user's preference for reduced motion
    const reducedMotionRequested = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

    if (reducedMotionRequested) {
        return;
    }

    document.querySelectorAll('.cms_video-with-text__video').forEach(videoContainer => {
        try {
            const existingIframe = videoContainer.querySelector('iframe');
            const inBackend = document.body.classList.contains('neos-backend');

            // abort if cookie notice is not accepted and not in backend
            if (!isCookieNoticeAccepted() && !inBackend) {
                // remove already existing iframe
                if (existingIframe) {
                    existingIframe.remove();
                }

                return;
            }

            if (existingIframe) {
                // edge-case: iframe already exists -> do nothing
                return;
            }

            const embedSrc = videoContainer.getAttribute(EMBED_SRC_ATTRIBUTE);

            const iframe = document.createElement('iframe');
            iframe.setAttribute('id', "vimeo-player");
            iframe.setAttribute('loading', 'lazy');
            iframe.setAttribute('src', embedSrc);
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allow', 'autoplay;');
            iframe.setAttribute('title', 'vimeo-player');

            videoContainer.appendChild(iframe);
        } catch (e) {
            Sentry.captureException(e);
        }
    });
}

// register event listender for user consent change
window.addEventListener(CONSENT_CHANGED_EVENT_NAME, init);

export default {
    init,
}
