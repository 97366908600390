import * as React from 'react';
import Footer from '../../../shop-ui/components/search-page/components/footer/Footer';
import { IApiQueryData, ISearchResponse } from '../../../shop-ui/components/search-page/interfaces';
import { doUpdateCatalogSearchPageData } from '../../actions/catalogSearchPage';
import { IStoreSearchResultItem } from '../../interfaces';
import StoreResultItem from './StoreResultItem';
import Header from '../../../shop-ui/components/search-page/components/header/Header';
import { VIEW_MODE_OPTIONS } from '../../../catalog-page/constants';
import { numberFormat } from '../../../shop-ui/includes/number';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IStoreResultProps {
    defaultParameter: IApiQueryData;
    searchResponse?: ISearchResponse;

    dispatch: React.Dispatch<any>;
}

const StoreResult: React.FunctionComponent<IStoreResultProps> = props => {
    const { searchResponse, defaultParameter, dispatch } = props;
    const { pagination, resultItems } = searchResponse;
    const { translate } = useTranslationHook();

    const customPaginationHandler = (newPage: string) => dispatch(doUpdateCatalogSearchPageData(defaultParameter, { page: newPage }));

    const itemPerPageOptions: number[] = pagination?.config?.validItemsPerPageOptions || [];
    const currentItemsPerPage: string = pagination?.currentItemsPerPage?.toString() || '';
    const currentPage: number = pagination?.currentPage || 0;
    const maxPage: number = pagination?.maxPage || 0;
    const pageNumFound: number = pagination?.numFound || 0;

    const totalSearchResultItemsText: string = translate(
        'store',
        {
            count: pageNumFound,
            ns: 'search'
        }
    );

    const displayResultHeader = () => {
        if (pagination.numFound === 0) {
            return null;
        }

        return (
            <Header
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                totalSearchResultItemsText={totalSearchResultItemsText}
                sortParamNames={[]}
                currentSortParam={''}
                renderSortSelection={false}
                renderPerPageSelection={true}
                renderViewModeSwitch={false}
                availableViewModes={VIEW_MODE_OPTIONS}
                dispatch={dispatch}
            />
        );
    };

    const displayResultFooter = () => {
        if (pagination.numFound === 0) {
            return null;
        }

        return (
            <Footer
                perPageSelectText={translate('Stores per page', { ns: 'search'})}
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                currentPage={currentPage}
                renderPerPageSelection={true}
                maxPage={maxPage}
                customPagination={customPaginationHandler}
                dispatch={dispatch}
            />
        );
    };

    const displayResultItems = () => resultItems.map((resultItem: IStoreSearchResultItem) =>
        <StoreResultItem key={resultItem.idCmsPage} store={resultItem} />
    );

    return (
        <div className="store-tab page-layout-search-items-result">
            <div className="result-header grid-padding-y">
                {displayResultHeader()}
            </div>

            <div className="store-result">
                {displayResultItems()}
            </div>

            <div className="result-footer">
                {displayResultFooter()}
            </div>
        </div>
    );
};

export default StoreResult;
