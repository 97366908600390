import EventBus from "PyzShopUi/scripts/utils/eventBus";
import {WindowEvent} from "PyzShopUi/scripts/react/common/events/windowEvent";
import i18next from 'i18next';

const DATA_ATTRIBUTE_IS_ACTIVE = 'data-is-active';
const DATA_ATTRIBUTE_IS_COLLAPSED = 'data-is-collapsed';

const BUTTON_TEXT_SHOW_MORE = 'Show more';
const BUTTON_TEXT_SHOW_LESS = 'Show less';

/**
 * This is a re-implementation of the Read More component.
 * It takes the basic style of the existing one but behaves differently.
 *
 * WHY: To prevent breaking changes in `ReadMore` throughout the system.
 *
 * This class enhances a html component that has the following structure:
 *      ```html
 *          <div
 *              class="cms-read-more"
 *              data-read-more-max-line="8"
 *              data-is-collapsed="true"
 *              data-is-active="true"
 *          >
 *              <div class="cms-read-more__container">
 *                  <div class="cms-read-more__content">
 *                      // content here
 *                  </div>
 *                  <div class="cms-read-more__gradient"></div>
 *              </div>
 *              <span role="button" class="cms-read-more__button"></span>
 *          </div>
 *      ```
 */
class CmsReadMore {
    private expandedHeight: number;
    private collapsedHeight: number;

    private element: HTMLDivElement;

    constructor(el: HTMLDivElement, globalEventBus: EventBus) {
        this.element = el;

        // init button
        el.querySelector('.cms-read-more__button').addEventListener('click', () => {
            if (this.isCollapsed()) {
                this.expand();
            } else {
                this.collapse();
            }

            this.update();
        });

        // update on resize
        globalEventBus.subscribe(WindowEvent.WINDOW_RESIZE_DEBOUNCED, () => {
            this.update();
        });

        // first render
        this.update();

        this.element.setAttribute('data-initialized', 'true');
    }

    // update state
    private update() {
        if (this.shouldBeActive()) {
            this.activate();

            if (this.isCollapsed()) {
                this.collapse();
            }
        } else {
            this.deactivate();
        }

        this.render();
    }

    // change dom depending on state
    private render() {
        const containerElement = this.element.querySelector('.cms-read-more__container') as HTMLDivElement;
        const buttonElement = this.element.querySelector('.cms-read-more__button') as HTMLSpanElement;

        if (this.isActive() && this.isCollapsed()) {
            containerElement.style.maxHeight = this.collapsedHeight + 'px';
            buttonElement.innerText = i18next.t(BUTTON_TEXT_SHOW_MORE, { ns: 'layout' });
        } else {
            containerElement.style.maxHeight = this.expandedHeight + 'px';
            buttonElement.innerText = i18next.t(BUTTON_TEXT_SHOW_LESS, { ns: 'layout' });
        }
    }

    // determine if ShowMore is necessary
    // If max lines * lineHeight would be higher or equal the height of the element itself, we don't need the show more functionality.
    shouldBeActive() {
        const contentElement = this.element.querySelector('.cms-read-more__content') as HTMLDivElement;
        const maxLines = parseInt(this.element.getAttribute('data-read-more-max-line'));
        const lineHeight = parseInt(window.getComputedStyle(contentElement).lineHeight);

        this.collapsedHeight = maxLines * lineHeight;
        this.expandedHeight = contentElement.clientHeight;

        return this.collapsedHeight < this.expandedHeight;
    }

    // state lives on DOM element
    private isActive() {
        return this.element.getAttribute(DATA_ATTRIBUTE_IS_ACTIVE) === 'true';
    }

    private isCollapsed() {
        return this.element.getAttribute(DATA_ATTRIBUTE_IS_COLLAPSED) === 'true';
    }

    private activate() {
        this.element.setAttribute(DATA_ATTRIBUTE_IS_ACTIVE, 'true');
    }

    private deactivate() {
        this.element.setAttribute(DATA_ATTRIBUTE_IS_ACTIVE, 'false');
    }

    private expand() {
        this.element.setAttribute(DATA_ATTRIBUTE_IS_COLLAPSED, 'false');
    }

    private collapse() {
        this.element.setAttribute(DATA_ATTRIBUTE_IS_COLLAPSED, 'true');
    }
}

export default class CmsReadMoreHandler {

    public static init(globalEventBus: EventBus) {
        document.querySelectorAll('.cms-read-more').forEach((el: HTMLDivElement) => {
            new CmsReadMore(el, globalEventBus);
        });
    }
}
