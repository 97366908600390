import { ClickEventWithTracking, IBaseHandler } from './listener';
import defAddLanguageSlugToUrl from '../functions/languageSlug';

export const CLICK_TRACKING_PRODUCT_CLICK_EVENT = 'ProductClickTracking';
export const CLICK_TRACKING_ATTRIBUTE = 'data-clicktrackingparams';
export const CLICK_TRACKING_API_URL = 'reco/clicktracking/track-click';

const ProductClickTracking: IBaseHandler = (event: ClickEventWithTracking) => {
    const apiClickTracking = new URL(defAddLanguageSlugToUrl('/' + CLICK_TRACKING_API_URL), window.location.origin);
    const clickTrackingData = JSON.parse(event.payload.getAttribute(CLICK_TRACKING_ATTRIBUTE));
    Object.keys(clickTrackingData).forEach(value => {
        apiClickTracking.searchParams.append(value, clickTrackingData[value]);
    });
    const img = new Image();
    img.src = apiClickTracking.toString();
};

const clickTrackingHandler = {
    [CLICK_TRACKING_PRODUCT_CLICK_EVENT]: ProductClickTracking,
};

export const handler = clickTrackingHandler;
