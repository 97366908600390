import * as React from 'react';
import Loading from '../../../../react/common/components/Loading';
import { IStoreConfig } from '../../../../shop-ui/components/search-page/interfaces';
import BrandResultGrid from './grid-view/BrandResultGrid';
import { IBrandItem } from '../../../../shop-ui/interfaces';

interface IBrandListProps {
    brands: IBrandItem[];
    isProgressing: boolean;
    storeConfig: IStoreConfig;
    hasActiveFilter: boolean;
    isCompactMode: boolean;
    viewMode?: string;
}

const BrandList: React.FunctionComponent<IBrandListProps> = props => {
    const { brands, isProgressing, storeConfig, isCompactMode } = props;

    if (!brands) {
        return null;
    }

    if (isProgressing) {
        return <Loading />;
    }

    return <BrandResultGrid brands={brands} storeConfig={storeConfig} isCompactMode={isCompactMode} />;
};

export default BrandList;
