import { createRoot } from 'react-dom/client';
import * as React from 'react';
import SearchSuggestionContainer from './containers/SearchSuggestionContainer';
import { searchValueObserver } from './includes/searchSuggestionHelper';
import { MIN_SEARCH_STRING_LENGTH_TO_GET_DATA } from './constants';
import MiddlewareEventBus from '../react/common/lib/MiddlewareEventBus';
import { WindowEvent } from '../react/common/events/windowEvent';
import IReactEvent from '../interfaces/article-list/IReactEvent';
import { BREAKPOINTS } from '../config';
import { MDCTextField } from '@material/textfield';
import MaterialDesignComponentHandler from '../modules/materialDesignComponents';

class SearchSuggestionRendering {
    private isVisible: boolean = false;
    private searchValue: string = '';
    private isSmallScreen: boolean = BREAKPOINTS.medium();

    private readonly searchSuggestionElement: HTMLElement;
    private readonly searchInputElement: HTMLInputElement;
    private readonly searchFormElement: HTMLFormElement;
    private searchInputMDCTextField: MDCTextField;
    private searchSuggestionRoot;

    constructor(searchSuggestionElement: HTMLElement, searchFormElement: HTMLFormElement) {
        this.searchSuggestionElement = searchSuggestionElement;
        this.searchFormElement = searchFormElement;
        this.searchInputElement = this.searchFormElement.querySelector('#search');
        this.searchInputMDCTextField = MaterialDesignComponentHandler.getMDCTextField(this.searchInputElement) as MDCTextField;

        this.handleLargeScreenInput();
        this.handleSmallScreenOpenDialog();
        this.handleSearchValueFromObserver();

        this.subscribeToMiddlewareEventBus();
    }

    private handleLargeScreenInput = () => {
        this.searchInputElement.addEventListener('input', (event: Event) => {
            // @ts-ignore
            const inputValue: string = event.target.value;
            this.isVisible = inputValue.trim().length >= MIN_SEARCH_STRING_LENGTH_TO_GET_DATA;
            searchValueObserver.next(inputValue);
        });
        this.searchInputElement.addEventListener('focus', (event: Event) => {
            if (BREAKPOINTS.medium()) {
                return;
            }

            // @ts-ignore
            const inputValue: string = event.target.value;

            this.isVisible = inputValue.trim().length >= MIN_SEARCH_STRING_LENGTH_TO_GET_DATA;

            if (this.isVisible) {
                this.renderComponent();
            }
        });
    }

    private handleSmallScreenOpenDialog = () => {
        this.searchInputElement.addEventListener('click', (event: Event) => {
            if (!BREAKPOINTS.medium()) {
                return;
            }
            // @ts-ignore
            const inputValue: string = event.target.value;
            this.isVisible = true;
            searchValueObserver.next(inputValue);

            this.renderComponent();
        });
    }

    private handleSearchValueFromObserver = () => {
        searchValueObserver.subscribe(observedSearchValue => {
                this.searchValue = observedSearchValue.trim();
                this.searchInputMDCTextField.value = observedSearchValue;

                this.renderComponent();
            });
    }

    private subscribeToMiddlewareEventBus = () => {
        MiddlewareEventBus.getInstance().subscribe((event: IReactEvent<any>) => {
            if (event.type !== WindowEvent.WINDOW_RESIZE_DEBOUNCED) {
                return;
            }

            this.isSmallScreen = BREAKPOINTS.medium();

            this.renderComponent();
        });
    }

    private onClose = () => {
        this.isVisible = false;

        this.renderComponent();
    }

    private renderComponent = () => {

        if (!this.searchSuggestionRoot) {
            this.searchSuggestionRoot = createRoot(this.searchSuggestionElement);
        }

        this.searchSuggestionRoot.render(
            <SearchSuggestionContainer
                searchValue={this.searchValue}
                isSmallScreen={this.isSmallScreen}
                isVisible={this.isVisible}
                minSearchStringLengthToGetData={MIN_SEARCH_STRING_LENGTH_TO_GET_DATA}
                onClose={this.onClose}
                searchUrl={this.searchFormElement.action}
            />
        );
    }
}

export default class SearchSuggestionHandler {
    public static init = (): void => {
        const searchFormElement: HTMLFormElement = document.querySelector('#article-search');
        const searchSuggestionElement: HTMLElement = document.querySelector('#search-suggestion');

        if (!searchSuggestionElement || !searchFormElement) {
            return;
        }

        new SearchSuggestionRendering(searchSuggestionElement, searchFormElement);
    }
}
