import * as React from 'react';
import classNames from 'classnames';

interface IMdcTabLinkItemProps {
    tabIndex?: number;
    isMinWidth?: boolean;
    children?: React.ReactNode;
    name?: string;
    url?: string;
    isActive: boolean;

    onClick?(index: number, name: string, event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void;
}

export const MdcTabLinkItem: React.FunctionComponent<IMdcTabLinkItemProps> = props => {
    const { tabIndex, children, isMinWidth, isActive, name, url, onClick } = props;

    const onTabClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (!onClick) {
            return;
        }

        onClick(tabIndex, name, event);
    }

    const mdcTabClassName = classNames(
        'mdc-tab',
        {
            'mdc-tab--min-width': isMinWidth,
            'mdc-tab--active': isActive,
        }
    );

    const mdcTabIndicatorClassName = classNames(
        'mdc-tab-indicator',
        {
            'mdc-tab-indicator--active': isActive,
        }
    );

    //Accessibility handler for ENTER keypress when focused
    const onKeyDownHandler = (event) => {
        if (!onClick) {
            return;
        }
        if (event.key === "Enter") {
            onClick(tabIndex, name, event);
        }
    }


    return (
        <a
            onClick={onTabClick}
            onKeyDown={onKeyDownHandler}
            className={mdcTabClassName}
            tabIndex={tabIndex}
            href={url}
            role="tab"
        >
            <span className='mdc-tab__content'>
                {children}

                <span className={mdcTabIndicatorClassName}>
                    <span className='mdc-tab-indicator__content mdc-tab-indicator__content--underline'></span>
                </span>
            </span>
            <span className='mdc-tab__ripple'></span>
        </a>
    );
};

MdcTabLinkItem.defaultProps = {
    tabIndex: -1,
    isMinWidth: false,
    isActive: false,
    name: '',
    url: '',
}
