import { IFilterStateHandler, ISelectFilterOption } from '../interfaces';
import NullFilterHandler from '../handler/NullFilterHandler';
import { Filter } from '../types';

export default class FilterStateService {
    private filterTypeMapping: any;

    constructor(filterTypeMapping) {
        this.filterTypeMapping = filterTypeMapping;
    }

    public createQueryParams = (filter: Filter, params: object): any => {
        this.getFilterStateHandler(filter.name).createQueryParams(filter, params);
    }

    public getSelectedOptions = (filter: Filter): ISelectFilterOption[] => this.getFilterStateHandler(filter.name).getSelectedOptions(filter);

    public isFilterActive = (filter: Filter): boolean => this.getFilterStateHandler(filter.name).isFilterActive(filter);

    public resetQueryParams = (filterName: string, params: object): void => {
        this.getFilterStateHandler(filterName).resetQueryParams(params);
    }

    public updateSelectedOptions = (filter: Filter, selectedValues: string[] | number[]): void => {
        this.getFilterStateHandler(filter.name).updateSelectedOptions(filter, selectedValues);
    }

    public hasActiveFilter = (filters: Filter[]): boolean => {
        for (const filter of filters) {
            if (this.isFilterActive(filter)) {
                return true;
            }
        }

        return false;
    }

    private getFilterStateHandler = (filterName: string): IFilterStateHandler => this.filterTypeMapping[filterName]?.handler || new NullFilterHandler();
}
