import * as React from 'react';
import MyBikeList from '../components/MyBikeList';
import IBike from '../interfaces/IBike';
import MiddlewareEventBus from '../../common/lib/MiddlewareEventBus';
import IPagination from '../interfaces/IPagination';
import { MyBikeEvent } from '../../common/events/myBikeEvent';
import { IConvertedBikeData } from '../../../interfaces/myBike/service/IMyBikeService';
import { IBikeItem } from 'PyzShopUi/scripts/bike/interfaces';

interface IMyBikeListStates {
    imageEmptyMyBikeUrl: string;
    pagination: IPagination;
    bikes: IBike[] | IBikeItem[];
    placeholderImage: string;
    totalBikes: number;
    hasSideBar: boolean;
    csrfToken: string;
}

interface IMyBikeListProps {
    middlewareEventBus: MiddlewareEventBus;
    bikeGridComponent: React.FunctionComponent;
    initialBikeData: IConvertedBikeData;
    bikeDatabaseUrl: string;
    hasSideBar: boolean;
    csrfToken: string;
}

class MyBikeListContainer extends React.Component<IMyBikeListProps, IMyBikeListStates> {
    private readonly bikeGridComponent: React.FunctionComponent;
    private readonly bikeDatabaseUrl: string;

    public constructor(props) {
        super(props);

        this.bikeGridComponent = props.bikeGridComponent;
        this.bikeDatabaseUrl = props.bikeDatabaseUrl;

        this.state = {
            imageEmptyMyBikeUrl: props.initialBikeData.extraInformation.imageEmptyMyBikeUrl,
            totalBikes: props.initialBikeData.extraInformation.totalBikes,
            bikes: props.initialBikeData.bikes,
            pagination: props.initialBikeData.pagination,
            placeholderImage: props.initialBikeData.extraInformation.placeholderImage,
            hasSideBar: props.hasSideBar,
            csrfToken: props.csrfToken,
        };

        this.onRemove = this.onRemove.bind(this);
    }

    public componentDidMount() {
        this.props.middlewareEventBus.subscribe(event => {
            if (event.type === MyBikeEvent.TOGGLE && true === event.payload.status) {
                const pagination = this.state.pagination;
                // @ts-ignore
                const bikes = this.state.bikes.map(myBike => {
                    if (myBike.bikeId?.toString() === event.payload.articleNumber.toString()) {
                        myBike.isShown = true;
                        pagination.totalItems++;
                    }
                    if (myBike.externalId?.toString() === event.payload.articleNumber.toString()) {
                        myBike.isShown = true;
                        pagination.totalItems++;
                    }
                    return myBike;
                });

                this.setState({ bikes });
            }
        });
    }

    public render = (): React.ReactNode => (
        <MyBikeList
            imageEmptyMyBikeUrl={this.state.imageEmptyMyBikeUrl}
            totalBikes={this.state.totalBikes}
            placeholderImage={this.state.placeholderImage}
            bikes={this.state.bikes}
            pagination={this.state.pagination}
            bikeGridComponent={this.bikeGridComponent}
            onRemove={this.onRemove}
            bikeDatabaseUrl={this.bikeDatabaseUrl}
            hasSideBar={this.state.hasSideBar}
            csrfToken={this.state.csrfToken}
        />
    )

    private onRemove = (externalId: string): void => {
        const pagination = this.state.pagination;
        // @ts-ignore
        const bikes = this.state.bikes.map((myBike: any) => {
            if (myBike.bikeId?.toString() === externalId.toString()) {
                myBike.isShown = false;
            }
            if (myBike.externalId?.toString() === externalId.toString()) {
                myBike.isShown = false;
            }
            return myBike;
        });

        const visibleBikes = bikes.filter((myBike: any): boolean =>  myBike.isShown);

        pagination.totalItems = visibleBikes.length;

        this.setState({ bikes, pagination });
    }
}

export default MyBikeListContainer;
