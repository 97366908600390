import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import useViewModeSwitcher from '../../hooks/useViewModeSwitcher';
import { VIEW_MODE_GRID, VIEW_MODE_LIST } from '../../constants';
import MiddlewareEventBus from '../../../../../react/common/lib/MiddlewareEventBus';
import { FootnoteEvents } from '../../../../events/footnoteEvents';
import { EFootnoteNamespace } from '../../../../constants';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';
import { IFootnoteNamespaceResetPayload } from 'PyzShopUi/scripts/shop-ui/interfaces';
import i18next from 'i18next';

interface IHeaderViewModeProps {
    currentViewMode: string;
    availableViewModes: string[];
    dispatch: React.Dispatch<any>;
}

const HeaderViewModeComponent: React.FunctionComponent<IHeaderViewModeProps> = props => {
    const [onViewModeSwitchToGrid, onViewModeSwitchToList] = useViewModeSwitcher(props.dispatch);

    React.useEffect(() => {
        const footnoteResetEvent: IReactEvent<IFootnoteNamespaceResetPayload> = {
            type: FootnoteEvents.FOOTNOTE_RESET,
            payload: {
                name: EFootnoteNamespace.ARTICLE_PRICE,
                viewMode: props.currentViewMode,
            },
        };

        MiddlewareEventBus.getInstance().publish(footnoteResetEvent)
    }, [props.currentViewMode])

    const displayGridViewButton = () => {
        if (!props.availableViewModes.includes(VIEW_MODE_GRID)) {
            return null;
        }
        const labelGrid: string = i18next.t('catalog.view.mode.grid', { ns: 'spryker-core' });

        return (
            <button onClick={onViewModeSwitchToGrid} aria-label={labelGrid} className="grid-icon-wrapper">
                <i className={`icon icon-grid icon-only icon-size-tiny${props.currentViewMode === VIEW_MODE_GRID ? ' highlight' : ''}`}/>
            </button>
        );
    };

    const displayListViewButton = () => {
        if (!props.availableViewModes.includes(VIEW_MODE_LIST)) {
            return null;
        }
        const labelList: string = i18next.t('catalog.view.mode.list', { ns: 'spryker-core' });

        return (
            <button onClick={onViewModeSwitchToList} aria-label={labelList}>
                <i className={`icon icon-variant-list icon-only variant-icon-size${props.currentViewMode === VIEW_MODE_LIST ? ' highlight' : ''}`}/>
            </button>
        );
    };

    return (
        <div className={'view-options-buttons-container view-mode-switcher'}>
            {displayGridViewButton()}
            {displayListViewButton()}
        </div>
    );
};

const HeaderViewModeSwitcher = withMemo(HeaderViewModeComponent);

export default HeaderViewModeSwitcher;
