import { ISelectOption } from '../../../interfaces';
import { PARAM_SORT } from '../constants';
import { ISetSortPayload, setSort } from '../actions/sort';
import i18next from 'i18next';

function useSortSelect(sortParamNames: string[], dispatch: React.Dispatch<any>): [ISelectOption[], (value: string) => void]  {
    const sortSelectOptions: ISelectOption[] = [];

    sortParamNames.forEach(paramName => {
        sortSelectOptions.push({
            value: paramName,
            label: i18next.t(paramName, { ns: 'search-page-sorter' })
        })
    });

    const onSortSelect = (value: string) => {
        const payload: ISetSortPayload = {
            [PARAM_SORT]: value
        }

        dispatch(setSort(payload));
    }

    return [sortSelectOptions, onSortSelect];
}

export default useSortSelect;
