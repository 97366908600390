import * as React from 'react';
import Header from '../search-page/components/header/Header';
import Footer from '../search-page/components/footer/Footer';
import { useEnhancedReducer } from '../../includes/enhanced-reducer';
import { updateSearchPageHeadTitle } from '../search-page/includes/searchPageHelper';
import FilterStateService from '../search-page/services/FilterStateService';
import useApiQueryDataUpdate from '../search-page/hooks/useApiQueryDataUpdate';
import { VIEW_MODE_OPTIONS } from '../../../catalog-page/constants';
import { createCatalogReducer } from '../../../catalog-page/reducer';
import { initSearchPageData } from '../search-page/actions/searchPage';
import { IApiQueryData, ISearchPageActionState, ISearchResponse } from '../search-page/interfaces';
import {
    CATALOG_PAGE_CONTEXT_NAME,
    FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
} from '../search-page/constants';
import { numberFormat } from '../../includes/number';
import BrandList from '../../../catalog-page/components/catalog-page-content/brand-list/BrandList';
import { IBrandItem } from '../../interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import {DEFAULT_CURRENCY_FACTOR} from 'PyzShopUi/scripts/shop-ui/constants';

interface IBrandListProps {
    apiUrl: string;
    defaultParameter: IApiQueryData;
    filterStateService: FilterStateService;
    isCompactMode: boolean;
    searchResponse?: ISearchResponse;
}

const emptyBrandListPageState: ISearchPageActionState = {
    sort: {
        sortParamNames: [],
        currentSortParam: '',
    },
    pagination: {
        numFound: 0,
        currentPage: 1,
        maxPage: 1,
        currentItemsPerPage: 0,
        config: {
            defaultItemsPerPage: 48,
            validItemsPerPageOptions: [],
        },
    },
    searchResultItems: [],
    filters: [],
    storeConfig: {
        currency: '',
        currencyFactor: DEFAULT_CURRENCY_FACTOR,
        priceMode: 'GROSS_MODE',
    },
    extraInformation: {
        breadcrumb: null,
    },
    isPageLoading: false,
    apiQueryData: {},
    updatePageDebounceTime: FILTER_ITEM_SELECT_DEBOUNCE_TIME_MS,
    categoriesTree: [],
    navigationActiveUrl: null,
    skipUpdateBaseOnApiQueryParams: false,
    initCategoryKey: null,
    headTitle: ''
};

const BrandListContainer: React.FunctionComponent<IBrandListProps> = props => {
    const {
        filterStateService,
        apiUrl,
        defaultParameter,
        isCompactMode,
        searchResponse
    } = props;

    const [state, dispatch] = useEnhancedReducer(createCatalogReducer(props.filterStateService), emptyBrandListPageState);

    const {
        pagination,
        isPageLoading,
        storeConfig,
        apiQueryData,
        updatePageDebounceTime,
        skipUpdateBaseOnApiQueryParams,
        headTitle,
        viewMode
    } = state;

    const itemPerPageOptions: number[] = pagination?.config?.validItemsPerPageOptions || [];
    const currentItemsPerPage: string = pagination?.currentItemsPerPage?.toString() || '';
    const pageNumFound: number = pagination?.numFound || 0;
    const currentPage: number = pagination?.currentPage || 0;
    const maxPage: number = pagination?.maxPage || 0;
    const { translate } = useTranslationHook();
    const totalSearchResultItemsText: string = translate('brand', { ns: 'search', count: pageNumFound });

    useApiQueryDataUpdate(
        apiQueryData,
        dispatch,
        updatePageDebounceTime,
        filterStateService,
        skipUpdateBaseOnApiQueryParams,
        [],
        CATALOG_PAGE_CONTEXT_NAME
    );

    React.useEffect(() => {
        if (searchResponse !== null) {
            dispatch(initSearchPageData(apiUrl, defaultParameter, filterStateService, [], null, CATALOG_PAGE_CONTEXT_NAME, searchResponse));

            return;
        }

        dispatch(initSearchPageData(apiUrl, defaultParameter, filterStateService, [], null, CATALOG_PAGE_CONTEXT_NAME));
    }, []);

    React.useEffect(() => {
        if (headTitle) {
            updateSearchPageHeadTitle(headTitle);
        }
    }, [headTitle]);

    const displayResultHeader = () => {
        if (isPageLoading || pageNumFound === 0 || isCompactMode) {
            return null;
        }

        return (
            <Header
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                totalSearchResultItemsText={totalSearchResultItemsText}
                sortParamNames={[]}
                currentSortParam={''}
                renderSortSelection={false}
                renderPerPageSelection={true}
                renderViewModeSwitch={false}
                availableViewModes={VIEW_MODE_OPTIONS}
                viewMode={viewMode}
                dispatch={dispatch}
            />
        );
    };

    const displayResultFooter = () => {
        if (isPageLoading || pageNumFound === 0 || isCompactMode) {
            return null;
        }

        return (
            <Footer
                perPageSelectText={translate('Brands per page', { ns: 'search' })}
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                currentPage={currentPage}
                renderPerPageSelection={true}
                maxPage={maxPage}
                dispatch={dispatch}
            />
        );
    };

    const displayBrandList = () => {
        const { searchResultItems } = state;

        return (
            <BrandList
                brands={searchResultItems as IBrandItem[]}
                isProgressing={isPageLoading}
                storeConfig={storeConfig}
                viewMode={viewMode}
                hasActiveFilter={false}
                isCompactMode={isCompactMode}
            />
        );
    };

    return (
        <div className='brand-tab'>
            <div className='grid-x grid-padding-x'>
                <div className='cell small-12 page-layout-search-items-result'>
                    <div className='result-header grid-padding-y'>
                        {displayResultHeader()}
                    </div>

                    <div className='brand-list'>
                        {displayBrandList()}
                    </div>

                    <div className='result-footer'>
                        {displayResultFooter()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BrandListContainer;
