import { MDCTextField } from '@material/textfield';
import { MDCSelect } from '../types/MDCSelect';
import { MDCSlider } from '@material/slider';
import { MDCRadio } from '@material/radio';
import { MDCFloatingLabel } from '@material/floating-label';
import { MDCNotchedOutline } from '@material/notched-outline';
import EventBus from '../utils/eventBus';
import { MDCTextFieldCharacterCounter } from '@material/textfield/character-counter';
import { MDCTextFieldCharacterCounterFoundation } from '@material/textfield/character-counter/foundation';
import { ETabEvent } from 'PyzShopUi/scripts/modules/events/tab.event';
import { EAccordionEvent } from 'PyzShopUi/scripts/modules/events/accordion.event';

export default class MaterialDesignComponentHandler {
    private static eventBus: EventBus;
    public static textFieldArr = [];
    public static selectArr = [];
    public static sliderArr = [];
    public static radioArr = [];
    public static floatingLabelArr = [];
    public static notchedOutlineArr = [];

    public static init = (): void => {
        this.eventBus = EventBus.getInstance();
        MaterialDesignComponentHandler.initTextFields();
        MaterialDesignComponentHandler.initSelects();
        MaterialDesignComponentHandler.initSlider();
        MaterialDesignComponentHandler.initRadios();
        MaterialDesignComponentHandler.initFloatingLabel();
        MaterialDesignComponentHandler.initNotchedOutline();

        this.addEventListeners();
    }

    public static getMDCObjectForFormField = (field: HTMLInputElement, errorLogging: boolean = true): MDCTextField | MDCSelect | void => {
        //  We know that this might not find a mdc object so turn off error log.
        const mdcObject = MaterialDesignComponentHandler.getMDCTextField(field, false)
                            || MaterialDesignComponentHandler.getMDCSelect(field, false);
        if (!mdcObject) {
            if (errorLogging) {
                /* eslint-disable-next-line no-console */
                console.error('MDCTextField or MDCSelect could not be found');
            }
        } else {
            return mdcObject;
        }
    }

    public static getMDCTextField = (field: HTMLElement, errorLogging: boolean = true): MDCTextField | void => {
        for (const mdcFieldObj of MaterialDesignComponentHandler.textFieldArr) {
            if (mdcFieldObj.nativeField === field) {
                return mdcFieldObj.mdcObject;
            }
        }
        if (errorLogging) {
            /* eslint-disable-next-line no-console */
            console.error('MDCTextField could not be found');
        }
    }

    public static getMDCSelect = (field: HTMLElement, errorLogging: boolean = true): MDCSelect | void => {
        for (const mdcFieldObj of MaterialDesignComponentHandler.selectArr) {
            if (mdcFieldObj.nativeField === field) {
                return mdcFieldObj.mdcObject;
            }
        }
        if (errorLogging) {
            /* eslint-disable-next-line no-console */
            console.error('MDCSelect could not be found');
        }
    }

    public static getMDCSlider = (field: HTMLElement): MDCSlider | void => {
        for (const mdcFieldObj of MaterialDesignComponentHandler.selectArr) {
            if (mdcFieldObj.nativeField === field) {
                return mdcFieldObj.mdcObject;
            }
        }
        /* eslint-disable-next-line no-console */
        console.error('MDCSlider could not be found');
    }

    public static getMDCRadio = (field: HTMLElement): MDCRadio | void => {
        for (const mdcFieldObj of MaterialDesignComponentHandler.radioArr) {
            if (mdcFieldObj.nativeField === field) {
                return mdcFieldObj.mdcObject;
            }
        }
        /* eslint-disable-next-line no-console */
        console.error('MDCRadio could not be found');
    }

    public static getMDCFloatingLabel = (field: HTMLElement): MDCFloatingLabel | void => {
        for (const mdcFieldObj of MaterialDesignComponentHandler.floatingLabelArr) {
            if (mdcFieldObj.nativeField === field) {
                return mdcFieldObj.mdcObject;
            }
        }
        /* eslint-disable-next-line no-console */
        console.error('MDCFloatingLabel could not be found');
    }

    public static getMDCNotchedOutline = (field: HTMLElement): MDCNotchedOutline | void => {
        for (const mdcFieldObj of MaterialDesignComponentHandler.notchedOutlineArr) {
            if (mdcFieldObj.nativeField === field) {
                return mdcFieldObj.mdcObject;
            }
        }
        /* eslint-disable-next-line no-console */
        console.error('MDCNotchedOutline could not be found');
    }

    public static initSelect = (mdcSelectElement: HTMLElement): void => {
        const mdcSelectObject = new MDCSelect(mdcSelectElement);
        const label = mdcSelectElement.querySelector('.mdc-floating-label');

        MaterialDesignComponentHandler.selectArr.push({ nativeField: mdcSelectElement.querySelector('select'), mdcObject: mdcSelectObject });

        if (label) {
            this.eventBus.subscribe('radioButtonOption:changeVisibility', () => {
                if (mdcSelectObject.value) {
                    mdcSelectObject.getDefaultFoundation().notchOutline(true);
                } else {
                    mdcSelectObject.getDefaultFoundation().notchOutline(false);
                }
            });
        }
    }

    public static initTextField = (mdcTextFieldElement: HTMLElement): void => {
        const mdcTextFieldObject = new MDCTextField(mdcTextFieldElement);
        const label = mdcTextFieldElement.querySelector('.mdc-floating-label');

        MaterialDesignComponentHandler.textFieldArr.push({ nativeField: mdcTextFieldElement.querySelector('input, textarea'), mdcObject: mdcTextFieldObject });

        // tslint:disable-next-line:no-unused-expression
        new MDCTextFieldCharacterCounter(mdcTextFieldElement.querySelector('.mdc-text-field-character-counter'));

        if (label) {
            if (mdcTextFieldObject.value) {
                mdcTextFieldObject.getDefaultFoundation().notchOutline(true);
            }

            this.eventBus.subscribe('radioButtonOption:changeVisibility', () => {
                if (mdcTextFieldObject.value) {
                    mdcTextFieldObject.getDefaultFoundation().notchOutline(true);
                } else {
                    mdcTextFieldObject.getDefaultFoundation().notchOutline(false);
                }
            });
        }
    }

    private static addEventListeners = (): void => {
        this.eventBus.subscribe(ETabEvent.ACTIVATED, this.enableNotchOutlineForFormFieldsOnAccordionOrTabContent);
        this.eventBus.subscribe(EAccordionEvent.ACTIVATED, this.enableNotchOutlineForFormFieldsOnAccordionOrTabContent);
    }

    /**
     * Open notch outline inside the accordion or tab content when the form field has label and value
     */
    private static enableNotchOutlineForFormFieldsOnAccordionOrTabContent = (header): void => {
        if (!header) {
            return;
        }

        const content = header.nextElementSibling;

        if (!content || !content.classList.contains('accordion-content')) {
            return;
        }

        content.querySelectorAll('.mdc-text-field, .mdc-select').forEach((formFieldElement: HTMLElement): void => {
            const label = formFieldElement.querySelector('.mdc-floating-label');

            if (label) {
                let mdcObject: MDCTextField | MDCSelect;
                if (formFieldElement.classList.contains('mdc-select')) {
                    mdcObject = MaterialDesignComponentHandler.getMDCSelect(formFieldElement.querySelector('select')) as MDCSelect;
                } else {
                    mdcObject = MaterialDesignComponentHandler.getMDCTextField(formFieldElement.querySelector('textarea, input')) as MDCTextField;
                }

                if (mdcObject.value) {
                    mdcObject.getDefaultFoundation().notchOutline(true);
                }
            }
        });
    }

    private static initTextFields = (): void => {
        document.querySelectorAll('.mdc-text-field').forEach((textField: HTMLElement): void => {
            MaterialDesignComponentHandler.initTextField(textField);
        });
    }

    private static initSelects = (): void => {
        document.querySelectorAll('.mdc-select').forEach((selectComponent: HTMLElement): void => {
            MaterialDesignComponentHandler.initSelect(selectComponent);
        });
    }

    private static initSlider = (): void => {
        document.querySelectorAll('.mdc-slider').forEach((slider: HTMLElement): void => {
            MaterialDesignComponentHandler.sliderArr.push({ nativeField: slider, mdcObject: new MDCSlider(slider) });
        });
    }

    private static initRadios = (): void => {
        document.querySelectorAll('.mdc-radio').forEach((radio: HTMLElement): void => {
            MaterialDesignComponentHandler.radioArr.push({ nativeField: radio.querySelector('input'), mdcObject: new MDCRadio(radio) });
        });
    }

    private static initFloatingLabel = (): void => {
        document.querySelectorAll('.mdc-floating-label').forEach((floatingLabel: HTMLElement): void => {
            MaterialDesignComponentHandler.floatingLabelArr.push({ nativeField: floatingLabel, mdcObject: new MDCFloatingLabel(floatingLabel) });
        });
    }

    private static initNotchedOutline = (): void => {
        document.querySelectorAll('.mdc-notched-outline').forEach((notchedOutline: HTMLElement): void => {
            MaterialDesignComponentHandler.notchedOutlineArr.push({ nativeField: notchedOutline, mdcObject: new MDCNotchedOutline(notchedOutline) });
        });
    }
}

MDCTextFieldCharacterCounter.prototype.getDefaultFoundation = function () {
    const _this = this;
    const counterElement: HTMLElement = _this.root;

    if (!counterElement) {
        return new MDCTextFieldCharacterCounterFoundation();
    }

    const textSuffix = counterElement.dataset.textSuffix || '';

    const adapter = {
        setContent: function (content) {
            counterElement.textContent = content + ' ' + textSuffix;
        },
    };
    return new MDCTextFieldCharacterCounterFoundation(adapter);
};
