/**
 * This Service can be used to persist and return to a scrolling state which may be helpful in
 * a scenario like this:
 *      1. User is browsing the product list
 *      2. User is clicking on  a product to go to the product detail page
 *      3. Using browser back, but will be on top of the page as the product items are dynamically loaded
 * 
 * In this case persist the current document.body.scrollTop via setScrollCommand() and execute it by running
 * executeScrollCommand() once you want to return to the previous scroll position
 */

export const SESSION_STORAGE_SCROLL_KEY = 'scrollCommand';

export interface ScrollCommand {
    url: string;
    scrollPos: number;
}

export const setScrollCommand = (scrollCommand: ScrollCommand) => {
    sessionStorage.setItem(SESSION_STORAGE_SCROLL_KEY, JSON.stringify({...scrollCommand }));
};

export const executeScrollCommand = () => {
    const scrollCommand = sessionStorage.getItem(SESSION_STORAGE_SCROLL_KEY);

    if (!scrollCommand) {
        return;
    }
    
    const {url, scrollPos } = JSON.parse(scrollCommand) as ScrollCommand

    // if there's a scroll command remove it no matter what, since the command
    // is valid for the immediate navigation after setting it only
    sessionStorage.removeItem(SESSION_STORAGE_SCROLL_KEY);


    // if the navigation wasn't a browser back (so the urls don't match), don't
    // execute the scroll command
    if (url !== window.location.href) {
        return;
    }

    document.body.scrollTo({ top: scrollPos });
}
