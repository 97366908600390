import * as React from 'react';
import { EClasses } from '../../../constants/lexicon-material';
import { LexiconMaterialEvent } from '../events/lexiconMaterialEvent';
import MiddlewareEventBus from '../lib/MiddlewareEventBus';

interface ILexiconMaterialProps {
    materialId: number;
    text: string;
}

const LexiconMaterialLink: React.FunctionComponent<ILexiconMaterialProps> = props => {
    const tooltipClickHandle = () => MiddlewareEventBus.getInstance().publish({
        type: LexiconMaterialEvent.DISPLAY_INFORMATION,
        payload: { lexiconMaterialId: props.materialId },
    });

    return (
        <span className={EClasses.LEXICON_MATERIAL_LINK} onClick={tooltipClickHandle}>{props.text}</span>
    );
};

export default LexiconMaterialLink;
