import * as React from 'react';
import LinkButton from '../../../../../react/common/components/form/LinkButton';
import { PARAM_PAGE } from '../../constants';
import { ISetPaginationPayload, setPagination } from '../../actions/pagination';
import {scrollToTopElement} from 'PyzShopUi/scripts/helpers/scrollHelpers';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IFooterPaginationProps {
    currentPage: number;
    maxPage: number;

    customPagination?(newPage: string): void;
    dispatch: React.Dispatch<any>;
}

const FooterPagination: React.FunctionComponent<IFooterPaginationProps> = props => {
    const { currentPage, maxPage, dispatch, customPagination } = props;
    const { translate } = useTranslationHook();

    const browserUrl = new URL(
        window.location.pathname + window.location.search,
        window.location.origin
    );

    const hasNextPage = currentPage < maxPage;
    const hasPreviousPage = currentPage > 1;

    const handleNextPageClick = (event: Event) => {
        event.preventDefault();

        if (!hasNextPage) {
            return;
        }

        const newPage = (currentPage + 1).toString();
        updateData(newPage);
    };

    const handlePreviousPageClick = (event: Event) => {
        event.preventDefault();

        if (!hasPreviousPage) {
            return;
        }

        const newPage = (currentPage - 1).toString();
        updateData(newPage);
    };

    const createButtonNextLinkHref = (): string => (
        hasNextPage ? getPageUrl(currentPage + 1) : '#'
    );

    const createButtonPreviousLinkHref = (): string => (
        hasPreviousPage ? getPageUrl(currentPage - 1) : '#'
    );

    const getPageUrl = (page: number) => {
        browserUrl.searchParams.set(PARAM_PAGE, page.toString());

        return browserUrl.href;
    }

    const updateData = (newPage: string) => {
        if (customPagination) {
            customPagination(newPage);
        } else {
            const payload: ISetPaginationPayload = {
                page: newPage
            };

            dispatch(setPagination(payload));
        }

        scrollToTopElement();
    }

    return (
        <div className='pagination-buttons'>
            <LinkButton
                additionalClass=''
                label={translate('Back', { ns: 'article-list'})}
                icon='chevron-thin-left'
                iconAdditionalClass='icon-size-small icon-only'
                isDisabled={!hasPreviousPage}
                onClick={handlePreviousPageClick}
                href={createButtonPreviousLinkHref()}
            />

            <span className='pagination-buttons__information'>
                {
                    translate(
                        'Page {{currentPage}} of {{totalPage}}',
                        {
                            replace: {
                                currentPage,
                                totalPage: maxPage
                            },
                            ns: 'article-list'
                        }
                    )
                }
            </span>

            <LinkButton
                additionalClass=''
                label={translate('Next', { ns: 'article-list'})}
                iconPosition='right'
                icon='chevron-thin-right'
                iconAdditionalClass='icon-size-small icon-only'
                isDisabled={!hasNextPage}
                onClick={handleNextPageClick}
                href={createButtonNextLinkHref()}
            />
        </div>
    );
};

export default FooterPagination;
