import EventBus from '../../utils/eventBus';
import MegaMenuNavigationHandler, { MegaMenuNavigation } from './megaMenuNavigation';
import { BREAKPOINTS } from '../../config';
import DrillDownNavigationHandler, { DrillDownNavigation } from './drillDownNavigation';

enum NavigationStatus {
    DROPDOWN,
    DRILLDOWN,
}

class ResponsiveNavigation {

    private currentStatus: NavigationStatus;
    private drillDownNavigation: DrillDownNavigation;
    private megaMenuNavigation: MegaMenuNavigation;

    constructor(navigationElement: HTMLElement, eventBus: EventBus) {
        this.megaMenuNavigation = MegaMenuNavigationHandler.create(navigationElement, eventBus);
        this.drillDownNavigation = DrillDownNavigationHandler.create(navigationElement, eventBus);

        eventBus.subscribe('window:resize:debounced', this.switchNavigationAccordingToBreakpoint);
        this.switchNavigationAccordingToBreakpoint();
    }

    private switchNavigationAccordingToBreakpoint = (): void => {
        if ((this.currentStatus === undefined
            || this.currentStatus === NavigationStatus.DROPDOWN)
            && BREAKPOINTS.medium()
        ) {
            this.drillDownNavigation.activate();
            this.megaMenuNavigation.deactivate();
            this.currentStatus = NavigationStatus.DRILLDOWN;
        } else if ((this.currentStatus === undefined
            || this.currentStatus === NavigationStatus.DRILLDOWN)
            && BREAKPOINTS.minLarge()
        ) {
            this.megaMenuNavigation.activate();
            this.drillDownNavigation.deactivate();
            this.currentStatus = NavigationStatus.DROPDOWN;
        }
    }
}

export default class ResponsiveNavigationHandler {
    public static init = (eventBus: EventBus): void => {
        const navigationElement: HTMLElement = document.querySelector('nav.responsive-navigation');

        if (!navigationElement) {
            return;
        }

        new ResponsiveNavigation(navigationElement, eventBus);
    }
}
