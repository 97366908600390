import * as ReactDOM from 'react-dom';
import * as React from 'react';
import Header from 'PyzShopUi/scripts/shop-ui/components/search-page/components/header/Header';
import Footer from 'PyzShopUi/scripts/shop-ui/components/search-page/components/footer/Footer';
import { useEnhancedReducer } from 'PyzShopUi/scripts/shop-ui/includes/enhanced-reducer';
import {
    removeCatalogPageSeoText,
    updateSearchPageHeadTitle,
    updateSearchPageTitle
} from 'PyzShopUi/scripts/shop-ui/components/search-page/includes/searchPageHelper';
import SortFilterSidebar from 'PyzShopUi/scripts/shop-ui/components/search-page/components/sidebar/SortFilterSidebar';
import FilterStateService from 'PyzShopUi/scripts/shop-ui/components/search-page/services/FilterStateService';
import useApiQueryDataUpdate from 'PyzShopUi/scripts/shop-ui/components/search-page/hooks/useApiQueryDataUpdate';
import { CatalogContextName, FILTER_TYPE_MAPPING, VIEW_MODE_OPTIONS } from 'PyzShopUi/scripts/catalog-page/constants';
import { createCatalogReducer } from 'PyzShopUi/scripts/catalog-page/reducer';
import {
    initSearchPageService,
    initSearchPageData
} from 'PyzShopUi/scripts/shop-ui/components/search-page/actions/searchPage';
import { loadCategoriesTree } from 'PyzShopUi/scripts/shop-ui/components/search-page/actions/categoriesTree';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { numberFormat } from 'PyzShopUi/scripts/react/common/lib';
import { IApiQueryData, ISearchPageActionState } from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import {
    CATALOG_PAGE_CONTEXT_NAME, PARAM_CONTEXT,
    VIEW_MODE_GRID,
    VIEW_MODE_LIST
} from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import {
    isNavigationFilterApplyForContext,
    isViewModeApplyForContext
} from 'PyzShopUi/scripts/catalog-page/includes/helper/context-helper';

interface ICatalogPageProps {
    apiUrl: string;
    defaultParameter: IApiQueryData;
    filterStateService: FilterStateService;
    isCompactMode: boolean;
    initialState: ISearchPageActionState;
    isStateNeedInitiated: boolean;
}

export const CatalogPage: React.FunctionComponent<ICatalogPageProps> = props => {
    const {
        filterStateService,
        apiUrl,
        defaultParameter,
        isCompactMode,
        initialState,
        isStateNeedInitiated
    } = props;

    const [state, dispatch] = useEnhancedReducer(
        createCatalogReducer(props.filterStateService),
        initialState
    );

    const {
        pagination,
        sort,
        isPageLoading,
        filters,
        storeConfig,
        apiQueryData,
        updatePageDebounceTime,
        categoriesTree,
        navigationActiveCategoryKey,
        skipUpdateBaseOnApiQueryParams,
        activeCategoryName,
        headTitle,
        viewMode
    } = state;

    const isGridViewMode = viewMode === VIEW_MODE_GRID;
    const hasViewMode = isViewModeApplyForContext(defaultParameter);
    const { translate } = useTranslationHook();

    const itemPerPageOptions: number[] = pagination?.config?.validItemsPerPageOptions || [];
    const currentItemsPerPage: string = pagination?.currentItemsPerPage?.toString() || '';
    const pageNumFound: number = pagination?.numFound || 0;
    const currentPage: number = pagination?.currentPage || 0;
    const maxPage: number = pagination?.maxPage || 0;

    const createTotalSearchResultItemsText = (): string => {
        if (defaultParameter[PARAM_CONTEXT] === CatalogContextName.BIKE_DETAIL) {
            return translate('articleVariant',
                {
                    ns: 'article-list',
                    count: pageNumFound
                });
        }

        return translate('article',
            {
                ns: 'article-list',
                count: pageNumFound,
            });
    };

    useApiQueryDataUpdate(
        apiQueryData,
        dispatch,
        updatePageDebounceTime,
        filterStateService,
        skipUpdateBaseOnApiQueryParams,
        [],
        CATALOG_PAGE_CONTEXT_NAME
    );

    React.useEffect(() => {
        if (isStateNeedInitiated) {
            dispatch(
                initSearchPageData(
                    apiUrl,
                    defaultParameter,
                    filterStateService,
                    [],
                    null,
                    CATALOG_PAGE_CONTEXT_NAME
                )
            );
        } else {
            dispatch(
                initSearchPageService(
                    apiUrl,
                    defaultParameter,
                    filterStateService
                )
            );
        }

        if (isNavigationFilterApplyForContext(defaultParameter)) {
            return;
        }

        dispatch(loadCategoriesTree());
    }, []);

    React.useEffect(() => {
        if (activeCategoryName) {
            updateSearchPageTitle(activeCategoryName);
            removeCatalogPageSeoText();
        }
    }, [activeCategoryName]);

    React.useEffect(() => {
        if (headTitle) {
            updateSearchPageHeadTitle(headTitle);
        }
    }, [headTitle]);

    const displaySidebar = () => {
        if (isCompactMode) {
            return null;
        }

        return ReactDOM.createPortal(
            <SortFilterSidebar
                apiQueryData={apiQueryData}
                pagination={pagination}
                sort={sort}
                filters={filters}
                storeConfig={storeConfig}
                isProgressing={isPageLoading}
                dispatch={dispatch}
                categoriesTree={categoriesTree}
                navigationActiveCategoryKey={navigationActiveCategoryKey}
                filterStateService={filterStateService}
                filtersMapping={FILTER_TYPE_MAPPING}
                renderPerPageSelection={isGridViewMode}
                renderSortSelection={isGridViewMode}
                renderTextSearchField={false}
            />,
            document.querySelector('.page-layout-catalog-sidebar .sidebar-container'),
        );
    };

    const displayResultHeader = () => {
        if (isPageLoading || pageNumFound === 0 || isCompactMode) {
            return null;
        }

        return ReactDOM.createPortal(
            <Header
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                totalSearchResultItemsText={createTotalSearchResultItemsText()}
                sortParamNames={sort.sortParamNames}
                currentSortParam={sort.currentSortParam}
                renderSortSelection={isGridViewMode}
                renderPerPageSelection={isGridViewMode}
                renderViewModeSwitch={hasViewMode}
                availableViewModes={VIEW_MODE_OPTIONS}
                viewMode={viewMode}
                dispatch={dispatch}
            />,
            document.querySelector('.result-header'),
        );
    };

    const displayResultFooter = () => {
        if (isPageLoading || pageNumFound === 0 || isCompactMode) {
            return null;
        }

        return ReactDOM.createPortal(
            <Footer
                perPageSelectText={translate('per page', { ns: 'article-list' })}
                itemPerPageOptions={itemPerPageOptions}
                currentItemsPerPage={currentItemsPerPage}
                currentPage={currentPage}
                renderPerPageSelection={isGridViewMode}
                maxPage={maxPage}
                dispatch={dispatch}
            />,
            document.querySelector('.result-footer'),
        );
    };

    return (
        <React.Fragment>
            {displayResultHeader()}
            {displaySidebar()}
            {displayResultFooter()}
        </React.Fragment>
    );
};
