import * as React from 'react';
import { IBikeItem } from '../../../../../bike/interfaces';
import { bikeImageSrcSet } from '../../../../../react/common/lib';
import MyBikeContainer from '../../../../../react/bike-db/containers/MyBikeContainer';
import FormattedNumber from '../../../../../react/common/components/FormattedNumber';
import MyBikeIconFilled from '../../../../../react/bike-db/components/MyBikeIconFilled';
import MyLouisService, { EMyBikeAPIEndpoint } from '../../../../../react/bike-db/services/MyLouisService';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import Loading from 'PyzShopUi/scripts/react/common/components/Loading';

interface IBikeItemProps {
    bike: IBikeItem;
    csrfToken: string;

    onRemove?(bikeId: string): void;
}

const BikeItemGrid: React.FunctionComponent<IBikeItemProps> = props => {
    const { bike } = props;
    const { translate } = useTranslationHook();

    const isManufactureYearDisplayed = bike.manufactureYearStart || bike.manufactureYearEnd;

    const displayManufactureYear = () => {
        if (isManufactureYearDisplayed) {
            const manufactureYearStart = (bike.manufactureYearStart > 0) ? bike.manufactureYearStart : '...';
            const manufactureYearEnd = (bike.manufactureYearEnd > 0) ? bike.manufactureYearEnd : '...';
            return `${manufactureYearStart} - ${manufactureYearEnd}`;
        }

        return <React.Fragment>&nbsp;</React.Fragment>;
    };
    let bikeName = bike.marketingName;
    if(bikeName.length < 5 ){
        bikeName = translate("Bike", { ns: 'article-list-filter' }) + " " + bike.marketingName
    }
    return (
        <div className='bike-card'>
            <div className='bike-card-container'>
                <React.Suspense fallback={<Loading />}>
                    <MyBikeContainer
                        myLouisService={new MyLouisService(EMyBikeAPIEndpoint.ShopSpryker)}
                        bikeId={bike.idBike.toString()}
                        externalId={bike.externalId.toString()}
                        isInMyBike={bike.isInMyBike}
                        wrapperComponent={MyBikeIconFilled}
                        onRemove={props.onRemove}
                        csrfToken={props.csrfToken}
                    />
                </React.Suspense>
                <a className='bike-image-link' href={bike.url} data-qa='bike-detail-page-link' tabIndex={-1}>
                    <div data-fly-to-cart-object='true' className='image-blend-mode lazy-image-container'>
                        <img
                            className='bike-thumbnail lazyload'
                            src={bike.images[0].externalUrlLarge}
                            data-src={bike.images[0].externalUrlLarge}
                            data-srcset={bikeImageSrcSet(bike.images[0].externalUrlLarge)}
                            alt={bikeName}
                        />
                    </div>
                </a>
                <p className='build-year-info'>
                    {displayManufactureYear()}
                </p>
            </div>

            <div className='bike-card-info paragraph-s'>
                <div className='sub-title'>{bike.manufacturer}</div>
                <div className='title'>
                    <a href={bike.url}>
                        <span className='bike-name' data-qa='bike-marketing-name'>{bike.marketingName}</span>{bike.model}
                    </a>
                </div>
                <div className='engine-size'><FormattedNumber number={bike.engineSize}/>&nbsp;{translate('cc', { ns: 'bike-db' })}</div>
            </div>
        </div>
    );
};

export default BikeItemGrid;
