import * as React from 'react';
import { ESliderType } from '../../constants/article-list';
import { ISliderContainerProps } from '../interfaces';
import DefaultSlider from './types/default-slider/DefaultSlider';
import RecentSlider from './types/recent/RecentSlider';
import DialogSlider from './types/dialog/DialogSlider';
import { ListNameContext } from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductItemListNameContext'

const SLIDER_LIST_NAME_PREFIX = 'Slider-'

const Slider: React.FunctionComponent<ISliderContainerProps> = props => {
    const displaySlider = () => {
        switch (props.type) {
            case ESliderType.RECENT:
                return <RecentSlider {...props} />;
            case ESliderType.DIALOG:
                return <DialogSlider {...props} />;
            case ESliderType.DEFAULT:
            default:
                return <DefaultSlider {...props} />;
        }
    };

    return <ListNameContext.Provider value={`${SLIDER_LIST_NAME_PREFIX}${props.title}`}>
        {displaySlider()}
    </ListNameContext.Provider>;
};

Slider.defaultProps = {
    allItemTitle: '',
    allItemLink: '',
    type: ESliderType.DEFAULT
};

export default Slider;
