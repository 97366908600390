import defHandleImageSrcSet from './handleImageSrcSet';
import IImageResolution from '../../interfaces/IImageResolution';

export default function defBikeImageSrcSet(imageSrc: string) {
    const BIKE_DEFAULT_RESOLUTION: string = 'o_pad,w_300,h_300,ha_center,va_center,c_fff::o_extension,e_webp';
    const BIKE_RESOLUTIONS: IImageResolution[] = [
        {
            resolution: 'o_pad,w_300,h_300,ha_center,va_center,c_fff::o_extension,e_webp',
            descriptor: ''
        },
        {
            resolution: 'o_pad,w_600,h_600,ha_center,va_center,c_fff::o_extension,e_webp',
            descriptor: '2x'
        }
    ];

    return defHandleImageSrcSet(imageSrc, BIKE_DEFAULT_RESOLUTION, BIKE_RESOLUTIONS);
}
