import * as React from 'react';
import TagItem from '../../../TagItem';
import { ISelectFilterOption, ISelectFilter } from '../../interfaces';
import { ISetListFilterOptionPayload, setListFilterOption } from '../../actions/filter';

interface IMdcPerPageSelectProps {
    filter: any;
    options: any;
    dispatch: React.Dispatch<any>;
}

const SelectedFilterLabels: React.FunctionComponent<IMdcPerPageSelectProps> = props => {
    const filter: ISelectFilter = props.filter;
    const options: ISelectFilterOption[] = props.options;
    const dispatch = props.dispatch;

    const deselectListFilterSelectedLabel = (filterName: string, value: string, selectedOptions: ISelectFilterOption[]) => {
        const selectedValues = selectedOptions
            .filter(option => option.value !== value)
            .map(option => option.value);

        const payload: ISetListFilterOptionPayload = {
            filterName,
            selectedValues,
        };

        dispatch(setListFilterOption(payload));
    };

    const optionTags = options.map(selectedOption => (
        <React.Fragment key={selectedOption.value}>
            <TagItem
                key={selectedOption.value}
                label={selectedOption.label}
                onClick={() => deselectListFilterSelectedLabel(filter.name, selectedOption.value, options)}
                dataQa={`tag-item-${filter.name}-${selectedOption.value}`}
            />
        </React.Fragment>
    ));

    return <React.Fragment key={options.reduce((acc,current) => acc+current.value+';', '')}>{optionTags}</React.Fragment>
};

export default SelectedFilterLabels;
