import IReactEvent from '../interfaces/article-list/IReactEvent';
import MiddlewareEventBus from '../react/common/lib/MiddlewareEventBus';
import { ProductSliderEvent } from '../product-slider/events';
import { ISliderDataUpdatedPayload } from '../product-slider/interfaces';
import { CatalogPageEvent } from '../catalog-page/events/catalogPageEvent';
import { SearchPageEvent } from 'PyzShopUi/scripts/shop-ui/components/search-page/events/searchPageEvent';

class LayoutSkeleton {
    constructor() {
        this.initEventListener();
    }

    private initEventListener = () => {
        MiddlewareEventBus.getInstance().subscribe((event: IReactEvent<any>) => {
            if (event.type === CatalogPageEvent.CATALOG_PAGE_CONTENT_DATA_INIT || event.type === SearchPageEvent.SEARCH_PAGE_CONTENT_DATA_UPDATED) {
                this.hideSkeleton(['sidebar-button-filter']);
            }

            if (event.type === ProductSliderEvent.PRODUCT_SLIDER_DATA_UPDATED) {
                const { skeletonName } = event.payload as ISliderDataUpdatedPayload;

                this.hideSkeleton([skeletonName]);
            }
        });
    }

    private hideSkeleton = (skeletonNames: string[]) => {
        skeletonNames.forEach(name => {
            const $skeletonElements = document.querySelectorAll(`[data-skeleton-name="${name}"]`);

            if (!$skeletonElements.length) {
                return;
            }

            $skeletonElements.forEach(skeletonElement => {
                skeletonElement.remove();
            });
        })
    }
}

export default class LayoutSkeletonHandler {
    public static init = (): void => {
        new LayoutSkeleton();
    }
}
