import IDataPasteOptions from '../interfaces/IDataPasteOptions';
import MaterialDesignComponentHandler from './materialDesignComponents';

class DataPaste {

    private valueProvider: HTMLElement;
    private receivers: IDataPasteOptions[] = [];

    constructor(valueProvider: HTMLElement) {
        this.valueProvider = valueProvider;

        if (this.prepareReceivers()) {
            this.addEventListener();
        }
    }

    private prepareReceivers(): boolean {
        const arrFrom: string[] = this.valueProvider.dataset.pasteFrom.split(' ');
        const arrTo: string[] = this.valueProvider.dataset.pasteTo.split(' ');
        const arrTargets: string[] = this.valueProvider.dataset.pasteTarget.split(' ');

        if (arrFrom.length !== arrTo.length || arrFrom.length !== arrTargets.length) {
            /* eslint-disable */
            console.error('FEHLER: DataPaste - from/to/target length');
            /* eslint-enable */
            return false;
        }

        arrTargets.forEach((target: string, i: number): void => {
            this.receivers.push({
                selector: target,
                from: arrFrom[i],
                to: arrTo[i]
            });
        });
        return true;
    }

    private addEventListener = () => {
        this.valueProvider.addEventListener('change', (): void => {
            this.receivers.forEach(this.changeValue);
        });
    }

    private changeValue = (receiver: IDataPasteOptions): void => {
        // @ts-ignore
        const value: string = this.valueProvider.value as string;
        const label: string = this.valueProvider.querySelector('option:checked').textContent.trim();

        const write = (receiver.from === 'value') ? value : label;

        const receiverSelectorElement: HTMLElement = document.querySelector(receiver.selector);
        const mdcInput = MaterialDesignComponentHandler.getMDCTextField(receiverSelectorElement, false);
        if (mdcInput) {
            mdcInput.value = write;
            return;
        }

        const mdcSelect = MaterialDesignComponentHandler.getMDCSelect(receiverSelectorElement, false);
        if (mdcSelect) {
            mdcSelect.value = write;
            return;
        }
        // @ts-ignore
        receiverSelectorElement.value = write;
    }
}

export default class DataPasteHandler {

    public static init = (): void => {
        document.querySelectorAll('*[data-paste-target]').forEach((valueProvider: HTMLElement): void => {
            new DataPaste(valueProvider);
        });
    }
}
