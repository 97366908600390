import { ICatalogSearchPageActionState, ICategory } from '../../interfaces';

export const createCategoriesWithSearchQueryParam = (
    state: ICatalogSearchPageActionState,
    q: string,
    categoriesStorage: object
): ICategory[] => {
    const newCategories: ICategory[] = [];

    state.categories.forEach(category => {
        const storedCategory = categoriesStorage[category.value] || {};
        const url = storedCategory.url || category.url;
        const newUrl = new URL(url, window.location.origin);

        newUrl.searchParams.set('q', q);

        newCategories.push({
            ...category,
            url: newUrl.pathname + newUrl.search,
            count: storedCategory.count ?? category.count,
        })
    });

    return newCategories;
}
