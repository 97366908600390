class CmsBenefits {
    private benefitsWrapper: HTMLElement;

    constructor(benefitsWrapper: HTMLElement) {
        this.benefitsWrapper = benefitsWrapper;
        this.addClassesToBenefits();
    }

    private addClassesToBenefits = () => {
        const benefits: NodeListOf<HTMLElement> = this.benefitsWrapper.querySelectorAll('.cms_benefits__benefit');
        const randomNumber: number = Math.floor(Math.random() * benefits.length);
        benefits.item(randomNumber).classList.add('cms_benefits--show-mobile')
    }
}

export default class CmsBenefitsHandler {
    public static init = () => {
        document.querySelectorAll('.cms_benefits').forEach((benefitsWrapper: HTMLElement): void => {
            new CmsBenefits(benefitsWrapper);
        });
    }
}
