import * as React from 'react';
import RangeSlider from '../../../../../shop-ui/components/RangeSlider';
import Accordion from '../../../../../shop-ui/components/Accordion';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import TagItem from '../../../../../shop-ui/components/TagItem';
import CurrencyInput from '../../../../../shop-ui/components/CurrencyInput';
import {
    ISetRangeFilterOptionPayload,
    setRangeFilterOption
} from '../../../../../shop-ui/components/search-page/actions/filter';
import {PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS} from '../../../../../shop-ui/components/search-page/constants';

interface IRangeFilterProps {
    name: string;
    label: string;
    tagLabelSuffix: string|null;
    max: number;
    min: number;
    activeMax: number;
    activeMin: number;
    minAriaLabel?: string;
    maxAriaLabel?: string;

    dispatch: React.Dispatch<any>;
}

interface IUpdateObject {
    minInputValue: number;
    maxInputValue: number;

    dispatch: React.Dispatch<any>;
}

const RangeFilter: React.FunctionComponent<IRangeFilterProps> = props => {
    const { name, label, tagLabelSuffix, max, min, activeMax, activeMin, minAriaLabel, maxAriaLabel, dispatch } = props;

    const symbol = '';

    const roundedMin = min;
    const roundedMax = max;

    const roundedActiveMin = activeMin;
    const roundedActiveMax = activeMax;

    const [minCapacityInputValue, setMinPriceInputValue] = React.useState<string>(activeMin.toString());
    const [maxCapacityInputValue, setMaxPriceInputValue] = React.useState<string>(activeMax.toString());

    const minInputObserver = React.useRef(new Subject<IUpdateObject>());
    const maxInputObserver = React.useRef(new Subject<IUpdateObject>());
    const sliderObserver = React.useRef(new Subject<IUpdateObject>());

    React.useEffect(() => {
        const sliderSubscription = sliderObserver.current.pipe(debounceTime(PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS)).subscribe(() => {
            accordionRef.current.scroll();
        });

        const minSubscription = minInputObserver.current.pipe(debounceTime(PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS)).subscribe(object => {
            const { minInputValue, maxInputValue } = object;

            accordionRef.current.scroll();

            if (minInputValue === undefined) {
                return;
            }

            const payload: ISetRangeFilterOptionPayload = {
                filterName: name,
                activeMin: minInputValue,
                activeMax: maxInputValue,
            }

            if (minInputValue < roundedMin) {
                setMinPriceInputValue(roundedMin.toString());
                payload.activeMin = roundedMin;
                object.dispatch(setRangeFilterOption(payload));

                return;
            }

            if (minInputValue >= maxInputValue) {
                const newMinValue = +maxInputValue - 1;
                setMinPriceInputValue(newMinValue.toString());
                payload.activeMin = newMinValue;
                object.dispatch(setRangeFilterOption(payload));

                return;
            }

            object.dispatch(setRangeFilterOption(payload));
        })

        const maxSubscription = maxInputObserver.current.pipe(debounceTime(PRICE_FILTER_INPUT_DEBOUNCE_TIME_MS)).subscribe(object => {
            const { minInputValue, maxInputValue } = object;

            accordionRef.current.scroll();

            if (maxInputValue === undefined) {
                return;
            }

            const payload: ISetRangeFilterOptionPayload = {
                filterName: name,
                activeMin: minInputValue,
                activeMax: maxInputValue,
            }

            if (maxInputValue > roundedMax) {
                setMaxPriceInputValue(roundedMax.toString());
                payload.activeMax = roundedMax;
                object.dispatch(setRangeFilterOption(payload));

                return;
            }

            if (maxInputValue <= minInputValue) {
                const newMaxValue = +minInputValue + 1;
                setMaxPriceInputValue(newMaxValue.toString());
                payload.activeMax = newMaxValue;
                object.dispatch(setRangeFilterOption(payload));

                return;
            }

            object.dispatch(setRangeFilterOption(payload));
        })

        return () => {
            minSubscription.unsubscribe();
            maxSubscription.unsubscribe();
            sliderSubscription.unsubscribe();
        }
    }, []);

    React.useEffect(() => {
        setMinPriceInputValue(roundedActiveMin.toString());
        setMaxPriceInputValue(roundedActiveMax.toString());
    }, [roundedActiveMin, roundedActiveMax]);

    const accordionRef = React.useRef(null);

    const onMinPriceInputChange = (value: string) => {
        setMinPriceInputValue(value);

        minInputObserver.current.next({
            minInputValue: value === '' ? undefined : +value,
            maxInputValue: roundedActiveMax,
            dispatch
        });
    }

    const onMaxPriceInputChange = (value: string) => {
        setMaxPriceInputValue(value);

        maxInputObserver.current.next({
            minInputValue: roundedActiveMin,
            maxInputValue: value === '' ? undefined : +value,
            dispatch
        });
    }

    const onSlideChange = (values: number[]) => {
        const payload: ISetRangeFilterOptionPayload = {
            filterName: name,
            activeMin: values[0],
            activeMax: values[1],
        }

        dispatch(setRangeFilterOption(payload));

        sliderObserver.current.next({
            minInputValue: roundedActiveMin,
            maxInputValue: roundedActiveMax,
            dispatch
        });
    }

    const createSelectedLabel = () => {
        if (roundedMax === roundedActiveMax && roundedMin === roundedActiveMin) {
            return null;
        }

        const minCapacityLabel = `${roundedActiveMin}${tagLabelSuffix ?? ''}`;
        const maxCapacityLabel = `${roundedActiveMax}${tagLabelSuffix ?? ''}`;

        const onRemoveLabel = () => {
            const payload: ISetRangeFilterOptionPayload = {
                filterName: name,
                activeMin: null,
                activeMax: null,
            }

            dispatch(setRangeFilterOption(payload));
        }

        return (
            <div className='selected-filter-option-list'>
                <TagItem
                    key={name}
                    label={minCapacityLabel + ' - ' + maxCapacityLabel}
                    onClick={() => onRemoveLabel()}
                />
            </div>
        );
    };

    return (
        <Accordion
            label={label}
            headerContent={createSelectedLabel()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
            ref={accordionRef}
        >
            <div className='capacity-filter'>
                {createSelectedLabel()}
                <RangeSlider
                    className='capacity-filter__range-slider'
                    activeMax={roundedActiveMax}
                    max={roundedMax}
                    activeMin={roundedActiveMin}
                    min={roundedMin}
                    onSlideChange={onSlideChange}
                />

                <div className="capacity-filter__input-fields">
                    <CurrencyInput
                        additionalClass='capacity-filter__input-fields__min-input'
                        name='min'
                        value={minCapacityInputValue}
                        currencySymbol={symbol}
                        isSymbolLeftSide={false}
                        onInputChange={onMinPriceInputChange}
                        accessibilityLabel={minAriaLabel}
                    />
                    -
                    <CurrencyInput
                        additionalClass='capacity-filter__input-fields__max-input'
                        name='max'
                        value={maxCapacityInputValue}
                        currencySymbol={symbol}
                        isSymbolLeftSide={false}
                        onInputChange={onMaxPriceInputChange}
                        accessibilityLabel={maxAriaLabel}
                    />
                </div>
            </div>
        </Accordion>
    );
};

export default RangeFilter;
