import * as React from 'react';
import classNames from 'classnames';

interface ILinkButtonProps {
    label?: string;
    additionalClass: string;
    color?: 'grey' | 'red';
    isBordered?: boolean;
    isDisabled?: boolean;
    icon?: string;
    iconPosition?: 'left' | 'right';
    iconAdditionalClass?: string;
    href?: string;

    onClick?(event: Event): void;
}

const LinkButton = (props: ILinkButtonProps) => {
    const {
        label,
        additionalClass,
        color,
        isBordered,
        isDisabled,
        icon,
        iconPosition,
        iconAdditionalClass,
        href,
        onClick,
    } = props;

    const buttonClasses = classNames(
        'button',
        additionalClass,
        {
            [`button-${color}`]: color !== undefined,
            [`button-bordered-${color}`]: isBordered,
            disabled: isDisabled,
        },
    );
    const iconElement = icon ? <i className={`icon icon-${icon} ${iconAdditionalClass}`} /> : null;
    const labelElement = label ? <span>{label}</span> : null;

    const toggleClick = (event: any) => {
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <a className={buttonClasses} onClick={toggleClick} href={href}>
            {iconPosition === 'left' ? iconElement : null}
            {labelElement}
            {iconPosition === 'right' ? iconElement : null}
        </a>
    );
};

LinkButton.defaultProps = {
    isBordered: false,
    isDisabled: false,
    iconPosition: 'left',
    iconAdditionalClass: '',
    href: '#',
};

export default LinkButton;
