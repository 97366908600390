import * as React from 'react';
import { Checkbox, CheckboxProps } from '@mui/material';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const CheckBox: React.FunctionComponent<CheckboxProps> = props => {

    return (
        <Checkbox
            sx={{
                '& .MuiSvgIcon-root': {
                    color: "#191919",
                },
            }}
            checkedIcon={<CheckBoxOutlinedIcon />}
            {...props}
        />
    );
};

export default CheckBox;
