export default class UrlUtils {
    public static redirectCustomPath(redirectUrlElement: NodeListOf<HTMLElement> = null) {
        if (!redirectUrlElement) {
             redirectUrlElement = document.querySelectorAll('a[data-url-redirect-path]');
        }

        if (redirectUrlElement.length) {
            redirectUrlElement.forEach(link => {
                link.addEventListener('click', function(e) {
                    e.preventDefault();

                    let redirectUrl = link.getAttribute('href');
                    if (link.dataset.urlRedirectPath) {
                        redirectUrl = redirectUrl + link.dataset.urlRedirectPath;
                    }

                    window.location.href = redirectUrl;
                })
            });
        }
    }
}
