import { createActionCreator } from '../../../includes/enhanced-reducer';

export interface ISetListFilterOptionPayload {
    filterName: string;
    selectedValues: string[];
}

export interface ISetToggleFilterOptionPayload {
    filterName: string;
    selectedValue: string;
}

export interface ISetRangeFilterOptionPayload {
    filterName: string;
    activeMin: number;
    activeMax: number;
}

export interface ISetCategoryFilterOptionPayload {
    filterName: string;
    selectedValue: string;
}

const SET_LIST_FILTER_OPTION = 'SET_LIST_FILTER_OPTION';
const SET_TOGGLE_FILTER_OPTION = 'SET_TOGGLE_FILTER_OPTION';
const SET_RANGE_FILTER_OPTION = 'SET_RANGE_FILTER_OPTION';
const RESET_ALL_FILTER = 'RESET_ALL_FILTER';

const SET_CATEGORY_FILTER_OPTION = 'SET_CATEGORY_FILTER_OPTION';

export const setListFilterOption = createActionCreator<ISetListFilterOptionPayload>(SET_LIST_FILTER_OPTION);
export const setToggleFilterOption = createActionCreator<ISetToggleFilterOptionPayload>(SET_TOGGLE_FILTER_OPTION);
export const setRangeFilterOption = createActionCreator<ISetRangeFilterOptionPayload>(SET_RANGE_FILTER_OPTION);
export const resetAllFilter = createActionCreator(RESET_ALL_FILTER);

export const setCategoryFilterOption = createActionCreator<ISetCategoryFilterOptionPayload>(SET_CATEGORY_FILTER_OPTION);
