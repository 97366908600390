import * as React from 'react';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import FormattedCurrency from 'PyzShopUi/scripts/react/common/components/FormattedCurrency';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import FootnoteService from 'PyzShopUi/scripts/shop-ui/services/FootnoteService';
import { EFootnoteNamespace } from 'PyzShopUi/scripts/shop-ui/constants';
import {
    FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE,
    FootnoteValues
} from 'PyzShopUi/scripts/footnote/constants';
import MiddlewareEventBus from 'PyzShopUi/scripts/react/common/lib/MiddlewareEventBus';
import { FootnoteEvents } from 'PyzShopUi/scripts/shop-ui/events/footnoteEvents';
import IReactEvent from 'PyzShopUi/scripts/interfaces/article-list/IReactEvent';

interface IProductPriceProps {
    customerPrice: number;
    catalogPrice: number;
    merchantPrice: number;
    hasMultiplePrices: boolean;
    currency: string;
    catalogOrigin?: number;
    footnoteNumber?: string;
}

const ProductPriceComponent: React.FunctionComponent<IProductPriceProps> = props => {
    const {
        customerPrice,
        catalogPrice,
        merchantPrice,
        hasMultiplePrices,
        currency,
        catalogOrigin,
        footnoteNumber
    } = props;

    const footnoteServiceInstance = React.useRef<FootnoteService>(FootnoteService.getInstance());
    const middlewareEventBus = React.useRef<MiddlewareEventBus>(MiddlewareEventBus.getInstance());

    const [catalogPriceFootnoteNumber, setCatalogPriceFootnoteNumber] = React.useState<number>();

    const isCatalogPriceShown = (catalogPrice !== null && catalogPrice > customerPrice);
    const { translate } = useTranslationHook();

    React.useEffect(() => {
        const subscription = middlewareEventBus.current.subscribe((event: IReactEvent<any>) => {
            if (event.type === FootnoteEvents.FOOTNOTE_UPDATED) {
                if (!isCatalogPriceShown) {
                    return;
                }

                if (catalogOrigin === 5) {
                    setCatalogPriceFootnoteNumber(
                        getUvpFootnoteNumber(FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE)
                    );
                } else {
                    setCatalogPriceFootnoteNumber(
                        getUvpFootnoteNumber(FootnoteValues.UVP)
                    );
                }
            }
        });

        return () => {
            subscription.unsubscribe();
        };
    }, []);

    React.useEffect(() => {
        if (!isCatalogPriceShown) {
            return;
        }

        if (catalogOrigin === 5) {
            getUvpFootnoteNumber(FOOTNOTE_NAME_UVP_SET_PRICE_NOT_EQUAL_TO_INDIVIDUAL_PRICE);
        } else {
            getUvpFootnoteNumber(FootnoteValues.UVP);
        }

        middlewareEventBus
            .current
            .publish({ type: FootnoteEvents.FOOTNOTE_UPDATED, payload: {} });
    }, [isCatalogPriceShown]);

    const getUvpFootnoteNumber = (uvpName: string) => (
        footnoteServiceInstance
            .current
            .getFootnoteNumber(
                uvpName,
                EFootnoteNamespace.ARTICLE_PRICE
        )
    );

    const displayFromText = () => {
        if (hasMultiplePrices === false) {
            return;
        }

        return (
            <span className="product-price__from-label">{translate('from', { ns: 'article-list' })}</span>
        );
    };

    const displayFootnoteNumber = () => {
        if (!footnoteNumber || (!customerPrice && !catalogPrice)) {
            return;
        }

        return (
            <sup> {footnoteNumber} </sup>
        );
    };

    const displayCatalogPrice = () => {
        if (!isCatalogPriceShown) {
            return;
        }

        return (
            <span className="product-price__catalog">{translate('RRP', { ns: 'article-detail' })}&nbsp;
                <FormattedCurrency price={catalogPrice / 100} currency={currency} footnoteNumber={catalogPriceFootnoteNumber}/>
            </span>
        );
    };

    const displayB2cPrice = () => {
        return (
            <React.Fragment>
                {displayCatalogPrice()}
                <span className={isCatalogPriceShown ? 'product-price__customer--highlight' : 'product-price__customer'}>
                    {displayFromText()} <FormattedCurrency price={customerPrice / 100} currency={currency}/>
                </span>
            </React.Fragment>
        );
    };

    const displayB2bPrice = () => {
        return (
            <span className="product-price__merchant">
                {displayFromText()} <FormattedCurrency price={merchantPrice / 100} currency={currency}/>
                {displayFootnoteNumber()}
            </span>
        );
    };

    return (
        <React.Fragment>
            {merchantPrice ? (displayB2bPrice()) : (displayB2cPrice())}
        </React.Fragment>
    );
};

const ProductPrice = withMemo(ProductPriceComponent);

export default ProductPrice;
