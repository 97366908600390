export interface IPayload {
    token?: string;
}

export interface IProductPayload extends IPayload {
    name: string;
    id: string;
    price?: string;
    brand?: string;
    category?: string;
    variant?: string;
    quantity?: number;
    dimension24?: string;
    dimension28?: string;
}

export interface IActionField extends IPayload {
    list: string;
    action?: string;
}

export interface IStoreInterface {
    products: {[token: string]: IProductPayload[]};
    actionFields: {[token: string]: IActionField};
    selectors: {[selector: string]: string };
    pageData: IPageData;
}

export interface IAjaxSliderData {
    data: {
        products: IProductPayload[];
        actionField: IActionField;
        currencyCode?: string;
    };
    handlerName: string;
    listenerName: string;
    selector: string;
    token: string;
}

export interface IPageData {
    event: string;
    language: string;
    country: string;
    environment: string;
    pageType: string;
    siteParameter?: string;
    adBlocker?: string;
    uiCid?: string;
    uiEmailID?: string;
    viewportSize?: string;
    searchResults?: string;
    searchKeywords?: string;
    suggestedKeywordst?: string;
    userId?: string;
    uiLoyaltyCardID?: string;
    uiGender?: string;
    uiPostalCode?: string;
    uiCity?: string;
    uiBirthday?: string;
    uiLoggedin?: string;
    uinNrTransactionCRM?: string;
    uiNewsletterSubcriber?: string;
    uiCustomerType?: string;
}
export const EventPayloadStore: IStoreInterface = {
    products: {},
    actionFields: {},
    selectors: {},
    pageData: {
        event: '',
        language: '',
        country: '',
        environment: '',
        pageType: '',
    }
};

export interface IGTMEvent {
    data: {
        products: IProductPayload[];
        actionField: IActionField;
    };
    handlerName: string;
    listenerName: string;
    selector?: string;
    token?: string;
}
