import * as React from 'react';
import { ESearchSuggestionTypes } from '../constants';
import { ISearchSuggestion, ISearchSuggestionProduct, ISearchSuggestionProductItem } from '../interfaces';

interface IUsePopulateSearchSuggestionData {
    category: ISearchSuggestion;
    brand: ISearchSuggestion;
    product: ISearchSuggestionProduct;
    bike: ISearchSuggestion;
    store: ISearchSuggestion;
    servicePage: ISearchSuggestion;
}

function usePopulateSearchSuggestionData(data: ISearchSuggestion[]): IUsePopulateSearchSuggestionData {
    const [category, setCategory] = React.useState<ISearchSuggestion>(null);
    const [brand, setBrand] = React.useState<ISearchSuggestion>(null);
    const [product, setProduct] = React.useState<ISearchSuggestionProduct>(null);
    const [bike, setBike] = React.useState<ISearchSuggestion>(null);
    const [store, setStore] = React.useState<ISearchSuggestion>(null);
    const [servicePage, setServicePage] = React.useState<ISearchSuggestion>(null);

    React.useEffect(() => {
        const foundItem = data.filter((item: ISearchSuggestion) => item.type === ESearchSuggestionTypes.CATEGORY);
        setCategory(foundItem.length ? foundItem[0] : null);
    }, [data]);

    React.useEffect(() => {
        const foundItem = data.filter((item: ISearchSuggestion) => item.type === ESearchSuggestionTypes.BRAND);
        setBrand(foundItem.length ? foundItem[0] : null);
    }, [data]);

    React.useEffect(() => {
        const foundItem = data.filter((item: ISearchSuggestion) => item.type === ESearchSuggestionTypes.STORE);
        setStore(foundItem.length ? foundItem[0] : null);
    }, [data]);

    React.useEffect(() => {
        const foundItem = data.filter((item: ISearchSuggestion) => item.type === ESearchSuggestionTypes.BIKE);
        setBike(foundItem.length ? foundItem[0] : null);
    }, [data]);

    React.useEffect(() => {
        const foundItem = data.filter((item: ISearchSuggestion) => item.type === ESearchSuggestionTypes.SERVICE_PAGE);
        setServicePage(foundItem.length ? foundItem[0] : null);
    }, [data]);

    React.useEffect(() => {
        const foundItem = data.filter((item: ISearchSuggestion) => item.type === ESearchSuggestionTypes.PRODUCT);

        if (foundItem.length) {
            const newProduct: ISearchSuggestionProduct = {
                ...foundItem[0],
                ...{ items: foundItem[0].items.map(item => item as ISearchSuggestionProductItem) }
            };

            setProduct(newProduct);
        } else {
            setProduct(null);
        }
    }, [data]);

    return { category, brand, product, bike, store, servicePage };
}

export default usePopulateSearchSuggestionData;
