import * as React from 'react';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import Accordion from '../../../../../shop-ui/components/Accordion';
import useMultipleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useMultipleSelectFilter';
import FeatureFilterOptions from './FeatureFilterOptions';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const FasteningFilter: React.FunctionComponent<IFilterProps> = props => {
    const { options } = props;
    const selectedOptions = props.options
        .filter(option => option.selected);
    const [onToggleItem, displaySelectedFilterOptions] = useMultipleSelectFilter(props.name, selectedOptions, props.dispatch);
    const { translate } = useTranslationHook();

    return (
        <Accordion
            label={translate('Helmet features', { ns: 'helmet-buyer-guide'})}
            headerContent={displaySelectedFilterOptions()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className={`feature-filter`}>
                {displaySelectedFilterOptions()}
                <div className={`scroll-selection`}>
                    <FeatureFilterOptions
                        optionList={options}
                        onToggleItem={onToggleItem}
                    />
                </div>
            </div>
        </Accordion>
    );
}

export default FasteningFilter;
