class CmsSocialSharing {
    private socialSharingLink: HTMLElement;

    constructor(socialSharingLink: HTMLElement) {
        this.socialSharingLink = socialSharingLink;
        this.addClickEventToRedirect();
    }

    private addClickEventToRedirect = (): void => {
        this.socialSharingLink.addEventListener('click', (event: Event): void => {
            event.preventDefault();
            const currentUrl: string = window.location.href;
            const redirectUrl: string =
                this.socialSharingLink.classList.contains('cms_social-sharing__link--facebook') ?
                    'https://www.facebook.com/sharer.php?u=' + currentUrl + '?media=fb_share' :
                    this.socialSharingLink.classList.contains('cms_social-sharing__link--twitter') ?
                        'https://twitter.com/intent/tweet?url=' + currentUrl :
                        '';
            window.location.href = encodeURI(redirectUrl);
        });
    }
}

export default class CmsSocialSharingHandler {
    public static init = (): void => {
        document.querySelectorAll('.cms_social-sharing__link').forEach((socialSharingLink: HTMLElement): void => {
            new CmsSocialSharing(socialSharingLink);
        });
    }
}
