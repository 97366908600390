import * as React from 'react';
import CheckBox from '../../../../../shop-ui/components/Checkbox';
import Marker from '../../../../../shop-ui/components/Marker';
import useToggleFilter from '../../../../../shop-ui/components/search-page/hooks/useToggleFilter';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const SaleFilter: React.FunctionComponent<IFilterProps> = props => {
    if (!props.options.length) {
        return null;
    }

    const [isSelected, onToggleFilter] = useToggleFilter(props.name, props.options, props.dispatch);
    const { translate } = useTranslationHook();

    return (
        <div onClick={() => onToggleFilter()} className={`toggle-filter`}>
            <CheckBox
                id={props.name}
                checked={isSelected}
            />
            <Marker label={translate('Sale %', {ns: 'article-list'}).toLocaleUpperCase()} color={`red`} labelFor={props.name}/>
        </div>
    );
};

export default SaleFilter;
