import * as React from 'react';

interface ILabelProps {
    label: string;
    htmlFor?: string;
}

const Label = (props: ILabelProps) => {
    const { label } = props;

    if (!label) {
        return null;
    }

    return (
        <label htmlFor={props.htmlFor}>{label}:</label>
    );
};

export default Label;
