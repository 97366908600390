import * as React from "react";
import {ICategoryProps} from "PyzShopUi/scripts/main-rubric-page/interfaces";


const Category: React.FunctionComponent<ICategoryProps> = ({category}) => {
    return (
        <div className="cell small-12 medium-6 main-rubric-links-column">
            <a href={category.url}>
                {category.name}
            </a>
        </div>
    )
}

export default Category;
