import * as React from 'react';
import Accordion from '../../../../../shop-ui/components/Accordion';
import GenderFilterOptions from './GenderFilterOptions';
import { FILTER_LABEL_MAPPING, FilterNames } from '../../../../constants';
import useMultipleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useMultipleSelectFilter';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const GenderFilter: React.FunctionComponent<IFilterProps> = props => {
    const { translate } = useTranslationHook();
    const selectedOptions = props.options.filter(option => option.selected).map(option => ({
        ...option,
        label: translate(FILTER_LABEL_MAPPING[FilterNames.GENDER][option.value], { ns: 'article-list-filter'}),
    }));
    const [onToggleItem, displaySelectedFilterOptions, onToggleItems] = useMultipleSelectFilter(props.name, selectedOptions, props.dispatch);


    return (
        <Accordion
            label={translate('Geschlecht', { ns: 'article-list-filter'})}
            headerDataQa={'article-list-filter-header'}
            headerContent={displaySelectedFilterOptions()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className={`scroll-selection`}>
                {displaySelectedFilterOptions()}

                <GenderFilterOptions
                    optionList={props.options}
                    onToggleItem={onToggleItem}
                    onToggleItems={onToggleItems}
                />
            </div>
        </Accordion>
    );
};

export default GenderFilter;
