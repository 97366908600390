import * as React from 'react';
import { IBikeItem } from '../../../../bike/interfaces';
import BikeItemGrid from './bike-item/BikeItemGrid';

interface IBikeGridProps {
    bikes: IBikeItem[];
    hasSideBar: boolean;
    csrfToken: string;

    onRemove?(bikeId: string): void;
}

const BikeGrid: React.FunctionComponent<IBikeGridProps> = props => {
    const { bikes, hasSideBar, csrfToken } = props;

    if (!bikes) {
        return null;
    }

    const displayItems = () => bikes.map((bike: IBikeItem) => (
        <div key={`bike-${bike.idBike}`} className={`cell small-6${hasSideBar === true ? ' large-4' : ' large-3'}${bike.isShown === false ? ' hide' : ''}`}>
            <BikeItemGrid
                bike={bike}
                onRemove={props.onRemove}
                csrfToken={csrfToken}
            />
        </div>
    ));

    return (
        <React.Fragment>
            {displayItems()}
        </React.Fragment>
    );
};

BikeGrid.defaultProps = {};

export default BikeGrid;
