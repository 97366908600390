import {
    IApiQueryData,
    ISearchPageActionState,
    ISearchResponse
} from 'PyzShopUi/scripts/shop-ui/components/search-page/interfaces';
import { emptyCatalogPageState } from 'PyzShopUi/scripts/catalog-page/constants';
import { PARAM_CATEGORY_KEY, PARAM_FILTER_TOKEN } from 'PyzShopUi/scripts/shop-ui/components/search-page/constants';
import { getApiQueryDataFromLocation } from 'PyzShopUi/scripts/shop-ui/includes/query-string-helper';
import {
    buildCatalogPageSearchPageState,
    standardizedCategoryFilter
} from 'PyzShopUi/scripts/shop-ui/components/search-page/includes/searchPageHelper';
import FilterStateService from 'PyzShopUi/scripts/shop-ui/components/search-page/services/FilterStateService';

export const createCatalogPageStateWithInitResponse = (
    initResponse: ISearchResponse,
    defaultParameter: IApiQueryData,
    filterStateService: FilterStateService
): ISearchPageActionState => {
    const apiQueryData = { ...defaultParameter, ...getApiQueryDataFromLocation() };
    delete apiQueryData[PARAM_FILTER_TOKEN];

    const defaultCategoryParam = defaultParameter[PARAM_CATEGORY_KEY] || null;
    standardizedCategoryFilter(initResponse.filters, apiQueryData, defaultCategoryParam);

    const catalogPageState: ISearchPageActionState = {
        ...emptyCatalogPageState,
        ...buildCatalogPageSearchPageState(initResponse, apiQueryData, filterStateService),
        initCategoryKey: defaultCategoryParam,
        navigationActiveCategoryKey: defaultCategoryParam,
    };

    catalogPageState.isPageLoading = false;
    delete catalogPageState['resultItems'];

    return catalogPageState;
};
