import { isTrackingConsentGiven } from 'PyzShopUi/scripts/shop-ui/services/GoogleConsentModeService';

export const ACTION_FIELD_KEY = 'list' as const
export const EVENT_NAME = "EECproductClick"

const PRICE_DIVIDER = 100;

// boils down to { list: 'some string' }
export type ActionField = Record<typeof ACTION_FIELD_KEY, string>
export interface TrackProductClickConfig {
    products: TrackProductClickConfigProduct
    actionField: ActionField
}

export interface TrackProductClickConfigProduct {
    name: string,
    id: string,
    price: string
}

export interface TrackProductClickConfigProductWithPrices extends Omit<TrackProductClickConfigProduct, 'price'> {
    merchantPrice?: number
    customerPrice?: number
}

export const pushProductClickToDataLayer = (config: TrackProductClickConfig) => {
    if(!isTrackingConsentGiven()) {
        return;
    }

    const object = {
        event: EVENT_NAME,
        ecommerce: {
            click: {
                config
            }
        }
    }

    window.dataLayer.push(object)
}
/**
 * this can be used to build a TrackProductClickConfigProduct from an object that has multiple prices
 */
export const buildConfigProduct = ({ merchantPrice, customerPrice, ...rest }: TrackProductClickConfigProductWithPrices): TrackProductClickConfigProduct => {
    if (merchantPrice) {
        return { price: `${merchantPrice / PRICE_DIVIDER}`, ...rest }
    }

    return { price: `${customerPrice / PRICE_DIVIDER}`, ...rest }
}