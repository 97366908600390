import * as React from 'react';
import { ISelectOption } from '../../../interfaces';

interface ISelectOptionsProps {
    options: ISelectOption[];
}

const SelectOptions: React.FunctionComponent<ISelectOptionsProps> = props => {
    const { options } = props;

    const displayOptions = () => (
        options.map(option => {
            const isDisabled: boolean = option.disabled || false;

            return (
                <option
                    key={option.value}
                    value={option.value}
                    disabled={isDisabled}
                    data-qa='variant-select'>
                    {option.label}
                </option>
            );
        })
    )

    return (
        <React.Fragment>
            {displayOptions()}
        </React.Fragment>
    );
};

export default SelectOptions;
