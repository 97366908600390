const RESOLUTIONS_MAPPING = {
    external_url_thumbnail: '',
    external_url_thumbnail2x: '2x'
};

export default function defProductImageSrcSet(images: object): string {
    const srcSet = [];

    for (const resolutionKey in RESOLUTIONS_MAPPING) {
        if (!images.hasOwnProperty(resolutionKey)) {
            continue;
        }

        srcSet.push(images[resolutionKey] + ' ' + RESOLUTIONS_MAPPING[resolutionKey]);
    }

    return srcSet.join(', ');
}
