import * as React from 'react';
import classNames from 'classnames';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IMyBikeButtonProps {
    isActive?: boolean;
    bikeRefElement: React.MutableRefObject<HTMLButtonElement>;

    onMyBikeToggle(): void;
}

const MyBikeButton: React.FunctionComponent<IMyBikeButtonProps> = props => {
    const { isActive, onMyBikeToggle, bikeRefElement } = props;
    const buttonClass = classNames(
        'button button-fullwidth',
        { 'button-grey': isActive },
        { 'button-red': !isActive },
    );
    const { translate } = useTranslationHook();

    const buttonLabel = isActive ? 'That\'s no longer my bike' : 'That\'s my bike';

    return (
        <button data-fly-to-cart='true' data-qa='fly-to-cart-button' ref={bikeRefElement} className={buttonClass} onClick={onMyBikeToggle}>
            {translate(buttonLabel, { ns: 'bike-db'})}
        </button>
    );
};

MyBikeButton.defaultProps = { isActive: false };

export default React.memo(MyBikeButton);
