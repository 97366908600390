export const getSelectedConcretes = (filters, variantMap): Set<number> => {
    const selectedOptions = [];
    filters.forEach((filter: any) => {
        const selectedOption = filter.options.filter(option => option.selected);
        if (selectedOption.length === 0) {
            return;
        }
        selectedOptions.push(
            {
                type: filter.type,
                value: selectedOption[0]?.value,
            });
    });

    return filterMatchingAttributes(selectedOptions, variantMap);
}

export const filterMatchingAttributes = (selectedOptions: any[], variantMap): Set<number> => {
    let matchingVariants = Object.entries(variantMap);

    selectedOptions.forEach(selectedOption => {
        matchingVariants = matchingVariants.filter(entry => {
            const [, value] = entry;
            return value[selectedOption.type] === selectedOption.value;
        });
    })

    const matchingConcreteIds = matchingVariants.map(variant => {
        const [key] = variant;
        return parseInt(key);
    });

    return new Set<number>(matchingConcreteIds);
}
