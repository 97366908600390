import * as React from 'react';
import classNames from 'classnames';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface IWishListIconProps {
    isActive?: boolean;
    isProcessing?: boolean;
    wishListIconRef: React.MutableRefObject<HTMLDivElement>;
    isKeyboardAccessible?: boolean;

    onClick(): void;
}

const WishListIconComponent: React.FunctionComponent<IWishListIconProps> = props => {
    const { isActive, onClick, isProcessing, wishListIconRef, isKeyboardAccessible = true } = props;
    const className = classNames(
        'icon-fill icon-like-fill',
        {
            active: isActive,
            'is-processing': isProcessing,
        },
    );
    const { translate } = useTranslationHook();

    const iconClassName = isActive ? 'fill-red' : 'fill-black';
    const iconTitle = isActive ? translate('Remove from wish list', { ns: 'article-list'}) : translate('Add to wish list', { ns: 'article-list'});

    const wishlistAccessibilityProps = {
        onKeyDown: (e) => {
            if (e.key === "Enter") {
                onClick()
            }
        },
        role: "button",
        tabIndex: 0
    }

    return (
        <span
            ref={wishListIconRef}
            onClick={onClick}
            
            className={className}
            title={iconTitle}
            data-qa="wish-list-icon"
            {...(isKeyboardAccessible ? wishlistAccessibilityProps : {})}
        >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <g>
                    <path className="fill-white"
                          d="M16.37,3.56A5,5,0,0,0,12,6.31,5,5,0,0,0,7.63,3.56c-4.14,0-6.14,4.65-4.27,8.51S12,20.44,12,20.44h0s6.76-4.52,8.64-8.37S20.51,3.56,16.37,3.56Z"/>
                    <path className={iconClassName}
                          d="M12,20.94h-.08c-.41-.07-7.14-4.8-9-8.64a7,7,0,0,1,.21-6.74A5.23,5.23,0,0,1,7.63,3.06,5.28,5.28,0,0,1,12,5.36a5.28,5.28,0,0,1,4.37-2.3,5.23,5.23,0,0,1,4.51,2.49,7,7,0,0,1,.21,6.74c-1.95,4-8.75,8.52-8.82,8.57A.5.5,0,0,1,12,20.94ZM7.63,4.06A4.2,4.2,0,0,0,4,6.08a6,6,0,0,0-.16,5.77c1.58,3.25,6.91,7.09,8.19,8,1.28-.89,6.61-4.72,8.19-8A6,6,0,0,0,20,6.08a4.2,4.2,0,0,0-3.66-2,4.54,4.54,0,0,0-3.92,2.46.48.48,0,0,1-.45.29h0a.49.49,0,0,1-.45-.29A4.48,4.48,0,0,0,7.63,4.06Z"/>
                </g>
            </svg>
        </span>
    );
};

WishListIconComponent.defaultProps = {
    isActive: false
};

const WishListIcon = withMemo(WishListIconComponent);

export default WishListIcon;
