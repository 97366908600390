import { filterXSS } from 'xss';

export default class CmsStoreUtils {
    public static clearSearchInput() {
        const searchField = document.getElementById('cms_store__location-search-field');
        if (searchField instanceof HTMLInputElement) {
            searchField.value = '';
        }

        const notchedOutline = document.querySelector('.cms_store__location-search-label');
        if (notchedOutline instanceof Element) {
            notchedOutline.classList.remove('mdc-floating-label--float-above');
        }
    }

    public static resetMapHighlight() {
        const map = document.querySelector('.cms_store__map');
        if (map) {
            map.querySelectorAll('.active').forEach(activeState => {
                activeState.classList.remove('active');
            });
        }
    }

    public static hideStoreDistances() {
        document.querySelectorAll('.cms_store__distance').forEach((distanceContainer: HTMLElement) => {
            distanceContainer.style.display = 'none';
        });
    }

    public static hideActiveStores() {
        document.querySelectorAll('.cms_store-teaser').forEach(store => {
            store.classList.remove('is-active');
        });
    }

    public static resetLocationDropdown() {
        const locationDropdown = document.querySelector('.cms_store__location-dropdown');
        if (locationDropdown instanceof HTMLSelectElement) {
            locationDropdown.value = '__default__';
        }
    }

    public static showSearchTermIndicator(searchTerm: string) {
        const searchTermContainer = document.querySelector('.cms_store__search-term');
        const newContent = document.createTextNode(`„${filterXSS(searchTerm)}”`);
        const searchTermSpan = searchTermContainer.querySelector('.search-term');
        if (searchTermSpan.hasChildNodes() === true) {
            searchTermSpan.replaceChild(newContent, searchTermSpan.firstChild);
        } else {
            searchTermSpan.appendChild(newContent);
        }
        searchTermContainer.classList.remove('hide');
    }

    public static hideSearchTermIndicator() {
        const searchTermContainer = document.querySelector('.cms_store__search-term');
        searchTermContainer.classList.add('hide');
    }

    public static showNoResultsInfoBox() {
        document.querySelector('.cms_store__no-search-results-info').classList.remove('hide');
    }

    // two separate methods as we use data-switch for the location dropdown,
    // so we only need to show the store image in this case, not set the store to active
    public static setStoreToActive(store: Element) {
        store.classList.add('is-active');
    }

    public static showStoreImage(store: Element) {
        const storeImage = store.querySelector('.cms_store__teaser-image');
        if (storeImage instanceof HTMLImageElement) {
            storeImage.src = storeImage.dataset.src;
        }

        // whenever we need to show a store image, we can be sure to hide the infobox
        // for no results, so we append the method here
        document.querySelector('.cms_store__no-search-results-info').classList.add('hide');
    }

    public static setQueryParameter(queryString: string) {
        if (history.replaceState) {
            const updatedUrl = window.location.origin + window.location.pathname + `?${queryString}`;
            window.history.replaceState({ path: updatedUrl }, '', updatedUrl);
        }
    }

    // analogous to ImporterUtility->cleanUriPathSegment in CMS repo
    public static cleanLocationName = (string: string) => {
        const search = ['ä', 'ö', 'ü', 'Ä', 'Ö', 'Ü', '(', ')', '.', '/', ' ', '&', '+', "'"];
        const replace = ['ae', 'oe', 'ue', 'ae', 'oe', 'ue', '', '', '', '-', '-', '-', '', '-'];
        for (let i = 0; i < search.length; i++) {
            string = string.split(search[i]).join(replace[i])
        }
        return string;
    }
}
