class CmsTestimonials {
    private testimonialsWrapper: HTMLElement;

    constructor(testimonialsWrapper: HTMLElement) {
        this.testimonialsWrapper = testimonialsWrapper;
        this.displayRandomTestimonial();
    }

    private displayRandomTestimonial = () => {
        const testimonials: NodeListOf<HTMLElement> = this.testimonialsWrapper.querySelectorAll('.cms_testimonial');
        if (testimonials.length < 1) {
            return;
        }
        const randomTestimonial: HTMLElement = testimonials.item(Math.floor(Math.random() * testimonials.length));
        const testimonialImage = randomTestimonial.querySelector('.cms_testimonial__image-img');

        if (testimonialImage instanceof HTMLImageElement) {
            testimonialImage.src = testimonialImage.dataset.src;
            randomTestimonial.classList.remove('hide');
        }
    }
}

export default class CmsTestimonialsHandler {
    public static init = () => {
        document.querySelectorAll('.cms_testimonials').forEach((testimonialsWrapper: HTMLElement): void => {
            new CmsTestimonials(testimonialsWrapper);
        });
    }
}
