import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IGloveBuyerGuideLadingState {
    categoryKey: string;
    bannerContent: string;
}

const categoryKeysHavingGloveBuyerGuide = ['c-714', 'c-710', 'c-715', 'c-717', 'c-727', 'c-713', 'c-711', 'c-716', 'c-712', 'm-180'];

const GloveBuyerGuideComponent: React.FunctionComponent<IGloveBuyerGuideLadingState> = props => {
    const { categoryKey, bannerContent } = props;

    if (!categoryKeysHavingGloveBuyerGuide.includes(categoryKey) || !bannerContent) {
        return null;
    }

    return (
        <div dangerouslySetInnerHTML={{ __html: bannerContent }} />
    );
};

export const GloveBuyerGuideLanding = withMemo(GloveBuyerGuideComponent);
