import { createActionCreator } from '../../../includes/enhanced-reducer';
import { PARAM_PAGE } from '../constants';

export interface ISetPaginationPayload {
    [PARAM_PAGE]: string;
}

const SET_PAGINATION = 'SET_PAGINATION';

export const setPagination = createActionCreator<ISetPaginationPayload>(SET_PAGINATION);
