import { ISetSortPayload } from '../actions/sort';
import { PARAM_PAGE, PARAM_SORT } from '../constants';
import { IApiQueryData, ISearchPageActionState } from '../interfaces';

export const doSetSort = (state: ISearchPageActionState, payload: ISetSortPayload): ISearchPageActionState => {
    const newApiQueryData: IApiQueryData = {
        ...state.apiQueryData,
        [PARAM_SORT]: payload.sort,
        [PARAM_PAGE]: '1',
    };

    return {
        ...state,
        apiQueryData: newApiQueryData,
    };
};
