import Button from './elements/button';

class SubmitForm {
    private submitForm: HTMLFormElement;
    private formSubmitButton: Button;

    constructor(submitForm: HTMLFormElement) {
        this.submitForm = submitForm;
        const submitbutton = this.submitForm.querySelector<HTMLButtonElement>('#form-submit-button');

        if (submitbutton !== null) {
            this.formSubmitButton = new Button(submitbutton);
            this.addEventListener();
        }
    }

    private addEventListener = (): void => {
        this.submitForm.onsubmit = (event: Event): void => {
            event.preventDefault();
            this.formSubmitButton.toggleButtonProgressing();
            this.formSubmitButton.toggleDisable();
            this.submitForm.submit();
        };
    }
}

export default class FormSubmitButtonHandler {
    public static init = (): void => {
        const submitForms = document.querySelectorAll('#order-submit-form, #customerLoginViaEmail');
        submitForms.forEach((submitForm: HTMLFormElement) => {
            // tslint:disable-next-line:no-unused-expression
            new SubmitForm(submitForm);
        });
    }
}
