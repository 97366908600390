import { createActionCreator, ThunkActionCreator } from '../../shop-ui/includes/enhanced-reducer';
import { ICatalogSearchPageActionState } from '../interfaces';
import SearchPageTabService from '../services/SearchPageTabService';
import { createCategoriesWithSearchQueryParam } from '../includes/catalog-search-page-helper';
import {
    getApiQueryDataFromLocation, getApiQueryDataFromParameters, mergeQueryIntoUrl
} from 'PyzShopUi/scripts/shop-ui/includes/query-string-helper';
import { IApiQueryData } from '../../shop-ui/components/search-page/interfaces';
import { ParsedQuery } from 'query-string';
import { scrollToTopElement } from 'PyzShopUi/scripts/helpers/scrollHelpers';

const UPDATE_CATALOG_SEARCH_PAGE_STATE = 'UPDATE_CATALOG_SEARCH_PAGE_STATE';

export const updateCatalogSearchPageState = createActionCreator<ICatalogSearchPageActionState>(UPDATE_CATALOG_SEARCH_PAGE_STATE);

export const initCatalogSearchPageData: ThunkActionCreator<ICatalogSearchPageActionState> = (
    defaultParameter: IApiQueryData
) => dispatch => {
    const searchPageTabService = SearchPageTabService.getInstance();

    searchPageTabService.setDefaultQueryObject(defaultParameter);
    searchPageTabService.getData(getApiQueryDataFromLocation()).then(data => {
        const newState: ICatalogSearchPageActionState = { ...data };

        newState.categories = createCategoriesWithSearchQueryParam(
            newState,
            defaultParameter.q,
            {}
        );
        dispatch(updateCatalogSearchPageState(newState));
    });
};

export const doUpdateCatalogSearchPageData: ThunkActionCreator<ICatalogSearchPageActionState> = (
    defaultParameter: IApiQueryData,
    newApiQueryData: ParsedQuery
) => (dispatch, getState) => {
    const currentState = getState();
    const searchPageTabService = SearchPageTabService.getInstance();
    const selectedCategory = currentState.categories.find(category => category.value === currentState.type);
    const categoryUrl = mergeQueryIntoUrl(selectedCategory.url, newApiQueryData);

    searchPageTabService.setDefaultQueryObject(defaultParameter);
    searchPageTabService.updateBrowserParameters(categoryUrl.search);
    searchPageTabService.getData(newApiQueryData).then(data => {
        const newState: ICatalogSearchPageActionState = { ...data };

        newState.categories = currentState.categories;

        dispatch(updateCatalogSearchPageState(newState));
    });
};

export const updateCatalogSearchPageSelectedTab: ThunkActionCreator<ICatalogSearchPageActionState> = (
    selectedTabValue: string
) => (dispatch, getState) => {
    const currentState = getState();

    if (currentState.type === selectedTabValue) {
        return;
    }

    const selectedCategory = currentState.categories.find(category => category.value === selectedTabValue) || currentState.categories[0];
    const categoryUrl = new URL(selectedCategory.url, window.location.origin);
    const newApiQueryData: IApiQueryData = {
        ...getApiQueryDataFromParameters(categoryUrl.search),
        searchType: selectedCategory.value,
    }

    const searchPageTabService = SearchPageTabService.getInstance();

    searchPageTabService.updateBrowserUrl(selectedCategory.url);
    searchPageTabService.getData(newApiQueryData).then(data => {
        const newState: ICatalogSearchPageActionState = { ...data };

        newState.categories = createCategoriesWithSearchQueryParam(
            newState,
            searchPageTabService.getDefaultQueryObject().q,
            currentState.categoriesStorage
        );

        dispatch(updateCatalogSearchPageState(newState));
        scrollToTopElement();
    })
}
