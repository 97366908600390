export enum SearchTypeValue {
    PRODUCT = 'product',
    BIKE = 'bike',
    STORE = 'store',
    BRAND = 'brand',
    SERVICE_PAGE = 'service-page',
    CATEGORY = 'category',
}

export const API_SEARCH = '/ajax/search';
