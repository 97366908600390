import { IFilterStateHandler, ISelectFilterOption } from '../interfaces';
import { Filter } from '../types';
import AbstractFilterHandler from '../../../includes/filter-state/AbstractFilterHandler';

export default class NullFilterHandler extends AbstractFilterHandler implements IFilterStateHandler {
    public createQueryParams = (_filter: Filter, _params: object): void => {
        // Do nothing
    };

    public resetQueryParams = (_params: object): void => {
        // Do nothing
    };

    public getSelectedOptions = (_filter: Filter): ISelectFilterOption[] => [];

    public updateSelectedOptions = (_filter: Filter, _selectedValues: string[]): void => {
        // Do nothing
    };

    public getSelectionTagComponent = (_filter: Filter, _selectedValues: string[]): React.FunctionComponent | null => null

    public isFilterActive = (_filter: Filter): boolean => false;
}
