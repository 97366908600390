import * as React from 'react';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';
import useSortSelect from '../../hooks/useSortSelect';
import MdcSelect from '../../../select/MdcSelect';
import i18next from 'i18next';

interface ISidebarSortProps {
    sortParamNames: string[];
    currentSortParam: string;

    dispatch: React.Dispatch<any>;
}

const SortSelectComponent: React.FunctionComponent<ISidebarSortProps> = props => {
    const { sortParamNames, currentSortParam, dispatch } = props;

    const [sortSelectOptions, onSortSelect] = useSortSelect(sortParamNames, dispatch);
    const ariaLabel = i18next.t('Sort by', { ns: 'search' });
    return (
        <MdcSelect
            selectedValue={currentSortParam}
            options={sortSelectOptions}
            onChange={onSortSelect}
            ariaLabel={ariaLabel}
        />
    );
};

const SortSelect = withMemo(SortSelectComponent);

export default SortSelect;
