import * as React from 'react';
import { sprykerPlaceholderImage, sprykerProductImageSrcSet } from '../../../react/common/lib';
import { IProductItemImages } from '../../interfaces';
import { pushProductClickToDataLayer, buildConfigProduct } from 'PyzShopUi/scripts/shop-ui/services/ProductClickTrackingService'
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { useProductListName } from './ProductItemListNameContext';
import Utils from 'PyzShopUi/scripts/utils/utils';

interface IProductItemCompactProps {
    name: string;
    url: string;
    images: IProductItemImages;
    sku: string;
    merchantPrice: number;
    customerPrice: number;
    isKeyboardAccessible?: boolean;
    isInViewport?: boolean;
}

const ProductItemCompact: React.FunctionComponent<IProductItemCompactProps> = ({ sku, name, customerPrice, merchantPrice, isKeyboardAccessible, isInViewport, ...props }) => {
    const imgId = `product-compact-image-cover-${sku}-${Utils.getRandomId()}`;
    const listName = useProductListName();
    const { translate } = useTranslationHook();
    const imgAlt = name?.length < 10 ? `${translate('product', { ns: 'url' })} ${name}` : name;

    const onClickHandler = () => {
        const products = buildConfigProduct({ id: sku, name, customerPrice, merchantPrice });

        pushProductClickToDataLayer({ actionField: { list: listName }, products })
    }

    return (
        <div className='product-item product-item-compact' onClick={onClickHandler} onKeyDown={(e) => {
            if(e.key === "Enter") {
                onClickHandler();
            }
        }}>
            <div className='product-image-cover lazy-image-container'>
                <a itemProp='url' className='product-image-cover__full-link' href={props.url} {...(isInViewport ? { "aria-labelledby": imgId } : { 'aria-label': imgAlt })} tabIndex={isKeyboardAccessible ? 0 : -1} aria-hidden={isKeyboardAccessible ? "false" : "true"} />
                <img
                    id={imgId}
                    alt={imgAlt}
                    src={sprykerPlaceholderImage()}
                    className='product-image lazyload'
                    data-src={props.images.externalUrlThumbnail}
                    data-srcset={sprykerProductImageSrcSet(props.images)}
                />
            </div>
        </div>
    );
}

export default ProductItemCompact;
