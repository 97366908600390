export default function defAddLanguageSlugToUrl(urlWithoutLanguageSlug: string) {
    const slugRegex = new RegExp('^(\/[a-z]{2})\/.*?|(\/[a-z]{2})$');
    const results = slugRegex.exec(window.location.pathname);

    if (results === null) {
        return urlWithoutLanguageSlug;
    }

    const slug = results.filter(x => x);

    return slug[1] + urlWithoutLanguageSlug;
}
