import * as React from 'react';
import TagItem from '../../../TagItem';
import { ISetSearchFieldTextPayload, setSearchFieldText } from '../../actions/search';

interface ISearchFieldLabelProps {
    label: string;
    dispatch: React.Dispatch<any>;
}

const SearchFieldLabel: React.FunctionComponent<ISearchFieldLabelProps> = props => {
    const deselectSearchFieldLabel = (): void => {
        const payload: ISetSearchFieldTextPayload = {
            newSearchTerm: '',
        }

        props.dispatch(setSearchFieldText(payload));
    };

    return (
        <TagItem
            key={'searchFieldLabel'}
            label={props.label}
            onClick={deselectSearchFieldLabel}
        />
    );
};

export default SearchFieldLabel;
