import * as React from 'react';
import { MdcTabBar } from '../../shop-ui/components/mdc-tab/MdcTabBar';
import { ICatalogSearchPageActionState } from '../interfaces';
import { useEnhancedReducer } from '../../shop-ui/includes/enhanced-reducer';
import { catalogSearchPageReducer } from '../reducer';
import {
    initCatalogSearchPageData,
    updateCatalogSearchPageSelectedTab
} from 'PyzShopUi/scripts/catalog-search-page/actions/catalogSearchPage';
import { API_SEARCH, SearchTypeValue } from '../constants';
import { IMdcTabItem } from '../../shop-ui/interfaces';
import FilterStateService from '../../shop-ui/components/search-page/services/FilterStateService';
import { FILTER_TYPE_MAPPING } from '../../catalog-page/constants';
import ProductListContainer from '../../shop-ui/components/product-list/ProductListContainer';
import BikeListContainer from '../../shop-ui/components/bike-list/BikeListContainer';
import ServicePageResult from './service-page/ServicePagesResult';
import { IApiQueryData } from '../../shop-ui/components/search-page/interfaces';
import StoreResult from './store/StoreResult';
import BrandListContainer from '../../shop-ui/components/brand-list/BrandListContainer';
import { DEFAULT_CURRENCY_FACTOR } from 'PyzShopUi/scripts/shop-ui/constants';
import CategoryResult from 'PyzShopUi/scripts/catalog-search-page/components/category/CategoryResult';
import { ListNameContext } from 'PyzShopUi/scripts/shop-ui/components/product-item/ProductItemListNameContext'

const CATALOG_SEARCH_PAGE_LIST_NAME = 'Search';

interface ICatalogSearchPageProps {
    defaultParameter: IApiQueryData;
    csrfToken: string;
}

const emptyCatalogSearchPageActionState: ICatalogSearchPageActionState = {
    type: '',
    result: {
        sort: {
            sortParamNames: [],
            currentSortParam: ''
        },
        pagination: {
            numFound: 0,
            currentPage: 1,
            maxPage: 1,
            currentItemsPerPage: 0,
            config: {
                defaultItemsPerPage: 48,
                validItemsPerPageOptions: []
            }
        },
        resultItems: [],
        filters: [],
        storeConfig: {
            currency: '',
            currencyFactor: DEFAULT_CURRENCY_FACTOR,
            priceMode: 'GROSS_MODE'
        },
        extraInformation: {
            breadcrumb: null
        }
    },
    categories: [],
    categoriesStorage: {},
};

export const CatalogSearchPage: React.FunctionComponent<ICatalogSearchPageProps> = props => {
    const { defaultParameter, csrfToken } = props;

    const [state, dispatch] = useEnhancedReducer(catalogSearchPageReducer, emptyCatalogSearchPageActionState);
    const { categories, type } = state;

    React.useEffect(() => {
        dispatch(initCatalogSearchPageData(defaultParameter));
    }, []);

    const updateSelectedTab = (
        tabName: string,
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        event.persist();

        if (event.ctrlKey) {
            return;
        }

        event.preventDefault();

        state.categoriesStorage[type] = {
            ...(categories.find(c => c.value === type) || {}),
            url: window.location.pathname + window.location.search,
        };
        dispatch(updateCatalogSearchPageSelectedTab(tabName));
    };

    const prepareTabs = (): IMdcTabItem[] => categories.map(category => {
        const { label, value, count, url } = category;

        return {
            value,
            url,
            content: (
                <React.Fragment>
                    <div className="tab-item-header heading4">{label}</div>
                    <p className="tab-item-count">({count})</p>
                </React.Fragment>
            )
        };
    });

    const tabResultDefaultParameter = { ...defaultParameter };
    if (type !== '') {
        tabResultDefaultParameter.searchType = type;
    }

    const displayTabResult = () => {
        switch (type) {
            case SearchTypeValue.PRODUCT:
                return (
                    <ProductListContainer
                        apiUrl={API_SEARCH}
                        defaultParameter={tabResultDefaultParameter}
                        filterStateService={new FilterStateService(FILTER_TYPE_MAPPING)}
                        isCompactMode={false}
                        searchResponse={state.result}
                    />
                );

            case SearchTypeValue.BIKE:
                return (
                    <BikeListContainer
                        apiUrl={API_SEARCH}
                        defaultParameter={tabResultDefaultParameter}
                        filterStateService={new FilterStateService(FILTER_TYPE_MAPPING)}
                        isCompactMode={false}
                        searchResponse={state.result}
                        csrfToken={csrfToken}
                    />
                );

            case SearchTypeValue.STORE:
                return (
                    <StoreResult
                        defaultParameter={tabResultDefaultParameter}
                        dispatch={dispatch}
                        searchResponse={state.result}
                    />
                );

            case SearchTypeValue.BRAND:
                return (
                    <BrandListContainer
                        apiUrl={API_SEARCH}
                        defaultParameter={tabResultDefaultParameter}
                        filterStateService={new FilterStateService(FILTER_TYPE_MAPPING)}
                        isCompactMode={false}
                        searchResponse={state.result}
                    />
                );

            case SearchTypeValue.SERVICE_PAGE:
                return (
                    <ServicePageResult
                        defaultParameter={tabResultDefaultParameter}
                        dispatch={dispatch}
                        searchResponse={state.result}
                    />
                );
            case SearchTypeValue.CATEGORY:
                return (
                    <CategoryResult
                        defaultParameter={tabResultDefaultParameter}
                        dispatch={dispatch}
                        searchResponse={state.result}
                    />
                );
            default:
                return null;
        }
    };

    const displayTabBar = () => {
        const hideTabBar = categories.length === 0 || (typeof tabResultDefaultParameter.showTabBar !== 'undefined'
            && tabResultDefaultParameter.showTabBar === false);
        if (hideTabBar) {
            return null;
        }

        return (
            <MdcTabBar
                additionalClassNames="catalog-search-tab thin-line"
                onTabClick={updateSelectedTab}
                selectedTab={type}
                tabs={prepareTabs()}
            />
        );
    };

    return (
        <div className="catalog-search-page">
            <ListNameContext.Provider value={CATALOG_SEARCH_PAGE_LIST_NAME}>
                {displayTabBar()}
                {displayTabResult()}
            </ListNameContext.Provider>
        </div>
    );
};
