import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import MultiLoadAdapter from 'i18next-multiload-backend-adapter';
import * as packageJson from '@louis/translations/package.json';
import { initReactI18next } from "react-i18next";
import LanguageHandler from './language';

i18n
    .use(MultiLoadAdapter)
    .use(initReactI18next)
    .init({
        backend: {
            backend: HttpApi,
            backendOption: {
                loadPath: TRANSLATIONS_ROOT_PATH + '/{{lng}}.json',
                allowMultiLoading: true,
                crossDomain: true
            }
        },
        lng: LanguageHandler.getCurrentLanguage(),
        ns: packageJson.louisTranslations.textDomains,
        fallbackLng: false,
        nsSeparator: false,
        keySeparator: false,
        react: { useSuspense: true }
    });

export default i18n;