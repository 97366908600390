import { ISearchPageActionState } from '../interfaces';
import useFindCategoryByKey from 'PyzShopUi/scripts/shop-ui/components/search-page/hooks/useFindCategoryByKey';

export const doUpdateActiveCategoryName = (
    state: ISearchPageActionState,
    activeCategoryKey?: string | null
): ISearchPageActionState => {
    const category = useFindCategoryByKey(
        state.categoriesTree,
        activeCategoryKey || state.initCategoryKey
    );

    if (!category) {
        return state;
    }

    return {
        ...state,
        activeCategoryName: category.name,
    };
};

export const doUpdateHeadTitle = (
    state: ISearchPageActionState,
    activeCategoryKey?: string | null
): ISearchPageActionState => {
    const category = useFindCategoryByKey(
        state.categoriesTree,
        activeCategoryKey || state.initCategoryKey
    );

    if (!category) {
        return state;
    }

    return {
        ...state,
        headTitle: category.metaTitle,
    };
};
