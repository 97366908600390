import i18next from '../i18n';
import EventBus from '../../utils/eventBus';

export class DrillDownNavigation {

    public readonly ACTIVE_CLASS: string = 'active';
    public readonly NAVIGATION_ID: string = 'responsive-navigation';
    public readonly ALL_LIST_ITEMS_SELECTOR: string = '.navigation-root li';
    public readonly ACTIVE_LEVEL_LIST_SELECTOR: string = 'ul.active';
    public readonly NAVIGATION_WRAPPER_SELECTOR: string = '.navigation-wrapper';

    private navigationElement: HTMLElement;
    private eventBus: EventBus;
    private menuOpen: boolean = false;
    private backButton: HTMLElement;
    private readonly header: HTMLElement;
    private readonly myLouisLink: HTMLElement;
    private readonly contactLink: HTMLElement;

    constructor(navigationElement: HTMLElement, eventBus: EventBus) {
        this.navigationElement = navigationElement;
        this.eventBus = eventBus;

        this.resetNavigationState();

        this.header = document.querySelector('header');
        if (!this.header) {
            return;
        }
        this.myLouisLink = this.header.querySelector('.main-nav-item-login a');
        this.contactLink = this.header.querySelector('.header-contact');
    }

    public activate = (): void => {
        this.addEventListener();
        this.addBackButton();

        const backButtonNeeded: boolean = this.navigationElement.querySelectorAll(this.ACTIVE_LEVEL_LIST_SELECTOR).length >= 1;
        if (backButtonNeeded) {
            this.activateBackButton();
        }

        if (!this.menuOpen) {
            return;
        }
        this.toggleHeaderElementsClasses(true);
    }

    public deactivate = (): void => {
        this.removeEventListener();
        this.removeBackButton();

        if (!this.menuOpen) {
            return;
        }
        this.toggleHeaderElementsClasses(false);
    }

    private activateBackButton = (): void => {
        if (!this.backButton) {
            return;
        }
        this.backButton.classList.add(this.ACTIVE_CLASS);
        this.backButton.setAttribute('aria-label', i18next.t('general.back.button', { ns: 'spryker-core' }));
    }

    private deactivateBackButton = (): void => {
        if (!this.backButton) {
            return;
        }
        this.backButton.classList.remove(this.ACTIVE_CLASS);
    }

    private removeEventListener = (): void => {
        this.navigationElement.querySelectorAll(this.ALL_LIST_ITEMS_SELECTOR).forEach((el: HTMLElement) => el.onclick = undefined);
    }

    private addEventListener = (): void => {
        const navigationToggle: HTMLElement = document.querySelector(`[data-toggle="${this.NAVIGATION_ID}"]`);
        if (navigationToggle) {
            navigationToggle.addEventListener('click', this.toggleNavigation);
        }

        this.navigationElement.querySelectorAll(this.ALL_LIST_ITEMS_SELECTOR).forEach((element: HTMLElement) => {
            element.addEventListener('click', this.getShowNextLevelHandler(element));
        });
        this.addAccessibilityEventListeners();
    }

    private addAccessibilityEventListeners() {
        /*
        Login Button is only visible when user not logged in
        Language selector is only visible when using the EU site
        Variables used to determine proper taborder when reaching end of the currently visible menu
         */
        let isMultiLang = true;
        let isLoggedInButtonVisible = true;

        const logInButtonSelector: HTMLElement = document.querySelector('[data-qa="login-link"]');
        const languageSelector: HTMLElement = document.querySelector('.current-region-language');
        const burgerButtonSelector: HTMLElement = document.querySelector('.menu-icon.header-burger-container');
        const focusCatcherSelector = document.querySelectorAll('.focus-catcher');

        if (!languageSelector) {
            isMultiLang = false;
        }
        if (!logInButtonSelector) {
            isLoggedInButtonVisible = false;
            focusCatcherSelector.forEach((el: HTMLElement) => {
                el.addEventListener('focus', event => {
                    if (languageSelector) {
                        languageSelector.focus();
                    } else {
                        burgerButtonSelector.focus();
                    }
                });
            });
        } else {
            //Focus catchers are needed only for pages that do not have login, so delete them from DOM
            focusCatcherSelector.forEach((el: HTMLElement) => {
                el.remove();
            });
        }

        if (isMultiLang) {
            /*
            Language selector is out of the menu tree, so we need to:
                Going back - go to log in button (if visible) or burger
                Going forward - go to burger button
            */
            languageSelector.addEventListener('keydown', event => {
                //SHIFT + TAB, going back
                if (event.shiftKey && event.keyCode == 9) {
                    if (isLoggedInButtonVisible) {
                        event.preventDefault();
                        logInButtonSelector.focus();
                    } else {
                        burgerButtonSelector.focus();
                    }
                    return;
                }
                //TAB, going forward
                if (event.keyCode == 9) {
                    event.preventDefault();
                    burgerButtonSelector.focus();
                }
            });
        }
        if (isLoggedInButtonVisible) {
            /*
            Language selector is out of the menu tree, so we need to manually handle the tab order when the login
            button is focused:
                Going back - as expected
                Going forward - go to the language(if present) burger selector(if language not present)
             */
            logInButtonSelector.addEventListener('keydown', event => {
                //SHIFT + TAB, going back
                //This is needed, as otherwise we can loose the BACK function when tabbing with the other event
                if (event.shiftKey && event.keyCode == 9) {
                    return;
                }
                //TAB, going forward
                if (event.keyCode == 9) {
                    event.preventDefault();
                    if (isMultiLang) {
                        languageSelector.focus();
                    } else {
                        burgerButtonSelector.focus();
                    }
                }
            });
        }
        /*
        When focused the burger button we need to:
            Going back - go to login / langauge selector selection link
            Going forward - as expected
            Clicking enter - open/close menu
        */
        burgerButtonSelector.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                this.toggleNavigation();
            }
            if (this.menuOpen) {
                //SHIFT + TAB, going back
                if (event.shiftKey && event.keyCode == 9) {
                    event.preventDefault();
                    if (isMultiLang) {
                        languageSelector.focus();
                    } else if (isLoggedInButtonVisible) {
                        logInButtonSelector.focus();
                    }
                    return;
                }
            }
        });

    }

    private getShowNextLevelHandler = (element: HTMLElement): (ev: MouseEvent) => void =>
        (e: MouseEvent): void => {
            if (element.classList.contains('has-children')) {
                e.preventDefault();
                const nextLevel: HTMLElement = element.querySelector('ul');
                nextLevel.classList.add(this.ACTIVE_CLASS);
                this.navigationElement.querySelector(this.NAVIGATION_WRAPPER_SELECTOR).scrollTop = 0;
                this.activateBackButton()

                //Hidden pages of the menu need to be removed from taborder
                this.accessibilityRemoveAddItemsFromMenu(element, nextLevel);
            }
            e.stopPropagation();
        }

    private accessibilityRemoveAddItemsFromMenu(element: HTMLElement, nextLevel: HTMLElement) {
        const thisMenuUL = element.closest('ul');
        const thisMenuItems = thisMenuUL.querySelectorAll('li > a');
        //Remove all items from the taborder
        thisMenuItems.forEach(menuItem => {
            if (!menuItem.classList.contains('button')) {
                menuItem.setAttribute('tabindex', '-1');
            }
        });
        //Return of the active items in the taborder
        nextLevel.querySelectorAll('li > a').forEach((element) => {
            element.removeAttribute('tabindex');
        });
    }

    private toggleNavigation = (): void => {
        this.menuOpen = !this.menuOpen;
        this.toggleHeaderElementsClasses(this.menuOpen);
        if (this.menuOpen) {
            this.accessibilityHideShowMainMenu(false);
            return;
        } else {
            this.accessibilityHideShowMainMenu(true);
        }
        this.resetNavigationState();
    };

    private accessibilityHideShowMainMenu = (activate = false): void => {
        const mainMenuLinks = document.querySelectorAll('header .header-links > a');
        mainMenuLinks.forEach((element: HTMLElement): void => {
            if (!activate) {
                element.setAttribute('tabindex', '-1');
            } else {
                element.removeAttribute('tabindex');
            }

        });
    };

    private resetNavigationState = (): void => {
        this.navigationElement.querySelectorAll(`.${this.ACTIVE_CLASS}`)
            .forEach((element: HTMLElement) => element.classList.remove(this.ACTIVE_CLASS));
        this.addBackButton();
        this.deactivateBackButton();
    }

    private addBackButton = (): void => {
        if (this.backButton) {
            return;
        }

        const firstChild = this.navigationElement.firstChild;
        const backButton: HTMLElement = document.createElement('button');
        backButton.classList.add('navi-back-button');
        backButton.setAttribute('aria-label', 'Back');
        const icon: HTMLElement = document.createElement('i');
        icon.className = ('header-icon icon icon-chevron-thin-left');
        backButton.append(icon);

        this.backButton = this.navigationElement.insertBefore(backButton, firstChild);

        this.backButton.onclick = this.handleBackButtonClick();
    }

    private removeBackButton = (): void => {
        if (!this.backButton) {
            return;
        }
        this.backButton.remove();
        this.backButton = null;
    }

    private handleBackButtonClick = (): (e: MouseEvent) => void =>
        (e: MouseEvent): void => {
            const activeLevelLists: NodeListOf<HTMLElement> = this.navigationElement.querySelectorAll(this.ACTIVE_LEVEL_LIST_SELECTOR);
            const lastActiveLevelList: HTMLElement = activeLevelLists[activeLevelLists.length - 1];
            if (!lastActiveLevelList) {
                return;
            }
            this.navigationElement.querySelector(this.NAVIGATION_WRAPPER_SELECTOR).scrollTop = 0;
            lastActiveLevelList.classList.remove(this.ACTIVE_CLASS);
            if (activeLevelLists.length === 1) {
                this.deactivateBackButton();
            }
            this.accessibilityHandleBackButton();
            e.stopPropagation();
        };

    private accessibilityHandleBackButton() {
        //Remove all other levels of menus from the taborder
        this.accessibilityHideShowMainMenu(false);

        //Focus first active menu item after going back
        const allActiveLevels = document.querySelectorAll('ul.navigation-root ul.active');
        let activeLevel = allActiveLevels[allActiveLevels.length - 1];
        if (activeLevel) {
            if (activeLevel.firstChild) {
                activeLevel.firstChild.querySelector('a').focus();
            }

            //Activate active menu items by adding them in the taborder
            activeLevel.childNodes.forEach((element: HTMLElement) => {
                if (element.firstChild) {
                    element.firstChild.removeAttribute('tabindex');
                }
            });
        } else {
            // Root level
            let rootElevelElement = document.querySelector('ul.navigation-root > li:first-child > a');
            if (rootElevelElement) {
                rootElevelElement.focus();
            }
            document.querySelectorAll('ul.navigation-root > li > a').forEach((element: HTMLElement) => {
                element.removeAttribute('tabindex');
            });
        }
    }

    private toggleHeaderElementsClasses = (state: boolean): void => {
        this.addClassesToElement(this.header, ['responsive-navigation-open'], state);
        this.addClassesToElement(this.myLouisLink, ['button', 'button-red'], state);
        this.addClassesToElement(this.contactLink, ['button', 'button-grey'], state);
    }

    private addClassesToElement = (element: HTMLElement, cssClasses: string[], state: boolean): void => {
        if (!element) {
            return;
        }
        cssClasses.forEach((cssClass: string): void => state ? element.classList.add(cssClass) : element.classList.remove(cssClass));
    }
}

export default class DrillDownNavigationHandler {
    public static create = (navigationElement: HTMLElement, eventBus: EventBus): DrillDownNavigation => new DrillDownNavigation(navigationElement, eventBus)
}
