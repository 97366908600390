import * as React from 'react';
import SearchSuggestionResultContainer from './SearchSuggestionResultContainer';
import { LargeScreenLayout } from '../components/screen/LargeScreenLayout';
import { SmallScreenLayout } from '../components/screen/SmallScreenLayout';
import useFetchSearchSuggestion from 'PyzShopUi/scripts/search-suggestion/hooks/useFetchSearchSuggestion';

interface ISearchSuggestionContainerProps {
    searchValue: string;
    isVisible: boolean;
    isSmallScreen: boolean;
    minSearchStringLengthToGetData: number;
    searchUrl: string;

    onClose(): void;
}

const SearchSuggestionContainer: React.FunctionComponent<ISearchSuggestionContainerProps> = props => {
    const {
        searchValue,
        isVisible,
        isSmallScreen,
        minSearchStringLengthToGetData,
        searchUrl,
        onClose
    } = props;

    const suggestions = useFetchSearchSuggestion(searchValue, minSearchStringLengthToGetData);

    React.useEffect(() => {
        if (isVisible) {
            if (isSmallScreen) {
                document.querySelector('header').classList.add('height-100');
            }

            if (suggestions.length) {
                document.body.classList.add('scroll-disable');
            }

            return;
        }

        document.body.classList.remove('scroll-disable');
        document.querySelector('header').classList.remove('height-100');
    }, [isVisible, suggestions.length])

    if (isSmallScreen) {
        return (
            <SmallScreenLayout
                searchValue={searchValue}
                isVisible={isVisible}
                searchUrl={searchUrl}
                onClose={onClose}
            >
                <SearchSuggestionResultContainer data={suggestions} searchValue={searchValue}/>
            </SmallScreenLayout>
        );
    }

    if (!suggestions || !suggestions.length) {
        return null;
    }

    return (
        <LargeScreenLayout isVisible={isVisible} onClose={onClose}>
            <SearchSuggestionResultContainer data={suggestions} searchValue={searchValue}/>
        </LargeScreenLayout>
    );
};

SearchSuggestionContainer.defaultProps = {
    searchValue: ''
};

export default SearchSuggestionContainer;
