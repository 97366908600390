import ITabOptions, { ITab } from '../interfaces/ITabOptions';
import EventBus from '../utils/eventBus';
import IEventBusSubscription from '../interfaces/Utils/IEventBusSubscription';
import { ETabEvent } from 'PyzShopUi/scripts/modules/events/tab.event';

export default class Tabs {

    private static CLASSES = {
        ACTIVE_CLASS: 'is-active',
    };

    private options: ITabOptions;
    private eventBus: EventBus;
    private openTabSubscription: IEventBusSubscription;
    private tabClickListeners: any[];

    public constructor(options: ITabOptions) {
        this.options = options;
        this.eventBus = options.eventBus;
        this.tabClickListeners = [];
    }

    public activate = (headerToActivate?: HTMLElement): void => {
        this.addEventListener();

        const tabToActivate: ITab = this.getTabToActivate(headerToActivate);

        if (tabToActivate) {
            this.activateTab(tabToActivate);
        } else {
            this.activateTab(this.options.tabs[0]);
        }
    }

    public deactivate = (): void => {
        this.removeEventListener();
        this.deactivateAllTabs();
    }

    private addEventListener = (): void => {
        this.options.tabs.forEach((tab: ITab, loopIndex: number): void => {
            const clickListener = this.activateTab.bind(this, tab);
            this.tabClickListeners[loopIndex] = clickListener;
            tab.header.addEventListener('click', clickListener);
            tab.header.addEventListener('keydown', (e) => {
                if (e.key === 'Enter') {
                    clickListener(e);
                }
            });
        });

        this.openTabSubscription = this.eventBus.subscribe(ETabEvent.OPEN, this.openTab);
    }

    private removeEventListener = (): void => {
        this.options.tabs.forEach((tab: ITab, loopIndex: number): void => {
            tab.header.removeEventListener('click', this.tabClickListeners[loopIndex]);
        });

        if (this.openTabSubscription) {
            this.openTabSubscription.unsubscribe();
        }
    }

    private activateTab = (tab: ITab): void => {
        this.deactivateAllTabs();

        tab.header.classList.add(Tabs.CLASSES.ACTIVE_CLASS);
        tab.content.classList.add(Tabs.CLASSES.ACTIVE_CLASS);

        this.options.eventBus.publish(ETabEvent.ACTIVATED, tab.header);
    }

    private openTab = (headerToActivate: HTMLElement): void => {
        const tabToActivate = this.getTabToActivate(headerToActivate);

        if (tabToActivate) {
            this.activateTab(tabToActivate);
        }
    }

    private getTabToActivate = (headerToActivate?: HTMLElement): ITab => {
        let tabToActivate: ITab = null;

        if (headerToActivate) {
            this.options.tabs.forEach((tab: ITab): void => {
                if (tab.header === headerToActivate) {
                    tabToActivate = tab;
                }
            });
        }

        return tabToActivate;
    }

    private deactivateAllTabs = (): void => {
        this.options.tabs.forEach((tabToDeactivate: ITab) => {
            tabToDeactivate.header.classList.remove(Tabs.CLASSES.ACTIVE_CLASS);
            tabToDeactivate.content.classList.remove(Tabs.CLASSES.ACTIVE_CLASS);
        });
    }
}
