import { ISearchPageActionState } from '../interfaces';

export const doUpdatePageState = (state: ISearchPageActionState, data: ISearchPageActionState): ISearchPageActionState => ({
    ...state,
    ...data
});

export const doUpdatePageLoading = (state: ISearchPageActionState, newState: boolean): ISearchPageActionState => ({
    ...state,
    isPageLoading: newState,
});

export const doUpdateSkipBaseOnApiQueryParams = (state: ISearchPageActionState, status: boolean): ISearchPageActionState => ({
    ...state,
    skipUpdateBaseOnApiQueryParams: status,
});
