import * as React from 'react';
import { createRoot } from 'react-dom/client';

import Rubric from './components/Rubric';
import { ERubricAttribute } from './constants';

export default class MainRubricHandler {
    public static init = (): void => {
        const rubrics: NodeListOf<HTMLElement> = document.querySelectorAll('div [data-spryker-rubric="true"]');

        if (!rubrics.length) {
            return;
        }

        rubrics.forEach(rubric => {
            this.initSingle(rubric);
        });
    }

    public static initSingle = (rubricElement: Element) => {
        const apiUrl = new URL(rubricElement.getAttribute(ERubricAttribute.API_URL), window.location.origin);
        const skeletonName = rubricElement.getAttribute(ERubricAttribute.SKELETON_NAME);

        createRoot(rubricElement).render(
            <Rubric
                apiUrl={apiUrl}
                skeletonName={skeletonName}
            />
        );
    }

    public static destroySlider = (sliderElement: Element) => {
        createRoot(sliderElement).unmount();
    }
}
