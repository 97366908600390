import * as React from 'react';

interface ILoadingProps {
    classes?: string;
    animationClasses?: string;
}

const Loading: React.FunctionComponent<ILoadingProps> = props => (
    <div className={`${props.classes} loading`}>
        <div className={`${props.animationClasses} loading-animation`}/>
    </div>
);

Loading.defaultProps = {
    classes: '',
    animationClasses: ''
}

export default Loading;
