import * as React from "react";
import classNames from "classnames";
import { ISelectFilterOption } from "../../../../../shop-ui/components/search-page/interfaces";
import { withMemo } from "PyzShopUi/scripts/react/common/hoc/withMemo";
import { VariantMap } from "PyzShopUi/scripts/product-detail-page/interfaces";
import { MdcSelectSelection } from "PyzShopUi/scripts/product-detail-page/components/VarriantSelection/MdcSelectSelection";
import ProductVariantDataHandler from "PyzShopUi/scripts/product-detail-page/includes/productVariantData";
import { redirectToConcrete } from "PyzShopUi/scripts/product-detail-page/includes/redirectToConcrete";
import { filterMatchingAttributes } from "PyzShopUi/scripts/product-detail-page/includes/variant-helper/variantMap";

interface ISizeFilterOptionsProps {
    additionalClassName?: string;
    optionList: ISelectFilterOption[];
    onScrollChange?(event: React.UIEvent<HTMLUListElement, UIEvent>): void;
    onToggleItem?(value: string, isSelected: boolean): void;
    variants?: VariantMap;
    isKeyboardFocusable?: boolean
}

/**
 * For a given set of variants, this function will return a subset that have the same
 * size as the given activeConcreteOption
 */
const getVariantsForSize = (
    variants: VariantMap,
    activeConcreteOption?: ISelectFilterOption
) => {
    if (!activeConcreteOption || !variants) {
        // if there is no concrete selected, just return an empty object
        return {};
    }

    return Object.entries(variants).reduce<VariantMap>((acc, [key, value]) => {
        if (value.size !== activeConcreteOption.value) {
            return acc;
        }

        return { ...acc, [key]: value };
    }, {});
};

/**
 * For a given VariantMap this function will return the concrete id for the variant
 * that is currently selected. Which variant is selected is decided by the
 * filter_article_number query param
 */
const getCurrentConcreteId = (variants: VariantMap) => {
    // if there are no variants, return
    if (Object.keys(variants).length === 0) {
        return;
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    const filterArticleNumber = urlSearchParams.get("filter_article_number");

    if (!filterArticleNumber || !variants) {
        return;
    }

    const entry = Object.entries(variants).find(
        ([_, v]) => v.variantId === filterArticleNumber
    );

    if (!entry) {
        return;
    }

    return entry[0];
};

const SizeFilterOptionsComponent: React.FunctionComponent<
    ISizeFilterOptionsProps
> = (props) => {
    const activeConcreteOption = props.optionList.find((o) => o.selected);

    const variantsForSize = getVariantsForSize(
        props.variants,
        activeConcreteOption
    );

    const [currentConcreteId, setCurrentConcreteId] = React.useState(
        // due to the fact that the rerendering of this component and the change of the
        // query param are asynchronical, we can use this for the initial state only
        // from that point onwards the new currentConcreteId will be set via the click
        // handler of the respective options
        getCurrentConcreteId(variantsForSize)
    );

    const isShopPimsProductQueueActiveElement = document.querySelector('[data-is-shop-pims-product-queue-active]');
    const isShopPimsProductQueueActive = isShopPimsProductQueueActiveElement ? isShopPimsProductQueueActiveElement.getAttribute('data-is-shop-pims-product-queue-active') : undefined;

    const filterClassName = isShopPimsProductQueueActive ? 'grid-filter-pims' : 'grid-filter';
    const filterItemClassName = isShopPimsProductQueueActive ? 'grid-filter-pims__item' : 'grid-filter__item';


    const displayItem = () =>
        props.optionList.map((option) => {
            const classes = classNames({
                checked: option.selected,
                disabled: option.disabled,
            });

            const variantsForNextSize = getVariantsForSize(
                props.variants,
                option
            );

            const matchingAttributes = filterMatchingAttributes(
                [{ type: "size", value: option.value }],
                variantsForNextSize
            );

            const onClick = () => {
                props.onToggleItem(option.value, option.selected);
                if (matchingAttributes.size > 0) {
                    setCurrentConcreteId(
                        // this is done to replicate the way that the concrete is selected
                        // in AttributeSelection.tsx to make sure that the selected option in the
                        // dropdown and the concreteId that is set as query param match
                        `${matchingAttributes.values().next().value}`
                    );
                }
            }

            return (
                <li
                    key={option.value}
                    className={`${filterItemClassName} ${classes}`}
                    data-qa="size-filter-option"
                >
                    <span
                        onClick={onClick}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                onClick()
                            }
                        }}
                        role="button"
                        tabIndex={0}
                    >
                        {" "}
                        {option.label}{" "}
                    </span>
                </li>
            );
        });

    return (
        <>
            <ul
                onScroll={props.onScrollChange}
                className={`${filterClassName} ${props.additionalClassName}`}
                {...(props.isKeyboardFocusable ? { 'tabIndex': 0 } : {})}
            >
                {displayItem()}
            </ul>
            {Object.entries(variantsForSize).length > 1 && (
                <MdcSelectSelection
                    onSelectConcrete={redirectToConcrete}
                    // for some reason onSelectFilter is a mandatory prop but it's not needed here
                    // so I've passed a no-op
                    onSelectFilter={() => {}}
                    activeConcreteId={currentConcreteId}
                    variantMap={variantsForSize}
                    variantNames={ProductVariantDataHandler.getArticleAllVariants()}
                />
            )}
        </>
    );
};

SizeFilterOptionsComponent.defaultProps = {
    additionalClassName: "",
    onScrollChange: () => ({}),
    onToggleItem: () => ({}),
};

const SizeFilterOptions = withMemo(SizeFilterOptionsComponent);

export default SizeFilterOptions;
