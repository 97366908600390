import EventBus from '../../utils/eventBus';
import IEventBusSubscription from '../../interfaces/Utils/IEventBusSubscription';
import {Simulate} from 'react-dom/test-utils';
import mouseOver = Simulate.mouseOver;

export class MegaMenuNavigation {

    public readonly MENU_OFFSET_BOTTOM_IN_PX: number = 10;
    public readonly HEAD_LINE_COUNT_PER_LEVEL: number = 1;
    public readonly LEVEL_3_COLUMN_COUNT: number = 2;
    public readonly OPEN_DELAY_IN_MS: number = 200;

    private navigationElement: HTMLElement;
    private level1lists: NodeListOf<HTMLElement>;
    private level1listItems: NodeListOf<HTMLElement>;
    private level2listItems: NodeListOf<HTMLElement>;
    private level3listItems: NodeListOf<HTMLElement>;
    private level1listSubmenus: NodeListOf<HTMLElement>;
    private level2listSubmenus: NodeListOf<HTMLElement>;
    private navigationRootLinks: NodeListOf<HTMLElement>;
    private eventBus: EventBus;
    private readonly backgroundElement: HTMLElement;

    private hoverTimeout: number = null;
    private openedMenu: HTMLElement = null;

    private eventBusSubscription: IEventBusSubscription;

    constructor(navigationElement: HTMLElement, eventBus: EventBus) {
        this.navigationElement = navigationElement;
        this.eventBus = eventBus;

        this.level1lists = this.navigationElement.querySelectorAll('.level-1');
        this.level1listItems = this.navigationElement.querySelectorAll('.level-1 > li');
        this.level2listItems = this.navigationElement.querySelectorAll('.level-2 > li');
        this.level3listItems = this.navigationElement.querySelectorAll('.level-3 > li');
        this.level1listSubmenus = this.navigationElement.querySelectorAll('.level-1 ul');
        this.level2listSubmenus = this.navigationElement.querySelectorAll('.level-2 ul');
        this.navigationRootLinks = this.navigationElement.querySelectorAll('.navigation-root > .main-nav-item');
        this.backgroundElement = document.createElement('div');
        this.backgroundElement.classList.add('navigation-background');
    }

    public activate = (): void => {
        this.addEventListener();
        this.recalculateListHeightsAndAddMoreLink();
    }

    public deactivate = (): void => {
        this.clearHeightsOnAllLevels();
        this.removeEventListener();
    }

    private addEventListener = (): void => {
        this.eventBusSubscription = this.eventBus.subscribe('window:resize:debounced', this.recalculateListHeightsAndAddMoreLink);

        this.navigationRootLinks.forEach((link) => {
            link.addEventListener('mouseover', this.mouseOver);
            link.addEventListener('mouseleave', this.mouseLeaveHandler);
            // Keyboard navigation Root level
            link.addEventListener('keydown', this.handleRootLevelItems);
        });
        // Keyboard navigation of Level 1-3
        this.level1listItems.forEach((link) => {
            this.handleLevel12Items(link, 'ul.level-2');
        });
        this.level2listItems.forEach((link) => {
            this.handleLevel12Items(link, 'ul.level-3');
        });
        this.level3listItems.forEach((link) => {
            link.addEventListener('keydown', (e) => {
                // Block handling from upper level menus
                e.stopPropagation();
            });
        });

    }

    private handleLevel12Items(link: HTMLElement, selector: string) {
        link.addEventListener('keydown', (e) => {
            //Stop event from handling of the upper level menu handlers
            e.stopPropagation();
            if (e.key === 'Enter' && link.classList.contains('has-children')) {
                e.preventDefault();
                link.querySelectorAll(selector).forEach((ulElement) => {
                    // Save action and reference to the item to open/close menu after clearing the previous state
                    let action = 'open';
                    if (ulElement.classList.contains('open')) {
                        action = 'remove';
                    }
                    // Clear opened menus on upper level
                    if (selector === 'ul.level-2') {
                        this.level1listSubmenus.forEach((allSubmenuItem) => {
                            allSubmenuItem.classList.remove('open');
                        });
                    }
                    // Clear opened menus on lower level
                    if (selector === 'ul.level-3') {
                        this.level2listSubmenus.forEach((allSubmenuItem) => {
                            allSubmenuItem.classList.remove('open');
                        });
                    }
                    // Open the menu that we previously saved as reference, with the appropriate action
                    if (action === 'open') {
                        ulElement.classList.add('open');
                    } else {
                        ulElement.classList.remove('open');
                    }
                });
            }
        });
    }

    private handleRootLevelItems = (e): void => {
        if (e.key === 'Enter' && e.currentTarget.classList.contains('has-children')) {
            e.preventDefault();
            const currentMenu: HTMLElement = e.currentTarget.querySelector('.menu');
            if (currentMenu.classList.contains('open')) {
                this.mouseLeaveHandler();
            } else {
                this.mouseOver(e);
            }

        }
    };

    private removeEventListener = (): void => {
        this.navigationRootLinks.forEach((link) => {
            link.removeEventListener('mouseover', this.mouseOver);
            link.removeEventListener('mouseleave', this.mouseLeaveHandler);
        });

        if (!this.eventBusSubscription) {
            return;
        }

        this.eventBusSubscription.unsubscribe();
    }

    private mouseOver = (e): void => {
        const currentMenu: HTMLElement = e.currentTarget.querySelector('.menu');
        if (!currentMenu) {
            return;
        }

        if (!this.hoverTimeout) {
            this.hoverTimeout = setTimeout(() => {
                this.openedMenu = currentMenu;
                if (this.openedMenu) {
                    this.openedMenu.classList.add('open');
                    document.body.append(this.backgroundElement);
                }
            }, this.OPEN_DELAY_IN_MS);
        }
    }

    private mouseLeaveHandler = () => {
        clearTimeout(this.hoverTimeout);
        this.hoverTimeout = null;
        if (this.openedMenu) {
            this.openedMenu.classList.remove('open');
            document.body.removeChild(this.backgroundElement);
        }
        this.openedMenu = null;
    }

    private recalculateListHeightsAndAddMoreLink = (): void => {
        this.level1lists.forEach((menuLevel1: HTMLElement): void => {
            const menuElement: HTMLElement = menuLevel1.closest('.menu');
            // menuElement is "visibility: hidden" by css but needs "display: block" to be able to calculate heights
            menuElement.style.display = 'block';
            const level2Lists: NodeListOf<HTMLElement> = menuLevel1.querySelectorAll('.level-2');
            const level3Lists: NodeListOf<HTMLElement> = menuLevel1.querySelectorAll('.level-3');

            this.clearMenuElementsHeights(menuLevel1, menuElement, [level2Lists]);
            this.setMenuElementsHeights(menuLevel1.offsetHeight, menuElement, level2Lists, level3Lists);

            const maxElementsPerCol: number = Math.max(
                menuLevel1.childElementCount,
                this.getMappedArrayMax(level2Lists, (element: HTMLElement) => element.childElementCount));
            this.addMoreLinkToLevelListsWhenTooMuch(level3Lists, maxElementsPerCol);
            menuElement.style.display = '';
        });
    }

    private clearHeightsOnAllLevels = (): void => {
        this.level1lists.forEach((menuLevel1: HTMLElement): void => {
            const menuElement: HTMLElement = menuLevel1.closest('.menu');
            const level2Lists: NodeListOf<HTMLElement> = menuLevel1.querySelectorAll('.level-2');
            const level3Lists: NodeListOf<HTMLElement> = menuLevel1.querySelectorAll('.level-3');
            this.clearMenuElementsHeights(menuLevel1, menuElement, [level2Lists, level3Lists]);
        });
    }

    private clearMenuElementsHeights = (menuElement: HTMLElement, menuLevel1: HTMLElement,  levelLists: Array<NodeListOf<HTMLElement>>): void => {
        menuLevel1.style.height = '';
        menuElement.style.height = '';
        levelLists.forEach((levelList: NodeListOf<HTMLElement>): void =>
            levelList.forEach((element: HTMLElement): string => element.style.height = ''));
    }

    private setMenuElementsHeights = (
        maxMenuHeightInPx: number,
        menuElement: HTMLElement,
        level2Lists: NodeListOf<HTMLElement>,
        level3Lists: NodeListOf<HTMLElement>
    ): void => {
        const maxListHeightInPx: number = Math.max(
            maxMenuHeightInPx,
            this.getMappedArrayMax(level2Lists, (element: HTMLElement) => element.offsetHeight)
        );

        const resultMenuHeightInPx: number = maxListHeightInPx + this.MENU_OFFSET_BOTTOM_IN_PX;

        menuElement.style.height = `${resultMenuHeightInPx}px`;
        this.setLevelListsHeightsInPx(level2Lists, resultMenuHeightInPx);
        this.setLevelListsHeightsInPx(level3Lists, resultMenuHeightInPx);
    }

    private addMoreLinkToLevelListsWhenTooMuch = (levelLists: NodeListOf<HTMLElement>, maxElementsPerCol: number): void => {
        levelLists.forEach((levelList: HTMLElement): void => {
            const maxElements: number = (maxElementsPerCol * this.LEVEL_3_COLUMN_COUNT) - this.HEAD_LINE_COUNT_PER_LEVEL;
            this.addMoreLinkWhenTooMuchLinks(maxElements, levelList);
        });
    }

    private addMoreLinkWhenTooMuchLinks = (maxElements: number, list: HTMLElement): void => {
        const allLinksFitInList: boolean = list.childElementCount <= maxElements;
        if (allLinksFitInList) {
            return;
        }

        const lastFittingElement: HTMLElement = list.querySelector(`li:nth-child(${maxElements})`);
        const elementsToHide: NodeListOf<HTMLElement> = list.querySelectorAll(`li:nth-child(${maxElements}) ~ li`);
        elementsToHide.forEach((el: HTMLElement) => el.style.display = 'none');

        const link: HTMLAnchorElement = lastFittingElement.querySelector('a');
        const replacedElementByMoreLinkCount = 1;
        const notFittingElementsCount = (list.childElementCount - maxElements) + this.HEAD_LINE_COUNT_PER_LEVEL + replacedElementByMoreLinkCount;
        // TODO: Add translation
        link.innerText = `+${notFittingElementsCount} weitere`;
    }

    private getMappedArrayMax = (
        levelNodeList: NodeListOf<HTMLElement>,
        mapFunction: (element: HTMLElement) => number
    ): number =>
        Math.max(...(Array.from(levelNodeList)
            .map(mapFunction)));

    private setLevelListsHeightsInPx = (levelLists: NodeListOf<HTMLElement>, heightInPx: number): void => {
        levelLists.forEach((levelList: HTMLElement) => {
            levelList.style.height = `${heightInPx}px`;
        });
    }

}

export default class MegaMenuNavigationHandler {
    public static create = (navigationElement: HTMLElement, eventBus: EventBus): MegaMenuNavigation => new MegaMenuNavigation(navigationElement, eventBus);
}
