import EventBus from './eventBus';
import MaterialDesignComponentHandler from '../modules/materialDesignComponents';
import IDataErrorPublisherOptions from '../interfaces/IDataErrorPublisherOptions';

export default class FormUtils {

    public static enableRadioButton = (formField: HTMLElement): void => {
        const mdcRadio = MaterialDesignComponentHandler.getMDCRadio(formField.querySelector(FormUtils.DEFAULTS.MDC_RADIO_INPUT_SELECTOR));

        if (!mdcRadio) {
            return;
        }

        mdcRadio.getDefaultFoundation().setDisabled(false);
        formField.classList.remove(FormUtils.DEFAULTS.FORM_FIELD_DISABLED_CLASS);
    }

    public static disableRadioButton = (formField: HTMLElement, eventBus?: EventBus, unchecked: boolean = true): void => {
        const field: HTMLElement = formField instanceof HTMLInputElement ? formField : formField.querySelector(FormUtils.DEFAULTS.MDC_RADIO_INPUT_SELECTOR);
        const mdcRadio = MaterialDesignComponentHandler.getMDCRadio(field);

        if (!mdcRadio) {
            return;
        }

        mdcRadio.getDefaultFoundation().setDisabled(true);
        if (unchecked) {
            FormUtils.uncheckRadioButton(formField, eventBus);
        }
        formField.classList.add(FormUtils.DEFAULTS.FORM_FIELD_DISABLED_CLASS);
    }

    public static enableButton = (buttonElement: HTMLElement): void => {
        buttonElement.classList.remove(FormUtils.DEFAULTS.DISABLED_CLASS);
        buttonElement.removeAttribute(FormUtils.DEFAULTS.DISABLED_ATTRIBUTE);
    }

    public static disableButton = (buttonElement: HTMLElement): void => {
        buttonElement.classList.add(FormUtils.DEFAULTS.DISABLED_CLASS);
        buttonElement.setAttribute(FormUtils.DEFAULTS.DISABLED_ATTRIBUTE, FormUtils.DEFAULTS.DISABLED_ATTRIBUTE);
    }

    public static selectOptionIfOnlyOne = (selectField: HTMLElement): boolean => {
        if (!selectField.getAttribute('disabled)')) {
            const options = selectField.getElementsByTagName('option');

            if (options.length <= 2) {
                const firstOptionEmpty = !options[0].value;
                const mdcSelect = MaterialDesignComponentHandler.getMDCSelect(selectField.querySelector('select'));

                if (mdcSelect) {
                    mdcSelect.selectedIndex = firstOptionEmpty ? 1 : 0;
                }

                return true;
            }
        }

        return false;
    }

    public static checkRadioButton = (formField: HTMLElement): void => {
        const mdcRadio = MaterialDesignComponentHandler.getMDCRadio(formField.querySelector(FormUtils.DEFAULTS.MDC_RADIO_INPUT_SELECTOR));
        if (!mdcRadio) {
            return;
        }
        mdcRadio.checked = true;
        return;
    }

    public static uncheckRadioButton = (formField: HTMLElement, eventBus?: EventBus): void => {
        const mdcRadio = MaterialDesignComponentHandler.getMDCRadio(formField.querySelector(FormUtils.DEFAULTS.MDC_RADIO_INPUT_SELECTOR));

        if (!mdcRadio) {
            return;
        }

        const mdcRadioIsChecked = mdcRadio.checked;

        if (!mdcRadioIsChecked) {
            return;
        }

        if (!eventBus) {
            return;
        }

        const formFieldInput = formField.querySelector('input');
        if (formFieldInput) {
            return;
        }

        const dataSwitchEvents: string = formFieldInput.dataset.switch;

        if (!dataSwitchEvents) {
            return;
        }

        dataSwitchEvents.split(',').forEach((eventKey: string): void => {
            eventBus.publish(`contentSwitch:change:${eventKey}`, {
                trigger: formFieldInput,
                target: '',
                value: '',
            });
        });

        const dataErrorEvents = formFieldInput.dataset.errorGroup;
        dataErrorEvents.split(',').forEach((eventKey: string) => {
            const dataErrorOptions: IDataErrorPublisherOptions = {
                trigger: formFieldInput,
                target: formFieldInput.dataset.errorMessage,
            };
            eventBus.publish(`contentError:change:${eventKey}`, dataErrorOptions);
        });
    }

    public static notRequireFields = (container: HTMLElement): void => {
        setTimeout((): void => {
            const fields: NodeListOf<HTMLInputElement> = container.querySelectorAll(`[${FormUtils.DEFAULTS.REQUIRED_ATTRIBUTE}]`);

            fields.forEach(field => {
                // field can be a radio for which getMDCObjectForFormField returns void but this is intended so turn off error log
                field.setAttribute(FormUtils.DEFAULTS.NOT_REQUIRED_ATTRIBUTE, '')
                field.removeAttribute(FormUtils.DEFAULTS.REQUIRED_ATTRIBUTE);

                const mcdObject = MaterialDesignComponentHandler.getMDCObjectForFormField(field, false);
                if (mcdObject) {
                    mcdObject.valid = true;
                    mcdObject.getDefaultFoundation().setValid(true);
                }
            })
        }, 10);
    }

    public static requireFields = (container: HTMLElement): void => {
        const fields: NodeListOf<HTMLFormElement> = container.querySelectorAll(`[${FormUtils.DEFAULTS.NOT_REQUIRED_ATTRIBUTE}]`);
        fields.forEach(field => {
            field.removeAttribute(FormUtils.DEFAULTS.NOT_REQUIRED_ATTRIBUTE);
            field.setAttribute(FormUtils.DEFAULTS.REQUIRED_ATTRIBUTE, 'true');
        })
    }

    public static isInputFieldValueChanged = (inputField: HTMLInputElement): boolean => (inputField.getAttribute('value') !== inputField.value);

    public static isAnyInputFieldValueChanged = (inputFields: HTMLInputElement[]): boolean => inputFields.some(inputField => FormUtils.isInputFieldValueChanged(inputField));

    public static setInputFieldValue = (inputField: HTMLInputElement, text: string): void => {
        const mdcObject = MaterialDesignComponentHandler.getMDCTextField(inputField);
        if (!mdcObject) {
            return;
        }
        mdcObject.value = text;
    }

    public static enableSelectField = (selectFormField: HTMLElement): void => {
        const field = selectFormField instanceof HTMLSelectElement ? selectFormField : selectFormField.querySelector('select');
        const mdcSelect = MaterialDesignComponentHandler.getMDCSelect(field);

        if (!mdcSelect) {
            return;
        }

        mdcSelect.disabled = false;
    }

    public static disableSelectField = (selectFormField: HTMLElement): void => {
        const field = selectFormField instanceof HTMLSelectElement ? selectFormField : selectFormField.querySelector('select');
        const mdcSelect = MaterialDesignComponentHandler.getMDCSelect(field);

        if (!mdcSelect) {
            return;
        }

        mdcSelect.selectedIndex = -1;
        mdcSelect.disabled = true;
    }

    public static unselectSelectField = (selectFormField: HTMLElement): void => {
        FormUtils.setSelectedIndex(selectFormField.querySelector('select'), -1);
    }

    public static setSelectedIndex = (selectElement: HTMLSelectElement, index: number): void => {
        const mdcSelect = MaterialDesignComponentHandler.getMDCSelect(selectElement);

        if (!mdcSelect) {
            return;
        }

        mdcSelect.selectedIndex = index;
    }

    public static setSelectValue = (selectElement: HTMLSelectElement, value) => {
        const mdcSelect = MaterialDesignComponentHandler.getMDCSelect(selectElement, false);
        if (mdcSelect) {
            mdcSelect.value = value;
        }
    }

    public static removeSelectOptions = (selectFormField: HTMLSelectElement, callback?: () => void): void => {
        // going from large to small, because we remove to fast
        for (let i = selectFormField.options.length - 1; i > 0; i--) {
            if (!selectFormField.options[i].value) {
                continue;
            }
            selectFormField.options[i] = null;
        }
        FormUtils.disableSelectField(selectFormField);

        if (callback) {
            callback();
        }
    }

    public static addSelectOptions = (selectFormField: HTMLSelectElement, options: any): void => {
        options.forEach((option: any): void => {
            const optionElement: HTMLOptionElement = document.createElement('option');
            optionElement.value = option.value;
            optionElement.innerText = option.title;
            selectFormField.options.add(optionElement);
        });
    }

    public static setFormFieldLoading = (formElement: HTMLSelectElement | HTMLInputElement, isLoading: boolean): void => {
        if (isLoading) {
            const loadingContainer: HTMLElement = document.createElement('div');
            loadingContainer.classList.add('loading-animation');
            loadingContainer.classList.add('absolute-positioned');
            formElement.parentElement.appendChild(loadingContainer);
        } else {
            const loadingContainer: HTMLElement = formElement.parentElement.querySelector('.loading-animation');
            if (loadingContainer) {
                loadingContainer.remove();
            }
        }
    }

    public static getFieldType(field: HTMLElement): string {
        return (field.classList.length > 0) ? field.classList[0].split('__')[0] : '';
    }

    public static hideFormField = (formElement: HTMLInputElement | HTMLSelectElement) => {
        if (formElement instanceof HTMLInputElement) {
            formElement.closest('.mdc-text-field').parentElement.classList.add('hide');
        }
        if (formElement instanceof HTMLSelectElement) {
            formElement.closest('.mdc-select').parentElement.classList.add('hide');
        }
    }

    public static showFormField = (formElement: HTMLInputElement | HTMLSelectElement) => {
        if (formElement instanceof HTMLInputElement) {
            formElement.closest('.mdc-text-field').parentElement.classList.remove('hide');
        }
        if (formElement instanceof HTMLSelectElement) {
            formElement.closest('.mdc-select').parentElement.classList.remove('hide');
        }
    }

    public static disableAllFormFields = (formElement: HTMLElement): void => {
        formElement.querySelectorAll('input').forEach((inputElement: HTMLInputElement) => {
            FormUtils.disableInputField(inputElement);
        });
        formElement.querySelectorAll('select').forEach((selectElement: HTMLSelectElement) => {
            FormUtils.disableSelectField(selectElement);
        });
        formElement.querySelectorAll(FormUtils.DEFAULTS.MDC_RADIO_INPUT_SELECTOR).forEach((formElement: HTMLInputElement) => {
            FormUtils.disableRadioButton(formElement);
        });
    }

    public static disableInputField = (inputElement: HTMLElement): void => {
        const field = inputElement instanceof HTMLInputElement ? inputElement : inputElement.querySelector('input');
        const mdcTextField = MaterialDesignComponentHandler.getMDCTextField(field);

        if (!mdcTextField) {
            return;
        }

        mdcTextField.disabled = true;
    }

    public static enableInputField = (inputElement: HTMLElement): void => {
        const field = inputElement instanceof HTMLInputElement ? inputElement : inputElement.querySelector('input');
        const mdcTextField = MaterialDesignComponentHandler.getMDCTextField(field);

        if (!mdcTextField) {
            return;
        }

        mdcTextField.disabled = false;
    }

    public static resetForm = (formElement: HTMLFormElement): void => {
        formElement.querySelectorAll('.form-field')
            .forEach((element: HTMLElement) => {
                FormUtils.clearValueInputField(element);
            });
        formElement.reset();
    }

    public static clearValueInputField = (inputElement: HTMLElement): void => {
        const field: HTMLElement = inputElement instanceof HTMLInputElement ? inputElement : inputElement.querySelector('input, textarea');
        const mdcTextField = MaterialDesignComponentHandler.getMDCTextField(field, false);

        if (!mdcTextField) {
            return;
        }

        mdcTextField.getDefaultFoundation().setValue('');
        mdcTextField.getDefaultFoundation().setValid(true);
    }

    private static DEFAULTS = {
        ACTIVE: 'is-active',
        DISABLED_CLASS: 'disabled',
        DISABLED_ATTRIBUTE: 'disabled',
        FORM_FIELD_DISABLED_CLASS: 'mdc-form-field--disabled',
        MDC_RADIO_INPUT_SELECTOR: '.mdc-radio > input',
        NOT_REQUIRED_ATTRIBUTE: 'not-required',
        REQUIRED_ATTRIBUTE: 'required',
    };
}
