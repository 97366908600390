import register from 'ShopUi/app/registry';
import './shop-switcher.scss';
import { importWithFallback } from 'PyzShopUi/scripts/utils/importWithFallback';

export default register('shop-switcher', () =>
    importWithFallback(
        /* webpackMode: "lazy" */
        () => import('./shop-switcher')
        ),
);
