import * as React from 'react';
import { MdcTabBar } from '../../shop-ui/components/mdc-tab/MdcTabBar';
import { IMdcTabItem } from '../../shop-ui/interfaces';
import { ITab } from '../interfaces';

interface IMyLouisOrdersTabBarProps {
    tabs: ITab[];
    loadingElement: HTMLElement;
}

export const MyLouisOrdersTabBar: React.FunctionComponent<IMyLouisOrdersTabBarProps> = props => {
    const { tabs, loadingElement } = props;
    const [activeTab, setActiveTab] = React.useState('');

    React.useEffect(() => {
        loadingElement.classList.add('hide');
        setActiveTab(tabs[0]?.value);
    }, []);

    React.useEffect(() => {
        tabs.forEach((tab: ITab) => {
            if (tab.value === activeTab) {
                tab.element.classList.remove('hide');
            } else {
                tab.element.classList.add('hide');
            }
        });
    }, [activeTab]);

    const onTabClickHandler = (
        tabName: string,
        event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
        if (event.ctrlKey) {
            return;
        }

        event.preventDefault();
        setActiveTab(tabName);
    };

    const generateTabComponents = (): IMdcTabItem[] => tabs.map(tab => {
        const { label, value } = tab;

        return {
            value,
            url: '',
            content: (
                <>
                    <div className="tab-item-header heading4">{label}</div>
                </>
            )
        };
    });

    return (
        <div className="mylouis-orders-tab-container">
            <MdcTabBar
                additionalClassNames="mylouis-orders-tab thin-line"
                onTabClick={onTabClickHandler}
                selectedTab={activeTab}
                tabs={generateTabComponents()}
            />
        </div>
    );
}
