import * as React from 'react';
import FootnoteService from 'PyzShopUi/scripts/shop-ui/services/FootnoteService';

interface FootnoteNumberProps {
    identifier: string;
    namespace: string;
}

const FootnoteNumber: React.FunctionComponent<FootnoteNumberProps> = props => {
    const { identifier, namespace } = props;

    const footnoteServiceInstance = React.useRef(FootnoteService.getInstance());

    return (
        <React.Fragment>
            {
                footnoteServiceInstance
                    .current
                    .getFootnoteNumber(identifier, namespace)
            }
        </React.Fragment>
    );
};

export default FootnoteNumber;
