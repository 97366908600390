import Component from 'ShopUi/models/component';
import { setFixedUpdateConsentState, isConsentModeUsed } from 'PyzShopUi/scripts/shop-ui/services/GoogleConsentModeService';
import { setCookieAcceptedValue, injectGtmScript, triggerSsrEvents } from 'PyzShopUi/scripts/shop-ui/services/GoogleTagManagerService';

const DECLINE_BUTTON_ID = 'cookie-notice-decline';
const ACCEPT_BUTTON_ID = 'cookie-notice-accept';
export const CONSENT_CHANGED_EVENT_NAME = 'louis_consent-changed';

function triggerConsentChangedEvent(): void {
    window.dispatchEvent(new Event(CONSENT_CHANGED_EVENT_NAME));
}

export function handleAcceptAll() {
    setCookieAcceptedValue(true);

    if (isConsentModeUsed()) {
        setFixedUpdateConsentState('granted');
    } else {
        // before the user accepted cookies
        // if consent mode is not used, the GTM Script Tag hasn't been injected
        injectGtmScript();
    }

    triggerSsrEvents();
    triggerConsentChangedEvent();
}

export default class CookieNotice extends Component {
    protected readyCallback(): void {
    }

    acceptCookies() {
        handleAcceptAll();
        this.remove();
    }

    declineCookies() {
        setCookieAcceptedValue(false);
        triggerConsentChangedEvent();
        this.remove();
    }

    init() {
        const acceptButton = document.getElementById(ACCEPT_BUTTON_ID);
        const declineButton = document.getElementById(DECLINE_BUTTON_ID);

        // the .bind(this) makes "this" refer to the class this within the callback
        acceptButton.addEventListener('click', this.acceptCookies.bind(this));
        declineButton.addEventListener('click', this.declineCookies.bind(this));
    }
}
