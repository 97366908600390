import * as React from 'react';
import Label from '../Label';
import SelectOptions from './options/SelectOptions';
import { ISelectOption } from '../../interfaces';
import Utils from 'PyzShopUi/scripts/utils/utils';

interface ISelectProps {
    label?: string;
    options: ISelectOption[];
    selectedValue?: string;
    onChange?(value: string): void;
}

const NativeSelect: React.FunctionComponent<ISelectProps> = props => {
    const { label, options, onChange, selectedValue } = props;

    const onSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    }
    const id = 'select-' + Utils.getRandomId();
    return (
        <div className='native-select'>
            <Label label={label} htmlFor={id}/>
            <select
                className='selection'
                onChange={onSelectChange}
                id={id}
                value={selectedValue === null ? undefined : selectedValue}
            >
                <SelectOptions options={options} />
            </select>

            <i className='icon icon-chevron' />
        </div>
    );
};

NativeSelect.defaultProps = {
    label: '',
    selectedValue: '',
};

export default NativeSelect;
