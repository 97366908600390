import * as React from 'react';
import { Subject } from 'rxjs';
import classNames from 'classnames';
import ManufacturerFilterOptions from './ManufacturerFilterOptions';
import SearchInput from '../../../../../react/common/components/form/SearchInput';
import Accordion from '../../../../../shop-ui/components/Accordion';
import useGradient from '../../../../../shop-ui/components/search-page/hooks/useGradient';
import useSingleSelectFilter from '../../../../../shop-ui/components/search-page/hooks/useSingleSelectFilter';
import { IFilterProps } from '../../../../../shop-ui/components/search-page/interfaces';
import useSearchable from '../../../../../shop-ui/components/search-page/hooks/useSearchable';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

const searchInputObserver = new Subject<string>();

const ManufacturerFilter: React.FunctionComponent<IFilterProps> = props => {
    const sortedOptions = props.options.sort((option, nextOption) =>
        option.label.toLowerCase().localeCompare(nextOption.label.toLowerCase()),
    );

    const [options, onSearchInputChange, displayNoMatchText] = useSearchable(sortedOptions, searchInputObserver);
    const [isScrollable, onGradientChange, displayTopGradient, displayBottomGradient] = useGradient(options);

    const selectedOptions = props.options
        .filter(option => option.selected)
        .sort((option, nextOption) =>
            option.label.toLowerCase().localeCompare(nextOption.label.toLowerCase()),
        );

    const [onToggleItem, displaySelectedFilterOptions] = useSingleSelectFilter(props.name, selectedOptions, props.dispatch);

    const additionalClassNames = classNames(
        { scrollable: isScrollable },
    );

    const { translate } = useTranslationHook();

    return (
        <Accordion
            label={translate('Manufacturer', { ns: 'search-page-filter'})}
            headerContent={displaySelectedFilterOptions()}
            hideHeaderContentOnCollapsed={true}
            isScrollToCurrentOnCollapsed={true}
        >
            <div className={`manufacturer-filter`}>
                <SearchInput hasLabel={true} isFullWidth={true} onSearchChange={onSearchInputChange} inputProps={{ maxLength: 50 }}/>
                {displaySelectedFilterOptions()}
                <div className={`scroll-selection`}>
                    <ManufacturerFilterOptions
                        additionalClassName={additionalClassNames}
                        optionList={options}
                        onScrollChange={onGradientChange}
                        onToggleItem={onToggleItem}
                        isKeyboardFocusable={isScrollable}
                    />

                    {displayTopGradient()}
                    {displayBottomGradient()}
                </div>
                {displayNoMatchText()}
            </div>
        </Accordion>
    );
};

export default ManufacturerFilter;
