import * as React from 'react';
import {useTranslationHook} from 'PyzShopUi/scripts/utils/translationHook';

interface ILargeScreenLayoutProps {
    isVisible: boolean;

    onClose(): void;
}

export const LargeScreenLayout: React.FunctionComponent<ILargeScreenLayoutProps> = props => {
    const { isVisible, children, onClose } = props;
    const { translate } = useTranslationHook();
    const searchInput = document.getElementById("search");

    if (!isVisible) {
        return null;
    }

    const clearResults = (event) => {
        if (event.key === 'Enter') {
            event.stopPropagation();
            event.preventDefault();
            searchInput.value = '';
            searchInput.focus();
            onClose();
        }
    };

    const blurHandler = () => {
        searchInput.focus();
    };

    return (
        <div className='large-screen-suggest-result'>
            <div onClick={() => onClose()} className='large-screen-suggest-result__background' />
            <div className='large-screen-suggest-result__result-board'>
                {children}
            </div>
            <nav className="clear-results-nav" aria-label="{{ 'Accessibility navigation'|trans }}">
                <div id="clear-results-link"
                     className="skip-to-main-content-link"
                     onKeyDown={clearResults}
                     onBlur={blurHandler}
                     role="button"
                     tabIndex={0}>
                    {translate('cart.vouchers.clear-all', {ns: 'spryker-core'})}
                </div>
            </nav>
        </div>
    );
};
