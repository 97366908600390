import { FilterNames } from '../../constants';
import { IFilterStateHandler, ISelectFilterOption, IRangeFilter } from '../../../shop-ui/components/search-page/interfaces';
import AbstractRangeFilterHandler from '../../../shop-ui/includes/filter-state/AbstractRangeFilterHandler';
import SelectedPriceRangeFilterLabel from '../../components/sidebar/SelectedPriceRangeFilterLabel';

export default class PriceFilterHandler extends AbstractRangeFilterHandler implements IFilterStateHandler {
    public createQueryParams = (filter: IRangeFilter, params: object): void => {
        const roundedMax = Math.ceil(filter.max / 100);
        const roundedMin = Math.floor(filter.min / 100);
        const roundedActiveMax = Math.ceil(filter.activeMax / 100);
        const roundedActiveMin = Math.floor(filter.activeMin / 100);

        if (roundedActiveMax !== roundedMax || roundedActiveMin !== roundedMin) {
            params[FilterNames.PRICE + '[min]'] = roundedActiveMin.toString();
            params[FilterNames.PRICE + '[max]'] = roundedActiveMax.toString();

            return;
        }

        params[FilterNames.PRICE + '[min]'] = null;
        params[FilterNames.PRICE + '[max]'] = null;
    }

    public resetQueryParams = (params: object): void => {
        params[FilterNames.PRICE + '[min]'] = null;
        params[FilterNames.PRICE + '[max]'] = null;
    }

    public getSelectedOptions = (_filter: IRangeFilter): ISelectFilterOption[] => []

    public updateSelectedOptions = (filter: IRangeFilter, selectedValues: number[]): void => {
        filter.activeMin = selectedValues[0] ? selectedValues[0] * 100 : filter.min;
        filter.activeMax = selectedValues[1] ? selectedValues[1] * 100 : filter.max;
    }

    public getSelectionTagComponent = (filter: IRangeFilter): React.FunctionComponent | null => {
        const roundedMax = Math.ceil(filter.max / 100);
        const roundedMin = Math.floor(filter.min / 100);
        const roundedActiveMax = Math.ceil(filter.activeMax / 100);
        const roundedActiveMin = Math.floor(filter.activeMin / 100);

        if (roundedActiveMax === roundedMax && roundedActiveMin === roundedMin) {
            return null;
        }

        return SelectedPriceRangeFilterLabel;
    }
}
