import * as React from 'react';
import { TooltipEvent } from '../events/tooltipEvent';
import MiddlewareEventBus from '../lib/MiddlewareEventBus';
import classNames from 'classnames';

export interface ITooltipProps {
    content: string | React.ReactNode;
    title?: string;
    id?: string;
    isDisplayInline?: boolean;
    isHoverEnabled?: boolean;
    isInitRequired?: boolean;
    showInfoIcon?: boolean;
    showCloseIcon?: boolean;
    icon?: string;
    iconSize?: string;
    iconStyle?: 'default' | 'success' | 'warning' | 'error';
    tooltipText?: string;
    textId?: string;
}

const Tooltip: React.FunctionComponent<React.PropsWithChildren<ITooltipProps>> = props => {
    const { title, content, isHoverEnabled, isInitRequired, children, showInfoIcon, showCloseIcon, icon, iconSize, iconStyle, id, isDisplayInline, tooltipText, textId } = props;

    const middlewareEventBus: MiddlewareEventBus = MiddlewareEventBus.getInstance();
    const tooltipRef = React.useRef(null);

    React.useEffect(() => {
        if (null === tooltipRef.current) {
            return;
        }

        middlewareEventBus.publish({ type: TooltipEvent.INIT, payload: { element: tooltipRef.current } });
    }, [tooltipRef]);

    const tooltipContainerClass = classNames(
        'tooltip-container',
        {
            'tooltip-inline': isDisplayInline,
        }
    );

    const displayIcon = React.useCallback(() => {
        if (!showInfoIcon) {
            return null;
        }

        const tooltipTextId = `tooltipText-${id}`;
        const tooltipLabel = title || tooltipTextId;
        const tooltipLablledBy = textId || tooltipTextId;

        const iconClass = classNames(
            'icon tooltip-icon',
            {
                [`icon-${icon}`]: icon !== undefined,
                [`icon-size-${iconSize}`]: iconSize !== undefined,
                [`icon-color-${iconStyle}`]: iconStyle !== undefined,
            }
        );

        return <span className={iconClass} data-tooltip={id} tabIndex={0} role="button" {...(tooltipText || textId ? {"aria-labelledby": tooltipLablledBy} : {"aria-label": tooltipLabel} )} />;
    }, [showInfoIcon, id]);

    const displayText = React.useCallback(() => {
        if (!tooltipText) {
            return null;
        }

        return <span className={'tooltip-text tooltip-icon'} data-tooltip={id} id={`tooltipText-${id}`}>{tooltipText}</span>
    }, [tooltipText, id]);

    const displayTooltipHeader = React.useCallback(() => {
        if (!title) {
            return null;
        }

        const displayTitle = React.useMemo(() => !title ? null : <div className='tooltip-headline heading4'>{title}</div>, [title]);
        const displayCloseIcon = React.useMemo(() => !showCloseIcon ? null : <span className='icon icon-close tooltip-close' />, [showCloseIcon]);

        return (
            <div className='tooltip-header'>
                {displayTitle}{displayCloseIcon}
            </div>
        );
    }, [title, showCloseIcon]);

    const displayCloseButtonWithoutHeader = React.useCallback(() => {
        if (title || !showCloseIcon) {
            return null;
        }
        return (<span className="icon icon-close tooltip-close float-right"/>);
    }, [title, showCloseIcon]);

    return (
        <div className={tooltipContainerClass} ref={tooltipRef}>
            <div
                className={`tooltip`}
                data-tooltip-hover={`${isHoverEnabled}`}
                data-tooltip-position-init={`${isInitRequired}`}
            >
                {displayIcon()}
                {displayText()}
                {children}
                <div id={id} className='tooltip-content'>
                    <span className='tooltip-arrow' />
                    {displayTooltipHeader()}
                    <div className='tooltip-info'>
                        {displayCloseButtonWithoutHeader()}
                        {content}
                    </div>
                </div>
            </div>
        </div>
    );
};

Tooltip.defaultProps = {
    id: 'tooltip-' + new Date().getUTCMilliseconds(),
    isDisplayInline: true,
    isHoverEnabled: true,
    isInitRequired: false,
    showInfoIcon: true,
    showCloseIcon: true,
    icon: 'info',
    iconStyle: 'default',
    tooltipText: null,
};

export default Tooltip;
