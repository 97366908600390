export const CONSENT_TYPES = [
    'ad_storage',
    'analytics_storage',
    'functionality_storage',
    'personalization_storage',
    'security_storage',
    'ad_user_data',
    'ad_personalization'
] as const;
export type GoogleConsentType = typeof CONSENT_TYPES[number];

export const CONSENT_STATE_VALUES = ['denied', 'granted'] as const;
export type GoogleConsentStateValue = typeof CONSENT_STATE_VALUES[number];

export type GoogleConsentState = Record<GoogleConsentType, GoogleConsentStateValue>;

export const CONSENT_STATE_ITEM_TYPES = ['default', 'update'] as const
export type GoogleConsentStateItemType = typeof CONSENT_STATE_ITEM_TYPES[number];

export const CONSENT_ITEM_KEYWORD = 'consent' as const
// this is how you can set a default consent state or update the consent state
export type GoogleConsentStateItem = [typeof CONSENT_ITEM_KEYWORD, GoogleConsentStateItemType, Partial<GoogleConsentState>]

// for these domains, we don't use consent mode
const DOMAINS_WITHOUT_CONSENT_MODE = ['.com', '.eu', '.biz'];

const isConsentStateEntry = (x: unknown): x is GoogleConsentStateItem => {
    // the consent state item is not necessarily an array as it's an Arguments object in the
    // Google example code (https://developers.google.com/tag-platform/devguides/consent#implementation_example)
    if (!(x instanceof Object) || !x.hasOwnProperty('length') || (x as {length: number}).length !== 3) {
        return false;
    }

    const [keyword, type, state] = x as GoogleConsentStateItem;

    if (keyword !== CONSENT_ITEM_KEYWORD) {
        return false;
    }

    if (!CONSENT_STATE_ITEM_TYPES.includes(type)) {
        return false;
    }

    return Object.entries(state).every(
        ([key, value]: [GoogleConsentType, GoogleConsentStateValue]) =>
            CONSENT_TYPES.includes(key) && CONSENT_STATE_VALUES.includes(value)
    )
}

/**
 * This function returns true if the user consented to optional cookies
 * Use this instead of checking if window.dataLayer is truthy as this is always the
 * case in Consent Mode 
 */
export const isTrackingConsentGiven = () => {
    const consentItem = findConsentItemInDataLayer();

    if (!consentItem) {
        return false;
    }

    const state = consentItem[2];

    return Object.values(state).every(value => value === 'granted');
}

const setConsentState = (itemType: GoogleConsentStateItemType, state: Partial<GoogleConsentState>) => {
    if (!window.dataLayer || !window.gtag) {
        return;
    }

    window.gtag(CONSENT_ITEM_KEYWORD, itemType, state);
} 

/**
 * This function writes a given consent state as default state or update in window.dataLayer
 */
export const setDefaultConsentState = (state: GoogleConsentState) => 
    setConsentState('default', state);


export const setUpdateConsentState = (state: Partial<GoogleConsentState>) =>
    setConsentState('update', state);


/**
 * Convinience wrapper to set all consent types to either "granted" or "denied"
 */
export const setFixedUpdateConsentState = (value: GoogleConsentStateValue) => setUpdateConsentState({
    ad_storage: value,
    analytics_storage: value,
    functionality_storage: value,
    personalization_storage: value,
    security_storage: value,
    ad_personalization: value,
    ad_user_data: value
})

/**
 * Convinience wrapper to set all consent types to either "granted" or "denied"
 */
export const setFixedDefaultConsentState = (value: GoogleConsentStateValue) => setDefaultConsentState({
    ad_storage: value,
    analytics_storage: value,
    functionality_storage: value,
    personalization_storage: value,
    security_storage: value,
    ad_personalization: value,
    ad_user_data: value
})

const findConsentItemInDataLayer = () => {
    if (!window.dataLayer) {
        return;
    }

    // create a reverse copy of the array so that the first consent statement that
    // is found within the array is the most recent one
    const reversedDataLayer = window.dataLayer.slice().reverse();

    return reversedDataLayer.find(isConsentStateEntry)
}

export const isConsentModeUsed = () => {
    const domainOnBlacklist = DOMAINS_WITHOUT_CONSENT_MODE.some(
        domainEnding => window.location.host.includes(domainEnding)
    );

    // note the !
    return !domainOnBlacklist;
}
