import * as React from 'react';
import FormattedCurrency from '../../../react/common/components/FormattedCurrency';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';
import { withMemo } from 'PyzShopUi/scripts/react/common/hoc/withMemo';

interface IProductBasicPriceProps {
    unitName: string;
    unitAmount: number;
    unitValue: number;
    currency: string;
    displayLabel?: boolean;
}

const ProductBasicPriceComponent: React.FunctionComponent<IProductBasicPriceProps> = props => {
    const { unitName, unitAmount, unitValue, currency, displayLabel } = props;
    const { translate } = useTranslationHook();

    return (
        <div className='product-basic-price'>
            <span>{displayLabel ? `${translate('Baseprice', {ns:'article-list'})}: ` : undefined }
                ({unitAmount + ' ' + unitName} = {<FormattedCurrency price={unitValue / 100} currency={currency}/>})</span>
        </div>
    );
};

const ProductBasicPrice = withMemo(ProductBasicPriceComponent);

export default ProductBasicPrice;
