import * as interfaces from './../interfaces';
import { isTrackingConsentGiven } from 'PyzShopUi/scripts/shop-ui/services/GoogleConsentModeService';


(window as any).global = window;

export const EECproductClickWithDataToken = (event: interfaces.IProductClickEvent) => {
    if (!isTrackingConsentGiven()) {
        return;
    }

    const eecEvent: interfaces.IEECEcommerceEventEnvelope = {
        event: interfaces.EECEventType.PRODUCT_CLICK,
        ecommerce: {
            click: event.payload
        }
    };

    window.dataLayer.push(eecEvent);
};
