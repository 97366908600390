import * as React from 'react';
import CheckBox from './Checkbox';
import classNames from 'classnames';

interface ICheckboxListItemProps {
    dataQa?: string;
    label: string;
    value: string;
    isSelected: boolean;
    additionalWrapperClassName?: string;
    labelDataQa?: string;

    onClick(value: string, isSelected: boolean): void;
}

const CheckboxListItem: React.FunctionComponent<ICheckboxListItemProps> = props => {
    const { dataQa, label, value, isSelected, additionalWrapperClassName, labelDataQa, onClick } = props;

    const itemClassNames = classNames(
        'mdc-checkbox-wrapper',
        additionalWrapperClassName,
        {
            'mdc-checkbox-wrapper--selected': isSelected
        },
    );

    return (
        <li
            onClick={(event) => {
                event.preventDefault();
                onClick(value, isSelected);
            }}
            className={itemClassNames}
            data-qa={dataQa}
        >

            <CheckBox
                id={label + '_' + value}
                checked={isSelected}
            />

            <label
                htmlFor={label + '_' + value}
                data-qa={labelDataQa}>
                {label}
            </label>
        </li>
    )
};

CheckboxListItem.defaultProps = {
    additionalWrapperClassName: '',
};

export default CheckboxListItem;
