import { EProductType } from 'PyzShopUi/scripts/shop-ui/constants';
import { AbstractProductWishList } from 'PyzShopUi/scripts/product-wish-list/includes/AbstractProductWishList';
import { ConcreteProductWishList } from 'PyzShopUi/scripts/product-wish-list/includes/ConcreteProductWishList';
import { ProductWishListInterface } from 'PyzShopUi/scripts/product-wish-list/includes/ProductWishListInterface';
import { IProductWishListResponse } from 'PyzShopUi/scripts/product-wish-list/interfaces';
import { productWishListService } from 'PyzShopUi/scripts/product-wish-list/services/ProductWishListService';

export const createProductWishListByType = (type: string, sku: string): ProductWishListInterface => {
    switch (type) {
        case EProductType.PRODUCT_ABSTRACT:
            return new AbstractProductWishList(sku);
        case EProductType.PRODUCT_CONCRETE:
            return new ConcreteProductWishList(sku);
    }
};

export const getRemoveProductServiceFunctionByType = (type: string, sku: string): Promise<IProductWishListResponse> => {
    switch (type) {
        case EProductType.PRODUCT_ABSTRACT:
            return productWishListService.removeProductAbstract(sku);
        case EProductType.PRODUCT_CONCRETE:
            return productWishListService.removeProductConcrete(sku);
    }
};
