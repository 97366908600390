import * as React from 'react';
import { ISearchSuggestionProduct, ISearchSuggestionProductItem } from '../../../interfaces';
import SuggestionProductItem from './SuggestionProductItem';
import { useTranslationHook } from 'PyzShopUi/scripts/utils/translationHook';

interface ISuggestionProductListProps {
    data: ISearchSuggestionProduct;
    searchValue?: string;
}

const SuggestionProductList: React.FunctionComponent<ISuggestionProductListProps> = props => {
    const { data, searchValue } = props;
    const { translate } = useTranslationHook();

    if (!data || !data.items.length) {
        return null;
    }

    const displaySuggestionItems = data.items.map((item: ISearchSuggestionProductItem, index: number) => (
        <div key={index} className={`search-suggestions-item`}>
            <SuggestionProductItem product={item} />
        </div>
    ));

    const displayShowAllLink = React.useCallback(() => {
        if (data.total <= data.items.length) {
            return null;
        }

        const newUrl = new URL(data.link, window.location.origin);
        newUrl.searchParams.set('q', searchValue);

        return (
            <a href={newUrl.toString()} className="search-suggestion-show-all-link">
                {translate('Show all products', { ns: 'search'})}
            </a>
        );
    }, [data.total, data.items.length]);

    return (
        <div className={`search-suggestions-column-wrapper search-suggestions-column-wrapper-product`}>
            <h3>{data.name}</h3>
            {displaySuggestionItems}
            {displayShowAllLink()}
        </div>
    );
};

SuggestionProductList.defaultProps = {
    searchValue: ''
};

export default SuggestionProductList;
